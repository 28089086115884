import { readonly, ref, watch } from 'vue';
import { useRoute } from 'vue-router/composables';
import { useI18n } from 'vue-i18n-bridge';
import languages from '@/components/shared/locales/languages';

const appTitle = process.env.VUE_APP_TITLE;

const title = ref(appTitle);

export function useRouteTitle() {
  const route = useRoute();

  const { t } = useI18n({ locale: 'en', messages: languages });

  const syncRouteTitle = () => {
    watch(
      () => route.matched,

      (matched) => {
        const titleKey = [...matched]
          .reverse()
          .find((entry) => entry.meta?.titleKey)?.meta.titleKey;

        let pageTitle = null;

        if (titleKey) {
          pageTitle = t(titleKey);

          // Ignore if no translation exists for the key
          if (pageTitle === titleKey) {
            pageTitle = null;
          }
        }

        const fullTitle = [appTitle, pageTitle].filter(Boolean).join(' - ');

        title.value = fullTitle;

        document.title = fullTitle;
      },

      { immediate: true }
    );
  };

  return {
    title: readonly(title),
    syncRouteTitle,
  };
}
