import { render, staticRenderFns } from "./empty-list-message.vue?vue&type=template&id=7c0f25d7&scoped=true&"
import script from "./empty-list-message.vue?vue&type=script&setup=true&lang=js&"
export * from "./empty-list-message.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./empty-list-message.vue?vue&type=style&index=0&id=7c0f25d7&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c0f25d7",
  null
  
)

export default component.exports