<template>
  <div data-testid="combobox-input">
    <p class="custom-label mb-1">{{ label }}</p>
    <v-combobox
      dense
      outlined
      multiple
      :value="value"
      :items="items"
      :rules="rules"
      :item-text="itemText"
      :item-value="itemValue"
      :disabled="disabled"
      :clearable="clearable"
      :placeholder="placeholder"
      :background-color="disabled ? 'ashRegular' : 'white'"
      @change="emitUserInput($event)"
      @click:clear="emitUserInput('')"
    >
      <template #selection="{ item, index }">
        {{ getSelectedItemsLabel(item, index) }}
      </template>
      <template #item="{ item, attrs, on }">
        <v-list-item
          v-on="on"
          v-bind="attrs"
          v-slot="{ active }"
          @click="handleSelectedItem(item)"
        >
          <v-list-item-action>
            <v-checkbox :input-value="active"></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <v-row no-gutters align="center">
                <span>{{ item.text }}</span>
              </v-row>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';

const emit = defineEmits(['click', 'change', 'input']);

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    required: false,
    default: '',
  },
  items: {
    type: Array,
    required: false,
    default: () => [],
  },
  itemText: {
    type: String,
    required: false,
    default: 'text',
  },
  itemValue: {
    type: String,
    required: false,
    default: 'value',
  },
  value: {
    type: null,
    required: true,
  },
  rules: {
    type: Array,
    required: false,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  clearable: {
    type: Boolean,
    required: false,
    default: true,
  },
});

// --- Local variables ---
const selectedItem = ref({});

// --- Methods ---
const handleSelectedItem = (item) => {
  selectedItem.value = item;
};

const getSelectedItemsLabel = (item, index) => {
  const comma = index === props.value?.length - 1 ? '' : ', ';
  const selectedItem = props.items.find((i) => i.value === item.value);
  return selectedItem?.text ? `${selectedItem?.text}${comma}` : '';
};

const emitUserInput = (array) => {
  let payload = { array, lastSelection: selectedItem.value };
  // Click event for both the full array and last selected item
  emit('click', payload);
  // Change event for full array only
  emit('change', array);
  emit('input', array);
};
</script>

<style scoped lang="scss">
.custom-label {
  font-size: 13px;
}

.v-input--is-focused ::v-deep fieldset {
  border: 1px solid $blue-regular !important;
}

.v-text-field--outlined ::v-deep fieldset {
  border-color: $ash-dark;
}
</style>
