<template>
  <div data-testid="dropdown-input">
    <p class="custom-label mb-1">{{ label }}</p>
    <v-select
      dense
      outlined
      :value="value"
      :items="items"
      :rules="rules"
      :item-text="itemText"
      :item-value="itemValue"
      :hide-details="hideDetails"
      :disabled="disabled"
      :clearable="clearable"
      :placeholder="placeholder"
      :background-color="disabled ? 'ashRegular' : 'white'"
      :hint="hint"
      :persistent-hint="isHintPersistent"
      :loading="loading"
      @change="emitUserInput($event)"
      @click:clear="emitUserInput('')"
    ></v-select>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const emit = defineEmits(['input', 'change']);

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  label: {
    type: String,
    required: false,
    default: null,
  },
  placeholder: {
    type: String,
    required: false,
    default: '',
  },
  hideDetails: {
    type: Boolean,
    required: false,
    default: false,
  },
  items: {
    type: null,
    required: true,
  },
  itemText: {
    type: String,
    required: false,
    default: 'text',
  },
  itemValue: {
    type: String,
    required: false,
    default: 'value',
  },
  value: {
    type: null,
    required: true,
  },
  rules: {
    type: Array,
    required: false,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  clearable: {
    type: Boolean,
    required: false,
    default: true,
  },
  hint: {
    type: String,
    required: false,
    default: '',
  },
  isHintPersistent: {
    type: Boolean,
    required: false,
    default: false,
  },
  loading: {
    type: Boolean,
    required: false,
    default: false,
  },
});

// --- Methods ---
const emitUserInput = (input) => {
  emit('input', input);
  emit('change', input);
};
</script>

<style scoped lang="scss">
.custom-label {
  font-size: 13px;
}

.v-input--is-focused ::v-deep fieldset {
  border: 1px solid $blue-regular !important;
}

.v-input--disabled ::v-deep fieldset {
  border: 1px solid $ash-dark !important;
}

.v-text-field--outlined ::v-deep fieldset {
  border-color: $ash-dark;
}
</style>
