import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useMainStore } from '@/store/pinia/main.store.js';
import { useUserStore } from '@/store/pinia/user.store.js';
import { useLoadingStore } from '@/store/pinia/loading.store.js';

export const useProfileStore = defineStore('profile', () => {
  const mainStore = useMainStore();
  const userStore = useUserStore();
  const loadingStore = useLoadingStore();

  // --- State ---
  const profileResources = ref({
    actionTypes: [],
    civilStatusTypes: [],
    compensationTypes: [],
    contractPeriodTypes: [],
    contractTypes: [],
    disabilityTypes: [],
    documentCategoryTypes: [],
    employeeStatus: [],
    healthInsuranceTypes: [],
    levelEducationTypes: [],
    paymentTypes: [],
    probationNoticePeriodTypes: [],
    probationTypes: [],
    qualificationTypes: [],
    religionTypes: [],
    requestsStatus: [],
    taskAudiance: [],
    taxClassTypes: [],
  });
  const profileHubs = ref([]);
  const profileDepartments = ref([]);
  const profileTeams = ref([]);
  const profileHubRoles = ref([]);
  const cancelDialog = ref(false);

  const profileMenuInformation = ref({
    image: null,
    fullName: '',
    qualificationType: '',
    phoneNumber: '',
    email: '',
    timeBalance: '',
    nursingTasks: '',
    mainHub: '',
    company: '',
    hubRole: '',
    workPhoneNumber: '',
    employeeNumber: '',
    startDate: '',
    licensePlate: '',
  });

  const hasContract = ref(false);
  const signatureInformation = ref(null);
  const documentsResources = ref({});

  // --- Methods ---
  const getProfileResources = async () => {
    const params = {
      endpoint: `v2/api/people/resources`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startGlobalLoading('getProfileResources');
    try {
      profileResources.value = await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endGlobalLoading('getProfileResources');
    }
  };

  const getProfileHubList = async () => {
    const params = {
      endpoint: `v2/api/people/resources/hubs`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startGlobalLoading('getProfileHubList');
    try {
      const res = await mainStore.request(params);
      profileHubs.value = res;
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endGlobalLoading('getProfileHubList');
    }
  };

  const getProfileHubRoles = async () => {
    const params = {
      endpoint: `v2/api/organizations/resources/hub-roles`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startGlobalLoading('getProfileHubRoles');
    try {
      profileHubRoles.value = await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endGlobalLoading('getProfileHubRoles');
    }
  };

  const getProfileDepartments = async () => {
    const params = {
      endpoint: `v2/api/organizations/resources/departments`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startGlobalLoading('getProfileDepartments');
    try {
      profileDepartments.value = await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endGlobalLoading('getProfileDepartments');
    }
  };

  const getProfileTeams = async () => {
    const params = {
      endpoint: `v2/api/organizations/resources/teams`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startGlobalLoading('getProfileTeams');
    try {
      profileTeams.value = await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endGlobalLoading('getProfileTeams');
    }
  };

  const getProfileMenuInformation = async (currentProfileId) => {
    const id = currentProfileId || userStore.userInfo.sub;

    const params = {
      endpoint: `v2/api/employee/${id}/`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startGlobalLoading('getProfileMenuInformation');
    try {
      const res = await mainStore.request(params);
      profileMenuInformation.value = { ...{}, ...res };
      return res;
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endGlobalLoading('getProfileMenuInformation');
    }
  };

  const getProfileContractInformation = async (currentProfileId) => {
    const id = currentProfileId || userStore.userInfo.sub;

    const params = {
      endpoint: `v2/api/employee/${id}/contracts`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startPartialLoading('getProfileContractInformation');
    try {
      const res = await mainStore.request(params);
      hasContract.value = res.length > 0;
      return res;
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('getProfileContractInformation');
    }
  };

  const uploadProfileContract = async (config) => {
    const id = config.employeeId || userStore.userInfo.sub;

    let formData = new FormData();
    formData.append('file', config.file);
    formData.append('Id', config.contractId);
    formData.append('EmployeeId', id);

    const params = {
      endpoint: `v2/api/Contract/upload-signed-contract`,
      configs: {
        method: 'POST',
        body: formData,
        headers: {},
      },
    };

    loadingStore.startGlobalLoading('uploadProfileContract');
    try {
      await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endGlobalLoading('uploadProfileContract');
    }
  };

  const getSignatureStatus = async (currentProfileId) => {
    const id = currentProfileId || userStore.userInfo.sub;

    const params = {
      endpoint: `v2/api/Contract/signature-status?employeeId=${id}`,
      configs: {
        method: 'GET',
      },
    };

    try {
      const res = await mainStore.request(params);
      signatureInformation.value = res;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getProfileMasterInformation = async (currentProfileId) => {
    const id = currentProfileId || userStore.userInfo.sub;

    const params = {
      endpoint: `v2/api/employee/${id}/personal`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startPartialLoading('getProfileMasterInformation');
    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('getProfileMasterInformation');
    }
  };

  const updateProfileMasterInformation = async (data) => {
    const id = data.currentProfileId || userStore.userInfo.sub;

    const params = {
      endpoint: `v2/api/employee/${id}/personal`,
      configs: {
        method: 'PUT',
        body: data.masterDetails,
      },
    };

    loadingStore.startGlobalLoading('updateProfileMasterInformation');
    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endGlobalLoading('updateProfileMasterInformation');
    }
  };

  const getProfileInternalInformation = async (currentProfileId) => {
    const id = currentProfileId || userStore.userInfo.sub;

    const params = {
      endpoint: `v2/api/employee/${id}/internalInfo`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startPartialLoading('getProfileInternalInformation');
    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('getProfileInternalInformation');
    }
  };

  const getProfileTeamsByDepartmentId = async (departmentId) => {
    const params = {
      endpoint: `v2/api/organizations/departments/${departmentId}/teams`,
      configs: {
        method: 'GET',
      },
    };

    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const updateProfileInternalInformation = async (data) => {
    const id = data.currentProfileId || userStore.userInfo.sub;

    const params = {
      endpoint: `v2/api/employee/${id}/internalInfo`,
      configs: {
        method: 'PUT',
        body: data.internalInformation,
      },
    };

    loadingStore.startGlobalLoading('updateProfileInternalInformation');
    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endGlobalLoading('updateProfileInternalInformation');
    }
  };

  const getProfileBankInformation = async (currentProfileId) => {
    const id = currentProfileId || userStore.userInfo.sub;

    const params = {
      endpoint: `v2/api/employee/${id}/bank`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startPartialLoading('getProfileBankInformation');
    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('getProfileBankInformation');
    }
  };

  const updateProfileBankInformation = async (data) => {
    const id = data.currentProfileId || userStore.userInfo.sub;

    const params = {
      endpoint: `v2/api/employee/${id}/bank`,
      configs: {
        method: 'PUT',
        body: data.bankDetails,
      },
    };

    loadingStore.startGlobalLoading('updateProfileBankInformation');
    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endGlobalLoading('updateProfileBankInformation');
    }
  };

  const getProfileTaxInformation = async (currentProfileId) => {
    const id = currentProfileId || userStore.userInfo.sub;

    const params = {
      endpoint: `v2/api/employee/${id}/tax`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startPartialLoading('getProfileTaxInformation');
    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('getProfileTaxInformation');
    }
  };

  const updateProfileTaxInformation = async (data) => {
    const id = data.currentProfileId || userStore.userInfo.sub;

    const params = {
      endpoint: `v2/api/employee/${id}/tax`,
      configs: {
        method: 'PUT',
        body: data.taxDetails,
      },
    };

    loadingStore.startGlobalLoading('updateProfileTaxInformation');
    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endGlobalLoading('updateProfileTaxInformation');
    }
  };

  const getProfileDocumentInformation = async (data) => {
    const id = data.currentProfileId || userStore.userInfo.sub;

    const urlencoded = new URLSearchParams();
    if (data.pagination) {
      urlencoded.append('Page', data.pagination.page);
      urlencoded.append('PageSize', data.pagination.pageSize);
      urlencoded.append('Category', data.category);
    }
    const params = {
      endpoint: `v2/api/documents/employee/${id}`,
      configs: {
        method: 'GET',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: urlencoded,
      },
    };

    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getDocumentsResources = async (employeeId) => {
    const params = {
      endpoint: `v2/api/people/resources/documents?employeeId=${
        employeeId || userStore.userInfo.sub
      }`,
      configs: {
        method: 'GET',
      },
    };

    try {
      documentsResources.value = await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const uploadProfileDocument = async (newDocument) => {
    const id = newDocument.currentProfileId || userStore.userInfo.sub;

    let formData = new FormData();
    formData.append('File', newDocument.uploadedFile);
    formData.append('EmployeeId', id);
    formData.append('Name', newDocument.description);
    formData.append('Category', newDocument.type);

    const params = {
      endpoint: `v2/api/documents`,
      configs: {
        method: 'POST',
        body: formData,
        headers: {},
      },
    };

    try {
      await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const deleteDocument = async (documentId) => {
    const params = {
      endpoint: `v2/api/documents/${documentId}`,
      configs: {
        method: 'DELETE',
      },
    };

    try {
      await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getProfileCarInformation = async (currentProfileId) => {
    const id = currentProfileId || userStore.userInfo.sub;

    const params = {
      endpoint: `v2/api/employee/${id}/vehicle`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startPartialLoading('getProfileCarInformation');
    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('getProfileCarInformation');
    }
  };

  const getProfileDownloadInformation = async (currentProfileId) => {
    const id = currentProfileId || userStore.userInfo.sub;

    const params = {
      endpoint: `v2/api/employee/${id}/downloads`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startPartialLoading('getProfileDownloadInformation');
    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('getProfileDownloadInformation');
    }
  };

  return {
    profileResources,
    profileHubs,
    profileDepartments,
    profileTeams,
    profileHubRoles,
    cancelDialog,
    profileMenuInformation,
    hasContract,
    signatureInformation,
    getProfileResources,
    getProfileHubList,
    getProfileDepartments,
    getProfileTeams,
    getProfileHubRoles,
    getProfileMenuInformation,
    getProfileContractInformation,
    uploadProfileContract,
    getSignatureStatus,
    getProfileMasterInformation,
    updateProfileMasterInformation,
    getProfileInternalInformation,
    getProfileTeamsByDepartmentId,
    updateProfileInternalInformation,
    getProfileBankInformation,
    updateProfileBankInformation,
    getProfileTaxInformation,
    updateProfileTaxInformation,
    getProfileDocumentInformation,
    uploadProfileDocument,
    deleteDocument,
    getProfileCarInformation,
    getProfileDownloadInformation,
    getDocumentsResources,
    documentsResources,
  };
});
