const service = async (params) => {
  const headers = { 'content-type': 'application/json' };
  let url = process.env.VUE_APP_LOGIN_API + '/' + params.endpoint;
  if (params.configs.body) {
    if (
      !(params.configs.body instanceof URLSearchParams) &&
      !(params.configs.body instanceof FormData)
    ) {
      params.configs.body = JSON.stringify(params.configs.body);
    } else if (params.configs.body instanceof URLSearchParams) {
      url += '?' + params.configs.body.toString();
      delete params.configs.body;
    }
  }

  if (params.configs.headers) {
    params.configs.headers = {
      ...params.configs.headers,
    };
  } else {
    params.configs.headers = {
      ...headers,
    };
  }

  params.configs.credentials = 'include';

  const response = await fetch(url, params.configs);
  let json;
  const commitHash = process.env.VUE_APP_COMMIT_HASH;
  if (!response.ok) {
    let message = `Hash: ${commitHash} - Failed request to URL: ${url} ${response.statusText} `;
    const responseText = response.statusText;
    if (responseText) {
      message += responseText;
    }
    console.log(`Api request to ${url}: ${message} ${response.status}`);
    throw response;
  }
  try {
    json = await response.json();
  } catch (e) {
    return '';
  }
  return json;
};

export default {
  service,
};
