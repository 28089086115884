import { useMainStore } from '@/store/pinia/main.store.js';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { useContractTools } from '@/helpers/composables/contract-tools.js';

export const usePeopleApplicantsStore = defineStore('peopleApplicants', () => {
  const mainStore = useMainStore();
  const loadingStore = useLoadingStore();
  const { prepareRecruitmentListData } = useContractTools();

  // --- State ---
  const showProfileIndex = ref(null);
  const applicantsList = ref({});
  const processReponsibleListObj = ref({});

  // --- Methods ---
  const createCandidate = async (payload) => {
    const params = {
      endpoint: `v2/api/recruitments/applications/${payload.applicantId}/create-candidate`,
      configs: {
        method: 'POST',
        body: payload.body,
      },
    };

    loadingStore.startGlobalLoading('createCandidate');
    try {
      await mainStore.request(params);
    } catch (error) {
      console.log(error);
    } finally {
      loadingStore.endGlobalLoading('createCandidate');
    }
  };

  const deleteApplicant = async (applicantId) => {
    const params = {
      endpoint: `v2/api/recruitments/applications/${applicantId}`,
      configs: {
        method: 'DELETE',
      },
    };

    loadingStore.startGlobalLoading('deleteApplicant');
    try {
      await mainStore.request(params);
    } catch (error) {
      console.log(error);
    } finally {
      loadingStore.endGlobalLoading('deleteApplicant');
    }
  };

  const getApplicantsList = async (payload) => {
    const urlencoded = new URLSearchParams();

    urlencoded.append('HubId', payload.pageOptions.hubId);
    urlencoded.append('Page', payload.pagination.page);
    urlencoded.append('PageSize', payload.pagination.pageSize);
    urlencoded.append('SearchPattern', payload.pageOptions.searchTerm);
    urlencoded.append('Statuses', payload.pageOptions.statuses);

    const params = {
      endpoint: 'v2/api/recruitments/applications',
      configs: {
        method: 'GET',
        body: urlencoded,
      },
    };

    loadingStore.startGlobalLoading('getApplicantsData');
    try {
      let response = await mainStore.request(params);
      applicantsList.value = prepareRecruitmentListData(response);
    } catch (error) {
      console.log(error);
    } finally {
      loadingStore.endGlobalLoading('getApplicantsData');
    }
  };

  const getHubProcessResponsibleList = async (hubId) => {
    const params = {
      endpoint: `v2/api/people/resources/hubs/${hubId}/employees`,
      configs: {
        method: 'GET',
      },
    };

    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return {
    applicantsList,
    showProfileIndex,
    getApplicantsList,
    getHubProcessResponsibleList,
    processReponsibleListObj,
    deleteApplicant,
    createCandidate,
  };
});
