import { render, staticRenderFns } from "./pending-employees.vue?vue&type=template&id=3afe2841&"
import script from "./pending-employees.vue?vue&type=script&setup=true&lang=js&"
export * from "./pending-employees.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports