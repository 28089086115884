import { render, staticRenderFns } from "./recruitment-required-documentation.vue?vue&type=template&id=2b39af4c&scoped=true&"
import script from "./recruitment-required-documentation.vue?vue&type=script&setup=true&lang=js&"
export * from "./recruitment-required-documentation.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b39af4c",
  null
  
)

export default component.exports