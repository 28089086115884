<template>
  <v-btn
    data-testid="btn-with-icon"
    :to="to"
    @click="emitUserClick"
    :width="width"
    :color="color"
    :text="onlyText"
    :disabled="disabled"
    :outlined="outlined"
  >
    <v-icon v-if="!iconRight" size="19" class="mr-1">{{ icon }}</v-icon>
    {{ text }}
    <v-icon v-if="iconRight" size="19" right class="mr-1">{{ icon }}</v-icon>
  </v-btn>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const emit = defineEmits(['click']);

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
  width: {
    type: String,
    required: false,
    default: '',
  },
  color: {
    type: String,
    required: false,
    default: '',
  },
  onlyText: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  outlined: {
    type: Boolean,
    required: false,
    default: false,
  },
  to: {
    required: false,
    type: Object,
    default: () => {},
  },
  iconRight: {
    required: false,
    type: Boolean,
    default: false,
  },
});

// --- Methods ---
const emitUserClick = () => {
  emit('click');
};
</script>

<style></style>
