<template>
  <v-row no-gutters>
    <v-col cols="12" class="d-flex align-center">
      <p
        class="section-detail greyRegular--text mr-1 mt-1"
        :class="profile ? 'profile-title' : ''"
      >
        {{ t('Msg_.signatureStatus') }}
      </p>
      <CheckboxControl
        :label="t('Msg_.employee')"
        :value="employeeSignature"
        disabled
        data-testid="digital-signature-employee-signature-checkbox"
      />
      <CheckboxControl
        label="Kenbi"
        :value="managementSignature"
        class="ml-2"
        disabled
        data-testid="digital-signature-management-signature-checkbox"
      />
    </v-col>
  </v-row>
</template>

<script setup>
import languages from '../locales/languages';
import CheckboxControl from '@/components/shared/customComponents/controls/checkbox-control.vue';
import { useI18n } from 'vue-i18n-bridge';
import { defineProps } from 'vue';

const { t } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  profile: {
    type: Boolean,
    required: false,
  },
  employeeSignature: {
    type: Boolean,
    default: false,
  },
  managementSignature: {
    type: Boolean,
    default: false,
  },
});
</script>

<style scoped lang="scss">
.profile-title {
  padding-top: 2px;
}
</style>
