import Profile from '@/components/profile/profile';
import ProfileMenu from '@/components/profile/profile-menu';
import { getLinkToKingdra } from '@/helpers/kingdra';

const profileRoutes = [
  {
    path: '/profile',
    component: Profile,
    meta: {
      requiresAuth: true,
      authorize: ['People.Employees.Read'],
      titleKey: 'Action_.item.profile',
    },
    children: [
      {
        path: '/',
        name: 'ProfileMenu',
        component: ProfileMenu,
        redirect: {
          name: 'BuroKingdra',
          query: getLinkToKingdra(`profile/contract-information`),
        },
        meta: {
          authorize: ['People.Employees.Read'],
        },
        children: [
          {
            path: 'contract-information',
            name: 'ProfileContractInformation',
            redirect: {
              name: 'BuroKingdra',
              query: getLinkToKingdra(`profile/contract-information`),
            },
            meta: {
              authorize: ['People.Employees.ReadContract'],
            },
          },
          {
            path: 'master-information',
            name: 'ProfileMasterInformation',
            redirect: {
              name: 'BuroKingdra',
              query: getLinkToKingdra(`profile/master-information`),
            },
            meta: {
              authorize: ['People.Employees.ReadMaster'],
            },
          },
          {
            path: 'internal-information',
            name: 'ProfileInternalInformation',
            redirect: {
              name: 'BuroKingdra',
              query: getLinkToKingdra(`profile/internal-information`),
            },
            meta: {
              authorize: ['People.Employees.ReadAdditionalInfo'],
            },
          },
          {
            path: 'bank-details',
            name: 'ProfileBankDetails',
            redirect: {
              name: 'BuroKingdra',
              query: getLinkToKingdra(`profile/payroll-information`),
            },
            meta: {
              authorize: ['People.Employees.ReadBank'],
            },
          },
          {
            path: 'tax-data',
            name: 'ProfileTaxData',
            redirect: {
              name: 'BuroKingdra',
              query: getLinkToKingdra(`profile/payroll-information`),
            },
            meta: {
              authorize: ['People.Employees.ReadTax'],
            },
          },
          {
            path: 'documents',
            name: 'ProfileDocuments',
            redirect: {
              name: 'BuroKingdra',
              query: getLinkToKingdra(`profile/documents`),
            },
            meta: {
              authorize: ['People.Employees.ReadDocuments'],
            },
          },
          {
            path: 'car-information',
            name: 'ProfileCarInformation',
            redirect: {
              name: 'BuroKingdra',
              query: getLinkToKingdra(`profile/car-information`),
            },
            meta: {
              authorize: ['People.Employees.ReadCar'],
            },
          },
          {
            path: 'downloads',
            name: 'ProfileDownloads',
            redirect: {
              name: 'BuroKingdra',
              query: getLinkToKingdra(`profile/downloads`),
            },
            meta: {
              authorize: ['People.Employees.ReadDownloads'],
            },
          },
          {
            path: 'absences',
            name: 'ProfileAbsences',
            redirect: {
              name: 'BuroKingdra',
              query: getLinkToKingdra(`profile/absences`),
            },
            meta: {
              authorize: ['Absences.Requests.Read'],
            },
          },
          {
            path: 'offboarding',
            name: 'ProfileOffboarding',
            redirect: {
              name: 'BuroKingdra',
              query: getLinkToKingdra(`profile/offboarding`),
            },
            meta: {
              authorize: ['People.Employees.ReadOffboarding'],
            },
          },
        ],
      },
      {
        path: 'contract-amendment/:employeeId',
        name: 'ContractAmendment',
        component: () => import('@/components/profile/contract-amendment'),
        beforeEnter: (to, from, next) => {
          if (!to.params.employeeId) {
            next({ name: 'Error', params: { status: '404' } });
          }
          next();
        },
        redirect: {
          name: 'BuroKingdra',
          query: getLinkToKingdra(`profile/internal-information`),
        },
        meta: {
          authorize: ['People.Employees.UpdateContract'],
        },
      },
    ],
  },
];

export default profileRoutes;
