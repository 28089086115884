/**
 * It takes a date string in the format YYYY-MM-DD and returns a date string in the format DD.MM.YYYY
 * or MM/DD/YYYY depending on the selected language
 * @param date - The date to be formatted.
 * @returns A function that takes a date as an argument and returns a string.
 */
const formatDate = (date) => {
  const selectedLanguage = localStorage.getItem('selectedLanguage');
  const dateCulture = selectedLanguage === 'de' ? '.' : '/';
  return date?.substring(0, 10).split('-').reverse().join(dateCulture);
};

/**
 * It takes a date string in the format YYYY-MM-DD and returns a date string in the format DD.MM.YY
 * @param {*} date - The date to be formatted.
 * @returns A function that takes a date as an argument and returns a string.
 */
const formatShortDate = (date) => {
  const longDate = formatDate(date);
  return longDate?.substring(0, 6) + longDate?.substring(8);
};

/**
 * It takes a date string in the format DD.MM.YY and returns MM.DD
 * or MM/YY depending on the selected language
 * @returns A function that takes a date as an argument and returns a string.
 */
const formatDateMonthYear = (date) => {
  const longDate = formatDate(date);
  return longDate?.substring(3) || '';
};

/**
 * Get time from a date in the format HH:MM
 * @param date - The date to be formatted.
 * @returns the time from the date
 */
const getTimeFromDate = (date) => {
  // Date.toLocaleTimeString('de-DE') method returns HH:MM:SS format time
  const dateToFormat = new Date(date).toLocaleTimeString('de-DE');

  // Splitting the date by the ':' will return an array with [ HH, MM, SS ]
  const dateSplitted = dateToFormat.split(':');

  // Joining positions 0 and 1 from array above with ':' will return HH:MM as expected
  return dateSplitted[0] + ':' + dateSplitted[1];
};

/**
 * If the string is longer than 19 characters, return the first 8 characters, followed by three dots, followed by the last 8 characters. Otherwise, return the string.
 * @param string - The string to shrink.
 * @returns The first 8 characters of the string, followed by 3 dots, followed by the last 8 characters of the string.
 */
const shrinkString = (string) => {
  if (string?.length > 19) {
    return (
      string.substring(0, 8) +
      '...' +
      string.substring(string.length - 8, string.length)
    );
  }
  return string;
};

/**
 * It takes an encoded image and returns a base64 image
 * @param encodedImage - The image that you want to convert to base64.
 * @returns base64 image
 */
const convertImgToBase64 = (encodedImage) => {
  if (encodedImage) {
    let imgBase64 = Buffer.from(encodedImage, 'base64');
    return 'data:image/jpeg;base64, ' + imgBase64;
  }
};

/**
 * It takes an array of objects and returns an array of objects with the same keys but different values (if the value is missing)
 * @param [resource] - The resource you want to translate/compare.
 * @param i18n - The i18n object that contains the translations for the current language.
 * @returns An array of objects with the properties text and value.
 */
const translateAndAdaptToVuetify = (i18n, resource = []) => {
  return resource.map((elem) => {
    const value =
      elem.Value || elem.Value === 0 || elem.Value === false
        ? elem.Value
        : elem.value;
    const name = elem.Name || elem.name;
    return {
      text: i18n[value] != undefined ? i18n[value] : name,
      value: value,
    };
  });
};

/**
 * It takes an object and a string as arguments and returns the value of the object's key that matches
 * the string
 * @param i18n - The object that contains all the translations.
 * @param id - The id of the translation you want to get.
 * @returns The value of the key in the i18n object that matches the id argument.
 * For single translation with multiple options
 */
const getTranslationById = (i18n, id) => {
  return i18n[id];
};

/**
 * It takes an object and returns an array of objects
 * @param i18n - The i18n object that you want to convert.
 * @returns An array of objects with the value and text properties.
 */
const getValueFromI18nAndAdaptToVuetify = (i18n) => {
  const keys = Object.keys(i18n);
  const final = [];
  keys.forEach((key) => {
    final.push({
      value: parseInt(key),
      text: i18n[key],
    });
  });

  return final;
};

/**
 * It converts the objects to JSON strings, and then compares the strings
 * @param obj1 - The first object to compare.
 * @param obj2 - The object to compare against.
 * @returns a boolean value.
 */
const compareTwoObjects = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

/**
 * Get the employee's complete name and return the initials
 * @param str - Complete name as a string
 * @returns string with initials.
 */
const getUserInitials = (completeName) => {
  const userNameArray = completeName.split(' ');
  const userInitials =
    userNameArray[0].charAt(0) + userNameArray.pop().charAt(0);
  return userInitials.toUpperCase();
};

/**
 * It receives a value to convert to currency
 * defines the desired pattern for the currency
 * checks if there is cents and adjusts the string to be passed to the mask method
 * @param value - The value to be converted.
 * @returns A function that calls the mask method with the defined pattern and the string number already adjusted
 */
const convertValueToCurrency = (value) => {
  let floatValue = value;

  // Regex to remove " €" in the end of the currency string
  const currencySymbolRegex = /\s€/g;

  if (!isNumber(value)) {
    floatValue = convertCurrencyToFloat(value);
  }

  return floatValue
    .toLocaleString('de-DE', {
      style: 'currency',
      currency: 'EUR',
    })
    ?.replaceAll(currencySymbolRegex, '');
};

/**
 * It removes all non-numeric characters from a string, and substitutes the coma with a dot
 * Used so the value can be parsed to float before is sent in the requests to backend
 * @param convertedCurrency - The value that will be converted to a float.
 * @returns A function that receives a parameter called convertedCurrency.
 */
const convertCurrencyToFloat = (convertedCurrency) => {
  if (!convertedCurrency) {
    return 0;
  }
  // Check if is negative value
  const negativeCurrency = convertedCurrency.includes('-');

  // remove negative sign and currency type indicator (if applicable)
  convertedCurrency = convertedCurrency?.replace(/[€-]/g, '');

  if (!!convertedCurrency && isCurrency(convertedCurrency)) {
    convertedCurrency = convertedCurrency.replaceAll('.', '').replace(',', '.');
  }
  const floatValue = parseFloat(convertedCurrency);

  return negativeCurrency ? floatValue * -1 : floatValue;
};

/**
 * Checks if a value is a number
 * @param {*} value - the value to be checked for number type
 * @returns True if it's a number, false if it's not
 */
const isNumber = (value) => typeof value === 'number' && !isNaN(value);

/**
 * Checks if a value is already in currency format
 * @param {*} value - the value to be checked for currency format
 * @returns True if it's currency format, false if it's currency
 */
const isCurrency = (value) => typeof value === 'string' && value.includes(',');

const fillObjectFromAnotherObject = (destination, original) => {
  return Object.assign(
    destination,
    ...Object.keys(destination).map(
      (k) => k in original && { [k]: original[k] }
    )
  );
};

const getDaysInMonth = (year, month) => {
  return new Date(parseInt(year), parseInt(month), 0).getDate();
};

const getShortWeekDay = (year, month, day, locale) => {
  // JS counts months from 0 to 11 (that is why month-1 below)
  return new Date(parseInt(year), parseInt(month - 1), parseInt(day))
    .toLocaleString(locale, { weekday: 'short' })
    .substring(0, 2);
};

const convertStringToLowerCamelCase = (separator, string) => {
  return string
    .toLowerCase()
    .split(separator)
    .reduce(
      (previousWord, nextWord) =>
        previousWord + (nextWord.charAt(0).toUpperCase() + nextWord.slice(1))
    );
};

/**
 * Sets all object's keys to null or the provided exception value(s).
 * @param obj - The object to be reset.
 * @param exception - An object containing the exception values. { name of the field: desired value }
 */

const resetObjectToNull = (obj, exception) => {
  for (const key in obj) {
    if (exception) {
      for (const k in exception) {
        if (k === key) {
          obj[key] = exception[k];
        } else {
          obj[key] = null;
        }
      }
    } else {
      obj[key] = null;
    }
  }
};

/**
 * Converts a date YYYY-MM to YYYY-MM-01 (1st day of the given month)
 * @param date - The date to be converted
 */
const convertMonthDateToDayDate = (date) => {
  if (date) {
    return new Date(date).toISOString().substring(0, 10);
  }
  return null;
};

/**
 * Converts a date YYYY-MM-DD to MM-YYYY
 * @param date - The date to be converted
 */
const formatDateToMonthDate = (date) => {
  return date?.substring(0, 7);
};

/**
 * Converts a number of minutes in DD:HH:MM
 * @param minutes - The number of minutes to be converted
 */
const convertMinutesIntoHoursAndDays = (minutes) => {
  if (!minutes) {
    return '-';
  }

  const hoursInDay = 24;
  const minutesInHour = 60;
  const timeIsNegative = minutes < 0;
  const minutesInDay = hoursInDay * minutesInHour;

  // If minutes are smaller than a day (positive or negative), calls directly convertMinutesIntoHours
  if (minutes < minutesInDay && minutes > -minutesInDay) {
    return convertMinutesIntoHours(minutes);
  }

  minutes = Math.abs(minutes);

  let days = Math.floor(minutes / (minutesInHour * hoursInDay));

  let formattedDays = timeIsNegative ? '-' : '';

  formattedDays += String(days).padStart(2, '0') + ':';

  let remainingMinutes = minutes - days * minutesInHour * hoursInDay;
  const formatedRemainingMinutes = convertMinutesIntoHours(
    remainingMinutes
  ).replace(/-(?!.)/g, '');

  return `${formattedDays}${formatedRemainingMinutes}`;
};

/**
 * Converts a number of minutes in HH:MM
 * @param minutes - The number of minutes to be converted
 */
const convertMinutesIntoHours = (minutes) => {
  if (minutes === 0) {
    return '00:00';
  }

  if (!minutes) {
    return '-';
  }

  const timeIsNegative = minutes < 0;
  const minutesInHour = 60;

  minutes = Math.abs(minutes);

  const hours = Math.floor(minutes / minutesInHour);
  const remainingMinutes = minutes % minutesInHour;
  let formattedHours = timeIsNegative ? '-' : '';

  formattedHours += String(hours).padStart(2, '0');
  const formattedMinutes = String(remainingMinutes).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
};

/**
 * Converts an object's key value from null to an empty string
 * @param obj - Object with values to be converted
 */
const convertNullToEmptyStringInObject = (obj) => {
  for (let key in obj) {
    if (obj[key] === null) {
      obj[key] = '';
    }
  }
};

const getStatusBadgeColor = (status) => {
  switch (status) {
    case 11:
      return '#DAE9FF';
    case 21:
      return '#E6FBD9';
    case 51:
      return '#E9F1F6';
    case 101:
      return 'lowOpacityRed';
    case 201:
      return 'ashRegular';
  }
};

const getStatusTextColor = (status) => {
  switch (status) {
    case 11:
      return 'blueRegular';
    case 21:
      return 'caribbeanDark';
    case 51:
      return '#6E7A9D';
    case 201:
      return 'mineBlack';
    case 101:
      return 'berryDark';
  }
};

export default {
  formatDate,
  formatShortDate,
  formatDateMonthYear,
  shrinkString,
  convertImgToBase64,
  translateAndAdaptToVuetify,
  getTranslationById,
  getValueFromI18nAndAdaptToVuetify,
  compareTwoObjects,
  getUserInitials,
  fillObjectFromAnotherObject,
  convertValueToCurrency,
  convertCurrencyToFloat,
  getDaysInMonth,
  getShortWeekDay,
  convertStringToLowerCamelCase,
  resetObjectToNull,
  convertMonthDateToDayDate,
  formatDateToMonthDate,
  getTimeFromDate,
  convertMinutesIntoHoursAndDays,
  convertNullToEmptyStringInObject,
  convertMinutesIntoHours,
  getStatusBadgeColor,
  getStatusTextColor,
};
