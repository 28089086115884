<template>
  <v-container class="pa-8">
    <v-row>
      <v-col
        :data-testid="`candidates-action-required-${props.index}-title`"
        class="section-title"
      >
        {{ actionRequiredTitle }}
      </v-col>
    </v-row>
    <v-row v-if="showActionRequiredFields">
      <v-col cols="4">
        <DropdownInput
          :data-testid="`candidates-action-required-${props.index}-hub-dropdown`"
          :label="t('Msg_.hub')"
          :items="peopleStore.peopleHubList"
          item-text="name"
          item-value="id"
          v-model="candidate.applicant.mainHub"
          disabled
        />
      </v-col>
      <v-col cols="4">
        <DropdownInput
          :data-testid="`candidates-action-required-${props.index}-process-responsible-dropdown`"
          :label="t('Msg_.processResponsible')"
          :items="candidate.processResponsibleList"
          v-model="candidate.responsibleId"
          disabled
        />
      </v-col>
      <v-col cols="4">
        <RegularTextInput
          :data-testid="`candidates-action-required-${props.index}-documentation-field`"
          :label="t('Msg_.requiredDocumentation')"
          :value="requiredDocumentationValue"
          disabled
        />
      </v-col>
    </v-row>

    <template v-if="showTestDayFields">
      <v-row
        v-for="(testDay, testDayIndex) in candidate.testDays"
        :key="testDayIndex"
      >
        <v-col cols="4" class="pt-0">
          <DropdownInput
            :data-testid="`candidates-action-required-${props.index}-process-responsible-dropdown`"
            :label="t('Msg_.reviewer')"
            :items="candidate.processResponsibleList"
            v-model="testDay.reviewerId"
            disabled
          />
        </v-col>
        <v-col cols="4" class="pt-0">
          <RegularTextInput
            :data-testid="`candidates-action-required-${props.index}-test-day-${testDayIndex}-field`"
            :label="t('Msg_.testDay')"
            :value="getTestDayDateAndTime(testDay)"
            disabled
          />
        </v-col>
        <v-col v-if="!isEndedMenuSelected" cols="4" class="pt-0">
          <DropdownInput
            :data-testid="`candidates-action-required-${props.index}-test-day-result-${testDayIndex}-dropdown`"
            :label="t('Msg_.testDayResult')"
            :items="selectTestDayResults"
            v-model="testDay.testDayResult"
            :clearable="false"
            :disabled="isHandoverMenuSelected || isHiringMenuSelected"
          />
        </v-col>
      </v-row>
    </template>

    <v-row v-if="showFirstWorkDayField">
      <v-col cols="4">
        <RegularTextInput
          :data-testid="`candidates-action-required-${props.index}-first-work-day-field`"
          :label="t('Msg_.firstWorkDay')"
          :value="helpers.formatDate(candidate.applicant.startDate)"
          disabled
        />
      </v-col>
    </v-row>

    <v-row v-if="showDontNeedTestDayMessage">
      <v-col
        :data-testid="`candidates-action-required-${props.index}-dont-need-test-day-message`"
        class="test-day-message"
      >
        {{ t('Msg_.dontNeedTestDay') }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';

import languages from '../../locales/languages';
import helpers from '@/helpers/helpers';
import { usePeopleStore } from '@/store/pinia/people.store.js';
import { usePeopleCandidatesStore } from '@/store/pinia/people-candidates.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { computed, defineProps } from 'vue';

const peopleStore = usePeopleStore();
const peopleCandidatesStore = usePeopleCandidatesStore();
const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
const props = defineProps({
  index: {
    type: Number,
    default: null,
  },
});

// --- Methods ---
const getHubProcessResponsibleList = async () => {
  let mainHub =
    peopleCandidatesStore.candidatesList.items[props.index].applicant.mainHub;
  if (mainHub) {
    try {
      const response = await peopleCandidatesStore.getHubProcessResponsibleList(
        mainHub
      );
      if (response.length) {
        peopleCandidatesStore.candidatesList.items[
          props.index
        ].processResponsibleList = handleHubProcessResponsibleList(response);
      }
    } catch (error) {
      console.log(error);
    }
  }
};

const handleHubProcessResponsibleList = (list) => {
  return list.map((responsible) => ({
    text: responsible.name,
    value: responsible.id,
  }));
};

const getTestDayDateAndTime = (candidate) => {
  return `${helpers.formatDate(
    candidate.testDayStartsAt
  )} ${candidate.testDayStartsAt.substring(11, 16)}`;
};

// --- Computed ---
const showDontNeedTestDayMessage = computed(() => {
  return (
    !candidate.value.needTestDay &&
    !isPayrollInformationCandidateStatus.value &&
    !isWaitingFirstDayCandidateStatus.value &&
    !isWaitingForCandidateSignatureStatus.value
  );
});

const showActionRequiredFields = computed(() => {
  return (
    !isPayrollInformationCandidateStatus.value &&
    !isWaitingFirstDayCandidateStatus.value &&
    !isWaitingForCandidateSignatureStatus.value
  );
});

const isWaitingForCandidateSignatureStatus = computed(() => {
  return isHiringMenuSelected.value && candidate.value.processStatus === 65;
});

const isHandoverMenuSelected = computed(() => {
  return peopleCandidatesStore.selectedMenuItem === 'handover';
});

const candidate = computed(() => {
  return peopleCandidatesStore.candidatesList.items[props.index];
});

const selectTestDayResults = computed(() => {
  const source = [50, 55, 47]; // keys for this dropdown - 50-approved 55-rejected 47-no show
  const applicationStatusTypes = source.map((key) =>
    peopleStore.peopleResources.applicationStatusTypes.find(
      (type) => type.Value === key
    )
  );
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectApplicationStatusTypes'),
    applicationStatusTypes
  );
});

const showTestDayFields = computed(() => {
  return (
    peopleCandidatesStore.candidatesList.items[props.index].testDays.length &&
    !isPayrollInformationCandidateStatus.value &&
    !isWaitingFirstDayCandidateStatus.value &&
    !isWaitingForCandidateSignatureStatus.value
  );
});

const showFirstWorkDayField = computed(() => {
  return (
    isPayrollInformationCandidateStatus.value ||
    isWaitingFirstDayCandidateStatus.value ||
    isWaitingForCandidateSignatureStatus.value
  );
});

const actionRequired = computed(() => {
  if (
    isContractReviewCandidateStatus.value ||
    isPayrollInformationCandidateStatus.value ||
    isWaitingFirstDayCandidateStatus.value ||
    isWaitingForCandidateSignatureStatus.value ||
    isEndedMenuSelected.value
  ) {
    return false;
  }
  return !isTestDayInReviewProcessStatus.value && !isHiringMenuSelected.value;
});

const isTestDayInReviewProcessStatus = computed(() => {
  return candidate.value.processStatus === 40;
});

const isContractReviewCandidateStatus = computed(() => {
  return isHiringMenuSelected.value && candidate.value.processStatus === 60;
});

const isPayrollInformationCandidateStatus = computed(() => {
  return isHiringMenuSelected.value && candidate.value.processStatus === 67;
});

const isWaitingFirstDayCandidateStatus = computed(() => {
  return isHiringMenuSelected.value && candidate.value.processStatus === 70;
});

const isHiringMenuSelected = computed(() => {
  return peopleCandidatesStore.selectedMenuItem === 'hiring';
});

const isEndedMenuSelected = computed(() => {
  return peopleCandidatesStore.selectedMenuItem === 'ended';
});

const actionRequiredTitle = computed(() => {
  return actionRequired.value
    ? t('Msg_.actionRequired')
    : t('Msg_.noActionRequired');
});

const requiredDocumentationValue = computed(() => {
  return `${requiredDocumentationUploaded.value} of ${totalRequiredDocumentation.value}`;
});

const requiredDocumentationUploaded = computed(() => {
  return candidate.value.documents.filter(
    (item) => item.required && item.fileName && item.fileUrl
  ).length;
});

const totalRequiredDocumentation = computed(() => {
  return candidate.value.documents.filter((item) => item.required).length;
});

// --- Lifecycle hooks ---
getHubProcessResponsibleList();
</script>

<style lang="scss" scoped>
.test-day-message {
  font-size: 13px;
}
</style>
