<template>
  <v-container>
    <v-row class="mt-1">
      <template
        v-for="(document, index) in peopleRecruitmentContractStore.documents"
      >
        <v-col
          v-if="document.isLoading"
          :key="index"
          cols="6"
          class="d-flex justify-center align-center"
        >
          <LoadingAnimation dense />
        </v-col>
        <v-col v-else cols="6" :key="document.category">
          <FileInput
            :data-testid="`recruitment-required-documentation-${document.category}-file-uploader`"
            :label="getFieldLabel(document)"
            deletable
            v-model="document.fileName"
            :placeholder="t('Msg_.chooseAFile')"
            @change="uploadDocument(document)"
            @display-document="displayDocument(document)"
            @delete-document="deleteDocument(document, index)"
          />
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script setup>
import FileInput from '@/components/shared/customComponents/inputs/file-input.vue';
import helpers from '@/helpers/helpers';
import languages from '../../locales/languages';
import LoadingAnimation from '@/components/shared/customComponents/loadingAnimation/loading-animation.vue';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { onBeforeRouteLeave } from 'vue-router/composables';
import { usePeopleRecruitmentContractStore } from '@/store/pinia/people-recruitment-contract.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { computed } from 'vue';
import { usePopupDialogStore } from '@/store/pinia/popup-dialog.store.js';

const popupDialogStore = usePopupDialogStore();
const loadingStore = useLoadingStore();
const peopleRecruitmentContractStore = usePeopleRecruitmentContractStore();
const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Methods ---
const getFieldLabel = (document) => {
  const label = helpers.getTranslationById(
    tm('Action_.selectRequiredDocumentationTypes'),
    document.category
  );
  return `${label} ${document.required ? '*' : ''}`;
};

const uploadDocument = async (document) => {
  const payload = {
    document: document,
    applicantId: peopleRecruitmentContractStore.applicantId,
  };

  document.isLoading = true;

  try {
    await peopleRecruitmentContractStore.uploadDocument(payload);
  } catch (error) {
    console.log(error);
  } finally {
    document.isLoading = false;
  }
};

const displayDocument = (document) => {
  window.open(
    `${process.env.VUE_APP_LOGIN_API}/v2/api/recruitments/documents?path=${document.fileUrl}&download=false`,
    '_blank'
  );
};

const deleteDocument = async (document, index) => {
  const payload = {
    document: document,
    index: index,
    applicantId: peopleRecruitmentContractStore.applicantId,
  };

  document.isLoading = true;

  try {
    await peopleRecruitmentContractStore.deleteDocument(payload);
  } catch (error) {
    console.log(error);
  } finally {
    document.isLoading = false;
  }
};

const displaySaveInProgressDialog = () => {
  const params = {
    type: 'regular',
    title: t('Msg_.workInProgress'),
    message: t('Msg_.waitToFinishTheProcess'),
  };

  popupDialogStore.params = params;
  popupDialogStore.displayDialog = true;
};

// --- Computed ---
const isLoading = computed(() => {
  return loadingStore.isPartialLoading || loadingStore.isGlobalLoading;
});

// --- Routing guards ---
onBeforeRouteLeave((to, _, next) => {
  if (isLoading.value) {
    displaySaveInProgressDialog();
  } else {
    next();
  }
});
</script>

<style lang="scss" scoped></style>
