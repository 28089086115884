import FleetVehicles from '@/components/fleet/fleet-vehicles';
import FleetVehiclesList from '@/components/fleet/fleet-vehicles-list';
import FleetMenu from '@/components/fleet/fleet-menu';

const fleetVehiclesRoutes = [
  {
    path: '/fleet',
    component: FleetVehicles,
    meta: {
      requiresAuth: true,
      authorize: ['Fleet.Read'],
    },
    children: [
      {
        path: 'vehicle-list',
        name: 'FleetVehiclesList',
        component: FleetVehiclesList,
        meta: {
          authorize: ['Fleet.List'],

          titleKey: 'Action_.item.vehiclesList',
        },
      },
      {
        path: 'vehicle-form',
        name: 'FleetVehicleForm',
        component: () => import('@/components/fleet/fleet-vehicle-form'),
        meta: {
          authorize: ['Fleet.Create'],
        },
      },
      {
        path: 'vehicle-form/:vehicleId?',
        name: 'FleetVehicleFormEdit',
        component: () => import('@/components/fleet/fleet-vehicle-form'),
        meta: {
          authorize: ['Fleet.Update'],
        },
      },
      {
        path: 'vehicle-menu/:vehicleId',
        name: 'FleetMenu',
        component: FleetMenu,
        meta: {
          authorize: ['Fleet.Read'],
        },
        redirect: () => {
          return { name: 'FleetDriverAssignment' };
        },
        children: [
          {
            path: 'driver-assignment',
            name: 'FleetDriverAssignment',
            component: () =>
              import(
                '@/components/fleet/fleetMenu/fleet-driver-assignment.vue'
              ),
            meta: {
              authorize: ['Fleet.Read'],
            },
          },
          {
            path: 'vehicle-details',
            name: 'FleetVehicleDetails',
            component: () =>
              import('@/components/fleet/fleetMenu/fleet-vehicle-details.vue'),
            meta: {
              authorize: ['Fleet.Read'],
            },
          },
          {
            path: 'additional-information',
            name: 'FleetAdditionalInformation',
            component: () =>
              import(
                '@/components/fleet/fleetMenu/fleet-additional-information.vue'
              ),
            meta: {
              authorize: ['Fleet.Read'],
            },
          },
          {
            path: 'documents',
            name: 'FleetDocuments',
            component: () =>
              import('@/components/fleet/fleetMenu/fleet-documents.vue'),
            meta: {
              authorize: ['Fleet.Read'],
            },
          },
          {
            path: 'driver-information',
            name: 'FleetDriverInformation',
            component: () =>
              import(
                '@/components/fleet/fleetMenu/fleet-driver-information.vue'
              ),
            meta: {
              authorize: ['Fleet.Read'],
            },
          },
        ],
      },
      {
        path: 'vehicle-assignment/:vehicleId',
        name: 'FleetVehiclesAssignment',
        component: () => import('@/components/fleet/fleet-assign-vehicle'),
        meta: {
          authorize: ['Fleet.Assign'],
        },
      },
    ],
  },
];

export default fleetVehiclesRoutes;
