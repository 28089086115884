<template>
  <v-dialog
    data-testid="candidates-hiring-turn-into-employee-dialog"
    v-model="displayDialog"
    :retain-focus="false"
    persistent
    width="614"
  >
    <v-card class="pa-3">
      <v-card-title
        data-testid="candidates-hiring-turn-into-employee-dialog-title"
        class="dialog-title mb-4"
      >
        {{ t('Title_.page.wantTurnIntoEmployee') }}
      </v-card-title>
      <v-card-text class="section-detail mineBlack--text pb-0">
        <v-container>
          <v-row>
            <v-col class="pl-0 pr-0">
              <RegularTextInput
                data-testid="candidates-hiring-turn-into-employee-dialog-full-name-field"
                disabled
                :value="candidateFullName"
                :label="t('Msg_.candidate')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              data-testid="candidates-hiring-turn-into-employee-dialog-warning-message"
              class="pl-0 pt-0 mb-12"
            >
              {{ t('Msg_.turnIntoEmployeeWarningMessage') }}
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="d-flex justify-end mt-4">
        <v-btn
          data-testid="candidates-hiring-turn-into-employee-dialog-cancel-btn"
          class="cancel-outlined-btn"
          @click="closeDialog()"
        >
          {{ t('Action_.buttons.cancel') }}
        </v-btn>
        <v-btn
          data-testid="candidates-hiring-turn-into-employee-dialog-send-btn"
          class="main-action-btn ml-2"
          @click="turnIntoEmployee()"
        >
          {{ t('Action_.buttons.send') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';

import languages from '../../locales/languages';
import { usePeopleCandidatesStore } from '@/store/pinia/people-candidates.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { defineEmits, computed } from 'vue';

const emit = defineEmits(['close-dialog', 'turn-into-employee']);
const peopleCandidatesStore = usePeopleCandidatesStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const displayDialog = true;

// --- Methods ---
const closeDialog = () => {
  emit('close-dialog');
};

const turnIntoEmployee = () => {
  emit('turn-into-employee');
};

// --- Computed ---
const candidate = computed(() => {
  return peopleCandidatesStore.candidatesList.items[
    peopleCandidatesStore.candidateIndex
  ];
});

const candidateFullName = computed(() => {
  return `${candidate.value.applicant.firstName} ${candidate.value.applicant.lastName}`;
});
</script>

<style lang="scss" scoped></style>
