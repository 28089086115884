<template>
  <v-list-item-group append-icon="" v-if="canUser(permission)">
    <v-list-item
      :to="{ name: routeName, query: params }"
      class="item d-flex align-center"
      :color="color"
    >
      <img
        v-if="icon.includes('.svg')"
        :src="icon"
        alt="missing icon"
        class="mr-3"
      />
      <v-icon v-else color="white" class="mr-3">{{ icon }}</v-icon>
      <v-list-item-title class="menu_title_text">{{ title }}</v-list-item-title>
    </v-list-item>
  </v-list-item-group>
</template>

<script setup>
import { computed } from 'vue';

import { defineProps } from 'vue';
import { usePermissions } from '@/helpers/composables/permissions.js';

const { canUser } = usePermissions();

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  title: {
    type: String,
    default: 'Missing',
  },
  icon: {
    type: String,
    default: 'mdi-alert-decagram',
  },
  routeName: {
    type: String,
    default: 'Error',
  },
  permission: {
    type: Array,
    default: () => [],
  },
  params: {
    type: [String, Object],
    default: () => {},
  },
  controlProfileSelected: {
    type: Boolean,
    default: false,
  },
});

// This is a temporary solution as this behavior is proprietary to Vuetify and we
// don't have a specific way to prevent profile selection.
// This issue will be properly fixed in Kingdra
const color = computed(() =>
  props.controlProfileSelected ? 'rgba(0, 0, 0, 0)' : undefined
);
</script>
<style scoped>
.item {
  height: 48px !important;
}
</style>
