import { useMainStore } from '@/store/pinia/main.store.js';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useUserStore = defineStore('user', () => {
  const mainStore = useMainStore();

  // --- State ---
  const userInfo = ref({});

  // --- Loadings ---
  const isUserLoading = ref(false);

  // --- Methods ---
  const getUserInfo = async () => {
    const params = {
      endpoint: 'v2/api/Account/userInfo',
      configs: {
        method: 'GET',
      },
    };

    try {
      const res = await mainStore.request(params);

      const fields = [
        'preferred_username',
        'email',
        'phone_number',
        'sub',
        'name',
      ];
      const infoFinal = res.claims.reduce((arg, item) => {
        if (fields.includes(item.type)) {
          arg[item.type] = item.value;
        } else if (item.type === 'role') {
          if (!arg[item.type]) {
            arg[item.type] = [];
          }
          arg[item.type].push(item.value);
        }

        return arg;
      }, {});

      infoFinal.userPermissions = res.permissions;
      infoFinal.hub = res.hub;
      userInfo.value = infoFinal;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return {
    isUserLoading,
    userInfo,
    getUserInfo,
  };
});
