<template>
  <div>
    <div data-testid="file-input" v-if="!value">
      <p class="custom-label mb-1">{{ label }}</p>
      <v-file-input
        dense
        outlined
        :rules="rules"
        type="file"
        accept=".pdf"
        prepend-icon=""
        :value="value"
        :hint="hint"
        :persistent-hint="persistentHint"
        :disabled="disabled"
        color="blueRegular"
        :placeholder="placeholder"
        :persistent-placeholder="persistentPlaceholder"
        @change="emitUserInput($event)"
      >
        <v-icon
          slot="prepend-inner"
          class="custom-icon"
          size="20"
          :color="value ? 'blueRegular' : 'greyRegular'"
        >
          mdi-paperclip
        </v-icon>
      </v-file-input>
    </div>
    <div v-else-if="downloadable">
      <v-row>
        <v-col cols="8" xl="9" class="pr-0">
          <RegularTextInput
            :disabled="disabled"
            :label="label"
            :clearable="!disabled"
            :value="
              helpers.shrinkString(value.displayName || value.name || value)
            "
            @clear-input="emitUserInput(null)"
          />
        </v-col>

        <v-col
          cols="4"
          xl="3"
          class="d-flex justify-start my-3"
          align-self="center"
        >
          <IconButton
            class="icon-btn"
            icon="mdi-eye"
            :disabled="disabled && !downloadable"
            @click="displayDocument()"
          />
          <IconButton
            class="icon-btn"
            :class="$vuetify.breakpoint.xl ? 'ml-2' : 'ml-1'"
            icon="mdi-download"
            :disabled="disabled && !downloadable"
            :clearable="!disabled"
            @click="downloadDocument()"
          />
        </v-col>
      </v-row>
    </div>
    <div v-else-if="deletable">
      <v-row>
        <v-col cols="8" xl="9" class="pr-0">
          <RegularTextInput
            :label="label"
            readonly
            :value="
              helpers.shrinkString(value.displayName || value.name || value)
            "
            :error="showError"
            :rules="deletableFieldRules()"
          />
        </v-col>

        <v-col
          cols="4"
          xl="3"
          class="d-flex justify-start my-3"
          align-self="center"
        >
          <IconButton
            v-if="!hideDisplayDocument"
            class="icon-btn"
            icon="mdi-eye"
            :disabled="disableDisplayDocument"
            @click="displayDocument()"
          />
          <IconButton
            class="icon-btn"
            color="redDanger"
            :class="$vuetify.breakpoint.xl ? 'ml-2' : 'ml-1'"
            icon="mdi-delete"
            :disabled="disabled"
            @click="deleteDocument()"
          />
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <RegularTextInput
        :label="label"
        :disabled="true"
        :value="helpers.shrinkString(value.displayName || value.name)"
        @clear-input="emitUserInput(null)"
        :clearable="!disabled"
      />
    </div>
  </div>
</template>

<script setup>
import languages from '../../locales/languages';
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import IconButton from '@/components/shared/customComponents/buttons/icon-button.vue';
import helpers from '@/helpers/helpers';
import { useI18n } from 'vue-i18n-bridge';
import { computed, defineProps, defineEmits } from 'vue';

const emit = defineEmits([
  'input',
  'change',
  'display-document',
  'download-document',
  'delete-document',
]);
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  value: {
    type: null,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    required: false,
    default: '',
  },
  persistentPlaceholder: {
    type: Boolean,
    required: false,
    default: false,
  },
  hint: {
    type: String,
    required: false,
    default: '',
  },
  persistentHint: {
    type: Boolean,
    required: false,
    default: false,
  },
  downloadable: {
    type: Boolean,
    required: false,
    default: false,
  },
  deletable: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  rules: {
    type: Array,
    required: false,
    default: () => [],
  },
  maxFileSize: {
    type: null,
    required: false,
    default: null,
  },
  hideDisplayDocument: {
    type: Boolean,
    default: false,
  },
});

// --- Methods ---
const emitUserInput = (input) => {
  emit('input', input);
  emit('change', input);
};

const displayDocument = () => {
  emit('display-document');
};

const downloadDocument = () => {
  emit('download-document');
};

const deleteDocument = () => {
  emit('delete-document');
};

const deletableFieldRules = () => {
  let internalRules = [];
  if (showError.value) {
    internalRules = [
      props.disabled ||
        !props.maxFileSize ||
        maxFileSizeValidation.value ||
        `${t('Msg_.fileCannotExceed')} ${props.maxFileSize / 1000000}MB`,
    ];
  }
  return internalRules;
};

// --- Computed ---
const isFile = computed(() => {
  return props.value instanceof File;
});

const showError = computed(() => {
  if (isFile.value) {
    return maxFileSizeValidation.value ? false : true;
  } else {
    return false;
  }
});

const maxFileSizeValidation = computed(() => {
  return props.maxFileSize !== null && props.value.size < props.maxFileSize;
});

const disableDisplayDocument = computed(() => {
  if (props.value.displayName && props.disabled) return true;
  if (props.value.displayName) return false;
  return (
    (!maxFileSizeValidation.value || props.disabled) && +props.maxFileSize > 0
  );
});
</script>

<style lang="scss" scoped>
.custom-label {
  font-size: 13px;
}

.custom-icon {
  margin: 2px 4px 0 0;
}

::v-deep .v-input__slot {
  cursor: pointer !important;
}

.v-input--is-focused ::v-deep fieldset {
  border: 1px solid $blue-regular !important;
}

.v-text-field--outlined ::v-deep fieldset {
  border-color: $ash-dark;
}
</style>
