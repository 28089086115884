import helpers from './helpers';

/* rules for the validations in the form fields */

/**
 * Validates phone numbers format
 * Return true if number is composed by 9 to 12 digits
 * @param {*} message - the message to be returned in case of not valid value
 * @return {*} true if valid, error message if not valid
 */
const phoneNumber = (message) => {
  return (v) => v === '' || /^\+?\d{9,12}$/.test(v) || message;
};

/**
 * Validates iban format
 * Return true if number is composed: PT0123...
 * OBS: this rule dont validate the length of numeric characters, onlye if has 2 letter and numeric digits
 * @param {*} message - the message to be returned in case of not valid value
 * @return {*} true if valid, error message if not valid
 */
const iban = (message) => {
  return (v) => /^[a-zA-Z]{2}\d+$/.test(v) || message;
};

/**
 * Validates email format
 * @param {*} message - the message to be returned in case of not valid value
 * @return {*} true if valid, error message if not valid
 */
const email = (message) => {
  return (v) => /`null|undefined|^\S+@\S+\.\S+$/.test(v) || message;
};

/**
 * Validates if the field value has a given size
 * @param {*} size - the exact size the value should contain
 * @param {*} message - the message to be returned in case of not valid value
 * @param {*} allowNull - boolean, used in case of non-required fields, to include null in the regex
 * @return {*} true if valid, error message if not valid
 */
const totalSize = (size, message) => {
  const sizeRegex = new RegExp(`null|undefined|^$|^\\S{${size}}$`);
  return (v) => sizeRegex.test(v) || message;
};

/**
 * Validates if input string length is smaller than informed size
 * @param {*} size - the size the string should have be smaller
 * @param {*} message - the message to be returned in case of not valid value
 * @return {*} true if valid, error message if not valid
 */
const valueSize = (size, message) => {
  return (v) => v?.length <= size || message;
};

/**
 * Validates if field is filled
 * @param {*} message - the message to be returned in case of not valid value
 * @return {*} true if valid, error message if not valid
 */
const fieldRequired = (message, exception) => {
  return (v) => !!v || v === !!exception || v === exception || message;
};

/**
 * Validates if the field includes only numbers and letters
 * @param {*} message - the message to be returned in case of not valid value
 * @param {*} allowNull - boolean, used in case of non-required fields, to include null in the regex
 * @return {*} true if valid, error message if not valid
 */
const numbersAndLetters = (message) => {
  const numbersOrLettersRegex = new RegExp(`null|undefined|^$|[\\w\\d]$`);
  return (v) => numbersOrLettersRegex.test(v) || message;
};

/**
 * Validates if the field includes only numbers
 * @param {*} message - the message to be returned in case of not valid value
 * @return {*} true if valid, error message if not valid
 */
const onlyNumbers = (message) => {
  return (v) => /null|undefined|^\d*$/.test(v) || message;
};

/**
 * Validates if the field includes only numbers - integers or floats with dots - Ex: XX and XXX.X
 * @param {*} message - the message to be returned in case of not valid value
 * @return {*} true if valid, error message if not valid
 */
const numberWithDecimals = (message) => {
  return (v) => /^[+-]?([0-9]+\.?[0-9]*)$/.test(v) || message;
};

/**
 * Validates if the field includes only numbers (or nothing) - integers or floats with dots - Ex: XX and XXX.X
 * @param {*} message - the message to be returned in case of not valid value
 * @return {*} true if valid, error message if not valid
 */
const numberWithDecimalsOrNull = (message) => {
  return (v) => !v || /^[+-]?([0-9]+\.?[0-9]*)$/.test(v) || message;
};

/**
 * Validates if the field value has numbers with or without comma
 * @param {*} message - the message to be returned in case of not valid value
 * @return {*} true if valid, error message if not valid
 */
const currency = (message) => {
  return (v) => /null|undefined|^[\d,.]*$/.test(v) || message;
};

/**
 * Validates if the field value has the correct german vehicle plate format
 * @param {*} message - the message to be returned in case of not valid value
 * @return {*} true if valid, error message if not valid
 */
const vehiclePlate = (message) => {
  return (v) =>
    /null|undefined|[\w\d]{1,3}[-][\w\d]{1,2}[\s]\d{1,4}/g.test(v) || message;
};

/**
 * Validates if the value is greater than zero
 * To be type-proof, convert value to String, to be char proof, removes all non-digit chars, and then converts final value to float
 * @param {*} message - the message to be returned in case of not valid value
 * @return {*} true if valid, error message if not valid
 */
const greaterThanZero = (message) => {
  return (v) =>
    (!!v && parseFloat(v.toString().replace(/[\D]/, '')) > 0) || message;
};

/**
 * Validates if value is smaller than or equal to a maximum given value
 * @param {*} maximumValue - the reference maximum value for the validation
 * @param {*} message - the message to be returned in case of not valid value
 * @return {*} true if valid, error message if not valid
 */
const smallerOrEqualThan = (maximumValue, message) => {
  return (v) => v <= maximumValue || message;
};

/**
 * Validates if value is smaller than or equal to a maximum given value
 * @param {*} maximumValue - the reference maximum value for the validation
 * @param {*} message - the message to be returned in case of not valid value
 * @return {*} true if valid, error message if not valid
 */
const currencySmallerOrEqualThan = (maximumValue, message) => {
  return (v) => {
    if (typeof v === 'string') {
      return helpers.convertCurrencyToFloat(v) <= maximumValue || message;
    }
    return false;
  };
};

/**
 * Validates if value is bigger than or equal to a minimumValue given value
 * @param {*} minimumValue - the reference minimumValue value for the validation
 * @param {*} message - the message to be returned in case of not valid value
 * @return {*} true if valid, error message if not valid
 */
const biggerOrEqualThan = (minimumValue, message) => {
  return (v) => v >= minimumValue || message;
};

/**
 * Validates if field should be required or not by a given property
 * @param {*} shouldRequire - the reference boolean to validate or not
 * @param {*} message - the message to be returned in case of not valid value
 * @return {*} true if valid OR if field should not be required, error message if not valid
 */
const fieldRequiredByParameter = (shouldRequire, message) => {
  return (v) => (shouldRequire ? !!v : true) || message;
};

const germanyPhoneNumber = (message) => {
  return (v) => /^1/.test(v) || message;
};

/**
 * Validates if string contains a number within the specified range
 * @param {number} minValue - The minimum allowed value for the validation range
 * @param {number} maxValue - The maximum allowed value for the validation range
 * @param {string} message - The message to be returned in case of an invalid value
 * @return {boolean|string} Returns true if the string contains a valid number within the range, otherwise returns the provided error message.
 */
const validateNumberInRange = (minValue, maxValue, message) => {
  return (v) => {
    if (v === null || v === undefined || /^\d*$/.test(v)) {
      const n = Number(v);
      return !Number.isNaN(n) && n >= minValue && n <= maxValue
        ? true
        : message;
    }
    return false;
  };
};

export default {
  phoneNumber,
  totalSize,
  fieldRequired,
  numbersAndLetters,
  onlyNumbers,
  numberWithDecimals,
  numberWithDecimalsOrNull,
  currency,
  currencySmallerOrEqualThan,
  vehiclePlate,
  greaterThanZero,
  smallerOrEqualThan,
  biggerOrEqualThan,
  fieldRequiredByParameter,
  email,
  iban,
  valueSize,
  germanyPhoneNumber,
  validateNumberInRange,
};
