import People from '@/components/people/people';
import Employees from '@/components/people/employees/employees';
import Applicants from '@/components/people/applicants/applicants';
import ApplicantList from '@/components/people/applicants/applicants-list';
import Candidates from '@/components/people/candidates/candidates';
import CandidateList from '@/components/people/candidates/candidates-list';
import PendingEmployees from '@/components/people/pendingEmployees/pending-employees';
import PendingEmployeesList from '@/components/people/pendingEmployees/pending-employees-list';
import { getLinkToKingdra } from '@/helpers/kingdra';

const peopleRoutes = [
  {
    name: '',
    path: '/people',
    component: People,
    meta: {
      authorize: ['People.Employees.Read'],
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        component: Employees,
        meta: {
          authorize: ['People.Employees.Read'],
        },

        children: [
          {
            path: 'employees',
            name: 'EmployeeList',
            redirect: () => ({
              name: 'BuroKingdra',
              query: getLinkToKingdra(`people/employees`),
            }),
            meta: {
              authorize: [
                'People.Employees.ListAll',
                'People.Employees.ListActive',
              ],

              titleKey: 'Action_.subItem.employees',
            },
          },

          {
            path: 'profile',
            component: () => import('@/components/profile/profile.vue'),
            meta: {
              requiresAuth: true,
              authorize: ['People.Employees.Read'],
            },
            children: [
              {
                path: '/',
                name: 'EmployeeProfileMenu',
                component: () =>
                  import('@/components/profile/profile-menu.vue'),
                meta: {
                  authorize: ['People.Employees.Read'],
                },
                children: [
                  {
                    path: 'offboarding/:employeeId?',
                    name: 'EmployeeProfileOffboarding',
                    redirect: (to) => ({
                      name: 'BuroKingdra',
                      query: getLinkToKingdra(
                        `people/employees/${to.params.employeeId}/offboarding`
                      ),
                    }),
                    meta: {
                      authorize: ['People.Employees.ReadOffboarding'],
                    },
                  },
                  {
                    path: 'contract-information/:employeeId?',
                    name: 'EmployeeProfileContractInformation',
                    redirect: (to) => ({
                      name: 'BuroKingdra',
                      query: getLinkToKingdra(
                        `people/employees/${to.params.employeeId}/contract-information`
                      ),
                    }),
                    meta: {
                      authorize: ['People.Employees.Read'],
                    },
                  },
                  {
                    path: 'master-information/:employeeId?',
                    name: 'EmployeeProfileMasterInformation',
                    redirect: (to) => ({
                      name: 'BuroKingdra',
                      query: getLinkToKingdra(
                        `people/employees/${to.params.employeeId}/master-information`
                      ),
                    }),
                    meta: {
                      authorize: ['People.Employees.Read'],
                    },
                  },
                  {
                    path: 'internal-information/:employeeId?',
                    name: 'EmployeeProfileInternalInformation',
                    redirect: (to) => ({
                      name: 'BuroKingdra',
                      query: getLinkToKingdra(
                        `people/employees/${to.params.employeeId}/internal-information`
                      ),
                    }),
                    meta: {
                      authorize: ['People.Employees.Read'],
                    },
                  },
                  {
                    path: 'bank-details/:employeeId?',
                    name: 'EmployeeProfileBankDetails',
                    redirect: (to) => ({
                      name: 'BuroKingdra',
                      query: getLinkToKingdra(
                        `people/employees/${to.params.employeeId}/payroll-information`
                      ),
                    }),
                    meta: {
                      authorize: ['People.Employees.Read'],
                    },
                  },
                  {
                    path: 'tax-data/:employeeId?',
                    name: 'EmployeeProfileTaxData',
                    redirect: (to) => ({
                      name: 'BuroKingdra',
                      query: getLinkToKingdra(
                        `people/employees/${to.params.employeeId}/payroll-information`
                      ),
                    }),
                    meta: {
                      authorize: ['People.Employees.Read'],
                    },
                  },
                  {
                    path: 'documents/:employeeId?',
                    name: 'EmployeeProfileDocuments',
                    redirect: (to) => ({
                      name: 'BuroKingdra',
                      query: getLinkToKingdra(
                        `people/employees/${to.params.employeeId}/documents`
                      ),
                    }),
                    meta: {
                      authorize: ['People.Employees.Read'],
                    },
                  },
                  {
                    path: 'car-information/:employeeId?',
                    name: 'EmployeeProfileCarInformation',
                    redirect: (to) => ({
                      name: 'BuroKingdra',
                      query: getLinkToKingdra(
                        `people/employees/${to.params.employeeId}/car-information`
                      ),
                    }),
                    meta: {
                      authorize: ['People.Employees.Read'],
                    },
                  },
                  {
                    path: 'downloads/:employeeId?',
                    name: 'EmployeeProfileDownloads',
                    redirect: (to) => ({
                      name: 'BuroKingdra',
                      query: getLinkToKingdra(
                        `people/employees/${to.params.employeeId}/downloads`
                      ),
                    }),
                    meta: {
                      authorize: ['People.Employees.Read'],
                    },
                  },
                  {
                    path: 'absences/:employeeId?',
                    name: 'EmployeeProfileAbsences',
                    redirect: (to) => ({
                      name: 'BuroKingdra',
                      query: getLinkToKingdra(
                        `people/employees/${to.params.employeeId}/absences`
                      ),
                    }),
                    meta: {
                      authorize: ['People.Employees.Read'],
                    },
                  },
                ],
              },
              {
                path: 'contract-amendment/:employeeId',
                name: 'EmployeeContractAmendment',
                redirect: (to) => ({
                  name: 'BuroKingdra',
                  query: getLinkToKingdra(
                    `people/employees/${to.params.employeeId}/contract-amendment`
                  ),
                }),
                meta: {
                  authorize: ['People.Employees.UpdateContract'],
                },
              },
            ],
          },
        ],
      },
      {
        path: '/',
        component: Applicants,
        meta: {
          authorize: ['People.Applicants.List'],
        },

        children: [
          {
            path: 'applicants',
            name: 'ApplicantList',
            component: ApplicantList,
            meta: {
              authorize: ['People.Applicants.List'],

              titleKey: 'Action_.subItem.applicants',
            },
          },
        ],
      },
      {
        path: '/',
        component: Candidates,
        meta: {
          authorize: ['People.Candidates.List'],
        },

        children: [
          {
            path: 'candidates',
            name: 'CandidateList',
            component: CandidateList,
            meta: {
              authorize: ['People.Candidates.List'],

              titleKey: 'Action_.subItem.candidates',
            },
          },
        ],
      },
      {
        path: '/',
        component: PendingEmployees,
        meta: {
          authorize: ['People.Applicants.List'],
        },

        children: [
          {
            path: 'pending-employees',
            name: 'PendingEmployeesList',
            component: PendingEmployeesList,
            meta: {
              authorize: [
                'People.Employees.ListAll',
                'People.Employees.ListActive',
              ],

              titleKey: 'Action_.subItem.pendingEmployees',
            },
          },
        ],
      },
      {
        path: 'contract-creation',
        name: 'ContractCreation',
        component: () =>
          import(
            '@/components/people/contracts/contractCreation/contract-creation'
          ),
        meta: {
          authorize: ['People.Employees.UpdateContract'],
        },
      },
      {
        path: 'pending-employee-contract/:employeeId',
        name: 'PendingEmployeeContract',
        component: () =>
          import(
            '@/components/people/contracts/contractCreation/contract-creation'
          ),
        meta: {
          authorize: ['People.Employees.UpdateContract'],
        },
      },
      {
        path: 'absences',
        name: 'PeopleAbsences',
        component: () => import('@/components/people/absences/people-absences'),
        meta: {
          authorize: ['Absences.Requests.List', 'Absences.Schedule.List'],

          titleKey: 'Action_.subItem.absences',
        },
      },
    ],
  },
];

export default peopleRoutes;
