import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import helpers from './helpers/helpers';
import rules from './helpers/rules';
import { createPinia, PiniaVuePlugin } from 'pinia';
import router from './router';
import VueRouter from 'vue-router';
import resetStore from '@/plugins/resetStore.js';

import * as amplitude from '@amplitude/analytics-browser';
if (process.env.VUE_APP_AMPLITUDE_ENABLED === 'true') {
  amplitude.init(process.env.VUE_APP_AMPLITUDE, undefined, {
    serverZone: amplitude.Types.ServerZone.EU,
  });
}

export const pinia = createPinia();
pinia.use(resetStore);

Vue.config.productionTip = false;

const helperPlugin = {
  install() {
    Vue.prototype.$helpers = helpers;
  },
};

const rulesPlugin = {
  install() {
    Vue.prototype.$rules = rules;
  },
};

Vue.use(helperPlugin);
Vue.use(rulesPlugin);
Vue.use(i18n);

Vue.use(PiniaVuePlugin);

Vue.use(VueRouter);

new Vue({
  name: 'Main',
  vuetify,
  i18n,
  pinia,
  router,
  render: (h) => h(App),
}).$mount('#app');
