import helpers from '@/helpers/helpers';
import { usePeopleContractCreationStore } from '@/store/pinia/people-contract-creation.store.js';
import { usePeopleStore } from '@/store/pinia/people.store.js';
import { computed } from 'vue';

export function useContractTools() {
  const peopleContractCreationStore = usePeopleContractCreationStore();
  const peopleStore = usePeopleStore();

  const initializeContractData = (fromSearchScreen) => {
    if (peopleContractCreationStore.contractCreation.weeklyWorkingHours) {
      peopleContractCreationStore.contractCreation.weeklyWorkingHours =
        createWorkingHoursArray(
          peopleContractCreationStore.contractCreation.weeklyWorkingHours
        );
    } else {
      populateFieldsWithDefaultValues();
    }
    handleDivergentKeys(peopleContractCreationStore.contractCreation);
    handleDocumentsFields(peopleContractCreationStore.contractCreation);
    handleAdditionalTrainingField();
    if (fromSearchScreen) handleSearch();
  };

  const handleSearch = () => {
    let today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substring(0, 10);
    peopleContractCreationStore.contractCreation.signingDate = today;
    peopleContractCreationStore.contractCreation.paymentType = 2;
  };

  const handleDivergentKeys = (data) => {
    peopleContractCreationStore.contractCreation.jobPosition =
      data.qualificationType;
    peopleContractCreationStore.contractCreation.confession = data.religion;
  };

  const handleDocumentsFields = (data) => {
    peopleContractCreationStore.documents.cv = data.cv;
    peopleContractCreationStore.documents.covidVaccinationCertificate =
      data.covidVaccinationCertificates;
    peopleContractCreationStore.documents.firstAidCertificate =
      data.firstAidCertificate;
    peopleContractCreationStore.documents.controlDriversLicense =
      data.controlDriversLicense;
  };

  const populateFieldsWithDefaultValues = () => {
    const activeHours = 0;
    peopleContractCreationStore.contractCreation.totalWeeklyWorkingHours = 0;
    peopleContractCreationStore.contractCreation.weeklyWorkingHours = [
      { day: 'monday', hours: activeHours.toFixed(2), isActive: false },
      { day: 'tuesday', hours: activeHours.toFixed(2), isActive: false },
      { day: 'wednesday', hours: activeHours.toFixed(2), isActive: false },
      { day: 'thursday', hours: activeHours.toFixed(2), isActive: false },
      { day: 'friday', hours: activeHours.toFixed(2), isActive: false },
      { day: 'saturday', hours: activeHours.toFixed(2), isActive: false },
      { day: 'sunday', hours: activeHours.toFixed(2), isActive: false },
    ];
  };

  const handleAdditionalTrainingField = () => {
    let additionalTraining = [];
    if (
      peopleContractCreationStore.contractCreation?.additionalTraining?.length
    ) {
      additionalTraining = replaceAdditionalTrainingKeys(
        peopleContractCreationStore.contractCreation.additionalTraining
      );
    }
    peopleContractCreationStore.contractCreation.additionalTraining =
      additionalTraining;
  };

  const replaceAdditionalTrainingKeys = (additionalTraining) => {
    return additionalTraining?.map((item) => ({
      text: item.Name,
      value: item.Value,
    }));
  };

  const createWorkingHoursArray = (obj) => {
    const res = [];
    for (let key in obj) {
      res.push({
        day: key,
        hours: obj[key],
        isActive: !!obj[key],
      });
    }
    return res;
  };

  const validateAdditionalTraining = (payload) => {
    let finalArray = [];
    const hasNoAdditionalTraining = payload.array.find(
      ({ value }) => value === 0
    );

    if (payload.lastSelection.value === 0) {
      if (hasNoAdditionalTraining) {
        finalArray = [payload.lastSelection];
      }
    } else {
      finalArray = payload.array.filter(({ value }) => {
        return value !== 0;
      });
    }

    return finalArray;
  };

  const prepareDataToPost = (contractData) => {
    let payload = { ...{}, ...contractData };

    payload.weeklyWorkingHours = formatWeeklyWorkingHoursToPost(
      payload.weeklyWorkingHours
    );

    const currencyKeys = [
      'hourlyWage',
      'nonDynamicAllowance',
      'careAllowance',
      'monthlyBaseSalary',
      'thirteenthSalary',
      'annualSalary',
    ];

    convertCurrencyFieldsToPost(currencyKeys, payload);

    const percentageKeys = [
      'costCenter1Percentage',
      'costCenter2Percentage',
      'costCenter3Percentage',
    ];

    formatPercentageFieldsToPost(percentageKeys, payload);

    payload.additionalTraining = replaceAdditionalTrainingKeysToPost(
      payload.additionalTraining
    );

    payload.numberOfChildren = +payload.numberOfChildren;
    payload.yearsOfExperience = +payload.yearsOfExperience;
    payload.totalWeeklyWorkingHours = +payload.totalWeeklyWorkingHours;

    return payload;
  };

  const formatWeeklyWorkingHoursToPost = (data) => {
    const workingHours = data.map((item) => ({
      [item.day]: parseFloat(item.hours),
    }));
    return Object.assign({}, ...workingHours);
  };

  const convertCurrencyFieldsToPost = (currencyKeys, payload) => {
    currencyKeys.forEach((key) => {
      const convertedToCcy = helpers.convertValueToCurrency(payload[key]);
      payload[key] = helpers.convertCurrencyToFloat(convertedToCcy);
    });
  };

  const formatPercentageFieldsToPost = (percentageKeys, payload) => {
    percentageKeys.forEach((key) => {
      if (payload[key] != null) {
        payload[key] = parseFloat(payload[key]) || 0;
      }
    });
  };

  const replaceAdditionalTrainingKeysToPost = (payload) => {
    const additionalTraining = payload.map((item) => ({
      Name: item.text,
      Value: item.value,
    }));
    return additionalTraining;
  };

  const updateDailyWorkingHours = (index) => {
    updateWeekDay(index);
    peopleContractCreationStore.contractCreation.totalWeeklyWorkingDays =
      totalActiveSwitches();
    peopleContractCreationStore.contractCreation.vacationDaysPerYear =
      vacationDaysPerYear;
    updateSplitHours();
  };

  const updateWeekDay = (index) => {
    let freeDay = 0;
    peopleContractCreationStore.contractCreation.weeklyWorkingHours[
      index
    ].isActive =
      !peopleContractCreationStore.contractCreation.weeklyWorkingHours[index]
        .isActive;
    if (
      !peopleContractCreationStore.contractCreation.weeklyWorkingHours[index]
        .isActive
    ) {
      peopleContractCreationStore.contractCreation.weeklyWorkingHours[
        index
      ].hours = freeDay.toFixed(2);
    }
  };

  const updateSplitHours = () => {
    let splitTime =
      peopleContractCreationStore.contractCreation.totalWeeklyWorkingHours /
      totalActiveSwitches();

    peopleContractCreationStore.contractCreation.weeklyWorkingHours.forEach(
      (day, index) => {
        if (day.isActive) {
          peopleContractCreationStore.contractCreation.weeklyWorkingHours[
            index
          ].hours = splitTime.toFixed(2);
        }
      }
    );
  };

  const totalActiveSwitches = () => {
    return peopleContractCreationStore.contractCreation.weeklyWorkingHours.filter(
      (day) => day.isActive == true
    ).length;
  };

  // --- Computed ---
  const vacationDaysPerYear = computed(() => {
    let vacationsPerYear = null;

    if (
      peopleStore.peopleResources?.vacationDaysCalculations &&
      peopleContractCreationStore.contractCreation.totalWeeklyWorkingDays !== 7
    ) {
      const selectedVacationDaysCalculations =
        peopleStore.peopleResources?.vacationDaysCalculations.find(
          (days) =>
            days.weeklyWorkingDays ===
            peopleContractCreationStore.contractCreation.totalWeeklyWorkingDays
        );
      vacationsPerYear = selectedVacationDaysCalculations?.yearlyVacationDays;
    }

    return vacationsPerYear;
  });

  const prepareRecruitmentListData = (payload) => {
    payload.items = payload.items.map((recruit) => {
      recruit.processResponsibleList = [];
      recruit.clickedOnReviewContract = false;
      return recruit;
    });
    return payload;
  };

  return {
    initializeContractData,
    handleDivergentKeys,
    handleDocumentsFields,
    populateFieldsWithDefaultValues,
    handleAdditionalTrainingField,
    createWorkingHoursArray,
    validateAdditionalTraining,
    prepareDataToPost,
    formatWeeklyWorkingHoursToPost,
    convertCurrencyFieldsToPost,
    formatPercentageFieldsToPost,
    replaceAdditionalTrainingKeysToPost,
    replaceAdditionalTrainingKeys,
    updateDailyWorkingHours,
    vacationDaysPerYear,
    updateSplitHours,
    prepareRecruitmentListData,
  };
}
