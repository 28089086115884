<template>
  <v-container>
    <v-row class="mt-1">
      <v-col cols="3">
        <RegularTextInput
          data-testid="recruitment-personal-data-first-name-field"
          :label="t('Msg_.firstName')"
          :disabled="isFormDisabled"
          v-model="peopleRecruitmentContractStore.applicant.firstName"
        />
      </v-col>
      <v-col cols="3">
        <RegularTextInput
          data-testid="recruitment-personal-data-last-name-field"
          :label="t('Msg_.lastName')"
          :disabled="isFormDisabled"
          v-model="peopleRecruitmentContractStore.applicant.lastName"
        />
      </v-col>
      <v-col cols="4">
        <RegularTextInput
          data-testid="recruitment-personal-data-email-field"
          :label="t('Msg_.email')"
          :disabled="isFormDisabled"
          v-model="peopleRecruitmentContractStore.applicant.personalEmail"
        />
      </v-col>
      <v-col cols="2">
        <RegularTextInput
          data-testid="recruitment-personal-data-phone-field"
          :label="t('Msg_.phone')"
          :disabled="isFormDisabled"
          v-model="peopleRecruitmentContractStore.applicant.personalPhoneNumber"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" class="pt-0">
        <RegularTextInput
          data-testid="recruitment-personal-data-home-address-field"
          :label="t('Msg_.homeAddress')"
          :disabled="isFormDisabled"
          v-model="peopleRecruitmentContractStore.applicant.street"
        />
      </v-col>
      <v-col cols="3" class="pt-0">
        <RegularTextInput
          data-testid="recruitment-personal-data-zip-code-field"
          :label="t('Msg_.zipCode')"
          :disabled="isFormDisabled"
          v-model="peopleRecruitmentContractStore.applicant.zipCode"
        />
      </v-col>
      <v-col cols="3" class="pt-0">
        <RegularTextInput
          data-testid="recruitment-personal-data-city-field"
          :label="t('Msg_.city')"
          :disabled="isFormDisabled"
          v-model="peopleRecruitmentContractStore.applicant.city"
        />
      </v-col>
    </v-row>
    <v-row v-if="showEditInformationButton" class="mb-1">
      <v-col class="pl-1" v-if="isFormDisabled">
        <v-btn
          data-testid="recruitment-personal-data-edit-information-btn"
          class="main-action-btn ml-2"
          @click="toggleDisabled()"
        >
          {{ buttonText }}
        </v-btn>
      </v-col>
      <v-col class="pl-1" v-if="!isFormDisabled">
        <v-btn
          data-testid="recruitment-personal-data-cancel-btn"
          class="cancel-variant-btn ml-2"
          @click="checkAlteredForm()"
        >
          {{ t('Action_.buttons.cancel') }}
        </v-btn>
        <v-btn
          data-testid="recruitment-personal-data-save-changes-btn"
          class="main-action-btn ml-2"
          @click="updataApplicantData()"
        >
          {{ buttonText }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import helpers from '@/helpers/helpers';
import languages from '../../locales/languages';
import { usePermissions } from '@/helpers/composables/permissions.js';
import { usePeopleCandidatesStore } from '@/store/pinia/people-candidates.store.js';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { usePopupDialogStore } from '@/store/pinia/popup-dialog.store.js';
import { usePeopleRecruitmentContractStore } from '@/store/pinia/people-recruitment-contract.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { ref, computed, defineEmits } from 'vue';
import { onBeforeRouteLeave, useRouter } from 'vue-router/composables';

const peopleRecruitmentContractStore = usePeopleRecruitmentContractStore();
const popupDialogStore = usePopupDialogStore();
const router = useRouter();
const emit = defineEmits(['update-applicant']);
const loadingStore = useLoadingStore();
const peopleCandidatesStore = usePeopleCandidatesStore();
const { canUser } = usePermissions();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ----
const isFormDisabled = ref(true);
const ignoreFormChanges = ref(false);
const nextRoute = ref(null);

//  --- Methods ---
const toggleDisabled = () => {
  isFormDisabled.value = !isFormDisabled.value;
};

const updataApplicantData = () => {
  toggleDisabled();
  emit('update-applicant');
};

const checkAlteredForm = () => {
  if (isFormAltered.value) {
    displayConfirmationDialog();
  } else {
    toggleDisabled();
  }
};

const displayConfirmationDialog = () => {
  const params = {
    type: 'confirmation',
    title: t('Action_.dialog.saveChanges.title'),
    message: t('Action_.dialog.saveChanges.body'),
    reinforcement: t('Action_.dialog.saveChanges.reinforcement'),
    actionBtnText: t('Action_.buttons.confirm'),
    actionBtn: discardChanges,
  };

  popupDialogStore.params = params;
  popupDialogStore.displayDialog = true;
};

const discardChanges = () => {
  ignoreFormChanges.value = true;
  if (nextRoute.value) {
    router.push(nextRoute.value);
  } else {
    restoreInitialData();
    toggleDisabled();
  }
};

const restoreInitialData = () => {
  peopleRecruitmentContractStore.applicant = {
    ...{},
    ...peopleRecruitmentContractStore.applicantInitialData,
  };
};

const displaySaveInProgressDialog = () => {
  const params = {
    type: 'regular',
    title: t('Msg_.workInProgress'),
    message: t('Msg_.waitToFinishTheProcess'),
  };

  popupDialogStore.params = params;
  popupDialogStore.displayDialog = true;
};

// --- Computed ---
const buttonText = computed(() => {
  return !isFormDisabled.value
    ? t('Action_.buttons.saveChanges')
    : t('Action_.buttons.editInformation');
});

const isFormAltered = computed(() => {
  return !helpers.compareTwoObjects(
    peopleRecruitmentContractStore.applicant,
    peopleRecruitmentContractStore.applicantInitialData
  );
});

const isLoading = computed(() => {
  return loadingStore.isPartialLoading || loadingStore.isGlobalLoading;
});

const isHiringMenuSelected = computed(() => {
  return peopleCandidatesStore.selectedMenuItem === 'hiring';
});

const isHandoverMenuSelected = computed(() => {
  return peopleCandidatesStore.selectedMenuItem === 'handover';
});

const showEditInformationButton = computed(() => {
  if (
    (isHiringMenuSelected.value || isHandoverMenuSelected.value) &&
    !canUser(['People.Candidates.GenerateContract'])
  ) {
    return false;
  }
  return true;
});

// --- Routing guards ---
onBeforeRouteLeave((to, _, next) => {
  if (isLoading.value) {
    displaySaveInProgressDialog();
  } else {
    if (!isFormAltered.value || ignoreFormChanges.value) {
      nextRoute.value = to;
    }
    if (nextRoute.value) {
      next();
    } else {
      nextRoute.value = to;
      displayConfirmationDialog(next);
    }
  }
});
</script>

<style lang="scss" scoped></style>
