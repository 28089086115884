import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useMainStore } from '@/store/pinia/main.store.js';
import { useLoadingStore } from '@/store/pinia/loading.store.js';

export const usePeoplePendingEmployeesStore = defineStore(
  'peoplePendingEmployees',
  () => {
    const mainStore = useMainStore();
    const loadingStore = useLoadingStore();

    // --- State ---
    const showProfileIndex = ref(null);
    const pendingEmployeesList = ref([]);

    // --- Methods ---
    const getPendingEmployeeList = async (data) => {
      const urlencoded = new URLSearchParams();
      urlencoded.append('searchPattern', data.searchTerm || '');
      urlencoded.append('hubId', data.hubId || '');

      const params = {
        endpoint: `v2/api/precontract`,
        configs: {
          method: 'GET',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: urlencoded,
        },
      };

      loadingStore.startPartialLoading('getPendingEmployeeList');
      try {
        const res = await mainStore.request(params);
        pendingEmployeesList.value = res.items;
      } catch (error) {
        return Promise.reject(error);
      } finally {
        loadingStore.endPartialLoading('getPendingEmployeeList');
      }
    };

    return {
      showProfileIndex,
      pendingEmployeesList,
      getPendingEmployeeList,
    };
  }
);
