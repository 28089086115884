<template>
  <v-app>
    <!-- <ToastNotification ref="vtoast" /> -->
    <ToastNotification2 />
    <template v-if="isUserLogged">
      <UserDrawerDetails />
      <NavigationDrawer />
      <Toolbar />
      <v-main id="app_background">
        <router-view v-show="!loadingStore.isGlobalLoading" id="main-content" />
        <div v-if="loadingStore.isGlobalLoading" id="loading-container">
          <LoadingAnimation />
        </div>
      </v-main>
    </template>
    <template v-else>
      <v-main> <router-view /> </v-main>
    </template>
    <MainFooter />
    <ModalDialog />
  </v-app>
</template>

<script setup>
import ToastNotification2 from '@/components/shared/customComponents/alertsBadges/toast-notification-2.vue';
import NavigationDrawer from './components/shared/layout/navigationDrawer/navigation-drawer.vue';
import Toolbar from './components/shared/layout/toolbar/toolbar.vue';
import MainFooter from './components/shared/layout/footer/main-footer.vue';
import UserDrawerDetails from './components/shared/layout/toolbar/user-drawer-details.vue';
import LoadingAnimation from '@/components/shared/customComponents/loadingAnimation/loading-animation.vue';
import ModalDialog from '@/components/shared/customComponents/dialogs/modal-dialog.vue';
import { computed } from 'vue';
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';
import { useMainStore } from '@/store/pinia/main.store.js';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { useRouteTitle } from './helpers/composables/routeTitle';

// Sync document title with route title

const { syncRouteTitle } = useRouteTitle();

syncRouteTitle();

const { amplitudeGetAllHubs } = amplitudeComposable();
const mainStore = useMainStore();
const loadingStore = useLoadingStore();

const isUserLogged = computed(() => {
  return mainStore.isAuthenticated;
});

amplitudeGetAllHubs();
</script>

<style lang="scss">
#app_background {
  background-color: $app-background;
}

#main-content {
  @extend %content-spacing;
}

#loading-container {
  @extend %content-spacing;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
}

// --- Global Legacy Styles ---

// --- login styles ---
.login-title {
  @extend %login-title;
}

.login-subtitle {
  @extend %login-subtitle;
}

// --- project styles ---
.card-title {
  @extend %card-title;
}

.section-title {
  @extend %section-title;
}

.section-detail {
  @extend %section-detail;
}

.stepper-text {
  @extend %stepper-text;
}

.uppercase-header {
  @extend %uppercase-header;
}

.v-btn {
  @extend %v-btn;
}

.btn-regular {
  @extend %btn-regular;
}

.btn-text {
  @extend %btn-text;
}

.checkbox-label {
  @extend %checkbox-label;
}

.link {
  @extend %link;
}

// Buttons

.default-btn {
  @extend %default-btn;
}

.action-btn {
  @extend %action-btn;
}

.main-action-icon-btn {
  @extend %main-action-icon-btn;
}

.main-action-btn {
  @extend %main-action-btn;
}

.main-action-variant-btn {
  @extend %main-action-variant-btn;
}

.main-action-variant-transparent-btn {
  @extend %main-action-variant-transparent-btn;
}

.main-action-variant-no-border-transparent-btn {
  @extend %main-action-variant-no-border-transparent-btn;
}

.cancel-outlined-btn {
  @extend %cancel-outlined-btn;
}

.cancel-variant-btn {
  @extend %cancel-variant-btn;
}

.reject-cancel {
  @extend %reject-cancel;
}

.icon-btn {
  @extend %icon-btn;
}

.dropdown-btn {
  @extend %dropdown-btn;
}

.upload-file-btn {
  @extend %upload-file-btn;
}

.upload-file-btn-variant {
  @extend %upload-file-btn-variant;
}

// --- Global Styles ---

// * Box shadow *
.shadow-regular {
  @extend %shadow-regular;
}

.shadow-large {
  @extend %shadow-large;
}

.shadow-small {
  @extend %shadow-small;
}

.shadow-hover {
  @extend %shadow-hover;
}

// * Card border *
.border-grey-small {
  @extend %border-grey-small;
}

// * Typography *
// Heading
.heading-h1 {
  @extend %heading-h1;
}

.heading-h2 {
  @extend %heading-h2;
}

.heading-h3 {
  @extend %heading-h3;
}

.heading-h4 {
  @extend %heading-h4;
}

// Subtitle
.subtitle-text-1 {
  @extend %subtitle-text-1;
}

.subtitle-text-2 {
  @extend %subtitle-text-2;
}

.subtitle-text-3 {
  @extend %subtitle-text-3;
}

// Body
.body-text-1 {
  @extend %body-text-1;
}

.body-text-2 {
  @extend %body-text-2;
}

.body-text-3 {
  @extend %body-text-3;
}

.body-text-4 {
  @extend %body-text-4;
}

// Overline
.overline-1 {
  @extend %overline-1;
}

.overline-2 {
  @extend %overline-2;
}

.overline-3 {
  @extend %overline-3;
}

// Caption
.caption-1 {
  @extend %caption-1;
}

.caption-2 {
  @extend %caption-2;
}

.caption-3 {
  @extend %caption-3;
}

.caption-4 {
  @extend %caption-4;
}

.button-1 {
  @extend %button-1;
}
</style>
