import { defineStore } from 'pinia';
import { useMainStore } from '@/store/pinia/main.store.js';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { ref } from 'vue';

export const useHubsStore = defineStore('hubs', () => {
  const mainStore = useMainStore();
  const loadingStore = useLoadingStore();

  // --- State ---
  const hubList = ref([]);
  const hubInfo = ref({
    id: null,
    name: null,
    status: null,
    hubType: null,
    companyName: null,
    companyId: null,
    hubNumber: null,
    mainResponsibleTeamPlannerId: null,
    mainResponsibleTeamPlannerName: null,
    hubBubble: {
      billingHubId: null,
      billingHubName: null,
      subHubs: [
        {
          hubId: null,
          hubName: null,
        },
      ],
    },
  });
  const hubResponsibleEmployees = ref({
    mainResponsibleTeamPlanners: [
      {
        id: '',
        name: '',
      },
    ],
    coaches: [
      {
        id: '',
        name: '',
      },
    ],
    growthResponsible: [
      {
        id: '',
        name: '',
      },
    ],
  });
  const billingHubs = ref(null);
  const companies = ref(null);

  // --- Methods ---
  const getHubList = async (data) => {
    const urlencoded = new URLSearchParams();
    if (data) {
      urlencoded.append('Page', data.pagination.page);
      urlencoded.append('PageSize', data.pagination.pageSize);
      urlencoded.append('SearchTerm', data.pageOptions.searchTerm);
      urlencoded.append('State', data.pageOptions.state);
      urlencoded.append('Status', data.pageOptions.status);
    }

    const params = {
      endpoint: `v2/api/hubs`,
      configs: {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: urlencoded,
      },
    };

    loadingStore.startPartialLoading('getHubList');
    try {
      hubList.value = await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('getHubList');
    }
  };

  const getHubInfoById = async (hubId) => {
    const params = {
      endpoint: `v2/api/hubs/${hubId}`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startPartialLoading('getHubInfoById');
    try {
      hubInfo.value = await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('getHubInfoById');
    }
  };

  const createHub = async (payload) => {
    const params = {
      endpoint: `v2/api/hubs`,
      configs: {
        method: 'POST',
        body: payload,
      },
    };

    loadingStore.startGlobalLoading('createHub');
    try {
      await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endGlobalLoading('createHub');
    }
  };

  const getHubStatsById = async (hubId) => {
    const params = {
      endpoint: `v2/api/hubs/${hubId}/stats`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startPartialLoading('getHubStatsById');
    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('getHubStatsById');
    }
  };

  const getHubContactInfoById = async (hubId) => {
    const params = {
      endpoint: `v2/api/hubs/${hubId}/contact`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startPartialLoading('getHubContactInfoById');
    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('getHubContactInfoById');
    }
  };

  const updateHubContactInfoById = async (payload) => {
    const params = {
      endpoint: `v2/api/hubs/${payload.hubId}/contact`,
      configs: {
        method: 'PUT',
        body: payload.hubContactInfo,
      },
    };

    loadingStore.startPartialLoading('updateHubContactInfoById');
    try {
      await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('updateHubContactInfoById');
    }
  };

  const getHubResponsibleEmployeesById = async (hubId) => {
    const params = {
      endpoint: `v2/api/hubs/${hubId}/responsible-employees`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startPartialLoading('getHubResponsibleEmployeesById');
    try {
      hubResponsibleEmployees.value = await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('getHubResponsibleEmployeesById');
    }
  };

  const getHubBuildingInfoById = async (hubId) => {
    const params = {
      endpoint: `v2/api/hubs/${hubId}/building`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startPartialLoading('getHubBuildingInfoById');
    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('getHubBuildingInfoById');
    }
  };

  const updateHubBuildingInfoById = async (payload) => {
    const params = {
      endpoint: `v2/api/hubs/${payload.hubId}/building`,
      configs: {
        method: 'PUT',
        body: payload.hubBuildingInfo,
      },
    };

    loadingStore.startPartialLoading('updateHubBuildingInfoById');
    try {
      await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('updateHubBuildingInfoById');
    }
  };

  const getHubGeneralInfoById = async (hubId) => {
    const params = {
      endpoint: `v2/api/hubs/${hubId}/operations-general`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startPartialLoading('getHubGeneralInfoById');
    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('getHubGeneralInfoById');
    }
  };

  const updateHubGeneralInfoById = async (payload) => {
    const params = {
      endpoint: `v2/api/hubs/${payload.hubId}/operations-general`,
      configs: {
        method: 'PUT',
        body: payload.hubGeneralInfo,
      },
    };

    loadingStore.startPartialLoading('updateHubGeneralInfoById');
    try {
      await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('updateHubGeneralInfoById');
    }
  };

  const getBillingHubInfoById = async (hubId) => {
    const params = {
      endpoint: `v2/api/hubs/${hubId}/operations-billinghub`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startPartialLoading('getBillingHubInfoById');
    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('getBillingHubInfoById');
    }
  };

  const updateBillingHubInfoById = async (payload) => {
    const params = {
      endpoint: `v2/api/hubs/${payload.hubId}/operations-billinghub`,
      configs: {
        method: 'PUT',
        body: payload.billingHubInfo,
      },
    };

    loadingStore.startPartialLoading('updateBillingHubInfoById');
    try {
      await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('updateBillingHubInfoById');
    }
  };

  const getHubTotalWeeklyWorkingHoursById = async (hubId) => {
    const params = {
      endpoint: `v2/api/hubs/${hubId}/operations-workinghours`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startPartialLoading('getHubTotalWeeklyWorkingHoursById');
    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('getHubTotalWeeklyWorkingHoursById');
    }
  };

  const getHubInsuranceContractEmployeesById = async (hubId) => {
    const params = {
      endpoint: `v2/api/hubs/${hubId}/operations-insurance`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startPartialLoading('getHubInsuranceContractEmployeesById');
    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('getHubInsuranceContractEmployeesById');
    }
  };

  const updateHubInsuranceContractEmployeesById = async (payload) => {
    const params = {
      endpoint: `v2/api/hubs/${payload.hubId}/operations-insurance`,
      configs: {
        method: 'PUT',
        body: payload.employeeIds,
      },
    };

    loadingStore.startPartialLoading('updateHubInsuranceContractEmployeesById');
    try {
      await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('updateHubInsuranceContractEmployeesById');
    }
  };

  const getHubEmployeesById = async (hubId) => {
    const params = {
      endpoint: `v2/api/people/resources/hubs/${hubId}/employees`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startPartialLoading('getHubEmployeesById');
    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('getHubEmployeesById');
    }
  };

  const getBillingHubs = async () => {
    const params = {
      endpoint: 'v2/api/hubs/billingHubs',
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startPartialLoading('getBillingHubs');
    try {
      billingHubs.value = await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('getBillingHubs');
    }
  };

  const getCompanies = async () => {
    const params = {
      endpoint: 'v2/api/companies',
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startPartialLoading('getCompanies');
    try {
      companies.value = await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('getCompanies');
    }
  };

  const getHubEmployeeList = async (hubId) => {
    const urlencoded = new URLSearchParams();
    if (hubId) {
      urlencoded.append('OfficeId', hubId);
      urlencoded.append('FormatForHubs', true);
    }

    const params = {
      endpoint: `v2/api/employees`,
      configs: {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: urlencoded,
      },
    };

    loadingStore.startPartialLoading('getHubEmployeeList');
    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('getHubEmployeeList');
    }
  };

  const getHubHistoryById = async (hubId) => {
    const params = {
      endpoint: `v2/api/hubs/${hubId}/history`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startPartialLoading('getHubHistoryById');
    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('getHubHistoryById');
    }
  };

  const getHubDownloadsById = async (hubId) => {
    const params = {
      endpoint: `v2/api/hubs/${hubId}/downloads`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startPartialLoading('getHubDownloadsById');
    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('getHubDownloadsById');
    }
  };

  return {
    hubList,
    hubInfo,
    hubResponsibleEmployees,
    billingHubs,
    companies,
    getHubList,
    getHubInfoById,
    createHub,
    getHubStatsById,
    getHubContactInfoById,
    updateHubContactInfoById,
    getHubResponsibleEmployeesById,
    getHubBuildingInfoById,
    updateHubBuildingInfoById,
    getHubGeneralInfoById,
    updateHubGeneralInfoById,
    getBillingHubInfoById,
    updateBillingHubInfoById,
    getHubTotalWeeklyWorkingHoursById,
    getHubInsuranceContractEmployeesById,
    updateHubInsuranceContractEmployeesById,
    getHubEmployeesById,
    getBillingHubs,
    getCompanies,
    getHubEmployeeList,
    getHubHistoryById,
    getHubDownloadsById,
  };
});
