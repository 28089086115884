<template>
  <router-view></router-view>
</template>

<script setup>
import { onBeforeUnmount } from 'vue';
import { usePeopleRecruitmentContractStore } from '@/store/pinia/people-recruitment-contract.store.js';
import { usePeopleApplicantsStore } from '@/store/pinia/people-applicants.store.js';

const peopleRecruitmentContractStore = usePeopleRecruitmentContractStore();
const peopleApplicantsStore = usePeopleApplicantsStore();

// --- Methods ---
const applyRecruitType = () => {
  peopleRecruitmentContractStore.recruitType = 'applicant';
};

// --- Lifecycle hooks ---
applyRecruitType();

onBeforeUnmount(() => {
  peopleApplicantsStore.$partialReset('showProfileIndex');
  peopleRecruitmentContractStore.$partialReset('recruitType');
  peopleRecruitmentContractStore.$partialReset('selectedHubId');
});
</script>
