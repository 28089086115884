<template>
  <v-container data-testid="hub-list" class="mt-2">
    <v-row>
      <v-col>
        <h2 data-testid="hub-list-header" class="heading-h2">Hubs</h2>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="6">
        <SearchInput
          data-testid="hub-list-search-input"
          :label="t('Msg_.searchHub')"
          :placeholder="t('Msg_.searchPlaceholder')"
          v-model="pageOptions.searchTerm"
          @update-list="updateHubList()"
        />
      </v-col>
      <v-col cols="2">
        <DropdownInput
          data-testid="hub-list-all-states-dropdown"
          :label="t('Msg_.allStates')"
          :clearable="false"
          :items="selectStateTypes"
          v-model="pageOptions.state"
          @change="updateHubList()"
        />
      </v-col>
      <v-col cols="2">
        <DropdownInput
          data-testid="hub-list-all-status-dropdown"
          :label="t('Msg_.allStatus')"
          :clearable="false"
          :items="selectOperationTypes"
          v-model="pageOptions.status"
          @change="updateHubList()"
        />
      </v-col>
      <v-col class="ml-auto text-right" cols="2">
        <v-btn
          data-testid="hub-list-add-hub-btn"
          class="main-action-btn mb-1"
          :to="{ name: 'HubForm' }"
          v-if="canUser(['Hubs.Create'])"
        >
          {{ t('Action_.buttons.addNewHub') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-container class="pa-0" v-if="displayHubs">
      <v-row v-for="(hub, index) in hubsStore.hubList.items" :key="hub.id">
        <v-col class="my-n1">
          <HubListItem :hub="hub" :index="index" />
        </v-col>
      </v-row>
      <Pagination
        data-testid="hub-list-pagination"
        type="wide"
        v-model="pagination"
        @update-list="updateHubList()"
      />
    </v-container>
    <EmptyListMessage
      v-else-if="displayEmptyState"
      data-testid="hub-list-no-data"
    />
    <v-progress-linear
      v-else-if="loadingStore.isPartialLoading"
      data-testid="hub-list-loading"
      indeterminate
      rounded
      color="blueRegular"
    />
  </v-container>
</template>

<script setup>
import HubListItem from './hub-list-item.vue';
import SearchInput from '@/components/shared/customComponents/inputs/search-input.vue';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
import EmptyListMessage from '@/components/shared/customComponents/emptyStates/empty-list-message.vue';
import Pagination from '@/components/shared/customComponents/paginations/pagination.vue';
import helpers from '@/helpers/helpers';
import languages from './locales/languages';
import { useI18n } from 'vue-i18n-bridge';
import { useHubsStore } from '@/store/pinia/hubs.store.js';
import { usePeopleStore } from '@/store/pinia/people.store.js';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { usePagination } from '@/helpers/composables/pagination.js';
import { usePermissions } from '@/helpers/composables/permissions.js';
import { ref, computed } from 'vue';
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';

const { trackPage } = amplitudeComposable();
const hubsStore = useHubsStore();
const peopleStore = usePeopleStore();
const loadingStore = useLoadingStore();
const { canUser } = usePermissions();
const { t, tm } = useI18n({ locale: 'en', messages: languages });
const {
  pagination,
  validatePaginationData,
  validatePageOptions,
  updatePaginationData,
} = usePagination();

// --- Local variables ---
const pageOptions = ref({
  searchTerm: '',
  state: 0,
  status: 0,
});

// --- Methods ---
const getHubList = async () => {
  validatePaginationData();
  const payload = {
    pagination: pagination.value,
    pageOptions: pageOptions.value,
  };

  try {
    trackPage();
    await hubsStore.getHubList(payload);
    updatePaginationData(hubsStore.hubList);
  } catch (error) {
    console.log(error);
  }
};

const updateHubList = () => {
  validatePageOptions();
  if (!pageOptions.value.state) pageOptions.value.state = 0;
  if (!pageOptions.value.status) pageOptions.value.status = 0;
  getHubList();
};

// --- Computed ---
const displayHubs = computed(() => {
  return hubsStore.hubList && !loadingStore.isPartialLoading;
});

const displayEmptyState = computed(() => {
  return !hubsStore.hubList?.length && !loadingStore.isPartialLoading;
});

const selectStateTypes = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectStateTypes'),
    peopleStore.peopleResources?.stateTypes
  );
});

const selectOperationTypes = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectOperationTypes'),
    peopleStore.peopleResources?.operationTypes
  );
});

// --- Lifecycle hooks ---
getHubList();
</script>

<style scoped lang="scss">
.vertical-divider {
  border-left: 1px solid #9e9e9e;
  height: 22px;
}
</style>
