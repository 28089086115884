var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-form',{ref:"form",model:{value:(_setup.isFormValid),callback:function ($$v) {_setup.isFormValid=$$v},expression:"isFormValid"}},[_c('v-container',[_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"4"}},[_c(_setup.DropdownInput,{attrs:{"data-testid":"recruitment-typeform1-german-work-permit-dropdown","label":`${_setup.t('Msg_.germanWorkingPermit')}*`,"disabled":_setup.isFormDisabled,"clearable":false,"items":_setup.selectBooleanOptions,"rules":[
              _setup.rules.fieldRequired(
                `${_setup.t('Msg_.germanWorkingPermit')} ${_setup.t('Msg_.isRequired')}`,
                false
              ),
            ]},model:{value:(
              _setup.peopleRecruitmentContractStore.applicant.germanWorkingPermit
            ),callback:function ($$v) {_vm.$set(_setup.peopleRecruitmentContractStore.applicant, "germanWorkingPermit", $$v)},expression:"\n              peopleRecruitmentContractStore.applicant.germanWorkingPermit\n            "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c(_setup.DropdownInput,{attrs:{"data-testid":"recruitment-typeform1-driver-license-class-b-dropdown","label":`${_setup.t('Msg_.driverLicenseClassB')}*`,"disabled":_setup.isFormDisabled,"clearable":false,"items":_setup.selectBooleanOptions,"rules":[
              _setup.rules.fieldRequired(
                `${_setup.t('Msg_.driverLicenseClassB')} ${_setup.t('Msg_.isRequired')}`,
                false
              ),
            ]},model:{value:(
              _setup.peopleRecruitmentContractStore.applicant.driverLicenseClassB
            ),callback:function ($$v) {_vm.$set(_setup.peopleRecruitmentContractStore.applicant, "driverLicenseClassB", $$v)},expression:"\n              peopleRecruitmentContractStore.applicant.driverLicenseClassB\n            "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c(_setup.DropdownInput,{attrs:{"data-testid":"recruitment-typeform1-highest-school-degree-dropdown","label":`${_setup.t('Msg_.highestSchoolDegree')}*`,"disabled":_setup.isFormDisabled,"clearable":false,"items":_setup.selectLevelEducationType,"rules":[
              _setup.rules.fieldRequired(
                `${_setup.t('Msg_.highestSchoolDegree')} ${_setup.t('Msg_.isRequired')}`
              ),
            ]},model:{value:(
              _setup.peopleRecruitmentContractStore.applicant.highestSchoolDegree
            ),callback:function ($$v) {_vm.$set(_setup.peopleRecruitmentContractStore.applicant, "highestSchoolDegree", $$v)},expression:"\n              peopleRecruitmentContractStore.applicant.highestSchoolDegree\n            "}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.DropdownInput,{attrs:{"data-testid":"recruitment-typeform1-another-job-dropdown","label":`${_setup.t('Msg_.anotherJob')}*`,"disabled":_setup.isFormDisabled,"clearable":false,"items":_setup.selectBooleanOptions,"rules":[
              _setup.rules.fieldRequired(
                `${_setup.t('Msg_.anotherJob')} ${_setup.t('Msg_.isRequired')}`,
                false
              ),
            ]},on:{"change":function($event){return _setup.handleAnotherJobCompensationType($event)}},model:{value:(_setup.anotherJob),callback:function ($$v) {_setup.anotherJob=$$v},expression:"anotherJob"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.DropdownInput,{attrs:{"data-testid":"recruitment-typeform1-another-job-compensation-type-dropdown","label":`${_setup.t('Msg_.anotherJobCompensationType')}*`,"disabled":_setup.isFormDisabled || _setup.disableAnotherJobCompensationType,"clearable":false,"items":_setup.selectCompensationTypes,"rules":[
              _setup.rules.fieldRequired(
                `${_setup.t('Msg_.anotherJobCompensationType')} ${_setup.t(
                  'Msg_.isRequired'
                )}`
              ),
            ]},on:{"change":function($event){return _setup.handleHasAnotherJob($event)}},model:{value:(
              _setup.peopleRecruitmentContractStore.applicant.contractTypeForOthers
            ),callback:function ($$v) {_vm.$set(_setup.peopleRecruitmentContractStore.applicant, "contractTypeForOthers", $$v)},expression:"\n              peopleRecruitmentContractStore.applicant.contractTypeForOthers\n            "}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.DropdownInput,{attrs:{"data-testid":"recruitment-typeform1-t-shirt-size-dropdown","label":`${_setup.t('Msg_.tShirtSize')}*`,"disabled":_setup.isFormDisabled,"clearable":false,"items":_setup.selectTShirtSizeTypes,"rules":[
              _setup.rules.fieldRequired(
                `${_setup.t('Msg_.tShirtSize')} ${_setup.t('Msg_.isRequired')}`,
                0
              ),
            ]},model:{value:(_setup.peopleRecruitmentContractStore.applicant.tShirtSize),callback:function ($$v) {_vm.$set(_setup.peopleRecruitmentContractStore.applicant, "tShirtSize", $$v)},expression:"peopleRecruitmentContractStore.applicant.tShirtSize"}})],1)],1),_c('v-row',{staticClass:"mt-12"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.DropdownInput,{attrs:{"data-testid":"recruitment-typeform1-main-hub-field","label":`${_setup.t('Msg_.mainHub')}*`,"disabled":_setup.isFormDisabled,"clearable":false,"item-text":"name","item-value":"id","items":_setup.peopleStore.peopleHubList,"value":_setup.peopleRecruitmentContractStore.applicant.mainHub,"rules":[
              _setup.rules.fieldRequired(
                `${_setup.t('Msg_.mainHub')} ${_setup.t('Msg_.isRequired')}`
              ),
            ]},on:{"change":function($event){return _setup.handleMainHubOptions($event)}}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"data-testid":"recruitment-typeform1-billing-hub-field","label":_setup.t('Msg_.billingHub'),"disabled":"","value":_setup.billingHubName}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"data-testid":"recruitment-typeform1-company-field","label":_setup.t('Msg_.company'),"disabled":"","value":_setup.companyName}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.DropdownInput,{attrs:{"data-testid":"recruitment-typeform1-recruitment-type-dropdown","label":`${_setup.t('Msg_.contractType')}*`,"disabled":_setup.isFormDisabled,"clearable":false,"items":_setup.selectContractTypes,"rules":[
              _setup.rules.fieldRequired(
                `${_setup.t('Msg_.contractType')} ${_setup.t('Msg_.isRequired')}`
              ),
            ]},on:{"change":function($event){return _setup.handleCompensationType()}},model:{value:(
              _setup.peopleRecruitmentContractStore.applicant.contractTypeForKenbi
            ),callback:function ($$v) {_vm.$set(_setup.peopleRecruitmentContractStore.applicant, "contractTypeForKenbi", $$v)},expression:"\n              peopleRecruitmentContractStore.applicant.contractTypeForKenbi\n            "}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.DropdownInput,{attrs:{"data-testid":"recruitment-typeform1-compensation-type-dropdown","label":_setup.t('Msg_.compensationType'),"disabled":"","clearable":"","items":_setup.selectCompensationTypes},model:{value:(_setup.compensationType),callback:function ($$v) {_setup.compensationType=$$v},expression:"compensationType"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.DropdownInput,{attrs:{"data-testid":"recruitment-typeform1-job-position-dropdown","label":`${_setup.t('Msg_.jobPosition')}*`,"disabled":_setup.isFormDisabled,"clearable":false,"items":_setup.selectJobPosition,"rules":[
              _setup.rules.fieldRequired(
                `${_setup.t('Msg_.jobPosition')} ${_setup.t('Msg_.isRequired')}`
              ),
            ]},model:{value:(_setup.peopleRecruitmentContractStore.applicant.jobPosition),callback:function ($$v) {_vm.$set(_setup.peopleRecruitmentContractStore.applicant, "jobPosition", $$v)},expression:"peopleRecruitmentContractStore.applicant.jobPosition"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.Combobox,{attrs:{"data-testid":"recruitment-typeform1-additional-training-field","label":`${_setup.t('Msg_.additionalTraining')}*`,"items":_setup.selectAdditionalTrainingType,"value":_setup.recruitAdditionalTraining,"disabled":_setup.isFormDisabled,"clearable":false,"rules":[
              _setup.rules.fieldRequired(
                `${_setup.t('Msg_.additionalTraining')} ${_setup.t('Msg_.isRequired')}`
              ),
            ]},on:{"click":function($event){return _setup.handleAdditionalTraining($event)}}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"data-testid":"recruitment-typeform1-years-of-experience-field","label":`${_setup.t('Msg_.yearsOfExperience')}*`,"disabled":_setup.isFormDisabled,"rules":[
              _setup.rules.fieldRequired(
                `${_setup.t('Msg_.yearsOfExperience')} ${_setup.t('Msg_.isRequired')}`,
                0
              ),
            ]},model:{value:(
              _setup.peopleRecruitmentContractStore.applicant.yearsOfExperience
            ),callback:function ($$v) {_vm.$set(_setup.peopleRecruitmentContractStore.applicant, "yearsOfExperience", $$v)},expression:"\n              peopleRecruitmentContractStore.applicant.yearsOfExperience\n            "}})],1)],1),_c('v-row',_vm._l((_setup.peopleRecruitmentContractStore
            .applicant.workingDays),function(weekDay,key,index){return _c('v-col',{key:key,staticClass:"flex-grow-1 pt-0",attrs:{"cols":"auto"}},[_c(_setup.SwitchControl,{attrs:{"data-testid":`recruitment-typeform1-${key}-switch`,"label":_setup.t(`Action_.selectWeekDays[${index}]`),"disabled":_setup.isFormDisabled},on:{"change":function($event){return _setup.handleTotalWeeklyWorkingDays()}},model:{value:(
              _setup.peopleRecruitmentContractStore.applicant.workingDays[key]
            ),callback:function ($$v) {_vm.$set(_setup.peopleRecruitmentContractStore.applicant.workingDays, key, $$v)},expression:"\n              peopleRecruitmentContractStore.applicant.workingDays[key]\n            "}})],1)}),1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.DatePicker,{attrs:{"data-testid":"recruitment-typeform1-start-date-field","label":`${_setup.t('Msg_.startDate')}*`,"disabled":_setup.isFormDisabled,"rules":[
              _setup.rules.fieldRequired(
                `${_setup.t('Msg_.startDate')} ${_setup.t('Msg_.isRequired')}`
              ),
            ]},model:{value:(_setup.peopleRecruitmentContractStore.applicant.startDate),callback:function ($$v) {_vm.$set(_setup.peopleRecruitmentContractStore.applicant, "startDate", $$v)},expression:"peopleRecruitmentContractStore.applicant.startDate"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"data-testid":"recruitment-typeform1-weekly-working-hours-field","label":`${_setup.t('Msg_.hourPerWeek')}*`,"disabled":_setup.isFormDisabled,"rules":[
              _setup.rules.fieldRequired(
                `${_setup.t('Msg_.hourPerWeek')} ${_setup.t('Msg_.isRequired')}`
              ),
            ]},on:{"change":function($event){return _setup.handleCompensationType($event)}},model:{value:(
              _setup.peopleRecruitmentContractStore.applicant.weeklyWorkingHours
            ),callback:function ($$v) {_vm.$set(_setup.peopleRecruitmentContractStore.applicant, "weeklyWorkingHours", $$v)},expression:"\n              peopleRecruitmentContractStore.applicant.weeklyWorkingHours\n            "}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"data-testid":"recruitment-typeform1-vacation-days-field","label":_setup.t('Msg_.vacationDaysPerYear'),"disabled":""},model:{value:(_setup.vacationDaysPerYear),callback:function ($$v) {_setup.vacationDaysPerYear=$$v},expression:"vacationDaysPerYear"}})],1)],1),_c('v-row',{staticClass:"mt-12"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{attrs:{"data-testid":"recruitment-typeform1-job-level-dropdown","clearable":false,"items":_setup.selectJobLevelTypes,"label":`${_setup.t('Msg_.jobLevel')}*`,"disabled":_setup.isFormDisabled,"rules":[
              _setup.rules.fieldRequired(
                `${_setup.t('Msg_.jobLevel')} ${_setup.t('Msg_.isRequired')}`,
                0
              ),
            ]},model:{value:(_setup.peopleRecruitmentContractStore.applicant.jobLevel),callback:function ($$v) {_vm.$set(_setup.peopleRecruitmentContractStore.applicant, "jobLevel", $$v)},expression:"peopleRecruitmentContractStore.applicant.jobLevel"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{attrs:{"data-testid":"recruitment-typeform1-experience-level-dropdown","clearable":false,"items":_setup.selectExperienceLevelTypes,"label":`${_setup.t('Msg_.experienceLevel')}*`,"disabled":_setup.isFormDisabled,"rules":[
              _setup.rules.fieldRequired(
                `${_setup.t('Msg_.experienceLevel')} ${_setup.t('Msg_.isRequired')}`,
                0
              ),
            ]},model:{value:(_setup.peopleRecruitmentContractStore.applicant.experienceLevel),callback:function ($$v) {_vm.$set(_setup.peopleRecruitmentContractStore.applicant, "experienceLevel", $$v)},expression:"peopleRecruitmentContractStore.applicant.experienceLevel"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{attrs:{"data-testid":"recruitment-typeform1-city-bonus-field","maxlength":"12","icon":"mdi-currency-eur","label":`${_setup.t('Msg_.cityBonus')}*`,"value":_setup.helpers.convertValueToCurrency(
                _setup.peopleRecruitmentContractStore.applicant.cityBonus
              ),"disabled":_setup.isFormDisabled,"rules":[
              _setup.rules.fieldRequired(
                `${_setup.t('Msg_.cityBonus')} ${_setup.t('Msg_.isRequired')}`,
                0
              ),
            ]},on:{"change":function($event){_setup.updateCurrencyField(
                'cityBonus',
                _setup.helpers.convertValueToCurrency($event)
              )},"focus":function($event){_setup.updateCurrencyField(
                'cityBonus',
                _setup.helpers.convertCurrencyToFloat($event)
              )}}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{attrs:{"data-testid":"recruitment-typeform1-hourly-wage-field","maxlength":"12","icon":"mdi-currency-eur","label":`${_setup.t('Msg_.hourlyWage')}*`,"value":_setup.helpers.convertValueToCurrency(
                _setup.peopleRecruitmentContractStore.applicant.hourlyWage
              ),"disabled":_setup.isFormDisabled,"rules":[
              _setup.rules.fieldRequired(
                `${_setup.t('Msg_.hourlyWage')} ${_setup.t('Msg_.isRequired')}`,
                0
              ),
            ]},on:{"change":function($event){_setup.updateCurrencyField(
                'hourlyWage',
                _setup.helpers.convertValueToCurrency($event)
              )},"focus":function($event){_setup.updateCurrencyField(
                'hourlyWage',
                _setup.helpers.convertCurrencyToFloat($event)
              )}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"data-testid":"recruitment-typeform1-non-dynamic-allowance-field","disabled":_setup.isFormDisabled,"maxlength":"12","icon":"mdi-currency-eur","label":`${_setup.t('Msg_.nonDynamicAllowance')}*`,"value":_setup.helpers.convertValueToCurrency(
                _setup.peopleRecruitmentContractStore.applicant.nonDynamicAllowance
              ),"rules":[
              _setup.rules.fieldRequired(
                `${_setup.t('Msg_.nonDynamicAllowance')} ${_setup.t('Msg_.isRequired')}`,
                0
              ),
            ]},on:{"change":function($event){_setup.updateCurrencyField(
                'nonDynamicAllowance',
                _setup.helpers.convertValueToCurrency($event)
              )},"focus":function($event){_setup.updateCurrencyField(
                'nonDynamicAllowance',
                _setup.helpers.convertCurrencyToFloat($event)
              )}}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"disabled":_setup.isFormDisabled,"data-testid":"recruitment-typeform1-care-allowance-field","maxlength":"12","icon":"mdi-currency-eur","label":`${_setup.t('Msg_.careAllowance')}*`,"value":_setup.helpers.convertValueToCurrency(
                _setup.peopleRecruitmentContractStore.applicant.careAllowance
              ),"rules":[
              _setup.rules.fieldRequired(
                `${_setup.t('Msg_.careAllowance')} ${_setup.t('Msg_.isRequired')}`,
                0
              ),
            ]},on:{"change":function($event){_setup.updateCurrencyField(
                'careAllowance',
                _setup.helpers.convertValueToCurrency($event)
              )},"focus":function($event){_setup.updateCurrencyField(
                'careAllowance',
                _setup.helpers.convertCurrencyToFloat($event)
              )}}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"data-testid":"recruitment-typeform1-individual-allowance-field","disabled":_setup.isFormDisabled,"maxlength":"12","icon":"mdi-currency-eur","label":`${_setup.t('Msg_.individualAllowance')}*`,"value":_setup.helpers.convertValueToCurrency(
                _setup.peopleRecruitmentContractStore.applicant.individualAllowance
              ),"rules":[
              _setup.rules.fieldRequired(
                `${_setup.t('Msg_.individualAllowance')} ${_setup.t('Msg_.isRequired')}`,
                0
              ),
            ]},on:{"change":function($event){_setup.updateCurrencyField(
                'individualAllowance',
                _setup.helpers.convertValueToCurrency($event)
              )},"focus":function($event){_setup.updateCurrencyField(
                'individualAllowance',
                _setup.helpers.convertCurrencyToFloat($event)
              )}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"data-testid":"recruitment-typeform1-monthly-base-salary-field","disabled":_setup.isFormDisabled,"value":_setup.helpers.convertValueToCurrency(
                _setup.peopleRecruitmentContractStore.applicant.monthlyBaseSalary
              ),"maxlength":"12","icon":"mdi-currency-eur","label":`${_setup.t('Msg_.monthlyBaseSalary')}*`,"rules":[
              _setup.rules.fieldRequired(
                `${_setup.t('Msg_.monthlyBaseSalary')} ${_setup.t('Msg_.isRequired')}`,
                0
              ),
            ]},on:{"change":function($event){_setup.updateCurrencyField(
                'monthlyBaseSalary',
                _setup.helpers.convertValueToCurrency($event)
              )},"focus":function($event){_setup.updateCurrencyField(
                'monthlyBaseSalary',
                _setup.helpers.convertCurrencyToFloat($event)
              )}}})],1),_c('v-col',{staticClass:"mt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"data-testid":"recruitment-typeform1-thirteenth-salary-field","disabled":_setup.isFormDisabled,"maxlength":"12","icon":"mdi-currency-eur","label":`${_setup.t('Msg_.13thSalary')}*`,"value":_setup.helpers.convertValueToCurrency(
                _setup.peopleRecruitmentContractStore.applicant.thirteenthSalary
              ),"rules":[
              _setup.rules.fieldRequired(
                `${_setup.t('Msg_.13thSalary')} ${_setup.t('Msg_.isRequired')}`,
                0
              ),
            ]},on:{"change":function($event){_setup.updateCurrencyField(
                'thirteenthSalary',
                _setup.helpers.convertValueToCurrency($event)
              )},"focus":function($event){_setup.updateCurrencyField(
                'thirteenthSalary',
                _setup.helpers.convertCurrencyToFloat($event)
              )}}})],1),_c('v-col',{staticClass:"mt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"data-testid":"recruitment-typeform1-annual-salary-field","disabled":_setup.isFormDisabled,"maxlength":"12","icon":"mdi-currency-eur","label":`${_setup.t('Msg_.annualSalary')}*`,"value":_setup.helpers.convertValueToCurrency(
                _setup.peopleRecruitmentContractStore.applicant.annualSalary
              ),"rules":[
              _setup.rules.fieldRequired(
                `${_setup.t('Msg_.annualSalary')} ${_setup.t('Msg_.isRequired')}`,
                0
              ),
            ]},on:{"change":function($event){_setup.updateCurrencyField(
                'annualSalary',
                _setup.helpers.convertValueToCurrency($event)
              )},"focus":function($event){_setup.updateCurrencyField(
                'annualSalary',
                _setup.helpers.convertCurrencyToFloat($event)
              )}}})],1)],1),_c('v-row',{staticClass:"mt-12"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.DropdownInput,{attrs:{"data-testid":"recruitment-typeform1-probation-dropdown","clearable":false,"items":_setup.selectProbationType,"label":`${_setup.t('Msg_.probation')}*`,"disabled":_setup.isFormDisabled,"rules":[
              _setup.rules.fieldRequired(
                `${_setup.t('Msg_.probation')} ${_setup.t('Msg_.isRequired')}`
              ),
            ]},model:{value:(_setup.peopleRecruitmentContractStore.applicant.probation),callback:function ($$v) {_vm.$set(_setup.peopleRecruitmentContractStore.applicant, "probation", $$v)},expression:"peopleRecruitmentContractStore.applicant.probation"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.DropdownInput,{attrs:{"data-testid":"recruitment-typeform1-notice-during-probation-dropdown","clearable":false,"items":_setup.selectProbationNoticePeriodType,"label":`${_setup.t('Msg_.noticePeriodDuringProbation')}*`,"disabled":_setup.isFormDisabled,"rules":[
              _setup.rules.fieldRequired(
                `${_setup.t('Msg_.noticePeriodDuringProbation')} ${_setup.t(
                  'Msg_.isRequired'
                )}`,
                0
              ),
            ]},model:{value:(
              _setup.peopleRecruitmentContractStore.applicant
                .noticePeriodDuringProbation
            ),callback:function ($$v) {_vm.$set(_setup.peopleRecruitmentContractStore.applicant
                , "noticePeriodDuringProbation", $$v)},expression:"\n              peopleRecruitmentContractStore.applicant\n                .noticePeriodDuringProbation\n            "}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.DropdownInput,{attrs:{"data-testid":"recruitment-typeform1-notice-after-probation-dropdown","clearable":false,"items":_setup.selectProbationNoticePeriodAfterType,"label":`${_setup.t('Msg_.noticePeriodAfterProbation')}*`,"disabled":_setup.isFormDisabled,"rules":[
              _setup.rules.fieldRequired(
                `${_setup.t('Msg_.noticePeriodAfterProbation')} ${_setup.t(
                  'Msg_.isRequired'
                )}`,
                0
              ),
            ]},model:{value:(
              _setup.peopleRecruitmentContractStore.applicant
                .noticePeriodAfterProbation
            ),callback:function ($$v) {_vm.$set(_setup.peopleRecruitmentContractStore.applicant
                , "noticePeriodAfterProbation", $$v)},expression:"\n              peopleRecruitmentContractStore.applicant\n                .noticePeriodAfterProbation\n            "}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"data-testid":"recruitment-typeform1-interviewer-field","label":_setup.t('Msg_.interviewer'),"disabled":"","value":_setup.displayInterviewerValue}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.RegularTextInput,{attrs:{"data-testid":"recruitment-typeform1-device-field","label":_setup.t('Msg_.device'),"disabled":""},model:{value:(_setup.peopleRecruitmentContractStore.applicant.device),callback:function ($$v) {_vm.$set(_setup.peopleRecruitmentContractStore.applicant, "device", $$v)},expression:"peopleRecruitmentContractStore.applicant.device"}})],1)],1)],1)],1),(_setup.showEditInformationButton)?_c('v-container',[_c('v-row',{staticClass:"mb-1"},[(_setup.isFormDisabled)?_c('v-col',{staticClass:"pl-1"},[_c('v-btn',{staticClass:"main-action-btn ml-2",attrs:{"data-testid":"recruitment-typeform1-edit-information-btn"},on:{"click":function($event){return _setup.toggleFormDisabled()}}},[_vm._v(" "+_vm._s(_setup.buttonText)+" ")])],1):_vm._e(),(!_setup.isFormDisabled)?_c('v-col',{staticClass:"pl-1"},[_c('v-btn',{staticClass:"cancel-variant-btn ml-2",attrs:{"data-testid":"recruitment-typeform1-cancel-btn"},on:{"click":function($event){return _setup.checkAlteredForm()}}},[_vm._v(" "+_vm._s(_setup.t('Action_.buttons.cancel'))+" ")]),_c('v-btn',{staticClass:"main-action-btn ml-2",attrs:{"data-testid":"recruitment-typeform1-save-changes-btn","disabled":!_setup.isFormValid},on:{"click":function($event){return _setup.updateTypeform()}}},[_vm._v(" "+_vm._s(_setup.buttonText)+" ")])],1):_vm._e()],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }