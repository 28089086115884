<template>
  <div data-testid="regular-text-input">
    <p class="custom-label mb-1">
      {{ label }}
      <span v-if="optional" class="optional-label">
        {{ t('Msg_.optional') }}
      </span>
    </p>
    <v-text-field
      dense
      outlined
      :type="type"
      :rules="rules"
      :value="value"
      :disabled="disabled"
      :readonly="readonly"
      :clearable="clearable"
      :maxlength="maxlength"
      :prefix="prefix"
      :placeholder="placeholder"
      :hide-details="hideDetails"
      :suffix="suffix"
      :error="error"
      :error-messages="errorMessages"
      :background-color="disabled || readonly ? 'ashRegular' : 'white'"
      @click:clear="clearInput()"
      @blur="emitUserInput($event.target.value)"
      @focus="emitUserInputOnFocus($event.target.value)"
      :error-count="2"
      :hint="hint"
      :persistent-hint="isHintPersistent"
    >
      <v-icon
        v-if="icon"
        slot="prepend-inner"
        color="greyRegular"
        class="custom-icon"
        size="20"
      >
        {{ icon }}
      </v-icon>
    </v-text-field>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n-bridge';
import languages from '@/components/people/locales/languages';
import { defineProps, defineEmits } from 'vue';

const { t } = useI18n({ locale: 'en', messages: languages });
const emit = defineEmits(['input', 'change', 'focus', 'clear-input']);

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  label: {
    type: null,
    required: true,
  },
  placeholder: {
    type: String,
    required: false,
    default: '',
  },
  hideDetails: {
    type: Boolean,
    required: false,
    default: false,
  },
  prefix: {
    type: String,
    required: false,
    default: '',
  },
  value: {
    type: null,
    required: true,
  },
  type: {
    type: String,
    required: false,
    default: '',
  },
  maxlength: {
    type: String,
    required: false,
    default: '10000',
  },
  icon: {
    type: String,
    required: false,
    default: '',
  },
  suffix: {
    type: String,
    required: false,
    default: '',
  },
  hint: {
    type: String,
    required: false,
    default: '',
  },
  rules: {
    type: Array,
    required: false,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  readonly: {
    type: Boolean,
    required: false,
    default: false,
  },
  clearable: {
    type: Boolean,
    required: false,
    default: false,
  },
  error: {
    type: Boolean,
    required: false,
    default: null,
  },
  errorMessages: {
    type: String,
    required: false,
    default: '',
  },
  isHintPersistent: {
    type: Boolean,
    required: false,
    default: false,
  },
  optional: {
    type: Boolean,
    required: false,
    default: false,
  },
});

// --- Methods ---
const emitUserInput = (input) => {
  emit('input', input);
  emit('change', input);
};

const emitUserInputOnFocus = (input) => {
  emit('focus', input);
};

const clearInput = () => {
  emit('clear-input');
};
</script>

<style lang="scss" scoped>
.custom-label {
  font-size: 13px;
}

.optional-label {
  color: #666;
}

.custom-icon {
  margin: 2px 4px 0 0;
}

.v-input--is-focused ::v-deep fieldset {
  border: 1px solid $blue-regular !important;
}

.v-text-field--outlined ::v-deep fieldset {
  border-color: $ash-dark;
}
</style>
