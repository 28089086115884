<template>
  <router-view />
</template>

<script setup>
import { useI18n } from 'vue-i18n-bridge';
import languages from './locales/languages';
import { useMainStore } from '@/store/pinia/main.store.js';
import { usePeopleStore } from '@/store/pinia/people.store.js';

const mainStore = useMainStore();
const peopleStore = usePeopleStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Methods ---
const getPeopleResources = async () => {
  try {
    await peopleStore.getPeopleResources();
  } catch (error) {
    console.log(error);
  }
};

const setHubsHttpErrorMessage = () => {
  mainStore.errorPageMessage = t('Msg_.hubsHttpError');
};

const initializeHubs = () => {
  getPeopleResources();
  setHubsHttpErrorMessage();
};

// --- Lifecycle hooks ---
initializeHubs();
</script>
