export const fleetRoutes = {
  LogbookDashboard: 'fleet.logbook.listJourneys',
  FleetVehiclesList: 'fleet.vehicleDetails.listVehicles',
  FleetVehicleFormEdit: 'fleet.vehicleDetails.edit',
  FleetDriverAssignment: 'fleet.vehicleDetails.driverAssignment',
  FleetVehicleDetails: 'fleet.vehicleDetails.vehicleDetails',
  FleetAdditionalInformation: 'fleet.vehicleDetails.additionalInformation',
  FleetDocuments: 'fleet.vehicleDetails.documents',
  FleetDriverInformation: 'fleet.vehicleDetails.driverInformation',
  FleetVehiclesAssignment:
    'fleet.vehicleDetails.assignVehicle.employeeInformation',
  FleetVehicleForm: 'fleet.vehicleDetails.create',
};
