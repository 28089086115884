<template>
  <v-select
    v-model="$i18n.locale"
    :items="countries"
    label="Language"
    dense
    flat
    hide-details
    solo
    item-text="code"
    item-value="code"
    append-icon=""
  >
    <template #selection="{ item }">
      <img
        id="select_language"
        data-testid="select_language"
        class="rounded-circle"
        :src="require('@/assets/toolbar/flags/' + item.flag)"
        alt="flag"
      />
    </template>
    <template #item="{ item }">
      <div @click="setSelectedLanguage(item)">
        <img
          id="select_language_mini"
          data-testid="select_language_mini"
          class="rounded-circle"
          :src="require('@/assets/toolbar/flags/' + item.flag)"
          alt="flag"
        />
        {{ item.name }}
      </div>
    </template>
  </v-select>
</template>

<script setup>
import { ref } from 'vue';

// --- Local variables ---
const countries = ref([
  {
    code: 'en',
    flag: 'flag_united-kingdom.svg',
    name: 'English',
  },
  {
    code: 'de',
    flag: 'flag_germany.svg',
    name: 'Deutsch',
  },
]);

// --- Methods ---
const setSelectedLanguage = (lang) => {
  localStorage.setItem('selectedLanguage', lang.code);
};
</script>

<style scoped>
#select_language {
  height: 20px;
  width: auto;
  margin-top: 1.2rem;
}

#select_language_mini {
  height: 20px;
  width: auto;
  margin-right: 5px;
}
</style>
