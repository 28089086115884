import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useMainStore } from '@/store/pinia/main.store.js';
import { useUserStore } from '@/store/pinia/user.store.js';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import helpers from '@/helpers/helpers';
import i18n from '@/i18n';

export const useAbsencesStore = defineStore('absences', () => {
  const mainStore = useMainStore();
  const userStore = useUserStore();
  const loadingStore = useLoadingStore();

  // --- State ---
  const absencesResources = ref({
    requestsStatus: [],
    absenceTypes: [],
  });

  const vacationDays = ref({
    vacationBudgetCurrentYear: null,
    vacationBudgetPastYear: null,
    takenDaysCurrentYear: null,
    availableDaysCurrentYear: null,
  });

  const absencesStats = ref([]);

  const vacationDaysPerYear = ref(null);
  const absenceTypeVacation = ref(null);
  const absenceDetails = ref(null);
  const absenceList = ref([]);

  const profileCalendarDate = ref(new Date().toISOString().substring(0, 10));

  const absenceStatusLabelsColors = ref({
    Requested: {
      text: 'blueRegular',
      color: 'lowOpacityBlue',
      calendar: 'blueRegular',
    },
    Approved: {
      text: 'caribbeanDark',
      color: 'lowOpacityGreen',
      calendar: 'caribbeanRegular',
    },
    Rejected: { text: 'white', color: 'redDanger' },
    Canceled: { text: 'white', color: 'greyRegular' },
    Taken: { text: '', color: '' },
  });

  const newAbsenceRequestDefault = ref({
    comments: null,
    type: 0,
    startDate: null,
    endDate: null,
    fileName: '',
    id: null,
  });

  const showAbsenceDialog = ref(false);
  const isAbsenceInformationDisabled = ref(false);

  const hubList = ref([]);
  const absenceCalendarDays = ref([]);
  const absenceEmployeeCalendarDays = ref([]);

  const filters = ref({
    searchPattern: '',
    status: '',
    type: '',
  });

  const pagination = ref({
    pageSizes: [5, 10, 20],
    page: 1,
    length: 1,
    pageSize: 5,
    total: 0,
    category: 0,
  });

  // --- Methods ---
  const getAbsencesResources = async (displayGlobalLoading) => {
    if (displayGlobalLoading) {
      loadingStore.startGlobalLoading('absencesResources');
    } else {
      loadingStore.startPartialLoading('absencesResources');
    }
    try {
      absencesResources.value.requestsStatus = await getAbsenceRequestStatus();
      absencesResources.value.absenceTypes = await getAbsenceTypes();
    } catch (error) {
      console.log(error);
    } finally {
      if (displayGlobalLoading) {
        loadingStore.endGlobalLoading('absencesResources');
      } else {
        loadingStore.endPartialLoading('absencesResources');
      }
    }
  };

  const getAbsenceRequestStatus = async () => {
    const params = {
      endpoint: `v2/api/people/resources`,
      configs: {
        method: 'GET',
      },
    };
    try {
      const resources = await mainStore.request(params);
      return resources.requestsStatus;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getAbsenceTypes = async () => {
    const params = {
      endpoint: `v2/api/absences/types`,
      configs: {
        method: 'GET',
      },
    };

    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getAbsencesStats = async (data) => {
    const id = data.currentProfileId || userStore.userInfo.sub;
    const params = {
      endpoint: `v2/api/absences/stats?employeeId=${id}&year=${data.year}&top=4`,
      configs: {
        method: 'GET',
      },
    };
    loadingStore.startPartialLoading('absencesStats');

    try {
      absencesStats.value = await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('absencesStats');
    }
  };

  const getAbsencesListByEmployee = async (data) => {
    const id = data.currentProfileId || userStore.userInfo.sub;
    const urlencoded = new URLSearchParams();

    urlencoded.append('employeeId', id);

    if (pagination.value.page && pagination.value.pageSize) {
      urlencoded.append('Page', pagination.value.page);
      urlencoded.append('PageSize', pagination.value.pageSize);
    } else {
      urlencoded.append('Page', 1);
      urlencoded.append('PageSize', 5);
    }

    const params = {
      endpoint: `v2/api/requests`,
      configs: {
        method: 'GET',
        body: urlencoded,
      },
    };
    loadingStore.startPartialLoading('absencesList');

    try {
      const list = await mainStore.request(params);
      absenceList.value = list.items;

      pagination.value.length = Math.ceil(list.totalCount / list.pageSize);
      pagination.value.total = list.totalCount;
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('absencesList');
    }
  };

  const getUsedVacationDays = async (payload) => {
    const urlencoded = new URLSearchParams();

    if (payload.user) urlencoded.append('employeeId', payload.user);
    urlencoded.append('startDate', payload.details.startDate);
    urlencoded.append('endDate', payload.details.endDate);
    urlencoded.append('absenceType', payload.details.type);

    const params = {
      endpoint: 'v2/api/Vacations/simulate',
      configs: {
        method: 'GET',
        body: urlencoded,
      },
    };

    return await mainStore.request(params);
  };

  const getVacationDays = async (currentProfileId) => {
    const id = currentProfileId || userStore.userInfo.sub;

    const params = {
      endpoint: `v2/api/Vacations?employeeId=${id}`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startPartialLoading('absencesVacationDays');
    try {
      const res = await mainStore.request(params);
      vacationDays.value = res;
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('absencesVacationDays');
    }
  };

  const saveAbsenceApplication = async (data) => {
    const params = {
      endpoint: `v2/api/requests`,
      configs: {
        method: 'POST',
        body: data.body,
      },
      specificErrors: data.specificErrors,
    };
    loadingStore.startPartialLoading('saveAbsence');

    try {
      await mainStore.request(params);
    } catch (error) {
      console.log(error);
    } finally {
      loadingStore.endPartialLoading('saveAbsence');
    }
  };

  const attachFileToAbsenceApplication = async (fileToAttach, requestId) => {
    const params = {
      endpoint: `v2/api/Requests/${requestId}`,
      configs: {
        method: 'PATCH',
        body: { file: fileToAttach },
      },
    };
    loadingStore.startPartialLoading('attachFileToAbsence');

    try {
      await mainStore.request(params);
    } catch (error) {
      console.log(error);
    } finally {
      loadingStore.endPartialLoading('attachFileToAbsence');
    }
  };

  const changeAbsenceRequestStatus = async (request) => {
    const params = {
      endpoint: `v2/api/requests/${request.requestId}?status=${request.status}`,
      configs: {
        method: 'PUT',
      },
    };

    loadingStore.startPartialLoading('changeAbsenceStatus');

    try {
      await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('changeAbsenceStatus');
    }
  };

  const displayNewRequestAbsenceDialog = () => {
    absenceDetails.value = newAbsenceRequestDefault.value;
    isAbsenceInformationDisabled.value = false;
    showAbsenceDialog.value = true;
  };

  const displayExistingRequestAbsenceDialog = async (id) => {
    const params = {
      endpoint: `v2/api/requests/${id}`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startPartialLoading('displayExistingRequest');

    try {
      const request = await mainStore.request(params);
      if (requestIsMissingAttachment(request)) {
        absenceDetails.value = helpers.fillObjectFromAnotherObject(
          { ...newAbsenceRequestDefault.value },
          request
        );
      } else {
        absenceDetails.value = request;
      }
      isAbsenceInformationDisabled.value = true;
      showAbsenceDialog.value = true;
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('displayExistingRequest');
    }
  };

  const requestIsMissingAttachment = (request) => {
    const requestTypeConfig = absencesResources.value.absenceTypes.find(
      (type) => type.id === request.type
    );
    return (
      !request.uploadedDocumentId && requestTypeConfig?.shouldRequireAttachment
    );
  };

  const getHubList = async () => {
    const params = {
      endpoint: `v2/api/absences/Resources/hubs`,
      configs: {
        method: 'GET',
      },
    };
    loadingStore.startGlobalLoading('absenceHubListLoading');
    try {
      hubList.value = await mainStore.request(params);
    } catch (error) {
      console.log(error);
    } finally {
      loadingStore.endGlobalLoading('absenceHubListLoading');
    }
  };

  const getCalendarAbsenceDays = async (data) => {
    const params = {
      endpoint: `v2/api/Absences/calendarByHubYearAndMonth/${data.hub}/${data.month}/${data.year}`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startPartialLoading('absenceCalendarLoading');

    try {
      absenceCalendarDays.value = await mainStore.request(params);
    } catch (error) {
      console.log(error);
    } finally {
      loadingStore.endPartialLoading('absenceCalendarLoading');
    }
  };

  const getAbsencesListByHubAndMonth = async (data) => {
    const urlencoded = new URLSearchParams();

    if (filters.value.status) {
      urlencoded.append('Status', filters.value.status);
    } else {
      const approvedStatus = absencesResources.value.requestsStatus.find(
        (status) => status.Name === 'Approved'
      );
      urlencoded.append('Status', approvedStatus.Value);
      const requestedStatus = absencesResources.value.requestsStatus.find(
        (status) => status.Name === 'Requested'
      );
      urlencoded.append('Status', requestedStatus.Value);
    }

    if (filters.value.type) {
      urlencoded.append('Type', filters.value.type);
    }

    if (filters.value.searchPattern) {
      urlencoded.append('searchPattern', filters.value.searchPattern);
    }

    if (pagination.value.page && pagination.value.pageSize) {
      urlencoded.append('Page', pagination.value.page);
      urlencoded.append('PageSize', pagination.value.pageSize);
    } else {
      urlencoded.append('Page', 1);
      urlencoded.append('PageSize', 5);
    }

    const params = {
      endpoint: `v2/api/Requests/${data.hub}/${data.month}/${data.year}`,
      configs: {
        method: 'GET',
        body: urlencoded,
      },
    };

    if (data.loading) loadingStore.startPartialLoading('absencesList');

    try {
      const response = await mainStore.request(params);
      absenceList.value = response.items;

      pagination.value.length = Math.ceil(
        response.totalCount / response.pageSize
      );
      pagination.value.total = response.totalCount;
    } catch (error) {
      console.log(error);
    } finally {
      loadingStore.endPartialLoading('absencesList');
    }
  };

  const getUserMainHub = async (userId, useLoading = true) => {
    const id = userId ? userId : userStore.userInfo.sub;

    const params = {
      endpoint: `v2/api/employee/${id}/`,
      configs: {
        method: 'GET',
      },
    };

    if (useLoading) {
      loadingStore.startGlobalLoading('absenceMainHubLoading');
    }

    try {
      const res = await mainStore.request(params);
      return res?.offices?.mainOffice?.id;
    } catch (error) {
      console.log(error);
    } finally {
      loadingStore.endGlobalLoading('absenceMainHubLoading');
    }
  };

  const getEmployeeAbsenceCalendarDays = async (data) => {
    const id = data.currentProfileId || userStore.userInfo.sub;
    const params = {
      endpoint: `v2/api/Absences/calendarByEmployeeYearAndMonth/${id}/${data.month}/${data.year}`,
      configs: {
        method: 'GET',
      },
    };
    try {
      return await mainStore.request(params);
    } catch (error) {
      console.log(error);
    }
  };

  const getPublicHolidaysCalendarDays = async (data) => {
    const id = data.currentProfileId || userStore.userInfo.sub;
    const mainHubId = await getUserMainHub(id, false);
    const params = {
      endpoint: `v2/api/hubs/${mainHubId}/Holidays?year=${data.year}&month=${data.month}`,
      configs: {
        method: 'GET',
      },
    };
    try {
      return await mainStore.request(params);
    } catch (error) {
      console.log(error);
    }
  };

  const savePreApprovedAbsenceApplication = async (data) => {
    const params = {
      endpoint: `v2/api/requests/pre-approved`,
      configs: {
        method: 'POST',
        body: data.body,
      },
    };
    loadingStore.startPartialLoading('saveAbsence');

    try {
      await mainStore.request(params);
    } catch (error) {
      console.log(error);
    } finally {
      loadingStore.endPartialLoading('saveAbsence');
    }
  };

  const getEmployeeName = async (id) => {
    const params = {
      endpoint: `v2/api/employee/${id}/`,
      configs: {
        method: 'GET',
      },
    };
    try {
      const res = await mainStore.request(params);
      return res.fullName;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const exportAbsencesData = async (dates, filename) => {
    const url = `${process.env.VUE_APP_LOGIN_API}/v2/api/export/employees-absences-overview?language=${i18n.locale}`;

    const configs = {
      method: 'POST',
      body: JSON.stringify(dates),
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };

    loadingStore.startPartialLoading('exportAbsencesData');
    try {
      fetch(url, configs).then(async (response) => {
        const blob = await response.blob();

        // Create a temporary URL that points to the blob
        const downloadUrl = URL.createObjectURL(blob);
        // Create a new anchor tag to trigger the file download
        const downloadLink = document.createElement('a');
        // Set the "href" to the temporary URL
        downloadLink.href = downloadUrl;
        // Set the "download" attribute to the filename
        downloadLink.download = filename;
        // Programmatically click to start the download
        downloadLink.click();
        // Release the temporary URL to free up memory
        URL.revokeObjectURL(downloadUrl);
      });
    } catch (error) {
      console.log(error);
    } finally {
      loadingStore.endPartialLoading('exportAbsencesData');
    }
  };

  const resetTablePage = () => {
    pagination.value.page = 1;
  };

  return {
    absenceCalendarDays,
    absenceEmployeeCalendarDays,
    absenceDetails,
    absenceList,
    absencesResources,
    absencesStats,
    absenceTypeVacation,
    absenceStatusLabelsColors,
    hubList,
    newAbsenceRequestDefault,
    profileCalendarDate,
    vacationDays,
    vacationDaysPerYear,
    showAbsenceDialog,
    isAbsenceInformationDisabled,
    pagination,
    filters,
    attachFileToAbsenceApplication,
    changeAbsenceRequestStatus,
    displayExistingRequestAbsenceDialog,
    displayNewRequestAbsenceDialog,
    exportAbsencesData,
    getAbsencesListByEmployee,
    getAbsencesListByHubAndMonth,
    getAbsencesResources,
    getAbsencesStats,
    getAbsenceTypes,
    getCalendarAbsenceDays,
    getEmployeeAbsenceCalendarDays,
    getEmployeeName,
    getHubList,
    getPublicHolidaysCalendarDays,
    getUsedVacationDays,
    getUserMainHub,
    getVacationDays,
    requestIsMissingAttachment,
    saveAbsenceApplication,
    savePreApprovedAbsenceApplication,
    resetTablePage,
  };
});
