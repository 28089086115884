import { useMainStore } from '@/store/pinia/main.store.js';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { useContractTools } from '@/helpers/composables/contract-tools.js';

export const usePeopleCandidatesStore = defineStore('peopleCandidates', () => {
  const mainStore = useMainStore();
  const loadingStore = useLoadingStore();
  const { prepareRecruitmentListData } = useContractTools();

  // --- State ---
  const showProfileIndex = ref(null);
  const peopleHubList = ref([]);
  const rejectingCandidate = ref(false);

  const selectedMenuItem = ref('process');
  const menuItem = {
    engage: [10, 11, 15, 20, 25],
    process: [27, 30, 35, 37, 40, 45],
    handover: [57],
    hiring: [60, 65, 67, 70],
    ended: [47, 55, 56],
  };
  const candidateIndex = ref(null);
  const summary = ref([]);
  const candidatesList = ref({});

  // --- Methods ---
  const getCandidatesList = async (payload) => {
    const urlencoded = new URLSearchParams();

    urlencoded.append('HubId', payload.pageOptions.hubId);
    urlencoded.append('Page', payload.pagination.page);
    urlencoded.append('PageSize', payload.pagination.pageSize);
    urlencoded.append('SearchPattern', payload.pageOptions.searchTerm);

    menuItem[selectedMenuItem.value].forEach((status) => {
      urlencoded.append('Statuses', status);
    });

    const params = {
      endpoint: 'v2/api/recruitments/applications',
      configs: {
        method: 'GET',
        body: urlencoded,
      },
    };

    loadingStore.startPartialLoading('getCandidatesData');
    try {
      let response = await mainStore.request(params);
      candidatesList.value = prepareRecruitmentListData(response);
    } catch (error) {
      console.log(error);
    } finally {
      loadingStore.endPartialLoading('getCandidatesData');
    }
  };

  const getPeopleHubList = async () => {
    const params = {
      endpoint: `v2/api/people/resources/hubs`,
      configs: {
        method: 'GET',
      },
    };

    try {
      peopleHubList.value = await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getSummary = async (hubId) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append('hubId', hubId);

    const params = {
      endpoint: `v2/api/recruitments/applications/summary`,
      configs: {
        method: 'GET',
        body: urlencoded,
      },
    };

    loadingStore.startGlobalLoading('getSummary');

    try {
      summary.value = await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endGlobalLoading('getSummary');
    }
  };

  const getHubProcessResponsibleList = async (hubId) => {
    const params = {
      endpoint: `v2/api/people/resources/hubs/${hubId}/employees`,
      configs: {
        method: 'GET',
      },
    };

    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const sendCandidateToReview = async (payload) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append('status', payload.status);

    const params = {
      endpoint: `v2/api/recruitments/applications/${payload.candidateId}/application-review`,
      configs: {
        method: 'POST',
        body: urlencoded,
      },
    };

    try {
      await mainStore.request(params);
    } catch (error) {
      console.log(error);
    }
  };

  const sendCandidateToHR = async (candidateId) => {
    const params = {
      endpoint: `v2/api/recruitments/applications/${candidateId}/generate-contract`,
      configs: {
        method: 'POST',
      },
    };

    try {
      await mainStore.request(params);
    } catch (error) {
      console.log(error);
    }
  };

  const candidateEndProcess = async (candidateId) => {
    const params = {
      endpoint: `v2/api/recruitments/applications/${candidateId}/end-process`,
      configs: {
        method: 'POST',
      },
    };

    try {
      await mainStore.request(params);
    } catch (error) {
      console.log(error);
    }
  };

  const sendCandidateContract = async (candidateId) => {
    const params = {
      endpoint: `v2/api/recruitments/applications/${candidateId}/send-contract`,
      configs: {
        method: 'POST',
      },
    };

    try {
      await mainStore.request(params);
    } catch (error) {
      console.log(error);
    }
  };

  const generateCandidateContract = async (candidateId) => {
    const params = {
      endpoint: `v2/api/recruitments/applications/${candidateId}/generate-contract`,
      configs: {
        method: 'POST',
      },
    };

    try {
      await mainStore.request(params);
    } catch (error) {
      console.log(error);
    }
  };

  const turnIntoEmployee = async (candidateId) => {
    const params = {
      endpoint: `v2/api/recruitments/applications/${candidateId}/hire`,
      configs: {
        method: 'POST',
      },
    };

    try {
      await mainStore.request(params);
    } catch (error) {
      console.log(error);
    }
  };

  const uploadSignedContract = async (payload) => {
    let formData = new FormData();
    formData.append('file', payload.contractFile);

    const params = {
      endpoint: `v2/api/recruitments/applications/${payload.candidateId}/contract`,
      configs: {
        method: 'POST',
        body: formData,
        headers: {},
      },
    };

    try {
      await mainStore.request(params);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    menuItem,
    candidateIndex,
    selectedMenuItem,
    showProfileIndex,
    getCandidatesList,
    getPeopleHubList,
    peopleHubList,
    getSummary,
    summary,
    candidatesList,
    getHubProcessResponsibleList,
    rejectingCandidate,
    sendCandidateToReview,
    sendCandidateToHR,
    candidateEndProcess,
    sendCandidateContract,
    generateCandidateContract,
    turnIntoEmployee,
    uploadSignedContract,
  };
});
