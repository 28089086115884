export const getKingdraBaseUrl = () =>
  process.env.VUE_APP_TITLE === 'Local'
    ? 'https://localhost:5173'
    : process.env.VUE_APP_LOGIN_API;

export const getLinkToKingdra = (path) => {
  if (!path.startsWith('/')) {
    path = '/' + path;
  }

  return `${getKingdraBaseUrl()}/v3${path}`;
};
