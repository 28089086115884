<template data-testid="fleet-vehicles">
  <router-view></router-view>
</template>

<script setup>
import languages from './locales/languages';
import { useMainStore } from '@/store/pinia/main.store.js';
import { useFleetVehiclesStore } from '@/store/pinia/fleet-vehicles.store.js';

import { useI18n } from 'vue-i18n-bridge';

const mainStore = useMainStore();
const fleetVehiclesStore = useFleetVehiclesStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Methods ---
const getHubs = async () => {
  try {
    await fleetVehiclesStore.getHubList();
  } catch (error) {
    console.log(error);
  }
};

const getVehicleResources = async () => {
  try {
    await fleetVehiclesStore.getVehicleResources();
    await fleetVehiclesStore.getContractualPartnersList();
    await fleetVehiclesStore.getUsageList();
    await fleetVehiclesStore.getOwnersList();
  } catch (error) {
    console.log(error);
  }
};

const setFleetHttpErrorMessage = () => {
  mainStore.errorPageMessage = t('Msg_.errorVehicleInformation');
};

// --- Lifecycle hooks ---
setFleetHttpErrorMessage();
getHubs();
getVehicleResources();
</script>
