<template>
  <v-snackbar
    v-model="toastNotificationStore.displayToastNotification"
    :top="toastNotificationStore.params.position.top"
    :bottom="toastNotificationStore.params.position.bottom"
    :right="toastNotificationStore.params.position.right"
    :left="toastNotificationStore.params.position.left"
    :color="toastNotificationStore.params.colorAndIcon.color"
  >
    <v-icon left size="22">{{
      toastNotificationStore.params.colorAndIcon.icon
    }}</v-icon>

    <span class="toast-text">{{
      toastNotificationStore.params.messages[
        toastNotificationStore.params.type
      ] || t('Msg_.missingMessage')
    }}</span>

    <template
      #action="{ attrs }"
      v-if="toastNotificationStore.params.actionBtnPress"
    >
      <v-btn text v-bind="attrs" @click="handleClick" class="toast-btn">
        {{
          toastNotificationStore.params.btnActionLabel ||
          t('Msg_.missingBtnActionLabel')
        }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup>
import { useToastNotificationStore } from '@/store/pinia/toast-notification.store.js';
import { useI18n } from 'vue-i18n-bridge';
import languages from '../../locales/languages';

const { t } = useI18n({ locale: 'en', messages: languages });
const toastNotificationStore = useToastNotificationStore();

// --- Methods ---
const handleClick = () => {
  if (toastNotificationStore.params.actionBtnPress) {
    toastNotificationStore.params.actionBtnPress();
  }
};
</script>

<style lang="scss" scoped>
.toast-text {
  font-size: 13px !important;
  font-weight: 600 !important;
}

.toast-btn {
  font-size: 13px !important;
}
</style>
