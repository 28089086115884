<template>
  <div
    :class="
      variant ? 'd-flex flex-column align-center' : 'd-flex flex-row-reverse'
    "
    data-testid="checkbox-control"
  >
    <p :class="variant ? 'custom-label mt-0' : 'custom-label'">{{ label }}</p>
    <v-checkbox
      :class="variant ? 'ma-0 pl-2' : ''"
      dense
      color="blueRegular"
      :input-value="value"
      :disabled="disabled"
      @change="emitUserInput($event)"
    />
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const emit = defineEmits(['input', 'change']);

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  label: {
    type: String,
    required: false,
    default: '',
  },
  variant: {
    type: Boolean,
    required: false,
    default: false,
  },
  value: {
    type: Boolean,
    required: true,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

// --- Methods ---
const emitUserInput = (input) => {
  emit('input', input);
  emit('change', input);
};
</script>

<style scoped lang="scss">
.custom-label {
  font-size: 13px;
  margin: 10px 0 0 0;
}
</style>
