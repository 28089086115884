import Hubs from '@/components/hubs/hubs.vue';
import HubList from '@/components/hubs/hub-list.vue';

const hubsRoutes = [
  {
    path: '/hubs',
    component: Hubs,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'hub-list',
        name: 'HubList',
        component: HubList,
      },
      {
        path: 'hub-form',
        name: 'HubForm',
        component: () => import('@/components/hubs/hub-form.vue'),
      },
      {
        path: 'hub-profile/:hubId',
        name: 'HubProfile',
        redirect: () => {
          return { name: 'HubProfileMasterData' };
        },
        component: () => import('@/components/hubs/hubProfile/hub-profile.vue'),
        children: [
          {
            path: 'master-data',
            name: 'HubProfileMasterData',
            component: () =>
              import(
                '@/components/hubs/hubProfile/hubMasterData/hub-profile-master-data.vue'
              ),
          },
          {
            path: 'employees',
            name: 'HubProfileEmployees',
            component: () =>
              import(
                '@/components/hubs/hubProfile/hubEmployees/hub-profile-employees.vue'
              ),
          },
          {
            path: 'operations',
            name: 'HubProfileOperations',
            component: () =>
              import(
                '@/components/hubs/hubProfile/hubOperations/hub-profile-operations.vue'
              ),
          },
          {
            path: 'history',
            name: 'HubProfileHistory',
            component: () =>
              import(
                '@/components/hubs/hubProfile/hubHistory/hub-profile-history.vue'
              ),
          },
          {
            path: 'downloads',
            name: 'HubProfileDownloads',
            component: () =>
              import(
                '@/components/hubs/hubProfile/hubDownloads/hub-profile-downloads.vue'
              ),
          },
        ],
      },
    ],
  },
];

export default hubsRoutes;
