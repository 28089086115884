<template>
  <v-app-bar color="white" app height="75px">
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <v-container fluid class="d-flex align-center">
        <v-row class="justify-end px-2">
          <v-col cols="1" class="pa-0 mr-n2">
            <LanguageSelector />
          </v-col>
          <v-col cols="7">
            <v-btn plain @click.stop="userDetails()">
              <span class="greyDark--text"> Hi, </span>
              {{ userStore.userInfo.name }}

              <v-img
                class="ml-2"
                src="@/assets/authentication/auth_avatar.svg"
                alt="arrow-left"
                height="38"
                width="38"
              />
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script setup>
import LanguageSelector from '@/components/shared/layout/toolbar/languageSelector/language-selector.vue';
import { useMainStore } from '@/store/pinia/main.store.js';
import { useUserStore } from '@/store/pinia/user.store.js';

const mainStore = useMainStore();
const userStore = useUserStore();

// --- Methods ---
const userDetails = () => {
  mainStore.showUserDrawerDetails = true;
};
</script>

<style scoped lang="scss">
header {
  @extend %shadow-large;
}
</style>
