import { render, staticRenderFns } from "./pending-employees-list.vue?vue&type=template&id=14b06700&scoped=true&"
import script from "./pending-employees-list.vue?vue&type=script&setup=true&lang=js&"
export * from "./pending-employees-list.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./pending-employees-list.vue?vue&type=style&index=0&id=14b06700&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14b06700",
  null
  
)

export default component.exports