<template>
  <div>
    <router-view :current-profile-id="route.params.employeeId" />
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n-bridge';
import { useMainStore } from '@/store/pinia/main.store.js';
import { useProfileStore } from '@/store/pinia/profile.store.js';
import { useRoute } from 'vue-router/composables';
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';
import { watch, computed, ref } from 'vue';

import languages from './locales/languages';

const { trackPage } = amplitudeComposable();
const mainStore = useMainStore();
const profileStore = useProfileStore();

const route = useRoute();
const { t } = useI18n({ locale: 'en', messages: languages });

const skipTrack = ref(false);
// --- Methods ---

const getProfileResources = async () => {
  try {
    await profileStore.getProfileResources();
  } catch (error) {
    console.log(error);
  }
};

const getProfileHubList = async () => {
  try {
    await profileStore.getProfileHubList();
  } catch (error) {
    console.log(error);
  }
};

const getProfileDepartments = async () => {
  try {
    await profileStore.getProfileDepartments();
  } catch (error) {
    console.log(error);
  }
};

const getProfileTeams = async () => {
  try {
    await profileStore.getProfileTeams();
  } catch (error) {
    console.log(error);
  }
};

const getProfileHubRoles = async () => {
  try {
    await profileStore.getProfileHubRoles();
  } catch (error) {
    console.log(error);
  }
};

const setProfileHttpErrorMessage = () => {
  mainStore.errorPageMessage = t('Msg_.profileHttpError');
};

const initializeProfile = () => {
  try {
    getProfileResources();
    getProfileHubList();
    getProfileDepartments();
    getProfileTeams();
    getProfileHubRoles();
    setProfileHttpErrorMessage();
  } catch (err) {
    console.log(err);
  }
  if (
    mainOfficeId.value &&
    !skipTrack.value &&
    route.name != 'EmployeeProfileMenu'
  ) {
    trackPage(mainOfficeId.value);
  }
};

// --- Computed ---
const mainOfficeId = computed(() => {
  return profileStore.profileMenuInformation.offices?.mainOffice.id || null;
});

// --- Watchers ---
watch(
  () => route.name,
  (newValue) => {
    if (newValue != 'EmployeeProfileMenu') {
      trackPage(mainOfficeId.value);
    }
  }
);

watch(
  () => mainOfficeId.value,
  (newValue, oldValue) => {
    if (mainOfficeId.value && !oldValue) {
      skipTrack.value = true;
      trackPage(mainOfficeId.value);
    }
  }
);

// --- Lifecycle hooks ---
initializeProfile();
</script>
