<template>
  <v-btn
    :color="color"
    :class="border ? '' : 'no-border'"
    :to="to"
    outlined
    data-testid="icon-btn"
    v-on="on"
    v-bind="bind"
    @click="emitUserClick"
    :disabled="disabled"
  >
    <v-icon :size="iconSize"> {{ icon }} </v-icon>
  </v-btn>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const emit = defineEmits(['click']);

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    required: false,
    default: 'blueRegular',
  },
  iconSize: {
    type: String,
    required: false,
    default: '19',
  },
  bind: {
    type: Object,
    required: false,
    default: () => {},
  },
  on: {
    type: Object,
    required: false,
    default: () => {},
  },
  to: {
    type: Object,
    required: false,
    default: () => {},
  },
  border: {
    type: Boolean,
    required: false,
    default: true,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

// --- Methods ---
const emitUserClick = () => {
  emit('click');
};
</script>

<style scoped lang="scss">
.no-border {
  border: 0px;
}
</style>
