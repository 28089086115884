import * as amplitude from '@amplitude/analytics-browser';
import { useUserStore } from '@/store/pinia/user.store.js';
import { useMainStore } from '@/store/pinia/main.store.js';
import { useRoute } from 'vue-router/composables';
import { authenticationRoutes } from '@/constants/amplitude-routes/amplitudeRoutesAuthentication';
import { fleetRoutes } from '@/constants/amplitude-routes/amplitudeRoutesFleet';
import { peopleRoutes } from '@/constants/amplitude-routes/amplitudeRoutesPeople';

let amplitudeHubList = [];
export function amplitudeComposable() {
  const router = useRoute();
  const userStore = useUserStore();
  const mainStore = useMainStore();

  const filterBrowser = () => {
    const userAgent = window.navigator.userAgent;

    const browsers = {
      Chrome: /Chrome/,
      Firefox: /Firefox/,
      Safari: /Safari/,
      Opera: /Opera|OPR/,
      Edge: /Edg/,
      IE: /Trident|MSIE/,
    };

    let detectedBrowser = Object.keys(browsers).find(function (browser) {
      return browsers[browser].test(userAgent);
    });
    return detectedBrowser;
  };

  const getDeviceType = () => {
    const userAgent = navigator.userAgent;

    const isMobile = /Mobile/.test(userAgent) || /Android/.test(userAgent);

    const isTablet = /Tablet/.test(userAgent) || /iPad/.test(userAgent);

    const deviceType = isMobile ? 'Mobile' : isTablet ? 'Tablet' : 'Desktop';

    return deviceType;
  };

  // const getPlatform = () => {
  //   const userAgent = navigator.userAgent;

  //   const windowsRegex = /Win(?:dows)?\s?(?:NT\s?\d+\.\d+)?/;
  //   const macRegex = /Macintosh|Mac\s?OS\s?X/;
  //   const linuxRegex = /Linux/;

  //   let platform;
  //   if (windowsRegex.test(userAgent)) {
  //     platform = 'Windows';
  //   } else if (macRegex.test(userAgent)) {
  //     platform = 'Mac';
  //   } else if (linuxRegex.test(userAgent)) {
  //     platform = 'Linux';
  //   } else {
  //     platform = 'Unknown';
  //   }
  //   return platform;
  // };

  const getResolution = () => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    return windowWidth + 'x' + windowHeight;
  };

  const getRouteEventName = (currentRoute) => {
    const routesName = {
      ...authenticationRoutes,
      ...fleetRoutes,
      ...peopleRoutes,
    };

    return routesName[currentRoute];
  };

  // const amplitudeObject = (router, userStore) => {
  //   const eventProperties = {
  //     browser: filterBrowser() || null,
  //     consulted_hub: 'in progress' || null,
  //     device_type: getDeviceType() || null,
  //     employee_id: userStore?.userInfo?.sub || null,
  //     environment: process.env.VUE_APP_TITLE || null,
  //     hub_id: userStore?.userInfo?.hub?.id || null,
  //     hub: userStore.userInfo?.hub?.name || null,
  //     page: getRouteEventName(router.name) || router.name || router || null,
  //     platform: getPlatform() || null,
  //     resolution: getResolution() || null,
  //     resource_id: router.params || null,
  //     roles: userStore?.userInfo?.role || null,
  //     user_agent: window.navigator.userAgent || null,
  //     username: userStore?.userInfo?.preferred_username || null,
  //     version: process.env.VUE_APP_VERSION || null,
  //   };

  //   if (process.env.VUE_APP_AMPLITUDE_ENABLED === 'true') {
  //     amplitude.track('page_view', eventProperties);
  //   }
  // };

  // -----
  const getEventProperties = (
    optionalParams,
    { resourceId = router.params, routeName = router.name } = {}
  ) => {
    // get route param
    if (typeof resourceId === 'object' && resourceId !== null) {
      const keys = Object.keys(resourceId);
      if (keys.length > 0) {
        resourceId = resourceId[keys[0]];
      } else {
        resourceId = null;
      }
    } else if (!resourceId) {
      resourceId = null;
    }

    let event = {
      browser: filterBrowser() || null,
      device_type: getDeviceType() || null,
      environment: process.env.VUE_APP_TITLE?.toUpperCase() || null,
      page: getRouteEventName(routeName) || routeName,
      platform: 'seadra',
      resolution: getResolution() || null,
      user_agent: window.navigator.userAgent || null,
      version: process.env.VUE_APP_VERSION || null,
      resource_id: resourceId || null,
      hub_id: optionalParams || null,
      hub_name: checkIfHasHubId(optionalParams)?.name || null,
    };
    return event;
  };

  const checkIfHasHubId = (hubId) => {
    if (hubId) {
      return amplitudeHubList.find((hub) => hub.id == hubId);
    }
  };

  const getUserProperties = () => {
    return {
      id: userStore?.userInfo?.sub || null,
      hub_id: userStore?.userInfo?.hub?.id || null,
      hub_name: userStore.userInfo?.hub?.name || null,
      roles: userStore?.userInfo?.role || null,
      username: userStore?.userInfo?.preferred_username || null,
    };
  };

  const trackPage = (
    optionalParams,
    resourceId = router.params,
    routeName = router.name
  ) => {
    if (routeName != 'EmployeeProfileMenu') {
      let baseEvent = {
        event_type: 'page_view',
        user_id: userStore?.userInfo?.sub || null,
        event_properties: getEventProperties(
          optionalParams,
          resourceId,
          routeName
        ),
        user_properties: getUserProperties(),
      };
      console.log('baseEvent');
      console.log(baseEvent);
      if (process.env.VUE_APP_AMPLITUDE_ENABLED === 'true') {
        amplitude.track(baseEvent);
      }
    }
  };

  const amplitudeGetAllHubs = async () => {
    amplitudeHubList = await mainStore.amplitudeGetAllHubs();
  };

  return {
    trackPage,
    amplitudeGetAllHubs,
  };
}
