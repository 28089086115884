<template>
  <v-row justify="center" data-testid="empty-list-message">
    <v-col cols="7" class="d-flex flex-column align-center text-center">
      <v-img
        max-height="175"
        max-width="175"
        :src="require('@/assets/empty_list_icon.svg')"
        class="mb-8"
        eager
      ></v-img>
      <p class="card-title mb-2 petrol--text">
        {{ t('Title_.header.emptyState') }}
      </p>
      <div class="message-container">
        <p class="section-detail mb-0 petrol--text">
          {{ t('Msg_.nothingToShow') }}
        </p>
        <p class="section-detail petrol--text">
          {{ t('Msg_.contactKenbiBlick') }}
        </p>
      </div>
    </v-col>
  </v-row>
</template>

<script setup>
import languages from '../../locales/languages';
import { useI18n } from 'vue-i18n-bridge';

const { t } = useI18n({ locale: 'en', messages: languages });
</script>

<style scoped lang="scss">
.message-container {
  width: 320px !important;
}

.text-message {
  color: $petrol !important;
}
</style>
