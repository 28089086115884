import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useMainStore } from '@/store/pinia/main.store.js';
import { useLoadingStore } from '@/store/pinia/loading.store.js';

export const useContractAmendmentStore = defineStore(
  'contractAmendment',
  () => {
    const mainStore = useMainStore();
    const loadingStore = useLoadingStore();

    // --- State ---
    const contractAmendmentData = ref({
      mainHub: null,
      billingHub: null,
      contractType: null,
      company: null,
      compensationType: null,
      jobTitle: null,
      jobSubtitle: '',
      additionalTraining: null,
      totalWeeklyWorkingDays: null,
      totalWeeklyWorkingHours: null,
      weeklyWorkingHours: [
        { day: 'monday', hours: 0, isActive: false },
        { day: 'tuesday', hours: 0, isActive: false },
        { day: 'wednesday', hours: 0, isActive: false },
        { day: 'thursday', hours: 0, isActive: false },
        { day: 'friday', hours: 0, isActive: false },
        { day: 'saturday', hours: 0, isActive: false },
        { day: 'sunday', hours: 0, isActive: false },
      ],
      jobLevel: null,
      experienceLevel: null,
      cityBonus: null,
      hourlyWage: null,
      nonDynamicAllowance: null,
      careAllowance: null,
      individualAllowance: null,
      monthlyBaseSalary: null,
      thirteenthSalary: null,
      annualSalary: null,
      startDate: '',
      duration: null,
      endDate: '',
      signingDate: null,
      assigner: null,
      isAddressCompleted: true,
      isEmailFilled: true,
    });
    const contractAmendmentFormValidation = ref({
      contractData: false,
      fullTimePartTime: false,
    });

    // --- Methods ---
    const getContractAmendmentData = async (id) => {
      const params = {
        endpoint: `v2/api/employee/${id}/contract-amendment`,
        configs: {
          method: 'GET',
        },
      };

      loadingStore.startGlobalLoading('getContractAmendmentData');
      try {
        let res = await mainStore.request(params);
        contractAmendmentData.value = { ...{}, ...res };
      } catch (error) {
        return Promise.reject(error);
      } finally {
        loadingStore.endGlobalLoading('getContractAmendmentData');
      }
    };

    const postContractAmendmentData = async (data) => {
      const params = {
        endpoint: `v2/api/employee/${data.id}/contract-amendment`,
        configs: {
          method: 'POST',
          body: data.payload,
        },
      };

      loadingStore.startGlobalLoading('postContractAmendmentData');
      try {
        return await mainStore.request(params);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        loadingStore.endGlobalLoading('postContractAmendmentData');
      }
    };

    const deleteContractAmendment = async (contractId) => {
      const params = {
        endpoint: `v2/api/Contract/delete-contract/${contractId}`,
        configs: {
          method: 'DELETE',
        },
      };

      loadingStore.startPartialLoading('deleteContractAmendment');
      try {
        await mainStore.request(params);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        loadingStore.endPartialLoading('deleteContractAmendment');
      }
    };

    return {
      contractAmendmentData,
      contractAmendmentFormValidation,
      getContractAmendmentData,
      postContractAmendmentData,
      deleteContractAmendment,
    };
  }
);
