import { defineStore } from 'pinia';
import { ref, watch } from 'vue';

export const useToastNotificationStore = defineStore(
  'toastNotification',
  () => {
    // --- State ---
    const displayToastNotification = ref(false);

    const params = ref({
      position: {
        top: false,
        bottom: false,
        right: false,
        left: false,
      },
      btnActionLabel: null,
      messages: {
        error: null,
        success: null,
        warning: null,
        info: null,
      },
      timeout: 3000,
      type: 'warning',
      colorAndIcon: {
        color: 'warning',
        icon: 'mdi-alert-circle-outline',
      },
      actionAfterClose: null,
      actionBtnPress: null,
      preventCloseToast: false,
    });

    // --- Methods ---
    const getColorAndIcon = (type) => {
      switch (type) {
        case 'success':
          params.value.colorAndIcon.color = 'success';
          params.value.colorAndIcon.icon = 'mdi-check-circle-outline';
          break;
        case 'info':
          params.value.colorAndIcon.color = 'blueRegular';
          params.value.colorAndIcon.icon = 'mdi-help-circle-outline';
          break;
        case 'warning':
          params.value.colorAndIcon.color = 'warning';
          params.value.colorAndIcon.icon = 'mdi-alert-circle-outline';
          break;
        case 'error':
          params.value.colorAndIcon.color = 'redDanger';
          params.value.colorAndIcon.icon = 'mdi-alert-circle-outline';
          break;
        default:
          params.value.colorAndIcon.color = 'warning';
          params.value.colorAndIcon.icon = 'mdi-alert-circle-outline';
      }
    };

    const showToastNotification = (newValues) => {
      if (!newValues.position) defaultPosition();
      params.value = { ...params.value, ...newValues };
      displayToastNotification.value = true;
    };

    const defaultPosition = () => {
      params.value.position.top = true;
      params.value.position.right = true;
    };

    // --- Watchers ---
    watch(
      () => params.value.type,
      (type) => {
        getColorAndIcon(type);
      }
    );

    watch(
      () => displayToastNotification.value,
      (displayToastNotification) => {
        if (!displayToastNotification && params.value.actionAfterClose) {
          params.value.actionAfterClose();
        }
      }
    );

    return {
      showToastNotification,
      displayToastNotification,
      params,
    };
  }
);
