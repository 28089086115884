import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useMainStore } from '@/store/pinia/main.store.js';
import { useUserStore } from '@/store/pinia/user.store.js';
import { useLoadingStore } from '@/store/pinia/loading.store.js';

export const useProfileOffboardingStore = defineStore(
  'profileOffboarding',
  () => {
    const mainStore = useMainStore();
    const userStore = useUserStore();
    const loadingStore = useLoadingStore();

    // --- State ---
    const offboardingInformation = ref({
      terminationReceiptDate: null,
      lastDayOfWorkDate: null,
      exitDate: null,
      terminationType: null,
      reasonForLeavingType: null,
      eligibleForRehireType: null,
      offboardingNotes: null,
      isProcessInDraft: null,
    });
    const offboardingInitialInformation = ref(null);
    const offboardingExitDate = ref(null);

    // --- Methods ---
    const updateOffboarding = async (payload) => {
      const params = {
        endpoint: `v2/api/employee/${payload.employeeId}/offboarding`,
        configs: {
          body: payload.body,
          method: 'PUT',
        },
      };

      loadingStore.startPartialLoading('updateOffboarding');
      try {
        await mainStore.request(params);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        loadingStore.endPartialLoading('updateOffboarding');
      }
    };

    const getOffboardingInformation = async (id) => {
      const employeeId = id || userStore.userInfo.sub;

      const params = {
        endpoint: `v2/api/employee/${employeeId}/offboarding`,
        configs: {
          method: 'GET',
        },
      };

      loadingStore.startPartialLoading('getOffboardingInformation');
      try {
        return await mainStore.request(params);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        loadingStore.endPartialLoading('getOffboardingInformation');
      }
    };

    const startEmployeeOffboarding = async (payload) => {
      loadingStore.startGlobalLoading('startOffboarding');

      const params = {
        endpoint: `v2/api/employee/${payload.employeeId}/offboarding`,
        configs: {
          method: 'POST',
          body: { exitDate: payload.exitDate },
        },
      };

      try {
        await mainStore.request(params);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        loadingStore.endGlobalLoading('startOffboarding');
      }
    };

    return {
      offboardingInformation,
      updateOffboarding,
      getOffboardingInformation,
      offboardingInitialInformation,
      startEmployeeOffboarding,
      offboardingExitDate,
    };
  }
);
