import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

export const useLoadingStore = defineStore('loading', () => {
  // --- Local variables ---
  const globalLoadings = ref([]);
  const partialLoadings = ref([]);

  // --- Computed ---
  const isGlobalLoading = computed(() => globalLoadings.value.length !== 0);
  const isPartialLoading = computed(() => partialLoadings.value.length !== 0);

  // --- Methods ---
  const startGlobalLoading = (key) => {
    globalLoadings.value = [...globalLoadings.value, key];
  };

  const startPartialLoading = (key) => {
    partialLoadings.value = [...partialLoadings.value, key];
  };

  const endGlobalLoading = (key) => {
    globalLoadings.value = globalLoadings.value.filter(
      (globalLoading) => globalLoading !== key
    );
  };

  const endPartialLoading = (key) => {
    partialLoadings.value = partialLoadings.value.filter(
      (partialLoading) => partialLoading !== key
    );
  };

  return {
    isGlobalLoading,
    isPartialLoading,
    startGlobalLoading,
    endGlobalLoading,
    startPartialLoading,
    endPartialLoading,
  };
});
