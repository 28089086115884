<template>
  <div>
    <p class="custom-label mb-1">{{ label }}</p>
    <v-menu
      v-model="showDatePicker"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      :disabled="disabled"
      min-width="auto"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          :background-color="disabled ? 'ashRegular' : 'white'"
          outlined
          dense
          :value="normalizedValue"
          :placeholder="placeholder"
          :disabled="disabled"
          readonly
          v-bind="attrs"
          v-on="on"
          :rules="rules"
          :hint="hint"
          :persistent-hint="isHintPersistent"
          :hide-details="hideDetails"
        >
          <template slot="prepend-inner">
            <v-icon
              class="custom-icon"
              :color="disabled ? 'greyRegular' : 'blueRegular'"
              size="20"
              >{{ 'mdi-calendar' }}
            </v-icon>
          </template>
        </v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        color="blueRegular"
        :locale="$i18n.locale === 'de' ? 'de-DE' : ''"
        :disabled="disabled"
        :allowed-dates="allowedDates"
        :min="min"
        :max="max"
        :type="type"
      >
        <v-spacer></v-spacer>
        <v-btn
          v-if="clearable"
          text
          color="redDanger"
          @click="emitDate('')"
          @blur="showDatePicker = false"
        >
          {{ t('Action_.buttons.clear') }}
        </v-btn>
        <v-btn text color="greyRegular" @blur="showDatePicker = false">
          {{ t('Action_.buttons.cancel') }}
        </v-btn>
        <v-btn
          text
          color="blueRegular"
          @blur="showDatePicker = false"
          @click="emitDate(date)"
        >
          {{ t('Action_.buttons.save') }}
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script setup>
import languages from '../../../profile/locales/languages';
import { ref, computed, defineProps, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n-bridge';
import helpers from '@/helpers/helpers';

const emit = defineEmits(['input', 'change']);
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  label: {
    type: String,
    required: false,
    default: '',
  },
  value: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    required: false,
    default: '',
  },
  rules: {
    type: Array,
    required: false,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  min: {
    type: String,
    required: false,
    default: '',
  },
  max: {
    type: String,
    required: false,
    default: '',
  },
  hint: {
    type: String,
    required: false,
    default: '',
  },
  allowedDates: {
    type: Function,
    required: false,
    default: () => {
      return true;
    },
  },
  type: {
    type: String,
    required: false,
    default: 'date',
  },
  clearable: {
    type: Boolean,
    required: false,
    default: true,
  },
  isHintPersistent: {
    type: Boolean,
    required: false,
    default: false,
  },
  hideDetails: {
    type: Boolean,
    required: false,
    default: false,
  },
});

// --- Local variables ---
const showDatePicker = ref(false);
const date = ref(null);

// Normalize value
const normalizedValue = computed(() => helpers.formatDate(props.value) || null);

// --- Methods ---
const emitDate = (date) => {
  emit('input', date);
  emit('change', date);
};
</script>

<style scoped lang="scss">
.custom-label {
  font-size: 13px;
}

.custom-icon {
  margin: 2px 4px 0 0;
}

.v-text-field--outlined ::v-deep fieldset {
  border-color: $ash-dark;
}

.v-text-field ::v-deep label {
  font-size: 13px;
}

.v-input--is-focused ::v-deep fieldset {
  border: 1px solid $blue-regular !important;
}
</style>
