import { ref } from 'vue';

export const usePagination = () => {
  // --- Local variables ---
  const pagination = ref({
    page: 1,
    total: 0,
    length: 1,
    pageSize: 20,
    pageSizes: [5, 10, 20, 50],
  });

  // --- Methods ---
  const validatePaginationData = () => {
    pagination.value.page =
      pagination.value.page > 0 ? pagination.value.page : 1;

    pagination.value.length = !isNaN(pagination.value.length)
      ? pagination.value.length
      : 1;
  };

  const validatePageOptions = () => {
    const maxPage = Math.ceil(
      pagination.value.total / pagination.value.pageSize
    );

    if (pagination.value.page > maxPage) {
      pagination.value.page = maxPage;
    }
  };

  const updatePaginationData = (response) => {
    pagination.value.length = Math.ceil(
      response.totalCount / response.pageSize
    );
    pagination.value.total = response.totalCount;
  };

  return {
    pagination,
    validatePaginationData,
    validatePageOptions,
    updatePaginationData,
  };
};
