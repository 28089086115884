<template>
  <v-dialog
    data-testid="candidates-hiring-contract-is-signed-dialog"
    v-model="displayDialog"
    :retain-focus="false"
    persistent
    width="614"
  >
    <v-card class="pa-3">
      <v-card-title
        data-testid="candidates-hiring-contract-is-signed-title"
        class="dialog-title mb-4"
      >
        {{ t('Title_.page.haveSignedContract') }}
      </v-card-title>
      <v-card-text class="section-detail mineBlack--text pb-0">
        <v-container>
          <v-row>
            <v-col class="pl-0 pr-0">
              <RegularTextInput
                data-testid="candidates-hiring-contract-is-signed-full-name-field"
                disabled
                :value="candidateFullName"
                :label="t('Msg_.candidate')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              data-testid="candidates-hiring-warning-message"
              class="pl-0 pt-0 mb-6"
            >
              {{ t('Msg_.haveSignedContractWarningMessage') }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pa-0 mb-12" cols="6">
              <FileInput
                data-testid="candidates-hiring-contract-is-signed-contract-file-input"
                :label="t('Msg_.signedContract')"
                deletable
                hide-display-document
                v-model="signedContract"
                :placeholder="t('Msg_.selectFileToUpload')"
                @delete-document="deleteDocument()"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="d-flex justify-end mt-4">
        <v-btn
          data-testid="candidates-hiring-contract-is-signed-abort-btn"
          class="cancel-outlined-btn"
          @click="closeDialog()"
        >
          {{ t('Action_.buttons.abort') }}
        </v-btn>
        <v-btn
          data-testid="candidates-hiring-contract-is-signed-confirm-btn"
          class="main-action-btn ml-2"
          @click="uploadSignedContract()"
          :disabled="!signedContract"
        >
          {{ t('Action_.buttons.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import FileInput from '@/components/shared/customComponents/inputs/file-input.vue';
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';

import languages from '../../locales/languages';
import { usePeopleCandidatesStore } from '@/store/pinia/people-candidates.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { defineEmits, ref, computed } from 'vue';

const emit = defineEmits(['close-dialog', 'upload-signed-contract']);
const peopleCandidatesStore = usePeopleCandidatesStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const signedContract = ref(null);
const displayDialog = true;

// --- Methods ---
const closeDialog = () => {
  emit('close-dialog');
};

const uploadSignedContract = () => {
  emit('upload-signed-contract', signedContract.value);
};

const deleteDocument = () => {
  signedContract.value = null;
};

// --- Computed ---
const candidate = computed(() => {
  return peopleCandidatesStore.candidatesList.items[
    peopleCandidatesStore.candidateIndex
  ];
});

const candidateFullName = computed(() => {
  return `${candidate.value.applicant.firstName} ${candidate.value.applicant.lastName}`;
});
</script>

<style lang="scss" scoped></style>
