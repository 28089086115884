<template>
  <v-dialog
    data-testid="applicants-handover-dialog"
    v-model="displayDialog"
    :retain-focus="false"
    persistent
    width="614"
  >
    <v-card class="pa-3">
      <v-card-title
        data-testid="applicants-dialog-title"
        class="dialog-title mb-4"
      >
        {{ t('Title_.page.startRecruitmentProcess') }}
      </v-card-title>
      <v-card-text class="section-detail mineBlack--text pb-0">
        <v-container>
          <v-row>
            <v-col class="pl-0">
              <RegularTextInput
                data-testid="applicants-dialog-full-name-field"
                disabled
                v-model="applicantFullName"
                :label="'Candidate'"
              />
            </v-col>
            <v-col class="pr-0">
              <DropdownInput
                data-testid="applicants-dialog-hub-dropdown"
                :label="t('Msg_.hub')"
                :items="peopleStore.peopleHubList"
                item-text="name"
                item-value="id"
                :value="props.recruit.applicant.mainHub"
                :clearable="false"
                disabled
              />
            </v-col>
          </v-row>
          <v-row class="mb-0">
            <v-col cols="6" class="pl-0 pt-0 pb-0">
              <DropdownInput
                data-testid="applicants-dialog-process-responsible-field"
                :label="t('Msg_.processResponsible')"
                :items="props.recruit.processResponsibleList"
                :value="props.recruit.responsibleId"
                disabled
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <template v-if="props.recruit.testDays.length">
        <div
          v-for="(testDay, index) in props.recruit.testDays"
          :key="index"
          class="mr-2 ml-2"
        >
          <v-card-subtitle
            data-testid="applicants-dialog-test-day-subtitle"
            class="section-title pb-0"
          >
            {{ `${t('Title_.subTitle.testDay')} # ${index + 1}` }}
          </v-card-subtitle>
          <v-card-text class="section-detail mineBlack--text pb-0">
            <v-container>
              <v-row>
                <v-col cols="4" class="pl-0">
                  <DropdownInput
                    data-testid="applicants-dialog-reviewer-field"
                    :label="t('Msg_.reviewer')"
                    :items="props.recruit.processResponsibleList"
                    :value="testDay.reviewerId"
                    disabled
                  />
                </v-col>
                <v-col cols="4">
                  <RegularTextInput
                    data-testid="applicants-dialog-date-field"
                    disabled
                    :value="helpers.formatDate(testDay.testDayDate)"
                    :label="t('Msg_.date')"
                  />
                </v-col>
                <v-col cols="4" class="pr-0">
                  <RegularTextInput
                    data-testid="applicants-dialog-time-field"
                    disabled
                    :value="testDayTime(testDay)"
                    :label="t('Msg_.time')"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </div>
      </template>

      <v-card-actions class="d-flex justify-end pa-6">
        <v-btn
          data-testid="applicants-dialog-cancel-button"
          class="cancel-outlined-btn"
          @click="emit('close-dialog')"
        >
          {{ t('Action_.buttons.cancel') }}
        </v-btn>
        <v-btn
          data-testid="applicants-dialog-confirm-button"
          class="main-action-btn ml-2"
          @click="createCandidate()"
        >
          {{ t('Action_.buttons.confirmCandidate') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';

import languages from '../../locales/languages';
import { useRouter } from 'vue-router/composables';
import { usePeopleApplicantsStore } from '@/store/pinia/people-applicants.store.js';
import { usePeopleStore } from '@/store/pinia/people.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { computed, defineEmits, defineProps } from 'vue';
import helpers from '@/helpers/helpers';

const router = useRouter();
const peopleStore = usePeopleStore();
const emit = defineEmits(['close-dialog']);
const peopleApplicantsStore = usePeopleApplicantsStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  recruit: {
    type: Object,
    required: true,
  },
});

// --- Local variables ---
const displayDialog = true;

// --- Methods ---
const createCandidate = async () => {
  const payload = {
    applicantId: props.recruit.id,
    body: {
      testDays: prepareTestDaysDataToCreateCandidate(),
      responsibleId: props.recruit.responsibleId,
      hubId: props.recruit.applicant.mainHub,
    },
  };

  try {
    await peopleApplicantsStore.createCandidate(payload);
    redirectToCandidatesList();
  } catch (error) {
    console.log(error);
  }
};

const redirectToCandidatesList = () => {
  router.push({ name: 'CandidateList' });
};

const prepareTestDaysDataToCreateCandidate = () => {
  return props.recruit.testDays?.map((testDay) => {
    const testDayStartsAt = new Date(
      `${testDay.testDayDate}T${testDay.testDayStartsAt}`
    ).toISOString();
    const testDayEndsAt = new Date(
      `${testDay.testDayDate}T${testDay.testDayEndsAt}`
    ).toISOString();

    return {
      reviewerId: testDay.reviewerId,
      testDayEndsAt,
      testDayStartsAt,
    };
  });
};

const testDayTime = (data) => {
  return `${data.testDayStartsAt} - ${data.testDayEndsAt}`;
};

// --- Computed ---
const applicantFullName = computed(() => {
  return `${props.recruit.applicant.firstName} ${props.recruit.applicant.lastName}`;
});
</script>

<style lang="scss" scoped></style>
