<template>
  <v-container data-testid="fleet-menu" v-if="!!vehicleDetails">
    <v-row align="center" class="pt-3">
      <v-col cols="auto" class="card-title">
        {{ t('Title_.page.vehicle') }} -
        <span id="text_title_plate_color"> {{ vehicleDetails.plate }} </span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="flex-grow-0">
        <v-btn
          v-if="canUser(['Fleet.Update'])"
          :to="{
            name: 'FleetVehicleFormEdit',
            params: { vehicleId: vehicleDetails.id },
          }"
          class="main-action-btn"
          data-testid="fleet-menu-edit-vehicle-details-btn"
        >
          {{ t('Action_.buttons.editVehicleDetails') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-row>
          <v-col>
            <template>
              <v-card class="rounded-lg py-1 px-2 shadow-regular">
                <v-card-text class="pa-4">
                  <v-container pa-0>
                    <v-row no-gutters>
                      <v-col cols="auto">
                        <v-img
                          v-if="vehicleDetails.picture"
                          class="rounded-lg ma-1"
                          :src="
                            $helpers.convertImgToBase64(vehicleDetails.picture)
                          "
                          alt="car Icon"
                          width="80"
                          height="80"
                          data-testid="fleet-menu-vehicle-img"
                        />
                        <v-icon v-else size="80" color="greyLight">
                          mdi-car
                        </v-icon>
                      </v-col>
                      <v-col cols="auto" align-self="center" class="ml-2">
                        <v-row>
                          <v-col
                            class="section-title pt-4 pb-0"
                            data-testid="fleet-menu-vehicle-plate-text"
                          >
                            {{ vehicleDetails.plate }}
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            class="section-detail pt-0"
                            data-testid="fleet-menu-vehicle-brand-model-line-text"
                          >
                            {{
                              `${vehicleDetails.brand || '-'} ${
                                vehicleDetails.model || '-'
                              } ${vehicleDetails.line || ''}`
                            }}
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-4 section-detail pb-2">
                      <v-col>
                        <v-row>
                          <v-col class="pt-2 pb-0">
                            <v-icon class="pr-1" color="greyRegular" small>
                              mdi-account
                            </v-icon>
                            <span
                              data-testid="fleet-menu-vehicle-assigned-driver-link"
                              :class="
                                !!assignedDriver
                                  ? 'assigned-driver-redirect'
                                  : ''
                              "
                              @click="redirectToAssignedDriver()"
                              >{{ assignedDriverName }}
                            </span>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            class="pt-0 pb-0"
                            data-testid="fleet-menu-vehicle-imei-serial-number-text"
                          >
                            <v-icon class="pr-1" color="greyRegular" small>
                              mdi-router-wireless
                            </v-icon>
                            {{
                              (vehicleDetails.imei || '-') +
                              ' ' +
                              (vehicleDetails.serialNumber || '-')
                            }}
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            class="pt-0 pb-0"
                            data-testid="fleet-menu-vehicle-hub-text"
                          >
                            <v-icon class="pr-1" color="greyRegular" small>
                              mdi-store
                            </v-icon>
                            {{ vehicleDetails.hubId ? getHubName : 'Null' }}
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            class="pt-0"
                            data-testid="fleet-menu-vehicle-current-km-text"
                          >
                            <v-icon class="pr-1" color="greyRegular" small>
                              mdi-speedometer
                            </v-icon>
                            {{ vehicleDetails.currentKm || '-' }}
                            {{ t('Msg_.km') }}
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>

                    <v-row class="pb-0 pt-4">
                      <v-col cols="4" class="pb-1">
                        <p
                          class="label-dates mb-0"
                          data-testid="fleet-menu-vehicle-first-registration-text"
                        >
                          {{ t('Msg_.firstRegistration') }}
                        </p>
                      </v-col>
                      <v-col cols="4" class="pb-1">
                        <p
                          class="label-dates mb-0"
                          data-testid="fleet-menu-vehicle-street-legal-text"
                        >
                          {{ t('Msg_.tuvAu') }}
                        </p>
                      </v-col>
                      <v-col cols="4" class="pb-1">
                        <p
                          class="label-dates mb-0"
                          data-testid="fleet-menu-vehicle-end-leasing-text"
                        >
                          {{ t('Msg_.endOfLeasing') }}
                        </p>
                      </v-col>
                    </v-row>
                    <v-row class="pt-0">
                      <v-col cols="4" class="py-0">
                        <p
                          class="value-dates"
                          data-testid="fleet-menu-vehicle-first-registration-date-text"
                        >
                          {{
                            $helpers.formatDateMonthYear(
                              vehicleDetails.carIdRegistrationDate
                            ) || '-'
                          }}
                        </p>
                      </v-col>
                      <v-col cols="4" class="py-0">
                        <p
                          class="value-dates"
                          data-testid="fleet-menu-vehicle-street-legal-date-text"
                        >
                          {{
                            $helpers.formatDateMonthYear(
                              vehicleDetails.streetLegalDate
                            ) || '-'
                          }}
                        </p>
                      </v-col>
                      <v-col cols="4" class="py-0">
                        <p
                          class="value-dates"
                          data-testid="fleet-menu-vehicle-end-leasing-date-text"
                        >
                          {{
                            $helpers.formatDateMonthYear(
                              vehicleDetails.endOfLeasing
                            ) || '-'
                          }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </template>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="py-2 px-1 rounded-lg shadow-regular">
              <v-list nav dense>
                <v-list-item-group mandatory>
                  <v-list-item
                    v-for="(item, index) in selectMenu"
                    :key="index"
                    :to="menuRoutes[index]"
                  >
                    <v-list-item-content :data-testid="`select-menu-${item}`">
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <template>
        <v-col cols="8">
          <router-view />
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script setup>
import languages from './locales/languages';
import { useFleetVehiclesStore } from '@/store/pinia/fleet-vehicles.store.js';
import { usePermissions } from '@/helpers/composables/permissions.js';
import { useI18n } from 'vue-i18n-bridge';
import { computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';

const { trackPage } = amplitudeComposable();
const route = useRoute();
const router = useRouter();
const fleetVehiclesStore = useFleetVehiclesStore();
const { canUser } = usePermissions();
const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const menuRoutes = {
  0: { name: 'FleetDriverAssignment' },
  1: { name: 'FleetVehicleDetails' },
  2: { name: 'FleetAdditionalInformation' },
  3: {
    name: 'BuroKingdra',
    query: `${process.env.VUE_APP_LOGIN_API}/v3/fleet/vehicle-menu/${route.params.vehicleId}/documents`,
  },
  4: { name: 'FleetDriverInformation' },
};

// --- Methods ---
const fetchVehicleDetails = async () => {
  try {
    await fleetVehiclesStore.getVehicleDetails(route.params.vehicleId);
  } catch (error) {
    console.log(error);
  }
  trackPage(fleetVehiclesStore.vehicleDetails.hubId);
};

const redirectToAssignedDriver = () => {
  const driverId =
    fleetVehiclesStore.vehicleDetails?.actualAssignment?.driver?.id;
  if (driverId) {
    routeToProfileMasterInformation(driverId);
  }
};

const routeToProfileMasterInformation = (id) => {
  router.push({
    name: 'EmployeeProfileMasterInformation',
    params: { employeeId: id },
  });
};

// --- Computed ---
const assignedDriver = computed(() => {
  return fleetVehiclesStore.vehicleDetails?.actualAssignment?.driver;
});

const assignedDriverName = computed(() => {
  return (
    fleetVehiclesStore.vehicleDetails?.actualAssignment?.driver?.completeName ||
    '-'
  );
});

const vehicleDetails = computed(() => {
  return fleetVehiclesStore.vehicleDetails;
});

const selectMenu = computed(() => {
  const translations = Object.values(tm('Action_.selectMenu'));
  if (!assignedDriver.value?.id) {
    translations.pop();
  }
  return translations;
});

const getHubName = computed(() => {
  let hub = fleetVehiclesStore.hubList.find(
    (obj) => obj.id == fleetVehiclesStore.vehicleDetails.hubId
  );
  return hub?.name;
});

// --- watcher ---
watch(
  () => route.name,
  () => {
    trackPage(fleetVehiclesStore.vehicleDetails.hubId);
  }
);

// --- Lifecycle hooks ---
fetchVehicleDetails();
</script>

<style scoped lang="scss">
.v-list-item--active {
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.4px;
  color: $blue-regular;
}

.assigned-driver-redirect {
  cursor: pointer;
  text-decoration: underline;
}

.assigned-driver-redirect:hover {
  color: $blue-regular !important;
}

.label-dates {
  font-size: 11px;
  color: $grey-dark;
}
.value-dates {
  font-size: 13px;
  color: $mine-black;
}
</style>
