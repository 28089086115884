<template>
  <div data-testid="hub-list-item-container">
    <v-hover v-slot="{ hover }">
      <v-card
        :class="hover ? 'shadow-hover' : 'shadow-regular'"
        class="rounded-lg"
      >
        <v-container>
          <v-row class="d-flex align-center pa-3">
            <v-col>
              <v-row align="center">
                <v-col
                  :data-testid="`hub-list-item-${index}-name`"
                  cols="auto"
                  class="subtitle-text-1"
                >
                  {{ hub.name }}
                </v-col>
                <v-col cols="auto" v-if="props.hub.operationStatus">
                  <v-chip
                    :data-testid="`hub-list-item-${index}-status`"
                    :color="statusChipColor()"
                    label
                    :text-color="statusChipTextColor()"
                    class="my-1 mr-2 caption-3"
                    small
                  >
                    {{ hubOperationStatus }}
                  </v-chip>
                </v-col>
              </v-row>
              <v-row align="start" class="mt-0 d-flex">
                <v-col
                  v-if="hub.hubType"
                  cols="auto"
                  class="d-flex align-center"
                >
                  <img
                    :data-testid="`hub-list-item-${index}-hub-type-icon`"
                    :src="hubTypeIcon"
                    class="mr-2"
                  />
                  <p
                    :data-testid="`hub-list-item-${index}-hub-type`"
                    class="mb-0 overline-2 greyDark--text hub-type-text"
                  >
                    {{ hubTypeMessage }}
                  </p>
                  <div class="vertical-divider ml-4" />
                </v-col>
                <v-col cols="auto" class="d-flex align-center">
                  <img
                    :data-testid="`hub-list-item-${index}-number-icon`"
                    :src="FingerprintIcon"
                    class="mr-2"
                  />
                  <p
                    :data-testid="`hub-list-item-${index}-number`"
                    class="mb-0 body-text-4 greyDark--text"
                  >
                    {{ hub.hubNumber || '-' }}
                  </p>
                </v-col>
                <v-col cols="auto" class="d-flex align-center">
                  <img
                    :data-testid="`hub-list-item-${index}-state-icon`"
                    :src="LocationOutlineIcon"
                    class="mr-2"
                  />

                  <p
                    :data-testid="`hub-list-item-${index}-state`"
                    class="mb-0 body-text-4 greyDark--text"
                  >
                    {{ hubState || '-' }}
                  </p>
                </v-col>
                <v-col cols="auto" class="d-flex align-center">
                  <img
                    :data-testid="`hub-list-item-${index}-team-planner-icon`"
                    :src="StarOutlineIcon"
                    class="mr-2"
                  />
                  <p
                    :data-testid="`hub-list-item-${index}-team-planner`"
                    class="mb-0 body-text-4 greyDark--text"
                  >
                    {{ hub.mainResponsibleTeamPlannerName || '-' }}
                  </p>
                </v-col>
                <v-col cols="auto" class="d-flex align-center">
                  <img
                    :data-testid="`hub-list-item-${index}-phone-icon`"
                    :src="PhoneOutlineIcon"
                    class="mr-2"
                  />
                  <p
                    :data-testid="`hub-list-item-${index}-phone`"
                    class="mb-0 body-text-4 greyDark--text"
                  >
                    {{ hub.phone || '-' }}
                  </p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <IconButton
                :data-testid="`hub-list-item-${index}-chevron-btn`"
                class="pa-2 mx-0 icon-btn"
                icon="mdi-chevron-right"
                icon-size="40"
                :border="false"
                :to="{
                  name: 'HubProfile',
                  params: { hubId: hub.id },
                }"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-hover>
  </div>
</template>

<script setup>
import IconButton from '@/components/shared/customComponents/buttons/icon-button.vue';
import BillingHubIcon from '@/assets/icons/billing-hub-icon.svg';
import SubhubIcon from '@/assets/icons/subhub-icon.svg';
import FingerprintIcon from '@/assets/icons/fingerprint-icon.svg';
import LocationOutlineIcon from '@/assets/icons/location-outline-icon.svg';
import StarOutlineIcon from '@/assets/icons/star-outline-icon.svg';
import PhoneOutlineIcon from '@/assets/icons/phone-outline-icon.svg';
import languages from './locales/languages';
import { useI18n } from 'vue-i18n-bridge';
import { defineProps, computed } from 'vue';

const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  hub: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
});

// --- Methods ---
const statusChipColor = () => {
  switch (props.hub.operationStatus) {
    case 11:
      return '#DAE9FF'; // blueLight in the new design
    case 21:
      return '#E6FBD9'; // greenLight in the new design
    case 201:
      return 'ashRegular';
  }
};

const statusChipTextColor = () => {
  switch (props.hub.operationStatus) {
    case 11:
      return 'blueRegular';
    case 21:
      return 'caribbeanDark';
    case 201:
      return 'mineBlack';
  }
};

// --- Computed ---
const hubTypeIcon = computed(() => {
  return props.hub.hubType === 1 ? BillingHubIcon : SubhubIcon;
});

const hubTypeMessage = computed(() => {
  return props.hub.hubType === 1
    ? t('Msg_.billingHub')
    : `${t('Msg_.subhubOf')} ${props.hub.billingHubName}`;
});

const hubOperationStatus = computed(() => {
  return tm(`Action_.selectOperationTypes[${props.hub.operationStatus}]`);
});

const hubState = computed(() => {
  if (props.hub.state && props.hub.state !== {}) {
    return tm(`Action_.selectStateTypes[${props.hub.state}]`);
  }
  return '-';
});
</script>

<style scoped lang="scss">
.hub-type-text {
  font-size: 12px !important;
}
.vertical-divider {
  border-left: 1px solid #9e9e9e;
  height: 22px;
}
</style>
