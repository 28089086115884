<template>
  <v-chip
    small
    label
    :color="color"
    :text-color="textColor"
    :close="deletable"
    close-icon="mdi-close"
    data-testid="regular-badge"
    @click:close="deleteBadge()"
  >
    {{ text }}
  </v-chip>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const emit = defineEmits(['delete-badge']);

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    required: true,
  },
  textColor: {
    type: null,
    required: true,
  },
  deletable: {
    type: Boolean,
    required: false,
    default: false,
  },
});

// --- Methods ---
const deleteBadge = () => {
  emit('delete-badge');
};
</script>

<style></style>
