import { defineStore } from 'pinia';
import { useMainStore } from '@/store/pinia/main.store.js';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { ref } from 'vue';

export const usePeopleEmployeesStore = defineStore('peopleEmployees', () => {
  const mainStore = useMainStore();
  const loadingStore = useLoadingStore();

  // --- State ---
  const employeeProfilePermissions = ref(null);

  // --- Methods ---
  const getEmployeeMenuPermissions = async (id) => {
    const menuPermissionsEndpoint = id
      ? `v2/api/employee/menupermissions?employeeId=${id}`
      : 'v2/api/employee/menupermissions';
    const params = {
      endpoint: menuPermissionsEndpoint,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startGlobalLoading('employeeMenuPermissions');
    try {
      const res = await mainStore.request(params);
      employeeProfilePermissions.value = res;
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endGlobalLoading('employeeMenuPermissions');
    }
  };

  const getEmployeeList = async (data) => {
    const employeeRole = data.pageOptions.employeeRole || 0;
    const urlencoded = new URLSearchParams();
    if (data) {
      urlencoded.append('Page', data.pagination.page);
      urlencoded.append('PageSize', data.pagination.pageSize);
      urlencoded.append('SearchTerm', data.pageOptions.searchTerm);
      urlencoded.append('QualificationType', employeeRole);
      urlencoded.append('OfficeId', data.pageOptions.hubId);
      urlencoded.append('HubRole', data.pageOptions.hubHole);
      urlencoded.append(
        'ShowOnlyPendingAmendment',
        data.pageOptions.showOnlyPendingAmendment
      );
      urlencoded.append(
        'ShowOnlyIsOffboardingInDraft',
        data.pageOptions.showOnlyIsOffboardingInDraft
      );
      data.pageOptions.employeeStatus.forEach((obj) => {
        urlencoded.append('Statuses', obj.value);
      });
      urlencoded.append('startMonth', data.pageOptions.startMonth);
    }

    const params = {
      endpoint: `v2/api/employees`,
      configs: {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: urlencoded,
      },
    };

    loadingStore.startPartialLoading('employeeList');
    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('employeeList');
    }
  };

  const getEmployeesByQuery = async (data) => {
    const urlencoded = new URLSearchParams();
    if (data) {
      urlencoded.append('SearchTerm', data);
    }

    const params = {
      endpoint: `v2/api/employees`,
      configs: {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: urlencoded,
      },
    };
    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return {
    employeeProfilePermissions,
    getEmployeeMenuPermissions,
    getEmployeeList,
    getEmployeesByQuery,
  };
});
