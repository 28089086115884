const vue27Routes = [
  {
    path: '/vue-27',
    name: 'CompositionApi',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/vue27.vue'),
    children: [
      {
        path: 'seb',
        name: 'seb',
        component: () => import('@/views/vue2.7/seb.vue'),
        meta: {
          requiresAuth: true,
          authorize: ['People.Employees.Read'],
        },
      },
      {
        path: 'aline',
        name: 'aline',
        component: () => import('@/views/vue2.7/aline.vue'),
        meta: {
          requiresAuth: true,
          authorize: ['People.Employees.Read'],
        },
      },
      {
        path: 'thomas',
        name: 'thomas',
        component: () => import('@/views/vue2.7/thomas.vue'),
        meta: {
          requiresAuth: true,
          authorize: ['People.Employees.Read'],
        },
      },
      {
        path: 'sidnei',
        name: 'sidnei',
        component: () => import('@/views/vue2.7/sidnei.vue'),
        meta: {
          requiresAuth: true,
          authorize: ['People.Employees.Read'],
        },
      },
    ],
  },
];

export default vue27Routes;
