import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useMainStore } from '@/store/pinia/main.store.js';
import { usePeopleApplicantsStore } from '@/store/pinia/people-applicants.store.js';
import { usePeopleCandidatesStore } from '@/store/pinia/people-candidates.store.js';

export const usePeopleRecruitmentContractStore = defineStore(
  'peopleRecruitmentContract',
  () => {
    const mainStore = useMainStore();
    const peopleApplicantsStore = usePeopleApplicantsStore();
    const peopleCandidatesStore = usePeopleCandidatesStore();

    // --- State ---
    const contract = ref({});
    const recruitType = ref(null);
    const applicant = ref({});
    const applicantId = ref(null);
    const applicantInitialData = ref({});
    const documents = ref({});
    const payroll = ref({});
    const payrollInitialData = ref({});
    const processStatusHistory = ref([]);
    const recruitData = ref({});
    const selectedHubId = ref(null);

    // --- Methods ---
    const updateApplicant = async (payload) => {
      const params = {
        endpoint: `v2/api/recruitments/applications/${payload.applicantId}`,
        configs: {
          method: 'PUT',
          body: payload.applicantContractData,
        },
      };

      try {
        await mainStore.request(params);
        if (recruitType.value === 'applicant') {
          peopleApplicantsStore.applicantsList.items[
            peopleApplicantsStore.showProfileIndex
          ].applicant = { ...{}, ...payload.applicantContractData };
        } else {
          peopleCandidatesStore.candidatesList.items[
            peopleCandidatesStore.showProfileIndex
          ].applicant = { ...{}, ...payload.applicantContractData };
        }
      } catch (error) {
        console.log(error);
      }
    };

    const uploadDocument = async (payload) => {
      let formData = new FormData();
      formData.append('file', payload.document.fileName);

      const params = {
        endpoint: `v2/api/recruitments/applications/${payload.applicantId}/upload-document?documentType=${payload.document.category}`,
        configs: {
          method: 'POST',
          body: formData,
          headers: {},
        },
      };

      try {
        const response = await mainStore.request(params);
        updateDocumentData(response);
      } catch (error) {
        return Promise.reject(error);
      }
    };

    const updateDocumentData = (response) => {
      documents.value = { ...{}, ...response.documents };
      if (recruitType.value === 'applicant') {
        peopleApplicantsStore.applicantsList.items[
          peopleApplicantsStore.showProfileIndex
        ].documents = [...[], ...response.documents];
      } else {
        peopleCandidatesStore.candidatesList.items[
          peopleCandidatesStore.showProfileIndex
        ].documents = [...[], ...response.documents];
      }
    };

    const deleteDocument = async (payload) => {
      const params = {
        endpoint: `v2/api/recruitments/Applications/${
          payload.applicantId
        }/documents/${encodeURIComponent(payload.document.fileUrl)}`,
        configs: {
          method: 'DELETE',
        },
      };

      try {
        await mainStore.request(params);
        handleDeletedDocument(payload);
      } catch (error) {
        return Promise.reject(error);
      }
    };

    const handleDeletedDocument = (payload) => {
      const emptyDocument = {
        category: payload.document.category,
        contextId: null,
        contextType: null,
        displayName: null,
        fileName: null,
        fileUrl: null,
        required: true,
      };
      documents.value[payload.index] = emptyDocument;
      if (recruitType.value === 'applicant') {
        peopleApplicantsStore.applicantsList.items[
          peopleApplicantsStore.showProfileIndex
        ].documents[payload.index] = { ...{}, ...emptyDocument };
      } else {
        const document =
          peopleCandidatesStore.candidatesList.items[
            peopleCandidatesStore.showProfileIndex
          ].documents[payload.index];
        document.category = payload.document.category;
        document.fileName = null;
        document.fileUrl = null;
        document.required = true;
      }
    };

    const getRecruitProfileData = async (recruitId) => {
      const params = {
        endpoint: `v2/api/recruitments/applications/${recruitId}`,
        configs: {
          method: 'GET',
        },
      };

      try {
        return await mainStore.request(params);
      } catch (error) {
        return Promise.reject(error);
      }
    };

    const updatePayroll = async (applicantId) => {
      const params = {
        endpoint: `v2/api/recruitments/applications/${applicantId}/payroll`,
        configs: {
          method: 'PUT',
          body: payroll.value,
        },
      };

      try {
        await mainStore.request(params);
      } catch (error) {
        console.log(error);
      }
    };

    return {
      contract,
      updateApplicant,
      recruitType,
      uploadDocument,
      deleteDocument,
      getRecruitProfileData,
      applicant,
      applicantId,
      applicantInitialData,
      documents,
      payroll,
      payrollInitialData,
      updatePayroll,
      processStatusHistory,
      recruitData,
      selectedHubId,
    };
  }
);
