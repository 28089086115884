<template>
  <v-container data-testid="fleet-ticket-form">
    <v-dialog
      data-testid="offboarding-dialog"
      :value="displayStartOffboardDialog"
      :retain-focus="false"
      persistent
      width="750"
      class="pa-4"
    >
      <v-card class="pa-3">
        <v-card-title
          data-testid="offboarding-dialog-title"
          class="dialog-title mb-4"
        >
          {{ offboardDialogTitle }}
        </v-card-title>
        <v-card-text class="pb-0">
          <DatePicker
            data-testid="offboarding-dialog-exit-date-field"
            v-model="profileOffboardingStore.offboardingExitDate"
            :allowed-dates="allowedDates"
            :label="t('Msg_.exitDate')"
            placeholder="--/--/----"
          />
        </v-card-text>
        <v-card-text>
          <p
            data-testid="offboarding-dialog-message"
            class="section-detail mineBlack--text dialog-message"
          >
            {{ t('Msg_.offboardDialogMessage') }}
          </p>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            data-testid="offboarding-dialog-cancel-btn"
            class="cancel-variant-btn"
            @click="closeOffboardDialog()"
          >
            {{ t('Action_.buttons.cancel') }}
          </v-btn>
          <v-btn
            data-testid="offboarding-dialog-start-offboarding-btn"
            :disabled="isStartOffboardingBtnDisabled"
            class="reject-cancel ml-2"
            @click="startEmployeeOffboarding()"
          >
            {{ t('Action_.buttons.startOffboarding') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script setup>
import languages from '@/components/profile/locales/languages';
import DatePicker from '@/components/shared/customComponents/inputs/date-picker.vue';
import { defineEmits, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n-bridge';
import { useProfileStore } from '@/store/pinia/profile.store.js';
import { useProfileOffboardingStore } from '@/store/pinia/profile-offboarding.store.js';

const emit = defineEmits(['close-offboard-dialog', 'start-offboarding']);
const profileStore = useProfileStore();
const profileOffboardingStore = useProfileOffboardingStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const displayStartOffboardDialog = ref(true);

// --- Methods ---
const closeOffboardDialog = () => {
  emit('close-offboard-dialog');
};

const allowedDates = (day) => {
  const today = new Date().toISOString().substring(0, 10);
  return day >= today;
};

const startEmployeeOffboarding = async () => {
  emit('start-offboarding');
};

// --- Computed ---
const offboardDialogTitle = computed(() => {
  return `${t('Title_.page.startOffboardingProcess')} [${
    profileStore.profileMenuInformation.fullName
  }]`;
});

const isStartOffboardingBtnDisabled = computed(() => {
  return !profileOffboardingStore.offboardingExitDate;
});
</script>

<style lang="scss" scoped>
.dialog-message {
  font-weight: bold;
}
.dialog-title {
  font-weight: 600;
  font-size: 24px;
  color: black;
}
</style>
