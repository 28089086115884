<template>
  <div class="ma-3">
    <v-container>
      <v-row>
        <v-col
          data-testid="applicants-action-required-title"
          class="section-title"
        >
          {{ t('Msg_.actionRequired') }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <DropdownInput
            data-testid="applicants-action-required-hub-dropdown"
            :label="t('Msg_.hub')"
            :items="peopleStore.peopleHubList"
            item-text="name"
            item-value="id"
            v-model="
              peopleApplicantsStore.applicantsList.items[props.index].applicant
                .mainHub
            "
            :clearable="false"
            @change="handleResponsibleList()"
          />
        </v-col>
        <v-col cols="3">
          <p class="custom-label mb-1">{{ t('Msg_.processResponsible') }}</p>
          <v-select
            data-testid="applicants-action-required-process-responsible-dropdown"
            dense
            outlined
            v-model="
              peopleApplicantsStore.applicantsList.items[props.index]
                .responsibleId
            "
            :items="processResponsibleList"
            :clearable="false"
            :loading="loading"
            :disabled="loading"
            :menu-props="{
              value: openMenu,
              closeOnClick: true,
              closeOnContentClick: true,
              openOnClick: true,
            }"
            @click="getResponsibleList()"
          ></v-select>
        </v-col>
        <v-col cols="auto" class="d-flex align-self-center mb-1">
          <v-btn
            data-testid="applicants-action-required-add-test-day-btn"
            class="main-action-btn ml-2"
            :disabled="isAddTestDayDisabled"
            @click="addTestDay()"
          >
            {{ t('Msg_.addTestDay') }}
          </v-btn>
        </v-col>
        <v-col cols="auto" class="d-flex align-self-center mt-1">
          <CheckboxControl
            data-testid="applicants-action-required-does-not-need-test-day-checkbox"
            :label="t('Msg_.dontNeedTestDay')"
            v-model="doesNotNeedTestDay"
            @change="resetTestDayData()"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container
      v-for="(testDay, testDayIndex) in peopleApplicantsStore.applicantsList
        .items[props.index].testDays"
      :key="testDayIndex"
    >
      <v-row>
        <v-col
          cols="2"
          class="section-title d-flex align-self-center"
          :data-testid="`applicants-action-required-test-day-${testDayIndex}-title`"
        >
          {{ `${t('Msg_.testDay')} # ${testDayIndex + 1}` }}
        </v-col>
        <v-col class="d-flex align-self-center">
          <ButtonWithIcon
            :data-testid="`applicants-action-required-test-day-${testDayIndex}-delete-btn`"
            class="default-btn"
            icon="mdi-delete"
            :text="t('Action_.buttons.deleteTestDay')"
            color="redDanger"
            :only-text="true"
            @click="deleteTestDay(testDayIndex)"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="3">
          <DropdownInput
            :data-testid="`applicants-action-required-test-day-${testDayIndex}-process-responsible-dropdown`"
            :label="t('Msg_.testDayReviewer')"
            :items="processResponsibleList"
            v-model="
              peopleApplicantsStore.applicantsList.items[props.index].testDays[
                testDayIndex
              ].reviewerId
            "
            :clearable="false"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4" class="pt-0">
          <DatePicker
            :data-testid="`applicants-action-required-test-day-${testDayIndex}-test-day-field`"
            :label="t('Msg_.testDay')"
            v-model="
              peopleApplicantsStore.applicantsList.items[props.index].testDays[
                testDayIndex
              ].testDayDate
            "
            :allowed-dates="allowedDates"
          />
        </v-col>
        <v-col cols="4" class="pt-0">
          <TimePicker
            :data-testid="`applicants-action-required-test-day-${testDayIndex}-start-time-field`"
            :label="t('Msg_.testDayStartTime')"
            v-model="
              peopleApplicantsStore.applicantsList.items[props.index].testDays[
                testDayIndex
              ].testDayStartsAt
            "
          />
        </v-col>
        <v-col cols="4" class="pt-0">
          <TimePicker
            :data-testid="`applicants-action-required-test-day-${testDayIndex}-end-time-field`"
            :label="t('Msg_.testDayEndTime')"
            v-model="
              peopleApplicantsStore.applicantsList.items[props.index].testDays[
                testDayIndex
              ].testDayEndsAt
            "
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script setup>
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
import ButtonWithIcon from '@/components/shared/customComponents/buttons/button-with-icon.vue';
import DatePicker from '@/components/shared/customComponents/inputs/date-picker.vue';
import TimePicker from '@/components/shared/customComponents/inputs/time-picker.vue';
import CheckboxControl from '@/components/shared/customComponents/controls/checkbox-control.vue';

import languages from '@/components/people/locales/languages';
import { usePeopleApplicantsStore } from '@/store/pinia/people-applicants.store.js';
import { usePeopleStore } from '@/store/pinia/people.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { computed, defineProps, ref } from 'vue';

const peopleStore = usePeopleStore();
const peopleApplicantsStore = usePeopleApplicantsStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
const props = defineProps({
  index: {
    type: Number,
    required: true,
  },
});

// --- Local variables ---
const loading = ref(false);
const openMenu = ref(false);

// --- Methods ---
const allowedDates = (day) => {
  const today = new Date().toISOString().substring(0, 10);
  return day >= today;
};

const getHubProcessResponsibleList = async () => {
  let mainHub =
    peopleApplicantsStore.applicantsList.items[props.index].applicant.mainHub;
  if (mainHub) {
    loading.value = true;
    try {
      const response = await peopleApplicantsStore.getHubProcessResponsibleList(
        mainHub
      );
      if (response.length) {
        peopleApplicantsStore.applicantsList.items[
          props.index
        ].processResponsibleList = handleHubProcessResponsibleList(response);
      }
      openMenu.value = true;
    } catch (error) {
      console.log(error);
    } finally {
      loading.value = false;
    }
  }
};

const handleHubProcessResponsibleList = (list) => {
  return list.map((responsible) => ({
    text: responsible.name,
    value: responsible.id,
  }));
};

const resetTestDayData = () => {
  peopleApplicantsStore.applicantsList.items[props.index].testDays = [];
};

const addTestDay = () => {
  peopleApplicantsStore.applicantsList.items[props.index].testDays.push({});
};

const deleteTestDay = (index) => {
  peopleApplicantsStore.applicantsList.items[props.index].testDays.splice(
    index,
    1
  );
};

const handleResponsibleList = () => {
  peopleApplicantsStore.applicantsList.items[props.index].responsibleId = null;
  getHubProcessResponsibleList();
};

const getResponsibleList = () => {
  if (loading.value || processResponsibleList.value.length) return;
  getHubProcessResponsibleList();
};

// --- Computed ---
const isAddTestDayDisabled = computed(() => {
  return (
    doesNotNeedTestDay.value ||
    !peopleApplicantsStore.applicantsList.items[props.index].responsibleId
  );
});

const doesNotNeedTestDay = computed({
  get() {
    return !peopleApplicantsStore.applicantsList.items[props.index].needTestDay;
  },
  set(value) {
    peopleApplicantsStore.applicantsList.items[props.index].needTestDay =
      !value;
  },
});

const processResponsibleList = computed(() => {
  return peopleApplicantsStore.applicantsList.items[props.index]
    .processResponsibleList;
});
</script>

<style lang="scss" scoped>
.custom-label {
  font-size: 13px;
}

.v-input--is-focused ::v-deep fieldset {
  border: 1px solid $blue-regular !important;
}

.v-input--disabled ::v-deep fieldset {
  border: 1px solid $ash-dark !important;
}

.v-text-field--outlined ::v-deep fieldset {
  border-color: $ash-dark;
}
</style>
