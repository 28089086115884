<template>
  <v-dialog
    v-model="displayDialog"
    :retain-focus="false"
    persistent
    width="614"
    data-testid="modal-dialog"
  >
    <v-card class="pa-3">
      <v-card-title class="dialog-title mb-4">
        {{ params.title }}
      </v-card-title>
      <v-card-text v-if="params.message" class="section-detail mineBlack--text">
        {{ params.message }}
      </v-card-text>
      <v-card-text
        v-if="params.reinforcement"
        class="section-detail font-weight-bold mineBlack--text"
      >
        {{ params.reinforcement }}
      </v-card-text>
      <v-card-text v-if="params.link" class="section-detail mineBlack--text">
        <a :href="params.link" target="_blank" rel="noopener noreferrer">
          {{ params.link }}
        </a>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div>
          <v-btn class="cancel-outlined-btn" @click="closeDialog()">
            {{ t('Action_.buttons.cancel') }}
          </v-btn>
          <v-btn
            v-if="params.actionBtn"
            :class="
              params.type === 'confirmation'
                ? 'reject-cancel ml-2'
                : 'main-action-btn ml-2'
            "
            @click="handleActionClick()"
          >
            {{ params.actionBtnText }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { useI18n } from 'vue-i18n-bridge';
import languages from '../../locales/languages';
import { usePopupDialogStore } from '@/store/pinia/popup-dialog.store.js';
import { computed, onBeforeMount } from 'vue';

const popupDialogStore = usePopupDialogStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Methods ---
const closeDialog = () => {
  if (popupDialogStore.params.cancelBtn) {
    popupDialogStore.params.cancelBtn();
  }
  popupDialogStore.displayDialog = false;
};

const handleActionClick = () => {
  if (popupDialogStore.params.actionBtn) {
    popupDialogStore.params.actionBtn();
    popupDialogStore.displayDialog = false;
  }
};

// --- Computed
const displayDialog = computed(() => {
  return popupDialogStore.displayDialog;
});

const params = computed(() => {
  return popupDialogStore.params;
});

// --- Lifecycle hooks ---
onBeforeMount(() => {
  popupDialogStore.params = { ...{}, ...popupDialogStore.initialParams };
});
</script>

<style lang="scss" scoped>
.dialog-title {
  font-weight: 600;
  font-size: 17px;
  color: black;
}
</style>
