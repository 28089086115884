<template>
  <v-dialog
    data-testid="candidates-generate-contract-dialog"
    v-model="displayDialog"
    :retain-focus="false"
    persistent
    width="500"
  >
    <v-card class="pa-6">
      <v-card-text class="section-detail mineBlack--text">
        <v-container>
          <v-row>
            <v-col class="d-flex justify-center">
              <v-icon size="120" color="caribbeanRegular">
                mdi-check-circle-outline
              </v-icon>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-center mb-4">
              <span class="card-title">
                {{ t('Msg_.changesSavedSuccessfully') }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-center mb-4">
              <span class="text-center">
                {{ t('Msg_.changesSavedSuccessfullyMessage') }}
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="d-flex justify-end">
        <v-btn
          data-testid="candidates-process-dialog-cancel-btn"
          class="cancel-outlined-btn"
          @click="closeDialog()"
        >
          {{ t('Action_.buttons.notYet') }}
        </v-btn>
        <v-btn
          data-testid="candidates-process-dialog-action-btn"
          class="main-action-btn ml-2"
          @click="sendCandidateToReview()"
        >
          {{ t('Action_.buttons.generateContract') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import languages from '../../locales/languages';
import { useI18n } from 'vue-i18n-bridge';
import { defineEmits } from 'vue';

const emit = defineEmits(['close-dialog', 'send-candidate-to-review']);
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const displayDialog = true;

// --- Methods ---
const closeDialog = () => {
  emit('close-dialog');
};
</script>

<style lang="scss" scoped></style>
