<template>
  <v-container data-testid="pending-employees-list" class="mt-2">
    <v-row align="center">
      <v-col cols="3">
        <SearchInput
          data-testid="pending-employees-list-search-input"
          :label="'Search Employee'"
          :placeholder="t('Msg_.search')"
          v-model="pageOptions.searchTerm"
          @update-list="getPendingEmployeeList()"
        />
      </v-col>
      <v-col cols="2">
        <DropdownInput
          data-testid="pending-employees-list-hub-dropdown"
          :label="t('Msg_.hub')"
          :items="peopleStore.peopleHubList"
          item-text="name"
          item-value="id"
          v-model="pageOptions.hubId"
          @change="getPendingEmployeeList($event)"
        />
      </v-col>
      <v-col class="ml-auto text-right" cols="4">
        <ButtonWithIcon
          data-testid="pending-employees-list-add-applicant-btn"
          class="main-action-icon-btn"
          icon="mdi-plus"
          :to="{
            name: 'ContractCreation',
          }"
          :text="t('Action_.buttons.addNewEmployee')"
        />
      </v-col>
    </v-row>
    <v-container v-if="displayPendingEmployees" class="pa-0">
      <v-row>
        <v-col class="my-n3">
          <PeopleInformationCard
            v-for="(
              employee, index
            ) in peoplePendingEmployeesStore.pendingEmployeesList"
            :key="employee.id"
            :index="index"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-progress-linear
      v-else-if="loadingStore.isPartialLoading"
      indeterminate
      rounded
      color="blueRegular"
    />
    <v-row v-else-if="displayEmptyState">
      <v-col>
        <EmptyListMessage data-testid="pending-employee-list-no-data-message" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import SearchInput from '@/components/shared/customComponents/inputs/search-input.vue';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
import ButtonWithIcon from '@/components/shared/customComponents/buttons/button-with-icon.vue';
import PeopleInformationCard from '@/components/people/peopleSharedComponents/peopleInformationCard/people-information-card.vue';
import EmptyListMessage from '@/components/shared/customComponents/emptyStates/empty-list-message.vue';
import languages from '../locales/languages';
import { usePeopleStore } from '@/store/pinia/people.store.js';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { usePeoplePendingEmployeesStore } from '@/store/pinia/people-pending-employees.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { ref, computed, onBeforeUnmount } from 'vue';
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';

const { trackPage } = amplitudeComposable();
const peopleStore = usePeopleStore();
const loadingStore = useLoadingStore();
const peoplePendingEmployeesStore = usePeoplePendingEmployeesStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const pageOptions = ref({
  searchTerm: '',
  hubId: '',
});

// --- Methods ---
const getPendingEmployeeList = async (event) => {
  if (event !== '') {
    try {
      await peoplePendingEmployeesStore.getPendingEmployeeList(
        pageOptions.value
      );
      scrollUp();
    } catch (error) {
      console.log(error);
    }
    trackPage(pageOptions.value.hubId);
  }
};

const scrollUp = () => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
};

// --- Computed ---
const displayPendingEmployees = computed(() => {
  return (
    peoplePendingEmployeesStore.pendingEmployeesList &&
    !loadingStore.isPartialLoading
  );
});

const displayEmptyState = computed(() => {
  return (
    !peoplePendingEmployeesStore.pendingEmployeesList.length &&
    !loadingStore.isPartialLoading
  );
});

// --- Lifecycle hooks ---
getPendingEmployeeList();

onBeforeUnmount(() => {
  peoplePendingEmployeesStore.showProfileIndex = null;
});
</script>

<style scoped lang="scss">
#user-initials {
  font-size: 1.6rem;
  color: $mine-black;
}
</style>
