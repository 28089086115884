<template>
  <router-view></router-view>
</template>

<script setup>
import { usePeopleContractCreationStore } from '@/store/pinia/people-contract-creation.store.js';
import { onBeforeUnmount } from 'vue';

const peopleContractCreationStore = usePeopleContractCreationStore();

// --- Lifecycle hooks ---
onBeforeUnmount(() => {
  peopleContractCreationStore.$partialReset('contractCreation');
  peopleContractCreationStore.$partialReset('documents');
});
</script>
