<template>
  <v-container>
    <v-row
      v-if="peopleRecruitmentContractStore.recruitType === 'candidate'"
      class="mt-1"
    >
      <v-col class="pb-0">
        <DropdownInput
          data-testid="recruitment-hiring-process-responsible"
          :label="t('Msg_.processResponsible')"
          :items="peopleRecruitmentContractStore.processResponsible"
          v-model="peopleRecruitmentContractStore.contract.processResponsible"
          disabled
        />
      </v-col>
    </v-row>

    <template
      v-if="peopleRecruitmentContractStore.recruitData.testDays?.length"
    >
      <RecruitmentContractTestDay
        v-for="(testDay, index) in peopleRecruitmentContractStore.recruitData
          .testDays"
        :key="index"
        :test-day="testDay"
        :index="index"
        class="pa-0"
      />
    </template>

    <v-row v-else>
      <v-col
        class="d-flex justify-center additional-info"
        data-testid="recruitment-hiring-process-dont-need-test-day-message"
      >
        {{ t('Msg_.dontNeedTestDay') }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
import RecruitmentContractTestDay from '@/components/people/contracts/contractRecruitment/recruitment-contract-test-day.vue';

import languages from '../../locales/languages';
import { useI18n } from 'vue-i18n-bridge';
import { usePeopleRecruitmentContractStore } from '@/store/pinia/people-recruitment-contract.store.js';

const peopleRecruitmentContractStore = usePeopleRecruitmentContractStore();
const { t } = useI18n({ locale: 'en', messages: languages });
</script>

<style lang="scss" scoped>
.additional-info {
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.3px;
  color: $grey-dark;
}
</style>
