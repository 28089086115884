const toursRoutes = [
  {
    path: '/tours',
    name: 'Tours',
    meta: {
      requiresAuth: true,
      authorize: ['Profitability.Read'],
    },
    redirect: () => {
      return { name: 'TourInformation' };
    },

    component: () => import('@/components/tours/tours.vue'),
    children: [
      //TODO: Remove tours-dashboard path redirect when tour-information reaches prod in Horsea
      {
        path: 'tours-dashboard',
        redirect: () => {
          return { name: 'TourInformation' };
        },
      },
      {
        path: 'tour-information',
        name: 'TourInformation',
        component: () =>
          import('@/components/tours/tourInformation/tour-information.vue'),
        meta: {
          authorize: ['Profitability.Read'],
        },
      },
      {
        path: 'tour-comparison',
        name: 'TourComparison',
        component: () =>
          import('@/components/tours/tourComparison/tour-comparison.vue'),
        meta: {
          requiresAuth: true,
          authorize: ['Profitability.Read'],
        },
      },
    ],
  },
];

export default toursRoutes;
