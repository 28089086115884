<template>
  <v-list-group
    :key="title"
    no-action
    :value="expandGroup"
    v-if="checkForSubmenu(submenus)"
  >
    <template #activator>
      <img
        v-if="icon.includes('.svg')"
        :src="icon"
        alt="missing icon"
        class="mr-3"
      />
      <v-icon v-else color="white" class="mr-3">{{ icon }}</v-icon>

      <v-list-item-title class="menu_title_text">{{ title }}</v-list-item-title>
    </template>

    <v-list-item
      v-for="submenu in submenus"
      v-show="canUser(submenu.permission)"
      :key="submenu.title"
      :to="{ name: submenu.routeName, query: submenu.params }"
      class="pl-8"
      :data-testid="submenu.id"
    >
      <v-list-item-title class="menu_subtitle_text ml-6">
        <!-- <img
          src="@/assets/navigationDrawer/submenu_dot.svg"
          class="submenu_dot"
          alt="submenu_dot"
        /> -->
        {{ submenu.title }}
      </v-list-item-title>
    </v-list-item>
  </v-list-group>
</template>

<script setup>
import { defineProps } from 'vue';
import { usePermissions } from '@/helpers/composables/permissions.js';

const { canUser } = usePermissions();

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  title: {
    type: String,
    default: 'Missing',
  },
  icon: {
    type: String,
    default: 'mdi-alert-decagram',
  },
  submenus: {
    type: Array,
    default: () => {
      return [{ title: 'Missing', route: 'Error' }];
    },
  },
  expandGroup: {
    type: Boolean,
    default: false,
  },
});

// --- Methods ---
const checkForSubmenu = (menu) => {
  return menu?.find((submenu) => canUser(submenu.permission));
};
</script>

<style scoped>
::v-deep .v-list-group__header {
  padding: 0 6px 0 16px !important;
}

::v-deep .v-list-group__header__append-icon {
  margin-left: 0 !important;
}

::v-deep .mdi-chevron-down {
  color: white !important;
}
</style>
