import { defineStore } from 'pinia';
import { ref } from 'vue';
import helpers from '@/helpers/helpers';
import { useMainStore } from '@/store/pinia/main.store.js';
import { useLoadingStore } from '@/store/pinia/loading.store.js';

export const useFleetVehiclesStore = defineStore('fleetVehicles', () => {
  const mainStore = useMainStore();
  const loadingStore = useLoadingStore();

  // --- State ---
  const vehiclesList = ref([]);
  const stepperCurrentStep = ref(1);
  const hubList = ref([]);
  const imageResponse = ref(null);
  const ticketList = ref([]);

  const vehicleResources = ref({
    acquirementTypeValues: [],
    assignmentStatus: [],
    conditionTypes: [],
    profileTypes: [],
    taxSelectionValues: [],
    vehicleTypes: [],
  });

  const vehicleDetails = ref({
    acquirementType: null,
    actualAssignment: null,
    bankGuarantee: null,
    basePrice: null,
    brand: null,
    carBranding: null,
    carIdRegistrationDate: null,
    carReturned: false,
    carReturnedDate: null,
    carRevenue: null,
    chassis: null,
    comments: null,
    contractDate: null,
    contractNumber: null,
    contractualPartnerId: null,
    currentKm: null,
    doorType: null,
    endOfLeasing: null,
    extraPayment: null,
    fuelCard: null,
    fuelType: null,
    hubId: null,
    id: null,
    imei: null,
    inspectionDate: null,
    inspectionRenewalDate: null,
    insuranceDeductible: null,
    insuranceProvider: null,
    insuranceRenewalDate: null,
    insuranceType: null,
    kmAllowance: null,
    line: null,
    model: null,
    monthlyInstallment: null,
    ownerId: null,
    plate: null,
    previousAssignments: [],
    serialNumber: null,
    streetLegalDate: null,
    streetLegalRenewalDate: null,
    transmissionType: null,
    type: null,
    updatedCarValue: null,
    usageId: null,
  });

  const assignmentEmployeeInformation = ref({
    handler: null,
    assigned: null,
    hubId: null,
    gasCard: null,
    taxSelection: null,
    taxSelectionType: null,
    driverLicenseNumber: null,
    driverLicenseClass: null,
    phoneNumber: null,
    office: null,
    qualification: null,
    dateOfIssue: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substring(0, 10),
    dateOfExpiry: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substring(0, 10),
    issuingAuthority: null,
  });

  const assignmentCarCondition = ref({
    id: null,
    kmStand: null,
    driverSide: null,
    passengerSide: null,
    above: null,
    front: null,
    back: null,
    interior: null,
  });

  const contractualPartnersList = ref([]);
  const usageList = ref([]);
  const ownersList = ref([]);

  // --- Methods ---
  const endAssignment = async (data) => {
    const params = {
      endpoint: `v2/api/Assignments/end-assignment`,
      configs: {
        method: 'POST',
        body: data,
      },
    };

    loadingStore.startGlobalLoading('endAssignment');
    try {
      await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endGlobalLoading('endAssignment');
    }
  };

  const downloadCurrentContract = async (data) => {
    const params = {
      endpoint: `v2/api/Assignments/${data}`,
      configs: {
        method: 'GET',
      },
    };

    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const uploadContract = async (payload) => {
    let formData = new FormData();
    formData.append('file', payload.uploadedFiles);

    const params = {
      endpoint: `v2/api/Assignments/${payload.actualAssignmentId}/upload-contract`,
      configs: {
        method: 'POST',
        body: formData,
        headers: {},
      },
    };

    loadingStore.startGlobalLoading('uploadContract');
    try {
      await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endGlobalLoading('uploadContract');
    }
  };

  const uploadDocument = async (payload) => {
    let formData = new FormData();
    formData.append('file', payload);

    const params = {
      endpoint: `v2/api/Vehicles/upload-document`,
      configs: {
        method: 'POST',
        body: formData,
        headers: {},
      },
    };

    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const uploadVehicleTicket = async (payload) => {
    let formData = new FormData();
    formData.append('file', payload);

    const params = {
      endpoint: `v2/api/Vehicles/upload-ticket`,
      configs: {
        method: 'POST',
        body: formData,
        headers: {},
      },
    };

    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const editDocument = async (payload) => {
    let formData = new FormData();
    formData.append('file', payload.file);

    const params = {
      endpoint: `v2/api/Vehicles/upload-document/${payload.id}`,
      configs: {
        method: 'PUT',
        body: formData,
        headers: {},
      },
    };

    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const postAssignmentInformation = async (data) => {
    const params = {
      endpoint: 'v2/api/Assignments',
      configs: {
        method: 'POST',
        body: data,
      },
    };

    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getEmployeesByQuery = async (data) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append('searchPattern', data);

    const params = {
      endpoint: 'v2/api/fleet/Resources/employees',
      configs: {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: urlencoded,
      },
    };

    loadingStore.startPartialLoading('getEmployeesByQuery');
    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('getEmployeesByQuery');
    }
  };

  const getEmployeeDetails = async (id) => {
    const params = {
      endpoint: `v2/api/fleet/Resources/employees/${id}`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startPartialLoading('getEmployeeDetails');
    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('getEmployeeDetails');
    }
  };

  const getVehicleResources = async () => {
    const params = {
      endpoint: 'v2/api/fleet/resources',
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startGlobalLoading('getVehicleResources');
    try {
      const res = await mainStore.request(params);
      vehicleResources.value = res;
      return res;
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endGlobalLoading('getVehicleResources');
    }
  };

  const getVehiclesList = async (data) => {
    const urlencoded = new URLSearchParams();
    if (data) {
      urlencoded.append('Page', data.pagination.page);
      urlencoded.append('PageSize', data.pagination.pageSize);
      urlencoded.append('SearchTerm', data.pageOptions.searchTerm);
      if (data.pageOptions.officeId && data.pageOptions.officeId != 'all') {
        urlencoded.append('OfficeId', data.pageOptions.officeId);
      }
      urlencoded.append('Assigned', data.pageOptions.assignType);
    }

    const params = {
      endpoint: 'v2/api/Vehicles',
      configs: {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: urlencoded,
      },
    };

    loadingStore.startPartialLoading('getVehiclesList');
    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('getVehiclesList');
    }
  };

  const getVehiclesListImage = async (imagePath) => {
    const params = {
      endpoint: `v2/api/Vehicles/image/${imagePath}`,
      configs: {
        method: 'GET',
      },
    };

    try {
      const res = await mainStore.request(params);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getVehicleDetails = async (id) => {
    const params = {
      endpoint: `v2/api/Vehicles/${id}`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startGlobalLoading('getVehicleDetails');
    try {
      const res = await mainStore.request(params);
      res.basePrice = helpers.convertValueToCurrency(res.basePrice);
      res.insuranceDeductible = helpers.convertValueToCurrency(
        res.insuranceDeductible
      );
      res.monthlyInstallment = helpers.convertValueToCurrency(
        res.monthlyInstallment
      );
      res.updatedCarValue = helpers.convertValueToCurrency(res.updatedCarValue);
      res.extraPayment = helpers.convertValueToCurrency(res.extraPayment);
      res.bankGuarantee = helpers.convertValueToCurrency(res.bankGuarantee);
      res.contractDate = helpers.formatDateToMonthDate(res.contractDate);
      res.carReturnedDate = helpers.formatDateToMonthDate(res.carReturnedDate);

      vehicleDetails.value = res;
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endGlobalLoading('getVehicleDetails');
    }
  };

  const getVehicleActualAssignment = async (id) => {
    const params = {
      endpoint: `v2/api/Vehicles/${id}`,
      configs: {
        method: 'GET',
      },
    };
    try {
      const res = await mainStore.request(params);
      vehicleDetails.value.actualAssignment = res.actualAssignment;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getVehicleActualAssignmentDriver = async (id) => {
    const params = {
      endpoint: `v2/api/Vehicles/${id}`,
      configs: {
        method: 'GET',
      },
    };
    try {
      const res = await mainStore.request(params);
      return res.actualAssignment?.driver;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getHubList = async () => {
    const params = {
      endpoint: 'v2/api/fleet/resources/hubs',
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startGlobalLoading('getHubList');
    try {
      hubList.value = await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endGlobalLoading('getHubList');
    }
  };

  const createVehicle = async (data) => {
    const params = {
      endpoint: `v2/api/Vehicles`,
      configs: {
        method: 'POST',
        body: data,
      },
    };

    try {
      await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const editVehicle = async (data) => {
    const params = {
      endpoint: `v2/api/Vehicles`,
      configs: {
        method: 'PUT',
        body: data,
      },
    };

    try {
      await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const postImage = async (data) => {
    let formData = new FormData();
    formData.append('file', data);

    const params = {
      endpoint: `v2/api/vehicles/upload-image`,
      configs: {
        method: 'POST',
        body: formData,
        headers: {},
      },
    };

    loadingStore.startGlobalLoading('postImage');
    try {
      imageResponse.value = await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endGlobalLoading('postImage');
    }
  };

  const deleteVehicle = async (id) => {
    const params = {
      endpoint: `v2/api/Vehicles/${id}`,
      configs: {
        method: 'DELETE',
      },
    };

    loadingStore.startPartialLoading('deleteVehicle');
    try {
      await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('deleteVehicle');
    }
  };

  const getTicketsByVehicle = async (data) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append('vehicleId', data.vehicleId);

    if (data.pagination) {
      urlencoded.append('Page', data.pagination.page);
      urlencoded.append('PageSize', data.pagination.pageSize);
    } else {
      urlencoded.append('Page', 1);
      urlencoded.append('PageSize', 5);
    }

    const params = {
      endpoint: `v2/api/VehicleTickets`,
      configs: {
        method: 'GET',
        body: urlencoded,
      },
    };

    loadingStore.startPartialLoading('getTicketsByVehicle');
    try {
      const res = await mainStore.request(params);
      ticketList.value = res.items;
      return res;
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('getTicketsByVehicle');
    }
  };

  const createTicket = async (data) => {
    const params = {
      endpoint: `v2/api/VehicleTickets`,
      configs: {
        method: 'POST',
        body: data,
      },
    };

    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const editTicket = async (data) => {
    const params = {
      endpoint: `v2/api/VehicleTickets/${data.id}`,
      configs: {
        method: 'PUT',
        body: data,
      },
    };

    try {
      await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const deleteTicket = async (id) => {
    const params = {
      endpoint: `v2/api/VehicleTickets/${id}`,
      configs: {
        method: 'DELETE',
      },
    };

    loadingStore.startPartialLoading('deleteTicket');
    try {
      return await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endPartialLoading('deleteTicket');
    }
  };

  const resetAssignmentEmployeeInformation = () => {
    helpers.resetObjectToNull(assignmentEmployeeInformation.value);
  };

  const resetAssignmentCarCondition = () => {
    helpers.resetObjectToNull(assignmentCarCondition.value);
  };

  const getContractualPartnersList = async () => {
    const params = {
      endpoint: 'v2/api/fleet/resources/contractual-partners',
      configs: {
        method: 'GET',
      },
    };
    loadingStore.startGlobalLoading('getContractualPartnersList');
    try {
      contractualPartnersList.value = await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endGlobalLoading('getContractualPartnersList');
    }
  };

  const getUsageList = async () => {
    const params = {
      endpoint: 'v2/api/fleet/resources/usages',
      configs: {
        method: 'GET',
      },
    };
    loadingStore.startGlobalLoading('getUsageList');
    try {
      usageList.value = await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endGlobalLoading('getUsageList');
    }
  };

  const getOwnersList = async () => {
    const params = {
      endpoint: 'v2/api/fleet/resources/owners',
      configs: {
        method: 'GET',
      },
    };
    loadingStore.startGlobalLoading('getOwnersList');
    try {
      ownersList.value = await mainStore.request(params);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endGlobalLoading('getOwnersList');
    }
  };

  return {
    assignmentCarCondition,
    assignmentEmployeeInformation,
    contractualPartnersList,
    hubList,
    imageResponse,
    ownersList,
    stepperCurrentStep,
    ticketList,
    usageList,
    vehicleDetails,
    vehicleResources,
    vehiclesList,
    createTicket,
    createVehicle,
    deleteTicket,
    deleteVehicle,
    downloadCurrentContract,
    editDocument,
    editTicket,
    editVehicle,
    endAssignment,
    getContractualPartnersList,
    getEmployeeDetails,
    getEmployeesByQuery,
    getHubList,
    getOwnersList,
    getTicketsByVehicle,
    getUsageList,
    getVehicleActualAssignment,
    getVehicleActualAssignmentDriver,
    getVehicleDetails,
    getVehicleResources,
    getVehiclesList,
    getVehiclesListImage,
    postAssignmentInformation,
    postImage,
    resetAssignmentCarCondition,
    resetAssignmentEmployeeInformation,
    uploadContract,
    uploadDocument,
    uploadVehicleTicket,
  };
});
