<template>
  <v-dialog
    data-testid="candidates-handover-dialog"
    v-model="displayDialog"
    :retain-focus="false"
    persistent
    width="614"
  >
    <v-card class="pa-3">
      <v-card-title
        data-testid="candidates-handover-dialog-title"
        class="dialog-title mb-4"
      >
        {{ dialogTitle }}
      </v-card-title>
      <v-card-text class="section-detail mineBlack--text pb-0">
        <v-container>
          <v-row>
            <v-col class="pl-0">
              <RegularTextInput
                data-testid="candidates-handover-dialog-full-name-field"
                disabled
                :value="`${candidate.applicant.firstName} ${candidate.applicant.lastName}`"
                :label="'Candidate'"
              />
            </v-col>
            <v-col class="pr-0">
              <DropdownInput
                data-testid="candidates-handover-dialog-hub-dropdown"
                :label="t('Msg_.hub')"
                :items="peopleStore.peopleHubList"
                item-text="name"
                item-value="id"
                :value="candidate.applicant.mainHub"
                :clearable="false"
                disabled
              />
            </v-col>
          </v-row>
          <v-row class="mb-0">
            <v-col cols="6" class="pl-0 pt-0 pb-0">
              <DropdownInput
                data-testid="candidates-handover-dialog-process-responsible-field"
                :label="t('Msg_.processResponsible')"
                :items="candidate.processResponsibleList"
                :value="candidate.responsibleId"
                disabled
              />
            </v-col>
          </v-row>
          <v-row v-if="!peopleCandidatesStore.rejectingCandidate">
            <v-col
              data-testid="candidates-handover-dialog-warning-message"
              class="pl-0 pt-0 mb-6"
            >
              {{ t('Msg_.sendToHRWarningMessage') }}
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="d-flex justify-end pa-6">
        <v-btn
          data-testid="candidates-handover-dialog-cancel-btn"
          class="cancel-outlined-btn"
          @click="emit('close-dialog')"
        >
          {{ cancelBtnLabel }}
        </v-btn>
        <v-btn
          data-testid="candidates-handover-dialog-confirm-btn"
          class="main-action-btn ml-2"
          @click="handleBtnClick()"
        >
          {{ actionBtnLabel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';

import languages from '../../locales/languages';
import { usePeopleStore } from '@/store/pinia/people.store.js';
import { usePeopleCandidatesStore } from '@/store/pinia/people-candidates.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { defineEmits, computed } from 'vue';

const peopleStore = usePeopleStore();
const peopleCandidatesStore = usePeopleCandidatesStore();
const emit = defineEmits([
  'close-dialog',
  'send-candidate-to-hr',
  'candidate-end-process',
]);
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const displayDialog = true;

// --- Methods ---
const handleBtnClick = () => {
  if (peopleCandidatesStore.rejectingCandidate) {
    emit('candidate-end-process');
  } else {
    emit('send-candidate-to-hr');
  }
};

// --- Computed ---
const candidate = computed(() => {
  return peopleCandidatesStore.candidatesList.items[
    peopleCandidatesStore.candidateIndex
  ];
});

const dialogTitle = computed(() => {
  return peopleCandidatesStore.rejectingCandidate
    ? t('Title_.page.rejectingCandidate')
    : t('Title_.page.sendToHR');
});

const actionBtnLabel = computed(() => {
  return peopleCandidatesStore.rejectingCandidate
    ? t('Action_.buttons.rejectCandidature')
    : t('Action_.buttons.send');
});

const cancelBtnLabel = computed(() => {
  return peopleCandidatesStore.rejectingCandidate
    ? t('Action_.buttons.cancel')
    : t('Action_.buttons.notYet');
});
</script>

<style lang="scss" scoped></style>
