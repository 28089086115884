const wikiRoutes = [
  {
    path: '/component-wiki',
    name: 'WikiMenu',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/wiki-menu.vue'),
    redirect: { name: 'FormInputWiki' },
    children: [
      {
        path: 'form-inputs',
        name: 'FormInputWiki',
        component: () => import('@/views/wikiViews/form-input-wiki.vue'),
        meta: {
          requiresAuth: true,
          authorize: ['People.Employees.Read'],
        },
      },
      {
        path: 'input-rules',
        name: 'InputRulesWiki',
        component: () => import('@/views/wikiViews/input-rules-wiki.vue'),
        meta: {
          requiresAuth: true,
          authorize: ['People.Employees.Read'],
        },
      },
      {
        path: 'form-controls',
        name: 'FormControlWiki',
        component: () => import('@/views/wikiViews/form-control-wiki.vue'),
        meta: {
          requiresAuth: true,
          authorize: ['People.Employees.Read'],
        },
      },
      {
        path: 'rich-text-editor',
        name: 'RichTextEditorWiki',
        component: () => import('@/views/wikiViews/rich-text-editor-wiki.vue'),
        meta: {
          requiresAuth: true,
          authorize: ['People.Employees.Read'],
        },
      },
      {
        path: 'alerts-badges',
        name: 'AlertsBadgesWiki',
        component: () => import('@/views/wikiViews/alerts-badges-wiki.vue'),
        meta: {
          requiresAuth: true,
          authorize: ['People.Employees.Read'],
        },
      },
      {
        path: 'buttons',
        name: 'ButtonWiki',
        component: () => import('@/views/wikiViews/button-wiki.vue'),
        meta: {
          requiresAuth: true,
          authorize: ['People.Employees.Read'],
        },
      },
      {
        path: 'dialogs',
        name: 'DialogWiki',
        component: () => import('@/views/wikiViews/dialog-wiki.vue'),
        meta: {
          requiresAuth: true,
          authorize: ['People.Employees.Read'],
        },
      },
      {
        path: 'paginations',
        name: 'PaginationWiki',
        component: () => import('@/views/wikiViews/pagination-wiki.vue'),
        meta: {
          requiresAuth: true,
          authorize: ['People.Employees.Read'],
        },
      },
      {
        path: 'empty-states',
        name: 'EmptyStatesWiki',
        component: () => import('@/views/wikiViews/empty-states-wiki.vue'),
        meta: {
          requiresAuth: true,
          authorize: ['People.Employees.Read'],
        },
      },
      {
        path: 'error-view',
        name: 'ErrorViewWiki',
        component: () => import('@/views/wikiViews/error-view-wiki.vue'),
        meta: {
          requiresAuth: true,
          authorize: ['People.Employees.Read'],
        },
      },
      {
        path: 'loading-animation',
        name: 'LoadingAnimationWiki',
        component: () => import('@/views/wikiViews/loading-animation-wiki.vue'),
        meta: {
          requiresAuth: true,
          authorize: ['People.Employees.Read'],
        },
      },
      {
        path: 'cards',
        name: 'CardsWiki',
        component: () => import('@/views/wikiViews/cards-wiki.vue'),
        meta: {
          requiresAuth: true,
          authorize: ['People.Employees.Read'],
        },
      },
    ],
  },
];

export default wikiRoutes;
