<template>
  <div>
    <p class="custom-label mb-1">{{ label }}</p>
    <v-menu
      ref="menu"
      v-model="showTimePicker"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="time"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          :background-color="disabled ? 'ashRegular' : 'white'"
          outlined
          dense
          :value="value"
          :placeholder="placeholder"
          :disabled="disabled"
          readonly
          v-bind="attrs"
          v-on="on"
          :rules="rules"
        >
          <template slot="prepend-inner">
            <v-icon
              class="custom-icon"
              :color="disabled ? 'greyRegular' : 'blueRegular'"
              size="20"
              >{{ 'mdi-clock' }}
            </v-icon>
          </template>
        </v-text-field>
      </template>
      <v-time-picker
        v-if="showTimePicker"
        color="blueRegular"
        v-model="time"
        full-width
        :rules="rules"
        :disabled="disabled"
        :min="min"
        :max="max"
        :format="format"
        @click:minute="$refs.menu.save(time)"
        @change="emitTime($event)"
        @input="emitTime($event)"
      ></v-time-picker>
    </v-menu>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';

const emit = defineEmits(['input', 'change']);

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  label: {
    type: String,
    required: false,
    default: '',
  },
  value: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    required: false,
    default: '',
  },
  rules: {
    type: Array,
    required: false,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  min: {
    type: String,
    required: false,
    default: '',
  },
  max: {
    type: String,
    required: false,
    default: '',
  },
  format: {
    type: String,
    required: false,
    default: '24hr',
  },
});

// --- Local variables ---
const showTimePicker = ref(false);
const time = ref(null);

// --- Methods ---
const emitTime = (time) => {
  emit('input', time);
  emit('change', time);
};
</script>

<style lang="scss" scoped>
.custom-label {
  font-size: 13px;
}
.v-text-field--outlined ::v-deep fieldset {
  border-color: $ash-dark;
}

.v-text-field ::v-deep label {
  font-size: 13px;
}

.v-input--is-focused ::v-deep fieldset {
  border: 1px solid $blue-regular !important;
}
</style>
