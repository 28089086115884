import VueRouter from 'vue-router';

import { checkUserAuthStatus, checkUserAccessPermission } from './middleware';

import authenticationRoutes from './modules/authentication-routes';
import hubsRoutes from './modules/hubs-routes';
import profileRoutes from './modules/profile-routes';
import peopleRoutes from './modules/people-routes';
import fleetVehiclesRoutes from './modules/fleet-vehicles-routes';
import wikiRoutes from './modules/wiki-routes';
import vue27Routes from './modules/vue27';
import toursRoutes from './modules/tours-routes';
import logbookRoutes from './modules/logbook-routes';
import exportRoutes from './modules/export-routes';

const routes = [
  { path: '/', name: 'Root' },

  ...authenticationRoutes,
  ...hubsRoutes,
  ...profileRoutes,
  ...peopleRoutes,
  ...fleetVehiclesRoutes,
  ...wikiRoutes,
  ...vue27Routes,
  ...toursRoutes,
  ...logbookRoutes,
  ...exportRoutes,
  {
    path: '/oss',
    name: 'oss',
    component: () => import('../components/oss/oss-table.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/error',
    name: 'Error',
    component: () => import('../components/shared/misc/error-page.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '*',
    component: () => import('../components/shared/misc/error-page.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/sandbox',
    name: 'Sandbox',
    meta: {
      requiresAuth: true,
    },
    component: () => import('../views/Sandbox.vue'),
  },

  {
    path: '/redirect',
    name: 'Horsea',
    beforeEnter(to) {
      location.replace(to.query);
    },
  },

  {
    path: '/redirect-kingdra',
    name: 'BuroKingdra',
    beforeEnter(to) {
      location.replace(to.query);
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(checkUserAuthStatus);
router.beforeEach(checkUserAccessPermission);

export default router;
