<template>
  <v-navigation-drawer
    right
    app
    temporary
    v-model="mainStore.showUserDrawerDetails"
    width="360"
    class="px-3 py-5"
  >
    <v-container>
      <v-row>
        <v-col class="d-flex" cols="12">
          <h3 class="card-title">User Profile</h3>
          <v-spacer></v-spacer>
          <v-icon @click="closeDrawer()">mdi-close</v-icon>
        </v-col>
      </v-row>
      <v-row class="px-2">
        <v-card elevation="0">
          <v-list>
            <v-list-item class="px-0">
              <v-list-item-avatar size="85">
                <v-img
                  class="ml-2"
                  src="@/assets/authentication/auth_avatar.svg"
                  alt="arrow-left"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="mb-2 section-title">
                  <a href="#" class="text-decoration-none mineBlack--text">
                    {{ userStore.userInfo.preferred_username }}
                  </a>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <a href="#" class="text-decoration-none greyRegular--text">
                    <v-icon size="18" color="blueRegular" class="mr-1">
                      mdi-email
                    </v-icon>
                    {{ userStore.userInfo.email }}
                  </a>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider />
          <div class="pr-8">
            <v-btn
              color="white"
              depressed
              height="4rem"
              width="85%"
              class="pa-5 mt-5"
            >
              <v-list-item>
                <v-list-item-avatar size="45">
                  <v-icon color="redDanger" class="mr-5">
                    mdi-account-box-multiple
                  </v-icon>
                </v-list-item-avatar>
                <div class="d-flex flex-column">
                  <v-list-item-subtitle class="align-self-start">
                    My Account
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="section-detail align-self-start">
                    Profile Info
                  </v-list-item-subtitle>
                </div>
              </v-list-item>
            </v-btn>
            <v-btn
              color="white"
              depressed
              height="4rem"
              width="85%"
              class="pa-5"
            >
              <v-list-item>
                <v-list-item-avatar size="45">
                  <v-icon color="caribbeanDark" class="mr-5">
                    mdi-tune-variant
                  </v-icon>
                </v-list-item-avatar>
                <div class="d-flex flex-column">
                  <v-list-item-subtitle class="align-self-start">
                    Today
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="section-detail align-self-start">
                    Today's tasks
                  </v-list-item-subtitle>
                </div>
              </v-list-item>
            </v-btn>
            <div class="d-flex justify-end">
              <v-btn
                @click="logoutUser()"
                depressed
                color="white"
                class="mb-5 mt-5 redDanger--text"
              >
                Sign Out
              </v-btn>
            </div>
          </div>
          <v-divider />
        </v-card>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script setup>
import { useMainStore } from '@/store/pinia/main.store.js';
import { useUserStore } from '@/store/pinia/user.store.js';
import { useRouter } from 'vue-router/composables';

const mainStore = useMainStore();
const userStore = useUserStore();
const router = useRouter();

// --- Methods ---
const closeDrawer = () => {
  mainStore.showUserDrawerDetails = false;
};

const logoutUser = async () => {
  router.push({
    name: 'Horsea',
    query: `${process.env.VUE_APP_LOGIN_API}/v2/api/Account/Logout`,
  });
};
</script>
