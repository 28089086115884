<template>
  <div data-testid="search-input">
    <p class="custom-label mb-1">{{ label }}</p>
    <v-text-field
      flat
      dense
      outlined
      clearable
      @click:clear="clearInput()"
      @keydown.enter.prevent="emitSearch($event.target.value)"
      :background-color="disabled ? 'ashRegular' : 'white'"
      color="blueRegular"
      :placeholder="placeholder"
      :value="value"
      :disabled="disabled"
      @input="emitSearchOnTyping($event)"
    >
      <v-icon slot="prepend-inner" class="custom-icon" size="20">
        mdi-magnify
      </v-icon>
    </v-text-field>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const emit = defineEmits(['input', 'update-list']);

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    required: true,
  },
  value: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  searchOnTyping: {
    type: Boolean,
    required: false,
    default: false,
  },
});

// --- Methods ---
const emitSearch = (input) => {
  emit('input', input);
  emit('update-list');
};

const clearInput = () => {
  emit('input', '');
  emit('update-list');
};

const emitSearchOnTyping = (input) => {
  if (props.searchOnTyping && input !== null) {
    emit('input', input);
  }
};
</script>

<style scoped lang="scss">
.custom-label {
  font-size: 13px;
}

.custom-icon {
  margin: 2px 4px 0 0;
}

.v-input--is-focused ::v-deep fieldset {
  border: 1px solid $blue-regular !important;
}

.v-text-field--outlined ::v-deep fieldset {
  border-color: $ash-dark;
}
</style>
