<template>
  <v-card data-testid="candidates-menu" class="shadow-regular rounded-lg">
    <v-container class="pa-8">
      <v-row>
        <v-col data-testid="candidates-menu-title" class="ml-3">
          {{ t('Msg_.candidateSteps') }}
        </v-col>
      </v-row>

      <v-row v-for="(candidates, key) in candidatesMenu" :key="key">
        <v-col>
          <v-hover v-slot="{ hover }">
            <v-card
              :data-testid="`candidates-menu-${key}`"
              flat
              :class="hover ? 'menu-item' : ''"
              class="rounded-lg pb-3"
              @click="selectMenuItem(key)"
            >
              <div
                v-if="peopleCandidatesStore.selectedMenuItem === key"
                class="pa-0 ma-0 border-selected"
              ></div>
              <v-container>
                <v-row
                  :class="isMenuSelected(key)"
                  class="section-title d-flex align-center"
                >
                  <v-col class="pb-0">
                    <span :data-testid="`candidates-menu-${key}-title`">
                      {{ t(`Msg_.${key}`) }}
                    </span>
                  </v-col>
                  <v-col cols="auto" class="pb-0">
                    <v-menu :value="candidateKeyShowDetails === key" offset-x>
                      <template #activator="{ on }">
                        <div
                          v-on="on"
                          @mouseover="showCandidateDetails(key)"
                          @mouseleave="hideCandidateDetails()"
                        >
                          <v-icon small :class="isMenuSelected(key)">
                            mdi-account
                          </v-icon>
                          <span
                            class="pl-2"
                            :data-testid="`candidates-menu-${key}-total-candidates`"
                          >
                            {{ `${sumTotalCandidates(candidates)}` }}
                          </span>
                        </div>
                      </template>
                      <v-card class="pr-2">
                        <CandidatesStepDetails
                          :data-testid="`candidates-menu-${key}-candidates-details-popup`"
                          :details="candidates"
                          :option-key="key"
                        />
                      </v-card>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
              <v-container v-if="noHandoverMenu(candidates)" class="mt-2">
                <v-row v-for="detail in candidates" :key="detail.Value">
                  <v-col
                    :data-testid="`candidates-menu-${key}-candidates-details`"
                    class="items pl-6 pa-0"
                  >
                    {{ getMenuLabelDetails(key, detail.Value, detail.total) }}
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script setup>
import CandidatesStepDetails from '@/components/people/candidates/candidates-step-details.vue';

import languages from '@/components/people/locales/languages';
import { usePeopleStore } from '@/store/pinia/people.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { usePeopleCandidatesStore } from '@/store/pinia/people-candidates.store.js';
import { computed, ref, defineEmits } from 'vue';

const peopleStore = usePeopleStore();
const peopleCandidatesStore = usePeopleCandidatesStore();
const candidateKeyShowDetails = ref(null);
const emit = defineEmits(['update-list']);
const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Methods ---
const getMenuLabelDetails = (key, detail, total) => {
  return `${tm('Action_.selectCandidateSteps')[key][detail]} (${total})`;
};

const noHandoverMenu = (candidates) => {
  return candidates.length > 1;
};

const showCandidateDetails = (candidateIndex) => {
  candidateKeyShowDetails.value = candidateIndex;
};

const hideCandidateDetails = () => {
  candidateKeyShowDetails.value = null;
};

const sumTotalCandidates = (candidates) => {
  return candidates.reduce((acc, candidate) => acc + candidate.total, 0);
};

const selectMenuItem = (menu) => {
  if (peopleCandidatesStore.selectedMenuItem !== menu) {
    peopleCandidatesStore.selectedMenuItem = menu;
    emit('update-list');
  }
  peopleCandidatesStore.showProfileIndex = null; // close any expanded profile
};

const isMenuSelected = (key) => {
  return peopleCandidatesStore.selectedMenuItem === key ? 'item-selected' : '';
};

const candidatesMenu = computed(() => {
  const candidatesMenu = {};

  for (const key in peopleStore.peopleResources?.candidateSteps) {
    candidatesMenu[key] = crossResourceAndSummaryDataByKey(key);
  }

  return candidatesMenu;
});

const crossResourceAndSummaryDataByKey = (key) => {
  return peopleStore.peopleResources.candidateSteps[key].map((candidate) => {
    const candidateData = findCandidateDataInSummary(candidate);
    const { applications = [], total = 0 } = candidateData;
    return { ...candidate, applications, total };
  });
};

const findCandidateDataInSummary = (candidate) => {
  const candidateSummary =
    peopleCandidatesStore.summary.find(
      (summaryItem) => summaryItem.status === candidate.Value
    ) || {};
  return candidateSummary;
};
</script>

<style lang="scss" scoped>
.items {
  font-style: italic;
  font-size: 0.8rem;
  font-weight: 500;
  color: $grey-regular;
}

.menu-item {
  cursor: pointer;
  background-color: $ash-light;
}

.border-selected {
  position: absolute;
  background-color: $blue-regular;
  width: 4px;
  height: 100%;
  border-radius: 10px 0px 0px 10px !important;
}

.item-selected {
  color: $blue-regular !important;
}
</style>
