<template>
  <v-container>
    <v-row
      v-if="peopleRecruitmentContractStore.recruitData.processStatusHistory"
    >
      <v-col>
        <v-data-table
          data-testid="recruitment-log-table"
          :headers="headers"
          :items="processLogData"
          :items-per-page="itemsPerPage"
          class="mt-2"
          hide-default-footer
        ></v-data-table>
      </v-col>
    </v-row>
    <v-row v-else class="mt-2">
      <v-col
        class="d-flex justify-center additional-info"
        data-testid="recruitment-hiring-process-dont-need-test-day-message"
      >
        {{ t('Msg_.noDataAvailable') }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import languages from '@/components/people/locales/languages';
import helpers from '@/helpers/helpers';
import { useI18n } from 'vue-i18n-bridge';
import { ref, computed } from 'vue';
import { usePeopleRecruitmentContractStore } from '@/store/pinia/people-recruitment-contract.store.js';

const peopleRecruitmentContractStore = usePeopleRecruitmentContractStore();
const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const itemsPerPage = ref(5);

// --- Computed ---
const headers = computed(() => {
  return [
    {
      text: t('Msg_.applicationStatus'),
      class: 'uppercase-header',
      value: 'applicationStatus',
    },
    {
      text: t('Msg_.startedAt'),
      class: 'uppercase-header',
      value: 'startedAt',
    },
  ];
});

const processLogData = computed(() => {
  return peopleRecruitmentContractStore.recruitData.processStatusHistory?.map(
    (log) => ({
      applicationStatus: tm('Action_.selectApplicationStatusTypes')[
        log.processStatus
      ],
      startedAt: helpers.formatDate(log.startedAt),
    })
  );
});
</script>

<style lang="scss" scoped>
.additional-info {
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.3px;
  color: $grey-dark;
}
</style>
