<template>
  <div class="fill-height">
    <router-view />
  </div>
</template>

<script setup>
import languages from './locales/languages';
import { useI18n } from 'vue-i18n-bridge';
import { useMainStore } from '@/store/pinia/main.store.js';
import { usePeopleStore } from '@/store/pinia/people.store.js';

const mainStore = useMainStore();
const peopleStore = usePeopleStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Methods ---
const getPeopleHubList = async () => {
  try {
    await peopleStore.getPeopleHubList();
  } catch (error) {
    console.log(error);
  }
};

const getPeopleResources = async () => {
  try {
    await peopleStore.getPeopleResources();
  } catch (error) {
    console.log(error);
  }
};

const setPeopleHttpErrorMessage = () => {
  mainStore.errorPageMessage = t('Msg_.peopleHttpError');
};

// --- Lifecycle hooks ---
getPeopleHubList();
getPeopleResources();
setPeopleHttpErrorMessage();
</script>
