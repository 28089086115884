const resetStore = ({ store }) => {
  // Create a deep copy of the default state
  const initialDefaultState = JSON.parse(JSON.stringify(store.$state));

  // Reset entire store's state to it's initial values
  store.$reset = () => {
    store.$patch(initialDefaultState);
  };

  /**
   * Receives an array with the object's keys to be reset to their initial values
   * @param array - An array of strings containing the objects's keys
   */
  store.$bulkReset = (array) => {
    array.forEach((key) => {
      store[key] = { ...initialDefaultState[key] };
    });
  };

  /**
   * Receive a single key corresponding to the object to be reset to it's initial state
   * @param key - A single string equal to the object's key
   */
  store.$partialReset = (key) => {
    store[key] = { ...initialDefaultState[key] };
  };
};

export default resetStore;
