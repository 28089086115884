var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ma-3"},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"section-title",attrs:{"data-testid":"applicants-action-required-title"}},[_vm._v(" "+_vm._s(_setup.t('Msg_.actionRequired'))+" ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c(_setup.DropdownInput,{attrs:{"data-testid":"applicants-action-required-hub-dropdown","label":_setup.t('Msg_.hub'),"items":_setup.peopleStore.peopleHubList,"item-text":"name","item-value":"id","clearable":false},on:{"change":function($event){return _setup.handleResponsibleList()}},model:{value:(
            _setup.peopleApplicantsStore.applicantsList.items[_setup.props.index].applicant
              .mainHub
          ),callback:function ($$v) {_vm.$set(_setup.peopleApplicantsStore.applicantsList.items[_setup.props.index].applicant
              , "mainHub", $$v)},expression:"\n            peopleApplicantsStore.applicantsList.items[props.index].applicant\n              .mainHub\n          "}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('p',{staticClass:"custom-label mb-1"},[_vm._v(_vm._s(_setup.t('Msg_.processResponsible')))]),_c('v-select',{attrs:{"data-testid":"applicants-action-required-process-responsible-dropdown","dense":"","outlined":"","items":_setup.processResponsibleList,"clearable":false,"loading":_setup.loading,"disabled":_setup.loading,"menu-props":{
            value: _setup.openMenu,
            closeOnClick: true,
            closeOnContentClick: true,
            openOnClick: true,
          }},on:{"click":function($event){return _setup.getResponsibleList()}},model:{value:(
            _setup.peopleApplicantsStore.applicantsList.items[_setup.props.index]
              .responsibleId
          ),callback:function ($$v) {_vm.$set(_setup.peopleApplicantsStore.applicantsList.items[_setup.props.index]
              , "responsibleId", $$v)},expression:"\n            peopleApplicantsStore.applicantsList.items[props.index]\n              .responsibleId\n          "}})],1),_c('v-col',{staticClass:"d-flex align-self-center mb-1",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"main-action-btn ml-2",attrs:{"data-testid":"applicants-action-required-add-test-day-btn","disabled":_setup.isAddTestDayDisabled},on:{"click":function($event){return _setup.addTestDay()}}},[_vm._v(" "+_vm._s(_setup.t('Msg_.addTestDay'))+" ")])],1),_c('v-col',{staticClass:"d-flex align-self-center mt-1",attrs:{"cols":"auto"}},[_c(_setup.CheckboxControl,{attrs:{"data-testid":"applicants-action-required-does-not-need-test-day-checkbox","label":_setup.t('Msg_.dontNeedTestDay')},on:{"change":function($event){return _setup.resetTestDayData()}},model:{value:(_setup.doesNotNeedTestDay),callback:function ($$v) {_setup.doesNotNeedTestDay=$$v},expression:"doesNotNeedTestDay"}})],1)],1)],1),_vm._l((_setup.peopleApplicantsStore.applicantsList
      .items[_setup.props.index].testDays),function(testDay,testDayIndex){return _c('v-container',{key:testDayIndex},[_c('v-row',[_c('v-col',{staticClass:"section-title d-flex align-self-center",attrs:{"cols":"2","data-testid":`applicants-action-required-test-day-${testDayIndex}-title`}},[_vm._v(" "+_vm._s(`${_setup.t('Msg_.testDay')} # ${testDayIndex + 1}`)+" ")]),_c('v-col',{staticClass:"d-flex align-self-center"},[_c(_setup.ButtonWithIcon,{staticClass:"default-btn",attrs:{"data-testid":`applicants-action-required-test-day-${testDayIndex}-delete-btn`,"icon":"mdi-delete","text":_setup.t('Action_.buttons.deleteTestDay'),"color":"redDanger","only-text":true},on:{"click":function($event){return _setup.deleteTestDay(testDayIndex)}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c(_setup.DropdownInput,{attrs:{"data-testid":`applicants-action-required-test-day-${testDayIndex}-process-responsible-dropdown`,"label":_setup.t('Msg_.testDayReviewer'),"items":_setup.processResponsibleList,"clearable":false},model:{value:(
            _setup.peopleApplicantsStore.applicantsList.items[_setup.props.index].testDays[
              testDayIndex
            ].reviewerId
          ),callback:function ($$v) {_vm.$set(_setup.peopleApplicantsStore.applicantsList.items[_setup.props.index].testDays[
              testDayIndex
            ], "reviewerId", $$v)},expression:"\n            peopleApplicantsStore.applicantsList.items[props.index].testDays[\n              testDayIndex\n            ].reviewerId\n          "}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.DatePicker,{attrs:{"data-testid":`applicants-action-required-test-day-${testDayIndex}-test-day-field`,"label":_setup.t('Msg_.testDay'),"allowed-dates":_setup.allowedDates},model:{value:(
            _setup.peopleApplicantsStore.applicantsList.items[_setup.props.index].testDays[
              testDayIndex
            ].testDayDate
          ),callback:function ($$v) {_vm.$set(_setup.peopleApplicantsStore.applicantsList.items[_setup.props.index].testDays[
              testDayIndex
            ], "testDayDate", $$v)},expression:"\n            peopleApplicantsStore.applicantsList.items[props.index].testDays[\n              testDayIndex\n            ].testDayDate\n          "}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.TimePicker,{attrs:{"data-testid":`applicants-action-required-test-day-${testDayIndex}-start-time-field`,"label":_setup.t('Msg_.testDayStartTime')},model:{value:(
            _setup.peopleApplicantsStore.applicantsList.items[_setup.props.index].testDays[
              testDayIndex
            ].testDayStartsAt
          ),callback:function ($$v) {_vm.$set(_setup.peopleApplicantsStore.applicantsList.items[_setup.props.index].testDays[
              testDayIndex
            ], "testDayStartsAt", $$v)},expression:"\n            peopleApplicantsStore.applicantsList.items[props.index].testDays[\n              testDayIndex\n            ].testDayStartsAt\n          "}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c(_setup.TimePicker,{attrs:{"data-testid":`applicants-action-required-test-day-${testDayIndex}-end-time-field`,"label":_setup.t('Msg_.testDayEndTime')},model:{value:(
            _setup.peopleApplicantsStore.applicantsList.items[_setup.props.index].testDays[
              testDayIndex
            ].testDayEndsAt
          ),callback:function ($$v) {_vm.$set(_setup.peopleApplicantsStore.applicantsList.items[_setup.props.index].testDays[
              testDayIndex
            ], "testDayEndsAt", $$v)},expression:"\n            peopleApplicantsStore.applicantsList.items[props.index].testDays[\n              testDayIndex\n            ].testDayEndsAt\n          "}})],1)],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }