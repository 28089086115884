<template>
  <v-container>
    <v-row>
      <v-col
        data-testid="recruit-dialog-test-day-title"
        class="section-title pb-0"
      >
        {{ `${t('Title_.subTitle.testDay')} # ${index + 1}` }}
      </v-col>
    </v-row>
    <v-row class="mb-2">
      <v-col cols="6">
        <DropdownInput
          :data-testid="`recruit-dialog-test-day-${index}-process-responsible-dropdown`"
          :label="t('Msg_.reviewer')"
          :items="processResponsibleList"
          :value="testDay.reviewerId"
          disabled
        />
      </v-col>
      <v-col cols="6">
        <RegularTextInput
          :data-testid="`recruit-dialog-test-day-${index}-test-day-result-field`"
          disabled
          :value="testDayResult"
          :label="t('Msg_.result')"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';

import languages from '../../locales/languages';
import helpers from '@/helpers/helpers';
import { usePeopleCandidatesStore } from '@/store/pinia/people-candidates.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { defineProps, computed } from 'vue';

const peopleCandidatesStore = usePeopleCandidatesStore();
const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  testDay: {
    type: Object,
    default: () => {},
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
});

// --- Computed ---
const processResponsibleList = computed(() => {
  return peopleCandidatesStore.candidatesList.items[
    peopleCandidatesStore.candidateIndex
  ].processResponsibleList;
});

const testDayResult = computed(() => {
  return helpers.getTranslationById(
    tm('Action_.selectApplicationStatusTypes'),
    props.testDay.testDayResult
  );
});
</script>

<style></style>
