const authenticationRoutes = [
  {
    path: '/auth',
    name: 'Authentication',
    component: () =>
      import('@/components/authentication/authentication-menu.vue'),
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: 'phone-number',
        name: 'AuthenticationPhoneNumber',
        component: () =>
          import(
            '@/components/authentication/phoneNumber/authentication-phone-number.vue'
          ),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'qr-code',
        name: 'AuthenticationQrCode',
        component: () =>
          import(
            '@/components/authentication/qrCode/authentication-qr-code.vue'
          ),
        meta: {
          requiresAuth: false,
        },
      },
    ],
  },
];

export default authenticationRoutes;
