<template>
  <v-container>
    <v-row
      class="ma-2"
      v-if="peopleRecruitmentContractStore.recruitData.referenceList?.length"
    >
      <v-col
        cols="6"
        class="pb-0"
        v-for="(reference, index) in peopleRecruitmentContractStore.recruitData
          .referenceList"
        :key="index"
      >
        <v-container>
          <v-row align="center" dense class="section-detail">
            <v-col
              cols="auto"
              class="section-title"
              data-testid="recruitment-references-full-name"
            >
              {{ reference.fullName }}
            </v-col>
            <v-col
              cols="auto"
              class="section-details reference-qualification"
              data-testid="recruitment-references-qualification"
            >
              {{ reference.qualification }}
            </v-col>
          </v-row>

          <v-row align="start" class="mt-0">
            <v-col cols="auto" class="d-flex align-center">
              <v-icon class="pr-2" color="greyRegular" small>
                mdi-phone mdi-rotate-270
              </v-icon>
              <p
                class="mb-0 section-detail"
                data-testid="recruitment-references-phone-number"
              >
                {{ reference.phoneNumber }}
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row v-else class="mt-2">
      <v-col class="d-flex justify-center additional-info">
        {{ t('Msg_.noInformationYet') }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import languages from '@/components/people/locales/languages';
import { usePeopleRecruitmentContractStore } from '@/store/pinia/people-recruitment-contract.store.js';
import { useI18n } from 'vue-i18n-bridge';

const peopleRecruitmentContractStore = usePeopleRecruitmentContractStore();

const { t } = useI18n({ locale: 'en', messages: languages });
</script>

<style lang="scss" scoped>
.additional-info {
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.3px;
  color: $grey-dark;
}
</style>
