<template>
  <v-container class="pb-6">
    <v-row
      v-for="itemDetails in details"
      class="candidates-details"
      :key="itemDetails.status"
    >
      <v-col
        class="item-title greyRegular--text"
        :data-testid="`candidates-menu-details-${optionKey}-title-info`"
      >
        {{
          `${
            tm('Action_.selectCandidateSteps')[optionKey][itemDetails.Value]
          } (${itemDetails.total})`
        }}
      </v-col>
      <v-container v-if="itemDetails.applications.length">
        <v-row
          class="d-flex align-center"
          :key="candidate.id"
          v-for="candidate in itemDetails.applications"
        >
          <v-col class="pa-0 ml-8 mineBlack--text">
            <span
              :data-testid="`candidates-menu-details-${optionKey}-name-info`"
            >
              {{ candidate.name }}
            </span>
            <v-icon
              :data-testid="`candidates-menu-details-${optionKey}-warning-info`"
              v-if="
                candidate.isToGenerateContract ||
                candidate.isToUploadRequiredDocuments
              "
              color="redDanger"
              size="16"
              class="mr-5"
            >
              mdi-alert-outline
            </v-icon>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else>
        <v-row>
          <v-col
            class="pa-0 ml-8 mineBlack--text"
            :data-testid="`candidates-menu-details-${optionKey}-empty`"
          >
            -
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script setup>
import languages from './../locales/languages';
import { useI18n } from 'vue-i18n-bridge';
import { defineProps } from 'vue';

const { tm } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  details: {
    type: Array,
    default: () => {},
  },
  optionKey: {
    type: String,
    default: '',
  },
});
</script>

<style lang="scss" scoped>
.item-title {
  font-size: 0.8rem;
  font-weight: 500;
}

.candidates-details {
  max-width: 400px;
}
</style>
