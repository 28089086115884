import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useMainStore } from '@/store/pinia/main.store.js';
import { useLoadingStore } from '@/store/pinia/loading.store.js';

export const usePeopleContractCreationStore = defineStore(
  'peopleContractCreation',
  () => {
    const mainStore = useMainStore();
    const loadingStore = useLoadingStore();

    // --- State ---
    const ignoreFromCreateEmployee = ref(false);
    const isHeadquarterEmployee = ref(true);
    const displayTurnIntoEmployeeDialog = ref(false);
    const displayTurnIntoPendingEmployeeDialog = ref(false);
    const currentScreen = ref('typeSelection');
    const recruitments = ref(null);
    const careOpsContract = ref({
      // Master info
      firstName: null,
      lastName: null,
      gender: null,
      birthName: '',
      dateOfBirth: null,
      nationality: null,
      street: null,
      houseNumber: null,
      zipCode: null,
      city: null,
      country: null,
      personalPhoneNumber: null,
      personalEmail: null,
      emergencyContact: '',
      emergencyContactPhone: '',
      lbnr: '',
      // Payroll info
      taxId: null,
      taxClass: null,
      numberOfChildren: null,
      degreeOfDisability: null,
      otherJobCompensationType: null,
      costCenter1: '',
      costCenter1Percentage: '',
      costCenter2: '',
      costCenter2Percentage: '',
      costCenter3: '',
      costCenter3Percentage: '',
      confession: null,
      healthInsuranceType: null,
      healthInsuranceName: null,
      socialSecurityNumber: null,
      accountOwner: null,
      paymentMethod: null,
      ibanNumber: null,
      // Contract info
      startDate: null,
      origin: null,
      startDatePriorToAcquisition: '',
      jobPosition: null,
      jobSubtitle: '',
      additionalTraining: null,
      highestSchoolDegree: null,
      yearsOfExperience: null,
      mainHub: null,
      billingHub: null,
      company: null,
      contractType: null,
      compensationType: null,
      totalWeeklyWorkingHours: null,
      vacationDays: null,
      weeklyWorkingHours: [
        { day: 'monday', hours: 0, isActive: false },
        { day: 'tuesday', hours: 0, isActive: false },
        { day: 'wednesday', hours: 0, isActive: false },
        { day: 'thursday', hours: 0, isActive: false },
        { day: 'friday', hours: 0, isActive: false },
        { day: 'saturday', hours: 0, isActive: false },
        { day: 'sunday', hours: 0, isActive: false },
      ],
      contractPeriod: null,
      exitDate: null,
      probation: null,
      noticePeriodDuringProbation: null,
      noticePeriodAfterProbation: null,
      jobLevel: null,
      experienceLevel: null,
      hourlyWage: null,
      cityBonus: null,
      careAllowance: null,
      nonDynamicAllowance: null,
      individualAllowance: null,
      monthlyBaseSalary: null,
      thirteenthSalary: null,
      annualSalary: null,
      signingDate: '',
      assigner: null,
      // Documents
      uploadedContract: null,
      generatedContractId: null,
      totalWeeklyWorkingDays: null,
    });
    const headquarterContract = ref({
      // Master info
      firstName: null,
      lastName: null,
      gender: null,
      birthName: null,
      dateOfBirth: null,
      nationality: null,
      street: null,
      houseNumber: null,
      zipCode: null,
      city: null,
      country: null,
      personalPhoneNumber: null,
      personalEmail: null,
      emergencyContact: '',
      emergencyContactPhone: '',
      // Payroll info
      taxId: null,
      taxClass: null,
      numberOfChildren: null,
      degreeOfDisability: null,
      otherJobCompensationType: null,
      costCenter1: '',
      costCenter1Percentage: '',
      costCenter2: '',
      costCenter2Percentage: '',
      costCenter3: '',
      costCenter3Percentage: '',
      confession: null,
      healthInsuranceType: null,
      healthInsuranceName: null,
      socialSecurityNumber: null,
      accountOwner: null,
      paymentMethod: null,
      ibanNumber: null,
      // Contract info
      startDate: null,
      jobPosition: 8, // must be administrative worker
      jobSubtitle: '',
      mainHub: null,
      company: null,
      workplace: null,
      contractType: null,
      compensationType: null,
      totalWeeklyWorkingHours: null,
      vacationDays: null,
      weeklyWorkingHours: [
        { day: 'monday', hours: 0, isActive: false },
        { day: 'tuesday', hours: 0, isActive: false },
        { day: 'wednesday', hours: 0, isActive: false },
        { day: 'thursday', hours: 0, isActive: false },
        { day: 'friday', hours: 0, isActive: false },
        { day: 'saturday', hours: 0, isActive: false },
        { day: 'sunday', hours: 0, isActive: false },
      ],
      contractPeriod: null,
      exitDate: null,
      probation: null,
      noticePeriodDuringProbation: null,
      noticePeriodAfterProbation: null,
      monthlyBaseSalary: null,
      annualSalary: null,
      bonusTarget: null,
      totalWeeklyWorkingDays: null,
      // Documents
      uploadedContract: null,
    });
    const formValidations = ref({
      masterInfoValidation: false,
      payrollInfoValidation: false,
      contractInfoValidation: false,
    });
    const maxFileSize = 1.6e7;

    // --- Methods ---
    const getEmptyWeeklyWorkingHours = () => {
      return [
        { day: 'monday', hours: 0, isActive: false },
        { day: 'tuesday', hours: 0, isActive: false },
        { day: 'wednesday', hours: 0, isActive: false },
        { day: 'thursday', hours: 0, isActive: false },
        { day: 'friday', hours: 0, isActive: false },
        { day: 'saturday', hours: 0, isActive: false },
        { day: 'sunday', hours: 0, isActive: false },
      ];
    };

    const getRecruitments = async (data) => {
      const urlencoded = new URLSearchParams();
      if (data) {
        urlencoded.append('firstName', data.firstName);
        urlencoded.append('lastName', data.lastName);
      }

      const params = {
        endpoint: `v2/api/precontract/search`,
        configs: {
          method: 'GET',
          body: urlencoded,
          headers: {},
        },
      };

      loadingStore.startGlobalLoading('getRecruitments');
      try {
        const res = await mainStore.request(params);
        if (res.noventiEmployees) {
          res.noventiEmployees.forEach((employee) => {
            employee.showDetails = false;
          });
        }
        recruitments.value = res;
      } catch (error) {
        console.log(error);
      } finally {
        loadingStore.endGlobalLoading('getRecruitments');
      }
    };

    const getPhoneNumberValidation = async (phoneNumber) => {
      const params = {
        endpoint: `v2/api/employee/validate/phone-number/${phoneNumber}`,
        configs: {
          method: 'GET',
        },
      };

      try {
        return await mainStore.request(params);
      } catch (error) {
        console.log(error);
      }
    };

    const getEmailValidation = async (email) => {
      const params = {
        endpoint: `v2/api/employee/validate/email/${email}`,
        configs: {
          method: 'GET',
        },
      };

      try {
        return await mainStore.request(params);
      } catch (error) {
        console.log(error);
      }
    };

    const generateNewContract = async (payload) => {
      const params = {
        endpoint: `v2/api/precontract/preview`,
        configs: {
          method: 'POST',
          body: payload,
          headers: {
            'Content-Type': 'application/json',
          },
        },
      };

      try {
        return await mainStore.request(params);
      } catch (error) {
        return Promise.reject(error);
      }
    };

    const getDocumentById = async (contractId) => {
      const params = {
        endpoint: `v2/api/recruitments/documents/preview/${contractId}`,
        configs: {
          method: 'GET',
        },
      };

      try {
        return await mainStore.request(params);
      } catch (error) {
        console.log(error);
      }
    };

    const postDocument = async (payload) => {
      let formData = new FormData();
      formData.append('file', payload.value);

      const params = {
        endpoint: `v2/api/recruitments/documents`,
        configs: {
          method: 'POST',
          body: formData,
          headers: {},
        },
      };
      try {
        if (payload.value.size < maxFileSize) {
          const response = await mainStore.request(params);
          payload.contract[payload.field] = {
            displayName: payload.value.name,
            fileUrl: response,
          };
        }
      } catch (error) {
        return Promise.reject(error);
      }
    };

    const deleteDocument = async (payload) => {
      const params = {
        endpoint: `v2/api/recruitments/documents?path=${
          payload.contract[payload.field].fileUrl
        }`,
        configs: {
          method: 'DELETE',
        },
      };

      try {
        await mainStore.request(params);
        payload.contract[payload.field] = null;
      } catch (error) {
        return Promise.reject(error);
      }
    };

    const postContractCreationData = async (payload) => {
      const params = {
        endpoint: `v2/api/precontract`,
        configs: {
          method: 'POST',
          body: payload,
          headers: {
            'Content-Type': 'application/json',
          },
        },
      };

      loadingStore.startGlobalLoading('postContractCreationData');
      try {
        return await mainStore.request(params);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        loadingStore.endGlobalLoading('postContractCreationData');
      }
    };

    const updateContractCreationData = async (payload) => {
      const params = {
        endpoint: `v2/api/precontract/${payload.id}`,
        configs: {
          method: 'PUT',
          body: payload,
          headers: {
            'Content-Type': 'application/json',
          },
        },
      };

      try {
        return await mainStore.request(params);
      } catch (error) {
        return Promise.reject(error);
      }
    };

    const getContractDataById = async (employeeId) => {
      const params = {
        endpoint: `v2/api/precontract/${employeeId}`,
        configs: {
          method: 'GET',
        },
      };

      loadingStore.startGlobalLoading('getContractDataById');
      try {
        return await mainStore.request(params);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        loadingStore.endGlobalLoading('getContractDataById');
      }
    };

    const getCompanies = async () => {
      const params = {
        endpoint: `v2/api/companies`,
        configs: {
          method: 'GET',
        },
      };

      loadingStore.startGlobalLoading('getCompanies');
      try {
        return await mainStore.request(params);
      } catch (error) {
        console.log(error);
      } finally {
        loadingStore.endGlobalLoading('getCompanies');
      }
    };

    return {
      ignoreFromCreateEmployee,
      isHeadquarterEmployee,
      displayTurnIntoEmployeeDialog,
      displayTurnIntoPendingEmployeeDialog,
      currentScreen,
      recruitments,
      careOpsContract,
      headquarterContract,
      formValidations,
      getRecruitments,
      getPhoneNumberValidation,
      getEmailValidation,
      generateNewContract,
      getDocumentById,
      postDocument,
      deleteDocument,
      postContractCreationData,
      updateContractCreationData,
      getContractDataById,
      getCompanies,
      getEmptyWeeklyWorkingHours,
    };
  }
);
