import { useMainStore } from '@/store/pinia/main.store.js';
import { useUserStore } from '@/store/pinia/user.store.js';
import { usePeopleEmployeesStore } from '@/store/pinia/people-employees.store.js';

// const mainStore = useMainStore();
// const userStore = useUserStore();
// const peopleEmployeesStore = usePeopleEmployeesStore();

/**
 * If the user is authenticated, then check if the user has permissions, if not, get them, then allow
 * the user to continue to the route. If the user is not authenticated, then redirect them to the
 * authentication page
 */
export const checkUserAuthStatus = async (to, from, next) => {
  const mainStore = useMainStore();
  const userStore = useUserStore();

  const isAuthenticated = await mainStore.checkAuthentication();
  const userPermissions = userStore.userInfo.userPermissions;

  // const isAuthenticated = await $store.dispatch('checkAuthentication');

  if (to.name && !isAuthenticated && to.name.startsWith('Authentication')) {
    return next();
  } else if (isAuthenticated && to.name === 'Authentication') {
    return next({ name: 'ProfileMenu' });
  }

  if (isAuthenticated) {
    if (!userPermissions) {
      await userStore.getUserInfo();
    }
    return next();
  } else {
    return next({ name: 'Authentication' });
  }
};

/**
 * If the route requires authentication and the user is authenticated, check if the route requires a
 * permission and if so, check if the user has the permission
 */
export const checkUserAccessPermission = async (to, from, next) => {
  const mainStore = useMainStore();
  const peopleEmployeesStore = usePeopleEmployeesStore();
  const userStore = useUserStore();

  const isAuthenticated = await mainStore.checkAuthentication();

  const isAuthRoute = to.matched.some((route) => route.meta.requiresAuth);

  if (isAuthRoute && isAuthenticated) {
    const needPermission = to.matched.some((route) => route.meta.authorize);
    if (needPermission) {
      const userPermissions = userStore.userInfo.userPermissions;
      const permissionNeeded = to.meta.authorize;
      let hasAccess =
        permissionNeeded.some((role) => userPermissions?.includes(role)) ||
        false;
      if (hasAccess) {
        if (isReturningToEmployeeList(to.name, from.name)) {
          return next({ name: 'EmployeeList' });
        } else {
          if (isAccessingEmployeeProfile(to.name, from.name)) {
            await peopleEmployeesStore.getEmployeeMenuPermissions(
              to.params.employeeId
            );
          }
          return next();
        }
      }
      next({ name: 'Error', params: { status: '403' } });
    }
  }
  next();
};

const isReturningToEmployeeList = (to, from) => {
  return to === 'EmployeeProfileMenu' && from?.includes('EmployeeProfile');
};

const isAccessingEmployeeProfile = (to, from) => {
  return to?.includes('EmployeeProfile') && !from?.includes('EmployeeProfile');
};
