<template>
  <v-dialog
    data-testid="candidates-hiring-send-contract-dialog"
    v-model="displayDialog"
    :retain-focus="false"
    persistent
    width="614"
  >
    <v-card class="pa-3">
      <v-card-title
        data-testid="candidates-hiring-send-contract-dialog-title"
        class="dialog-title mb-4"
      >
        {{ t('Title_.page.wantToSendContract') }}
      </v-card-title>
      <v-card-text class="section-detail mineBlack--text pb-0">
        <v-container>
          <v-row>
            <v-col class="pl-0">
              <RegularTextInput
                data-testid="candidates-hiring-send-contract-dialog-full-name-field"
                disabled
                :value="`${candidate.applicant.firstName} ${candidate.applicant.lastName}`"
                :label="'Candidate'"
              />
            </v-col>
            <v-col class="pr-0">
              <DropdownInput
                data-testid="candidates-hiring-send-contract-dialog-hub-dropdown"
                :label="t('Msg_.hub')"
                :items="peopleStore.peopleHubList"
                item-text="name"
                item-value="id"
                :value="candidate.applicant.mainHub"
                :clearable="false"
                disabled
              />
            </v-col>
          </v-row>
          <v-row class="mb-0">
            <v-col cols="6" class="pl-0 pt-0 pb-0">
              <DropdownInput
                data-testid="candidates-hiring-send-contract-dialog-process-responsible-dropdown"
                :label="t('Msg_.processResponsible')"
                :items="candidate.processResponsibleList"
                :value="candidate.responsibleId"
                disabled
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              data-testid="candidates-hiring-send-contract-dialog-warning-message"
              class="pl-0 pt-0 mb-12"
            >
              {{ t('Msg_.wantToSendContractWarningMessage') }}
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="d-flex justify-end mt-4">
        <v-btn
          data-testid="candidates-hiring-send-contract-dialog-cancel-btn"
          class="cancel-outlined-btn"
          @click="emit('close-dialog')"
        >
          {{ t('Action_.buttons.cancel') }}
        </v-btn>
        <v-btn
          data-testid="candidates-hiring-send-contract-dialog-send-btn"
          class="main-action-btn ml-2"
          @click="sendCandidateContract()"
        >
          {{ t('Action_.buttons.send') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';

import languages from '../../locales/languages';
import { usePeopleStore } from '@/store/pinia/people.store.js';
import { usePeopleCandidatesStore } from '@/store/pinia/people-candidates.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { defineEmits, computed } from 'vue';

const peopleStore = usePeopleStore();
const emit = defineEmits(['close-dialog', 'send-candidate-contract']);
const peopleCandidatesStore = usePeopleCandidatesStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const displayDialog = true;

// --- Methods ---
const sendCandidateContract = () => {
  emit('send-candidate-contract');
};

// --- Computed ---
const candidate = computed(() => {
  return peopleCandidatesStore.candidatesList.items[
    peopleCandidatesStore.candidateIndex
  ];
});
</script>

<style lang="scss" scoped></style>
