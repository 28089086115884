import httpClient from '@/helpers/httpClient';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import router from '@/router/index';
import { useToastNotificationStore } from '@/store/pinia/toast-notification.store.js';

export const useMainStore = defineStore('main', () => {
  const toastNotificationStore = useToastNotificationStore();

  // --- State ----
  const showUserDrawerDetails = ref(null);
  const errorPageMessage = ref(null);
  const isAuthenticated = ref(false);
  const isPostFormInProgress = ref(false);

  // --- Methods ---
  const request = async (params, toastExtraParams) => {
    let toastParams = {};
    if (toastExtraParams) toastParams = { ...toastExtraParams };
    const messageType = {
      POST: 'Created',
      PUT: 'Edited',
      PATCH: 'Edited',
      DELETE: 'Deleted',
    };
    const requestType = params.configs.method.toUpperCase();

    try {
      const data = await httpClient.service(params);
      toastParams = {
        messages: { success: messageType[requestType] + ' with success!' },
        type: 'success',
        ...toastExtraParams,
      };
      return Promise.resolve(data);
    } catch (error) {
      const messageType = {
        POST: 'Create',
        PUT: 'Edit',
        PATCH: 'Edit',
        DELETE: 'Delete',
      };

      console.log('error');
      console.log(error);
      const specificErrorMessage = params.specificErrors
        ? params.specificErrors[error.status]
        : null;

      if (requestType == 'GET') {
        router.push({
          name: 'Error',
          params: {
            status: error.status,
            message: `${error.statusText || error.Title || ''}
            - ${specificErrorMessage || errorPageMessage.value}`,
          },
        });
      } else {
        toastParams = {
          messages: {
            warning: ' Failed to ' + messageType[requestType].toLowerCase(),
          },
          type: 'warning',
          ...toastExtraParams,
        };
        // if (specificErrorMessage) {
        //   dispatch('toastNotification/setupToastNotification', {
        //     messages: {
        //       error: specificErrorMessage,
        //     },
        //   });
        // }
        // dispatch('toastNotification/setToastNotificationType', 'error');
        // dispatch('toastNotification/showToastNotification', true);
        console.log('FUTURE TOAST');
      }
      return Promise.reject(error);
    } finally {
      if (requestType !== 'GET') {
        toastNotificationStore.$partialReset('params');
        toastNotificationStore.showToastNotification(toastParams);
      }
    }
  };

  const checkAuthentication = async () => {
    try {
      const params = {
        endpoint: 'v2/api/Account/isAuthenticated',
        configs: {
          method: 'GET',
        },
      };

      const data = await httpClient.service(params);
      isAuthenticated.value = data.isAuthenticated;
      return data.isAuthenticated;
    } catch (error) {
      return false;
    }
  };

  const amplitudeGetAllHubs = async () => {
    const params = {
      endpoint: `v2/api/people/resources/hubs`,
      configs: {
        method: 'GET',
      },
    };

    try {
      return await request(params);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return {
    errorPageMessage,
    showUserDrawerDetails,
    isAuthenticated,
    isPostFormInProgress,
    request,
    checkAuthentication,
    amplitudeGetAllHubs,
  };
});
