<template>
  <v-container data-testid="applicants-list" class="mt-2">
    <v-row align="center">
      <v-col cols="3">
        <SearchInput
          data-testid="applicants-list-search-input"
          :label="t('Msg_.searchApplicant')"
          :placeholder="t('Msg_.search')"
          v-model="pageOptions.searchTerm"
          @update-list="updateApplicantsList()"
        />
      </v-col>
      <v-col cols="2">
        <DropdownInput
          data-testid="applicants-list-hub-dropdown"
          :label="t('Msg_.hub')"
          :items="peopleStore.peopleHubList"
          item-text="name"
          item-value="id"
          v-model="pageOptions.hubId"
          @change="updateApplicantsList()"
        />
      </v-col>
      <v-col class="ml-auto text-right" cols="auto">
        <ButtonWithIcon
          data-testid="applicants-list-add-applicant-btn"
          class="main-action-icon-btn"
          icon="mdi-plus"
          :text="t('Action_.buttons.addNewApplicant')"
          @click="addNewApplicant()"
        />
      </v-col>
    </v-row>
    <v-container
      class="pa-0"
      v-if="peopleApplicantsStore.applicantsList?.items?.length"
    >
      <v-row>
        <v-col class="my-n1">
          <v-hover
            v-slot="{ hover }"
            v-for="(applicant, index) in peopleApplicantsStore?.applicantsList
              ?.items"
            :key="applicant.id"
          >
            <v-card
              data-testid="recruit-item"
              :class="hover ? 'shadow-hover' : 'shadow-regular'"
              class="rounded-lg mb-4"
            >
              <RecruitmentListItem
                :index="index"
                type="applicants"
                @update-applicants-list="updateApplicantsList()"
              />

              <v-divider></v-divider>

              <ApplicantsActionRequired :index="index" />
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <Pagination
        data-testid="applicants-list-pagination"
        type="wide"
        v-model="pagination"
        @update-list="updateApplicantsList()"
      />
    </v-container>
    <EmptyListMessage
      v-else-if="!peopleApplicantsStore.applicantsList?.items?.length"
      data-testid="applicant-list-no-data-message"
    />
    <v-progress-linear
      v-else-if="loadingStore.isGlobalLoading"
      indeterminate
      rounded
      color="blueRegular"
    />
  </v-container>
</template>

<script setup>
import EmptyListMessage from '@/components/shared/customComponents/emptyStates/empty-list-message.vue';
import RecruitmentListItem from '@/components/people/peopleSharedComponents/recruitmentCards/recruitment-list-item';
import Pagination from '@/components/shared/customComponents/paginations/pagination.vue';
import ButtonWithIcon from '@/components/shared/customComponents/buttons/button-with-icon.vue';
import SearchInput from '@/components/shared/customComponents/inputs/search-input.vue';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
import ApplicantsActionRequired from '@/components/people/peopleSharedComponents/recruitmentCards/applicants-action-required.vue';

import languages from '../locales/languages';
import { usePeopleRecruitmentContractStore } from '@/store/pinia/people-recruitment-contract.store.js';
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { usePeopleStore } from '@/store/pinia/people.store.js';
import { usePeopleApplicantsStore } from '@/store/pinia/people-applicants.store.js';
import { usePagination } from '@/helpers/composables/pagination.js';
import { useI18n } from 'vue-i18n-bridge';
import { ref } from 'vue';

const peopleStore = usePeopleStore();
const peopleApplicantsStore = usePeopleApplicantsStore();
const loadingStore = useLoadingStore();
const peopleRecruitmentContractStore = usePeopleRecruitmentContractStore();
const { trackPage } = amplitudeComposable();
const { t } = useI18n({ locale: 'en', messages: languages });
const {
  pagination,
  validatePaginationData,
  validatePageOptions,
  updatePaginationData,
} = usePagination();

// --- Local variables ---
const pageOptions = ref({
  searchTerm: '',
  hubId: '',
  statuses: 1, // active
});

// --- Methods ---
const getApplicantsList = async () => {
  validatePaginationData();

  const payload = {
    pagination: pagination.value,
    pageOptions: pageOptions.value,
  };

  try {
    await peopleApplicantsStore.getApplicantsList(payload);
    updatePaginationData(peopleApplicantsStore.applicantsList);
  } catch (error) {
    console.log(error);
  }
};

const updateApplicantsList = () => {
  peopleRecruitmentContractStore.selectedHubId = pageOptions.value.hubId;
  peopleApplicantsStore.$partialReset('showProfileIndex');
  validatePageOptions();
  validateFilterOptions();
  scrollUp();
  getApplicantsList();
};

const validateFilterOptions = () => {
  if (!pageOptions.value.searchTerm) pageOptions.value.searchTerm = '';
  if (!pageOptions.value.hubId) pageOptions.value.hubId = '';
};

const scrollUp = () => {
  window.scrollTo(0, 0);
};

const getEnvLink = (env) => {
  const basePath = 'https://kenbi.typeform.com/to';
  if (env.includes('local')) {
    return `${basePath}/w8JO97RV`;
  } else if (env.includes('dev')) {
    return `${basePath}/w8JO97RV`;
  } else if (env.includes('stg')) {
    return `${basePath}/FO8bMO7O`;
  } else if (env.includes('prd')) {
    return `${basePath}/vT3tfGrw`;
  }
  return null;
};

const redirectToTypeform = () => {
  const typeformLink = getEnvLink(process.env.VUE_APP_LOGIN_API);
  if (typeformLink) {
    window.open(typeformLink, '_blank');
  }
};

const addNewApplicant = () => {
  redirectToTypeform();
};

const initializeApplicantList = () => {
  getApplicantsList();
  trackPage(pageOptions.value.hubId);
};

// --- Lifecycle hooks ---
initializeApplicantList();
</script>

<style scoped lang="scss">
#user-initials {
  font-size: 1.6rem;
  color: $mine-black;
}
</style>
