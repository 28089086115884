<template>
  <v-footer
    padless
    class="white py-1 px-2"
    v-if="!route.name?.startsWith('Authentication')"
  >
    <v-col class="text-right section-detail" cols="12">
      {{ new Date().getFullYear() }}©
      <span class="mineBlack--text ml-1">Kenbi</span>
      <router-link
        class="section-detail ml-1 text-caption removeEffects"
        :to="{ name: 'oss' }"
      >
        OSS
      </router-link>
    </v-col>
  </v-footer>
</template>

<script setup>
import { useRoute } from 'vue-router/composables';

const route = useRoute();
</script>

<style>
.removeEffects {
  text-decoration: none;
  color: inherit;
}
</style>
