<template>
  <div data-testid="switch-control">
    <p class="custom-label mb-1 pl-3">{{ label }}</p>
    <v-switch
      inset
      dense
      class="mt-0 pt-0 pl-3"
      color="blueRegular"
      :input-value="value"
      :disabled="disabled"
      :hide-details="hideDetails"
      @change="emitUserInput($event)"
      @click="emitClickEvent($event)"
    />
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const emit = defineEmits(['input', 'change', 'click']);

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  label: {
    type: String,
    required: false,
    default: '',
  },
  value: {
    type: null,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  hideDetails: {
    type: Boolean,
    required: false,
    default: false,
  },
});

// --- Methods ---
const emitUserInput = (input) => {
  emit('input', input);
  emit('change', input);
};

const emitClickEvent = (input) => {
  emit('click', input);
};
</script>

<style scoped lang="scss">
.custom-label {
  font-size: 13px;
}
</style>
