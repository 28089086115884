import helpers from '@/helpers/helpers';

export const useContractToolsV2 = () => {
  // --- Weekly working hours ---
  const createWorkingHoursArray = (obj) => {
    const res = [];
    for (let key in obj) {
      res.push({
        day: key,
        hours: obj[key],
        isActive: !!obj[key],
      });
    }
    return res;
  };

  const populateFieldsWithDefaultValues = (contract) => {
    const activeHours = 0;
    const weekDays = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ];

    contract.totalWeeklyWorkingHours = 0;
    contract.weeklyWorkingHours = weekDays.map((day) => ({
      day,
      hours: activeHours.toFixed(2),
      isActive: false,
    }));
  };

  const initiateWeeklyWorkingHours = (contract) => {
    if (contract.weeklyWorkingHours) {
      contract.weeklyWorkingHours = createWorkingHoursArray(
        contract.weeklyWorkingHours
      );
    } else {
      populateFieldsWithDefaultValues();
    }
  };

  const updateWeekDay = (index, contract) => {
    const freeDay = 0;
    contract.weeklyWorkingHours[index].isActive =
      !contract.weeklyWorkingHours[index].isActive;

    if (!contract.weeklyWorkingHours[index].isActive) {
      contract.weeklyWorkingHours[index].hours = freeDay;
    }
  };

  const totalActiveSwitches = (contract) => {
    return contract.weeklyWorkingHours.reduce(
      (total, day) => total + day.isActive,
      0
    );
  };

  const updateSplitHours = async (contract) => {
    const totalActive = totalActiveSwitches(contract);
    const splitTime = contract.totalWeeklyWorkingHours / totalActive;

    contract.weeklyWorkingHours.forEach((day, index) => {
      if (day.isActive) {
        contract.weeklyWorkingHours[index].hours = Number.isInteger(splitTime)
          ? splitTime
          : splitTime.toFixed(2);
      }
    });
  };

  const prepareWeeklyWorkingHoursToPost = (data) => {
    return data.reduce((result, item) => {
      result[item.day] = parseFloat(item.hours);
      return result;
    }, {});
  };

  // --- Additional training ---
  const convertAdditionalTraining = (additionalTraining) => {
    if (!Array.isArray(additionalTraining)) {
      return [];
    }

    return additionalTraining.map(({ Name, Value }) => ({
      text: Name,
      value: Value,
    }));
  };

  const initiateAdditionalTraining = (contract) => {
    contract.additionalTraining = convertAdditionalTraining(
      contract?.additionalTraining
    );
  };

  const validateAdditionalTraining = (payload) => {
    if (payload.lastSelection.value === 0) {
      return payload.array.some(({ value }) => value === 0)
        ? [payload.lastSelection]
        : [];
    }

    return payload.array.filter(({ value }) => value !== 0);
  };

  const formatAdditionalTrainingKeysToPost = (payload) => {
    return payload.map((item) => ({
      Name: item.text,
      Value: item.value,
    }));
  };

  // --- Compensation type ---
  const updateCompensationType = (contract) => {
    if (contract.contractType === 1) {
      if (contract.totalWeeklyWorkingHours >= 38.5) {
        return 1;
      } else {
        return 2;
      }
    } else {
      return 4;
    }
  };

  // --- Currency fields ---
  const initiateCurrencyFields = (currencyKeys, payload) => {
    currencyKeys.forEach((key) => {
      payload[key] = helpers.convertValueToCurrency(payload[key]);
    });
  };

  const formatCurrencyFieldsToPost = (currencyKeys, payload) => {
    currencyKeys.forEach((key) => {
      payload[key] = helpers.convertCurrencyToFloat(
        helpers.convertValueToCurrency(payload[key])
      );
    });
  };

  // --- Percentage fields ---
  const formatPercentageFieldsToPost = (percentageKeys, payload) => {
    percentageKeys.forEach((key) => {
      if (payload[key] != null) {
        payload[key] = parseFloat(payload[key]) || 0;
      }
    });
  };

  // --- Optional fields ---
  const initiateOptionalFields = (optionalFields, contract) => {
    for (const key of optionalFields) {
      if (key in contract && contract[key] === null) {
        contract[key] = '';
      }
    }
  };

  return {
    initiateWeeklyWorkingHours,
    updateWeekDay,
    totalActiveSwitches,
    updateSplitHours,
    prepareWeeklyWorkingHoursToPost,
    initiateAdditionalTraining,
    validateAdditionalTraining,
    formatAdditionalTrainingKeysToPost,
    updateCompensationType,
    initiateCurrencyFields,
    formatCurrencyFieldsToPost,
    formatPercentageFieldsToPost,
    initiateOptionalFields,
  };
};
