import { defineStore } from 'pinia';
import { ref } from 'vue';

export const usePopupDialogStore = defineStore('popupDialog', () => {
  // --- State ---
  const displayDialog = ref(false);
  const params = ref({
    type: 'confirmation',
    title: 'Dialog Title',
    message: 'Dialog descriptive message',
    reinforcement: 'Confirmation question?',
    link: null,
    actionBtnText: 'Proceed',
    actionBtn: null,
    cancelBtn: null,
  });
  const initialParams = {
    type: 'confirmation',
    title: 'Dialog Title',
    message: 'Dialog descriptive message',
    reinforcement: 'Confirmation question?',
    link: null,
    actionBtnText: 'Proceed',
    actionBtn: null,
    cancelBtn: null,
  };

  return {
    displayDialog,
    params,
    initialParams,
  };
});
