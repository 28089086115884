<template>
  <v-container>
    <v-row>
      <v-col class="my-n1 px-0">
        <v-hover v-slot="{ hover }">
          <v-card
            data-testid="people-information-card"
            :class="hover ? 'shadow-hover' : 'shadow-regular'"
            class="rounded-lg pt-3 pb-2 px-4"
          >
            <v-container>
              <v-row>
                <v-col cols="auto">
                  <v-avatar color="ashDark" size="70">
                    <span
                      data-testid="people-information-card-name-initials-field"
                      id="people-information-card-name-initials-field"
                    >
                      {{ getEmployeeInitials() }}
                    </span>
                  </v-avatar>
                </v-col>

                <v-col>
                  <v-container class="pa-0">
                    <v-row align="center">
                      <v-col
                        data-testid="people-information-card-complete-name-field"
                        cols="auto"
                        class="section-title ml-1"
                      >
                        {{ getEmployeeFullName() }}
                      </v-col>
                      <v-col cols="auto">
                        <RegularBadge
                          data-testid="people-information-card-main-hub-bagde"
                          :text="getMainHub"
                          color="lowOpacityBlue"
                          text-color="blueDark"
                        />
                      </v-col>
                      <v-col
                        v-if="missingSignature"
                        data-testid="people-information-card-waiting-for-signature-info"
                        class="link"
                      >
                        {{ t('Msg_.waitingForSignature') }}
                      </v-col>
                    </v-row>
                  </v-container>

                  <v-container class="pa-0">
                    <v-row
                      align="start"
                      class="section-detail greyRegular--text"
                    >
                      <v-col cols="auto" class="d-flex align-center ml-1">
                        <v-icon class="pr-2" color="greyRegular" small>
                          mdi-phone mdi-rotate-270
                        </v-icon>
                        <p
                          class="mb-0"
                          data-testid="people-information-card-phone-number-field"
                        >
                          {{
                            peoplePendingEmployeesStore.pendingEmployeesList[
                              props.index
                            ].personalPhoneNumber
                          }}
                        </p>
                      </v-col>
                      <v-col cols="auto" class="d-flex align-center">
                        <v-icon class="pr-2" color="greyRegular" small>
                          mdi-email-outline
                        </v-icon>
                        <p
                          class="mb-0"
                          data-testid="people-information-card-email-field"
                        >
                          {{
                            peoplePendingEmployeesStore.pendingEmployeesList[
                              props.index
                            ].personalEmail
                          }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-container>

                  <DigitalSignatureStatus
                    class="mt-1"
                    :employee-signature="
                      peoplePendingEmployeesStore.pendingEmployeesList[
                        props.index
                      ].contractualDocument?.signedByEmployee
                    "
                    :management-signature="
                      peoplePendingEmployeesStore.pendingEmployeesList[
                        props.index
                      ].contractualDocument?.signedByKenbi
                    "
                  />
                </v-col>
                <v-col cols="auto">
                  <v-menu bottom left offset-x>
                    <template #activator="{ on, attrs }">
                      <IconButton
                        data-testid="people-information-card-option-dots-btn"
                        :bind="attrs"
                        :on="on"
                        class="icon-btn"
                        icon="mdi-dots-vertical"
                      />
                    </template>
                    <v-list class="py-1 px-0">
                      <v-list-item
                        v-for="button in peopleActionButtons"
                        :key="button.dataTestId"
                      >
                        <ButtonWithIcon
                          :data-testid="button.dataTestId"
                          class="pa-2 mx-0 default-btn d-flex justify-start"
                          :icon="button.icon"
                          :text="t(button.text)"
                          :color="button.color"
                          :only-text="true"
                          width="100%"
                          @click="selectEmployeeAction(button.action)"
                        />
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
            <v-container class="section-detail info-line">
              <v-row class="mt-n5">
                <v-col
                  data-testid="people-information-card-info-line"
                  class="pt-5"
                >
                  {{ qualificationType }}
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import IconButton from '@/components/shared/customComponents/buttons/icon-button.vue';
import ButtonWithIcon from '@/components/shared/customComponents/buttons/button-with-icon.vue';
import RegularBadge from '@/components/shared/customComponents/alertsBadges/regular-badge.vue';
import DigitalSignatureStatus from '@/components/shared/digitalSignature/digital-signature-status.vue';
import languages from '@/components/people/locales/languages';
import helpers from '@/helpers/helpers';
import { usePeopleStore } from '@/store/pinia/people.store.js';
import { usePopupDialogStore } from '@/store/pinia/popup-dialog.store.js';
import { usePeoplePendingEmployeesStore } from '@/store/pinia/people-pending-employees.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { defineProps, computed } from 'vue';
import { useRouter } from 'vue-router/composables';

const peopleStore = usePeopleStore();
const popupDialogStore = usePopupDialogStore();
const peoplePendingEmployeesStore = usePeoplePendingEmployeesStore();
const { t, tm } = useI18n({ locale: 'en', messages: languages });
const router = useRouter();

// --- Props ---
const props = defineProps({
  index: {
    type: Number,
    default: null,
    required: false,
  },
});

// --- Local variables ---
const peopleActionButtons = [
  {
    dataTestId: 'people-information-card-download-contract-btn',
    icon: 'mdi-download',
    text: 'Action_.buttons.downloadContract',
    action: 'downloadContract',
  },
  {
    dataTestId: 'people-information-card-get-contract-link-for-signature-btn',
    icon: 'mdi-share-variant',
    text: 'Action_.buttons.getContractLinkForSignature',
    action: 'linkForSignature',
  },
  {
    dataTestId: 'people-information-card-edit-employee-btn',
    icon: 'mdi-pencil',
    text: 'Action_.buttons.editEmployee',
    action: 'editEmployee',
  },
];

// --- Methods ---
const selectEmployeeAction = (action) => {
  switch (action) {
    case 'downloadContract':
      downloadContract();
      break;
    case 'linkForSignature':
      openSignatureLinksModal();
      break;
    case 'editEmployee':
      routeToForm();
      break;
  }
};

const downloadContract = () => {
  if (
    peoplePendingEmployeesStore.pendingEmployeesList[props.index]
      ?.contractualDocument?.fileUrl
  ) {
    window.open(
      `${process.env.VUE_APP_LOGIN_API}/v2/api/recruitments/documents?path=${
        peoplePendingEmployeesStore.pendingEmployeesList[props.index]
          .contractualDocument.fileUrl
      }`
    );
  }
};

const openSignatureLinksModal = () => {
  if (
    peoplePendingEmployeesStore.pendingEmployeesList[props.index]
      ?.contractualDocument?.signUrlEmployee
  ) {
    displayLinkDialog();
  }
};

const displayLinkDialog = () => {
  const params = {
    type: 'regular',
    title: t('Msg_.employeeLink'),
    link: peoplePendingEmployeesStore.pendingEmployeesList[props.index]
      .contractualDocument.signUrlEmployee,
  };

  popupDialogStore.params = params;
  popupDialogStore.displayDialog = true;
};

const routeToForm = () => {
  router.push({
    name: 'PendingEmployeeContract',
    params: {
      employeeId:
        peoplePendingEmployeesStore.pendingEmployeesList[props.index].id,
    },
  });
};

const getEmployeeFullName = () => {
  const firstName =
    peoplePendingEmployeesStore.pendingEmployeesList[props.index].firstName;
  const lastName =
    peoplePendingEmployeesStore.pendingEmployeesList[props.index].lastName;

  return `${firstName} ${lastName}`;
};

const getEmployeeInitials = () => {
  const employeeFullName = getEmployeeFullName();
  return helpers.getUserInitials(employeeFullName);
};

// --- Computed ---
const missingSignature = computed(() => {
  const missingSignature =
    !peoplePendingEmployeesStore.pendingEmployeesList[props.index]
      .contractualDocument?.signedByEmployee ||
    !peoplePendingEmployeesStore.pendingEmployeesList[props.index]
      .contractualDocument?.signedByKenbi;
  return missingSignature;
});

const getMainHub = computed(() => {
  const hubName = peopleStore.peopleHubList?.filter((hub) => {
    return (
      hub.id ===
      peoplePendingEmployeesStore.pendingEmployeesList[props.index].mainHub
    );
  });
  const hubNameToReturn = hubName?.length ? hubName[0].name : 'Main Hub';
  return hubNameToReturn;
});

const qualificationType = computed(() => {
  const qualification = helpers.getTranslationById(
    tm('Action_.selectQualificationType'),
    peoplePendingEmployeesStore.pendingEmployeesList[props.index].jobPosition
  );
  const weeklyWorkingHours =
    peoplePendingEmployeesStore.pendingEmployeesList[props.index]
      .totalWeeklyWorkingHours;
  return `${qualification} • ${weeklyWorkingHours} ${t('Msg_.hours')}`;
});
</script>

<style scoped lang="scss">
#people-information-card-name-initials-field {
  font-size: 1.6rem;
  color: $mine-black;
}

.info-line {
  color: $blue-grey-dark !important;
  font-weight: 500;
}
</style>
