import { computed } from 'vue';
import { useUserStore } from '@/store/pinia/user.store.js';
import { usePeopleEmployeesStore } from '@/store/pinia/people-employees.store.js';

export function usePermissions() {
  const userStore = useUserStore();
  const peopleEmployeesStore = usePeopleEmployeesStore();

  const canUser = (permission) => {
    return permission?.some((permission) =>
      userPermissions.value?.includes(permission)
    );
  };

  const regardingOthersCanUser = (permission) => {
    return permission?.some((permission) =>
      userPermissionsRegardingOthers.value?.includes(permission)
    );
  };

  const userPermissions = computed(() => {
    return userStore.userInfo.userPermissions;
  });

  const userPermissionsRegardingOthers = computed(() => {
    return peopleEmployeesStore.employeeProfilePermissions;
  });

  return {
    canUser,
    regardingOthersCanUser,
  };
}
