const ExportRoutes = [
  {
    path: '/export',
    name: '',
    component: () => import('@/components/export/export.vue'),
    meta: {
      requiresAuth: false,
      authorize: ['People.Employees.Export'],
    },
    children: [
      {
        path: 'people',
        name: 'ExportPeople',
        component: () => import('@/components/export/export-people.vue'),
        meta: {
          authorize: ['People.Employees.Export'],
        },
      },
    ],
  },
];

export default ExportRoutes;
