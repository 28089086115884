<template>
  <router-view></router-view>
</template>

<script setup>
import { onBeforeUnmount } from 'vue';
import { usePeopleRecruitmentContractStore } from '@/store/pinia/people-recruitment-contract.store.js';
import { usePeopleCandidatesStore } from '@/store/pinia/people-candidates.store.js';

const peopleRecruitmentContractStore = usePeopleRecruitmentContractStore();
const peopleCandidatesStore = usePeopleCandidatesStore();

// --- Methods ---
const applyRecruitType = () => {
  peopleRecruitmentContractStore.recruitType = 'candidate';
};

// --- Lifecycle hooks ---
applyRecruitType();

onBeforeUnmount(() => {
  peopleCandidatesStore.$partialReset('showProfileIndex');
  peopleRecruitmentContractStore.$$partialReset('recruitType');
  peopleRecruitmentContractStore.$$partialReset('selectedHubId');
});
</script>
