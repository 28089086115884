const logbookRoutes = [
  {
    path: '/logbook',
    name: 'Logbook',
    meta: {
      requiresAuth: false,
      authorize: [],
    },

    component: () => import('@/components/logbook/logbook.vue'),
    children: [
      {
        path: 'logbook-dashboard',
        name: 'LogbookDashboard',
        component: () =>
          import('@/components/logbook/logbookDashboard/logbook-dashboard.vue'),
        meta: {
          requiresAuth: false,
          authorize: [],
        },
      },
    ],
  },
];

export default logbookRoutes;
