<template>
  <v-container>
    <v-row align="center" class="pt-3">
      <v-col>
        <span data-testid="contract-information-page-title" class="card-title">
          {{ t('Title_.page.profile') }}
        </span>
      </v-col>
      <v-col class="text-right py-2">
        <v-btn
          data-testid="contract-information-change-contract-btn"
          v-if="showChangeContractBtn"
          class="main-action-btn"
          @click="onChangeContractClicked()"
          >{{ t('Action_.buttons.changeContract') }}
        </v-btn>
        <v-btn
          data-testid="absences-new-application-btn"
          v-if="showAbsencesBtn"
          class="main-action-btn"
          @click="onNewAbsenceRequestClicked()"
          >{{ t('Action_.buttons.absenceNewApplication') }}
        </v-btn>
        <v-menu v-if="showOffboardingBtn" bottom left offset-x>
          <template #activator="{ on, attrs }">
            <IconButton
              data-testid="contract-information-options-menu-icon-btn"
              :bind="attrs"
              :on="on"
              class="icon-btn ml-3"
              icon="mdi-dots-vertical"
            />
          </template>
          <v-list class="py-1 px-0">
            <v-list-item>
              <v-btn
                data-testid="contract-information-offboard-employee-btn"
                class="default-btn"
                @click="toggleOffboardDialog()"
              >
                {{ t('Msg_.offboardEmployee') }}
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-row>
          <v-col>
            <v-card class="rounded-lg shadow-regular">
              <v-card-text class="py-5 px-7">
                <v-container class="pa-0">
                  <v-row no-gutters>
                    <v-col v-if="profileStore.profileMenuInformation.image">
                      <v-avatar size="70" color="ashDark">
                        <v-img
                          data-testid="profile-card-image"
                          :src="
                            helpers.convertImgToBase64(
                              profileStore.profileMenuInformation.image
                            )
                          "
                          alt="user image"
                          width="70"
                          height="70"
                        />
                      </v-avatar>
                    </v-col>
                    <v-col v-else>
                      <v-avatar color="ashDark" size="70">
                        <span
                          data-testid="profile-card-initials"
                          id="user-initials"
                        >
                          {{
                            helpers.getUserInitials(
                              profileStore.profileMenuInformation.fullName
                            )
                          }}
                        </span>
                      </v-avatar>
                    </v-col>
                    <v-col
                      v-if="canUser(['People.Employees.ListAll'])"
                      class="pa-0"
                      cols="auto"
                    >
                      <v-container>
                        <v-row>
                          <v-col class="d-flex justify-end pa-0">
                            <StatusBadge
                              data-testid="profile-card-status"
                              :text="statusBadgeText"
                              :color="statusBadgeColor"
                              :text-color="statusBadgeTextColor"
                              class="my-1 mr-2 caption-3"
                            />
                          </v-col>
                        </v-row>
                        <v-row v-if="showOffboardingMessage">
                          <v-col
                            class="d-flex justify-end caption-3 pa-0 mt-1 offboarding-incomplete"
                          >
                            {{ t('Msg_.offboardingProcessIncomplete') }}
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                  <v-card-subtitle
                    data-testid="profile-card-full-name"
                    class="pl-0 section-title heading-h4"
                  >
                    {{ profileStore.profileMenuInformation.fullName }}
                  </v-card-subtitle>
                  <v-row no-gutters>
                    <v-col>
                      <v-chip
                        data-testid="profile-card-main-hub"
                        color="lowOpacityGreen"
                        label
                        text-color="mineBlack"
                        class="mb-1"
                        small
                      >
                        <v-icon left small> mdi-domain </v-icon>
                        <span class="regular-badge-main-hub">
                          {{
                            profileStore.profileMenuInformation.mainHub || '-'
                          }}
                        </span>
                      </v-chip>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <RegularBadge
                        data-testid="profile-card-company"
                        :text="
                          profileStore.profileMenuInformation.company || '-'
                        "
                        color="ashRegular"
                        text-color="mineBlack"
                        class="my-1 mr-2"
                      />
                    </v-col>
                  </v-row>
                  <div class="mt-5 mb-5">
                    <v-row>
                      <v-col class="pt-2 pb-0">
                        <v-icon class="pr-1 pb-1" color="greyRegular" small>
                          mdi-medical-bag
                        </v-icon>
                        <span
                          data-testid="profile-card-qualification-type"
                          class="body-text-4"
                        >
                          {{ getQualificationType }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-2 pb-0">
                        <v-icon class="pr-1" color="greyRegular" small>
                          mdi-star-circle-outline
                        </v-icon>
                        <span
                          data-testid="profile-card-hub-role"
                          class="body-text-4"
                        >
                          {{ getHubRole || '-' }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-2 pb-0">
                        <v-icon class="pr-1 pb-1" color="greyRegular" small>
                          mdi-phone
                        </v-icon>
                        <span
                          data-testid="profile-card-work-phone-number"
                          class="body-text-4"
                        >
                          {{
                            profileStore.profileMenuInformation
                              .workPhoneNumber || '-'
                          }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-2 pb-0">
                        <v-icon class="pr-1 pb-1" color="greyRegular" small>
                          mdi-fingerprint
                        </v-icon>
                        <span
                          data-testid="profile-card-employee-number"
                          class="body-text-4"
                        >
                          {{
                            profileStore.profileMenuInformation
                              .employeeNumber || '-'
                          }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-2 pb-0">
                        <v-icon class="pr-1 pb-1" color="greyRegular" small>
                          mdi-play-outline
                        </v-icon>
                        <span
                          data-testid="profile-card-start-date"
                          class="body-text-4"
                        >
                          {{
                            helpers.formatDate(
                              profileStore.profileMenuInformation.startDate
                            ) || '-'
                          }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-2 pb-0">
                        <v-icon class="pr-1 pb-1" color="greyRegular" small>
                          mdi-car
                        </v-icon>
                        <span
                          data-testid="profile-card-license-plate"
                          class="body-text-4"
                        >
                          {{
                            profileStore.profileMenuInformation.licensePlate ||
                            '-'
                          }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row v-if="showExitDateInfo">
                      <v-col class="pt-2 pb-0">
                        <v-icon class="pr-1 pb-1" color="greyRegular" small>
                          mdi-login
                        </v-icon>
                        <span
                          data-testid="profile-card-license-plate"
                          class="body-text-4"
                        >
                          {{
                            helpers.formatDate(
                              profileStore.profileMenuInformation.exitDate ||
                                '-'
                            )
                          }}
                        </span>
                      </v-col>
                    </v-row>
                  </div>
                  <!-- Hide this row / https://kenbi-tech.atlassian.net/browse/SEADRA-685 -->
                  <!-- <v-divider class="my-5 mt-8"></v-divider>
                  <v-row class="mb-1">
                    <v-col cols="auto">
                      <div class="d-flex flex-column">
                        <p
                          data-testid="profile-card-time-balance-label"
                          class="ma-0 progress-title"
                        >
                          {{ t('Msg_.timeBalance') }}
                        </p>
                        <p
                          data-testid="profile-card-time-balance"
                          class="progress-details"
                        >
                          {{ profileStore.profileMenuInformation.timeBalance }}
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="auto">
                      <div class="d-flex flex-column">
                        <p
                          data-testid="profile-card-nursing-tasks-label"
                          class="ma-0 progress-title"
                        >
                          {{ t('Msg_.nursingTasks') }}
                        </p>
                        <p
                          data-testid="profile-card-nursing-tasks"
                          class="progress-details"
                        >
                          {{ profileStore.profileMenuInformation.nursingTasks }}
                        </p>
                      </div>
                    </v-col>
                  </v-row> -->
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="py-2 px-1 rounded-lg shadow-regular">
              <v-list nav dense>
                <v-list-item-group mandatory>
                  <v-list-item
                    v-for="(item, index) in selectMenu"
                    :key="index"
                    :data-testid="menuOptions[index].dataTestid"
                    :to="{
                      name: menuOptions[index].route,
                      params: { employeeId: route.params.employeeId },
                    }"
                    @click="scrollUp()"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="loadingStore.isPartialLoading"
        cols="8"
        class="d-flex justify-center align-center"
      >
        <LoadingAnimation dense />
      </v-col>
      <v-col cols="8" v-show="!loadingStore.isPartialLoading">
        <router-view :current-profile-id="props.currentProfileId" />
      </v-col>
    </v-row>

    <StartOffboardDialog
      v-if="displayStartOffboardDialog"
      @close-offboard-dialog="toggleOffboardDialog()"
      @start-offboarding="startOffboarding()"
    />
  </v-container>
</template>

<script setup>
import LoadingAnimation from '@/components/shared/customComponents/loadingAnimation/loading-animation.vue';
import RegularBadge from '@/components/shared/customComponents/alertsBadges/regular-badge.vue';
import IconButton from '@/components/shared/customComponents/buttons/icon-button.vue';
import StatusBadge from '@/components/shared/customComponents/alertsBadges/status-badge.vue';
import StartOffboardDialog from '@/components/profile/profileMenu/offboarding/startOffboardingDialog.vue';

import languages from './locales/languages';
import helpers from '@/helpers/helpers';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { useProfileStore } from '@/store/pinia/profile.store.js';
import { usePermissions } from '@/helpers/composables/permissions.js';
import { usePopupDialogStore } from '@/store/pinia/popup-dialog.store.js';
import { useContractAmendmentStore } from '@/store/pinia/contract-amendment.store.js';
import { useRoute, useRouter } from 'vue-router/composables';
import { useI18n } from 'vue-i18n-bridge';
import { ref, computed, onBeforeMount, defineProps } from 'vue';
import { useAbsencesStore } from '@/store/pinia/absences.store';
import { useProfileOffboardingStore } from '@/store/pinia/profile-offboarding.store.js';
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';

const { trackPage } = amplitudeComposable();
const route = useRoute();
const router = useRouter();
const absencesStore = useAbsencesStore();
const loadingStore = useLoadingStore();
const profileStore = useProfileStore();
const popupDialogStore = usePopupDialogStore();
const contractAmendmentStore = useContractAmendmentStore();
const displayStartOffboardDialog = ref(false);
const profileOffboardingStore = useProfileOffboardingStore();
const { canUser, regardingOthersCanUser } = usePermissions();
const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
const props = defineProps({
  currentProfileId: {
    type: String,
    default: '',
    required: false,
  },
});

// --- Local variables ---
const menuRoutes = {
  0: {
    route: 'ProfileOffboarding',
    permission: 'People.Employees.ReadOffboarding',
    dataTestid: 'offboarding-information-btn',
  },
  1: {
    route: 'ProfileContractInformation',
    permission: 'People.Employees.ReadContract',
    dataTestid: 'contract-information-btn',
  },
  2: {
    route: 'ProfileMasterInformation',
    permission: 'People.Employees.ReadMaster',
    dataTestid: 'contract-information-master-information-btn',
  },
  3: {
    route: 'ProfileInternalInformation',
    permission: 'People.Employees.ReadAdditionalInfo',
    dataTestid: 'contract-information-internal-information-btn',
  },
  4: {
    route: 'ProfileBankDetails',
    permission: 'People.Employees.ReadBank',
    dataTestid: 'contract-information-bank-details-btn',
  },
  5: {
    route: 'ProfileTaxData',
    permission: 'People.Employees.ReadTax',
    dataTestid: 'contract-information-tax-data-btn',
  },
  6: {
    route: 'ProfileDocuments',
    permission: 'People.Employees.ReadDocuments',
    dataTestid: 'contract-information-documents-btn',
  },
  7: {
    route: 'ProfileCarInformation',
    permission: 'People.Employees.ReadCar',
    dataTestid: 'contract-information-car-information-btn',
  },
  8: {
    route: 'ProfileDownloads',
    permission: 'People.Employees.ReadDownloads',
    dataTestid: 'contract-information-downloads-btn',
  },
  9: {
    route: 'ProfileAbsences',
    permission: 'People.Employees.ReadAbsences',
    dataTestid: 'contract-information-ansences-btn',
  },
};

const menuOptions = ref({});

// --- Methods ---
const getMenuOptions = async () => {
  menuOptions.value = {};
  for (let index = 0; index < Object.keys(menuRoutes).length; index++) {
    if (props.currentProfileId) {
      if (regardingOthersCanUser([menuRoutes[index].permission])) {
        if (
          menuRoutes[index].route === 'ProfileOffboarding' &&
          !profileStore.profileMenuInformation.hasOffboardingStarted
        )
          continue;
        menuOptions.value[Object.keys(menuOptions.value).length] = {
          route: 'Employee' + menuRoutes[index].route,
          position: index,
          dataTestid: menuRoutes[index].dataTestid,
        };
      }
    } else {
      if (canUser([menuRoutes[index].permission])) {
        if (menuRoutes[index].route === 'ProfileOffboarding') continue;
        menuOptions.value[Object.keys(menuOptions.value).length] = {
          route: menuRoutes[index].route,
          position: index,
          dataTestid: menuRoutes[index].dataTestid,
        };
      }
    }
  }
  if (route.name === 'EmployeeProfileMenu') {
    const employeeId = localStorage.getItem('employeeId');
    router.push({
      name: menuOptions.value[0].route,
      params: { employeeId: route.params.employeeId || employeeId },
    });
  }
};

const getProfileMenuInformation = async () => {
  let profileId = props.currentProfileId;
  if (route.name === 'EmployeeProfileMenu') {
    profileId = route.params.employeeId || localStorage.getItem('employeeId');
  }

  await profileStore.getProfileMenuInformation(profileId);
  await getMenuOptions();
};

const onNewAbsenceRequestClicked = () => {
  absencesStore.displayNewRequestAbsenceDialog();
  trackPage(profileStore.profileMenuInformation.offices?.mainOffice.id, {
    routeName: isLoggedUserProfile()
      ? 'profile.absences.submitApplication'
      : 'people.employeeDetails.absences.submitApplication',
  });
};

const isLoggedUserProfile = () => {
  return !props.currentProfileId;
};

const onChangeContractClicked = () => {
  if (!hasContractAmendment()) {
    changeContract();
  } else {
    toggleConfirmationDialog();
  }
};

const hasContractAmendment = () => {
  return !!profileStore.signatureInformation;
};

const toggleConfirmationDialog = () => {
  const params = {
    type: 'confirmation',
    title: t('Action_.dialog.replaceContractAmendment.title'),
    message: t('Action_.dialog.replaceContractAmendment.body'),
    reinforcement: t('Action_.dialog.replaceContractAmendment.reinforcement'),
    actionBtnText: t('Action_.dialog.replaceContractAmendment.btn_confirm'),
    actionBtn: changeContract,
  };

  popupDialogStore.params = params;
  popupDialogStore.displayDialog = true;
};

const routeToContractAmendmentPage = () => {
  router.push({
    name: 'ContractAmendment',
    params: {
      employeeId: props.currentProfileId,
    },
  });
};

const changeContract = async () => {
  if (hasContractAmendment()) {
    toggleConfirmationDialog();

    try {
      await contractAmendmentStore.deleteContractAmendment(
        profileStore.signatureInformation.id
      );
      routeToContractAmendmentPage();
    } catch (error) {
      console.log(error);
    }
  } else {
    routeToContractAmendmentPage();
  }
};

const scrollUp = () => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
};

const toggleOffboardDialog = () => {
  displayStartOffboardDialog.value = !displayStartOffboardDialog.value;
  if (displayStartOffboardDialog.value) {
    trackPage(profileStore.profileMenuInformation.offices?.mainOffice.id, {
      routeName: 'people.employeeDetails.startOffboarding',
    });
  }
};

const startOffboarding = async () => {
  const payload = {
    employeeId: route.params.employeeId,
    exitDate: profileOffboardingStore.offboardingExitDate,
  };

  try {
    toggleOffboardDialog();

    await profileOffboardingStore.startEmployeeOffboarding(payload);
  } catch (error) {
    console.log(error);
  } finally {
    initializeOffboardingSection();
  }
};

const initializeOffboardingSection = () => {
  getProfileMenuInformation();
  router.push({
    name: 'EmployeeProfileOffboarding',
    params: { employeeId: route.params.employeeId },
  });
  profileOffboardingStore.offboardingExitDate = null;
};

// --- Computed ---
const selectMenu = computed(() => {
  let menuList = {};

  for (let index = 0; index < Object.keys(menuOptions.value).length; index++) {
    menuList[index] =
      tm('Action_.selectMenu')[menuOptions.value[index].position];
  }
  return menuList;
});

const getQualificationType = computed(() => {
  if (profileStore.profileMenuInformation.qualificationType === 8) {
    return profileStore.profileMenuInformation.jobSubtitle;
  }
  return helpers.getTranslationById(
    tm('Action_.selectQualificationType'),
    profileStore.profileMenuInformation.qualificationType
  );
});

const getHubRole = computed(() => {
  return helpers.getTranslationById(
    tm('Action_.selectHubRoles'),
    profileStore.profileMenuInformation.hubRole
  );
});

const hasContract = computed(() => {
  return !!profileStore.hasContract || false;
});

const userCanCreateAbsences = computed(() => {
  return (
    isLoggedUserProfile() || canUser(['Absences.Requests.CreatePreApproved'])
  );
});

const showChangeContractBtn = computed(() => {
  return (
    route.name === 'EmployeeProfileContractInformation' &&
    !isLoggedUserProfile() &&
    !loadingStore.isPartialLoading &&
    hasContract.value &&
    canUser(['People.Employees.UpdateContract']) &&
    !profileStore.profileMenuInformation.hasOffboardingStarted
  );
});

const showAbsencesBtn = computed(() => {
  return (
    route.name.includes('ProfileAbsences') &&
    userCanCreateAbsences.value &&
    !loadingStore.isPartialLoading
  );
});

const showOffboardingBtn = computed(() => {
  return (
    !profileStore.profileMenuInformation.hasOffboardingStarted &&
    route.name === 'EmployeeProfileContractInformation' &&
    canUser(['People.Employees.UpdateOffboarding'])
  );
});

const showOffboardingMessage = computed(() => {
  return (
    profileStore.profileMenuInformation.hasOffboardingStarted &&
    profileStore.profileMenuInformation.isOffboardingInDraft &&
    canUser(['People.Employees.ReadOffboarding'])
  );
});

const showExitDateInfo = computed(() => {
  return (
    profileStore.profileMenuInformation.exitDate &&
    canUser(['People.Employees.ReadOffboarding'])
  );
});

const statusBadgeText = computed(() => {
  return (
    helpers.getTranslationById(
      tm('Action_.selectEmployeeStatusTypes'),
      profileStore.profileMenuInformation?.status
    ) || ''
  );
});

const statusBadgeColor = computed(() => {
  return (
    helpers.getStatusBadgeColor(profileStore.profileMenuInformation?.status) ||
    ''
  );
});

const statusBadgeTextColor = computed(() => {
  return (
    helpers.getStatusTextColor(profileStore.profileMenuInformation?.status) ||
    ''
  );
});

// --- Lifecycle hooks ---

onBeforeMount(async () => {
  await getProfileMenuInformation();
});
</script>

<style scoped lang="scss">
#user-initials {
  font-size: 1.6rem;
  color: $mine-black;
}

.progress-title {
  font-size: 12px;
}

.progress-details {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: $caribbean-dark;
  background-color: $green-low-opacity;
  text-align: center;
  padding: 4px;
  width: 5.2rem;
  border-radius: 5px;
}

.v-list-item--active {
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.4px;
  color: $blue-regular;
}

.regular-badge-status {
  font-size: 10px !important;
}

.regular-badge-main-hub {
  font-size: 14px !important;
}

.regular-badge-company {
  font-size: 12px !important;
}

.v-chip {
  margin-right: 0px !important;
}

.offboarding-incomplete {
  color: $red-danger;
  font-weight: 500 !important;
}
</style>
