<template>
  <div>
    <v-form ref="form" v-model="isFormValid">
      <v-container>
        <v-row class="mt-1">
          <v-col cols="4">
            <DropdownInput
              data-testid="recruitment-typeform1-german-work-permit-dropdown"
              :label="`${t('Msg_.germanWorkingPermit')}*`"
              :disabled="isFormDisabled"
              :clearable="false"
              :items="selectBooleanOptions"
              v-model="
                peopleRecruitmentContractStore.applicant.germanWorkingPermit
              "
              :rules="[
                rules.fieldRequired(
                  `${t('Msg_.germanWorkingPermit')} ${t('Msg_.isRequired')}`,
                  false
                ),
              ]"
            />
          </v-col>
          <v-col cols="4">
            <DropdownInput
              data-testid="recruitment-typeform1-driver-license-class-b-dropdown"
              :label="`${t('Msg_.driverLicenseClassB')}*`"
              :disabled="isFormDisabled"
              :clearable="false"
              :items="selectBooleanOptions"
              v-model="
                peopleRecruitmentContractStore.applicant.driverLicenseClassB
              "
              :rules="[
                rules.fieldRequired(
                  `${t('Msg_.driverLicenseClassB')} ${t('Msg_.isRequired')}`,
                  false
                ),
              ]"
            />
          </v-col>
          <v-col cols="4">
            <DropdownInput
              data-testid="recruitment-typeform1-highest-school-degree-dropdown"
              :label="`${t('Msg_.highestSchoolDegree')}*`"
              :disabled="isFormDisabled"
              :clearable="false"
              :items="selectLevelEducationType"
              v-model="
                peopleRecruitmentContractStore.applicant.highestSchoolDegree
              "
              :rules="[
                rules.fieldRequired(
                  `${t('Msg_.highestSchoolDegree')} ${t('Msg_.isRequired')}`
                ),
              ]"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pt-0">
            <DropdownInput
              data-testid="recruitment-typeform1-another-job-dropdown"
              :label="`${t('Msg_.anotherJob')}*`"
              :disabled="isFormDisabled"
              :clearable="false"
              :items="selectBooleanOptions"
              v-model="anotherJob"
              @change="handleAnotherJobCompensationType($event)"
              :rules="[
                rules.fieldRequired(
                  `${t('Msg_.anotherJob')} ${t('Msg_.isRequired')}`,
                  false
                ),
              ]"
            />
          </v-col>
          <v-col cols="4" class="pt-0">
            <DropdownInput
              data-testid="recruitment-typeform1-another-job-compensation-type-dropdown"
              :label="`${t('Msg_.anotherJobCompensationType')}*`"
              :disabled="isFormDisabled || disableAnotherJobCompensationType"
              :clearable="false"
              :items="selectCompensationTypes"
              v-model="
                peopleRecruitmentContractStore.applicant.contractTypeForOthers
              "
              @change="handleHasAnotherJob($event)"
              :rules="[
                rules.fieldRequired(
                  `${t('Msg_.anotherJobCompensationType')} ${t(
                    'Msg_.isRequired'
                  )}`
                ),
              ]"
            />
          </v-col>
          <v-col cols="4" class="pt-0">
            <DropdownInput
              data-testid="recruitment-typeform1-t-shirt-size-dropdown"
              :label="`${t('Msg_.tShirtSize')}*`"
              :disabled="isFormDisabled"
              :clearable="false"
              :items="selectTShirtSizeTypes"
              v-model="peopleRecruitmentContractStore.applicant.tShirtSize"
              :rules="[
                rules.fieldRequired(
                  `${t('Msg_.tShirtSize')} ${t('Msg_.isRequired')}`,
                  0
                ),
              ]"
            />
          </v-col>
        </v-row>

        <v-row class="mt-12">
          <v-col cols="4" class="pt-0">
            <DropdownInput
              data-testid="recruitment-typeform1-main-hub-field"
              :label="`${t('Msg_.mainHub')}*`"
              :disabled="isFormDisabled"
              :clearable="false"
              item-text="name"
              item-value="id"
              :items="peopleStore.peopleHubList"
              :value="peopleRecruitmentContractStore.applicant.mainHub"
              @change="handleMainHubOptions($event)"
              :rules="[
                rules.fieldRequired(
                  `${t('Msg_.mainHub')} ${t('Msg_.isRequired')}`
                ),
              ]"
            />
          </v-col>
          <v-col cols="4" class="pt-0">
            <RegularTextInput
              data-testid="recruitment-typeform1-billing-hub-field"
              :label="t('Msg_.billingHub')"
              disabled
              :value="billingHubName"
            />
          </v-col>
          <v-col cols="4" class="pt-0">
            <RegularTextInput
              data-testid="recruitment-typeform1-company-field"
              :label="t('Msg_.company')"
              disabled
              :value="companyName"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pt-0">
            <DropdownInput
              data-testid="recruitment-typeform1-recruitment-type-dropdown"
              :label="`${t('Msg_.contractType')}*`"
              :disabled="isFormDisabled"
              :clearable="false"
              :items="selectContractTypes"
              v-model="
                peopleRecruitmentContractStore.applicant.contractTypeForKenbi
              "
              @change="handleCompensationType()"
              :rules="[
                rules.fieldRequired(
                  `${t('Msg_.contractType')} ${t('Msg_.isRequired')}`
                ),
              ]"
            />
          </v-col>
          <v-col cols="4" class="pt-0">
            <DropdownInput
              data-testid="recruitment-typeform1-compensation-type-dropdown"
              :label="t('Msg_.compensationType')"
              disabled
              clearable
              :items="selectCompensationTypes"
              v-model="compensationType"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pt-0">
            <DropdownInput
              data-testid="recruitment-typeform1-job-position-dropdown"
              :label="`${t('Msg_.jobPosition')}*`"
              :disabled="isFormDisabled"
              :clearable="false"
              :items="selectJobPosition"
              v-model="peopleRecruitmentContractStore.applicant.jobPosition"
              :rules="[
                rules.fieldRequired(
                  `${t('Msg_.jobPosition')} ${t('Msg_.isRequired')}`
                ),
              ]"
            />
          </v-col>
          <v-col cols="4" class="pt-0">
            <Combobox
              data-testid="recruitment-typeform1-additional-training-field"
              :label="`${t('Msg_.additionalTraining')}*`"
              :items="selectAdditionalTrainingType"
              :value="recruitAdditionalTraining"
              :disabled="isFormDisabled"
              :clearable="false"
              @click="handleAdditionalTraining($event)"
              :rules="[
                rules.fieldRequired(
                  `${t('Msg_.additionalTraining')} ${t('Msg_.isRequired')}`
                ),
              ]"
            />
          </v-col>
          <v-col cols="4" class="pt-0">
            <RegularTextInput
              data-testid="recruitment-typeform1-years-of-experience-field"
              :label="`${t('Msg_.yearsOfExperience')}*`"
              :disabled="isFormDisabled"
              v-model="
                peopleRecruitmentContractStore.applicant.yearsOfExperience
              "
              :rules="[
                rules.fieldRequired(
                  `${t('Msg_.yearsOfExperience')} ${t('Msg_.isRequired')}`,
                  0
                ),
              ]"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            v-for="(weekDay, key, index) in peopleRecruitmentContractStore
              .applicant.workingDays"
            :key="key"
            cols="auto"
            class="flex-grow-1 pt-0"
          >
            <SwitchControl
              :data-testid="`recruitment-typeform1-${key}-switch`"
              :label="t(`Action_.selectWeekDays[${index}]`)"
              :disabled="isFormDisabled"
              v-model="
                peopleRecruitmentContractStore.applicant.workingDays[key]
              "
              @change="handleTotalWeeklyWorkingDays()"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pt-0">
            <DatePicker
              data-testid="recruitment-typeform1-start-date-field"
              :label="`${t('Msg_.startDate')}*`"
              v-model="peopleRecruitmentContractStore.applicant.startDate"
              :disabled="isFormDisabled"
              :rules="[
                rules.fieldRequired(
                  `${t('Msg_.startDate')} ${t('Msg_.isRequired')}`
                ),
              ]"
            />
          </v-col>
          <v-col cols="4" class="pt-0">
            <RegularTextInput
              data-testid="recruitment-typeform1-weekly-working-hours-field"
              :label="`${t('Msg_.hourPerWeek')}*`"
              :disabled="isFormDisabled"
              v-model="
                peopleRecruitmentContractStore.applicant.weeklyWorkingHours
              "
              @change="handleCompensationType($event)"
              :rules="[
                rules.fieldRequired(
                  `${t('Msg_.hourPerWeek')} ${t('Msg_.isRequired')}`
                ),
              ]"
            />
          </v-col>
          <v-col cols="4" class="pt-0">
            <RegularTextInput
              data-testid="recruitment-typeform1-vacation-days-field"
              :label="t('Msg_.vacationDaysPerYear')"
              disabled
              v-model="vacationDaysPerYear"
            />
          </v-col>
        </v-row>

        <v-row class="mt-12">
          <v-col cols="6" class="pt-0">
            <DropdownInput
              data-testid="recruitment-typeform1-job-level-dropdown"
              :clearable="false"
              :items="selectJobLevelTypes"
              :label="`${t('Msg_.jobLevel')}*`"
              v-model="peopleRecruitmentContractStore.applicant.jobLevel"
              :disabled="isFormDisabled"
              :rules="[
                rules.fieldRequired(
                  `${t('Msg_.jobLevel')} ${t('Msg_.isRequired')}`,
                  0
                ),
              ]"
            />
          </v-col>
          <v-col cols="6" class="pt-0">
            <DropdownInput
              data-testid="recruitment-typeform1-experience-level-dropdown"
              :clearable="false"
              :items="selectExperienceLevelTypes"
              :label="`${t('Msg_.experienceLevel')}*`"
              v-model="peopleRecruitmentContractStore.applicant.experienceLevel"
              :disabled="isFormDisabled"
              :rules="[
                rules.fieldRequired(
                  `${t('Msg_.experienceLevel')} ${t('Msg_.isRequired')}`,
                  0
                ),
              ]"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" class="pt-0">
            <RegularTextInput
              data-testid="recruitment-typeform1-city-bonus-field"
              maxlength="12"
              icon="mdi-currency-eur"
              :label="`${t('Msg_.cityBonus')}*`"
              :value="
                helpers.convertValueToCurrency(
                  peopleRecruitmentContractStore.applicant.cityBonus
                )
              "
              :disabled="isFormDisabled"
              @change="
                updateCurrencyField(
                  'cityBonus',
                  helpers.convertValueToCurrency($event)
                )
              "
              @focus="
                updateCurrencyField(
                  'cityBonus',
                  helpers.convertCurrencyToFloat($event)
                )
              "
              :rules="[
                rules.fieldRequired(
                  `${t('Msg_.cityBonus')} ${t('Msg_.isRequired')}`,
                  0
                ),
              ]"
            />
          </v-col>
          <v-col cols="6" class="pt-0">
            <RegularTextInput
              data-testid="recruitment-typeform1-hourly-wage-field"
              maxlength="12"
              icon="mdi-currency-eur"
              :label="`${t('Msg_.hourlyWage')}*`"
              :value="
                helpers.convertValueToCurrency(
                  peopleRecruitmentContractStore.applicant.hourlyWage
                )
              "
              :disabled="isFormDisabled"
              @change="
                updateCurrencyField(
                  'hourlyWage',
                  helpers.convertValueToCurrency($event)
                )
              "
              @focus="
                updateCurrencyField(
                  'hourlyWage',
                  helpers.convertCurrencyToFloat($event)
                )
              "
              :rules="[
                rules.fieldRequired(
                  `${t('Msg_.hourlyWage')} ${t('Msg_.isRequired')}`,
                  0
                ),
              ]"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pt-0">
            <RegularTextInput
              data-testid="recruitment-typeform1-non-dynamic-allowance-field"
              :disabled="isFormDisabled"
              maxlength="12"
              icon="mdi-currency-eur"
              :label="`${t('Msg_.nonDynamicAllowance')}*`"
              :value="
                helpers.convertValueToCurrency(
                  peopleRecruitmentContractStore.applicant.nonDynamicAllowance
                )
              "
              @change="
                updateCurrencyField(
                  'nonDynamicAllowance',
                  helpers.convertValueToCurrency($event)
                )
              "
              @focus="
                updateCurrencyField(
                  'nonDynamicAllowance',
                  helpers.convertCurrencyToFloat($event)
                )
              "
              :rules="[
                rules.fieldRequired(
                  `${t('Msg_.nonDynamicAllowance')} ${t('Msg_.isRequired')}`,
                  0
                ),
              ]"
            />
          </v-col>
          <v-col cols="4" class="pt-0">
            <RegularTextInput
              :disabled="isFormDisabled"
              data-testid="recruitment-typeform1-care-allowance-field"
              maxlength="12"
              icon="mdi-currency-eur"
              :label="`${t('Msg_.careAllowance')}*`"
              :value="
                helpers.convertValueToCurrency(
                  peopleRecruitmentContractStore.applicant.careAllowance
                )
              "
              @change="
                updateCurrencyField(
                  'careAllowance',
                  helpers.convertValueToCurrency($event)
                )
              "
              @focus="
                updateCurrencyField(
                  'careAllowance',
                  helpers.convertCurrencyToFloat($event)
                )
              "
              :rules="[
                rules.fieldRequired(
                  `${t('Msg_.careAllowance')} ${t('Msg_.isRequired')}`,
                  0
                ),
              ]"
            />
          </v-col>
          <v-col cols="4" class="pt-0">
            <RegularTextInput
              data-testid="recruitment-typeform1-individual-allowance-field"
              :disabled="isFormDisabled"
              maxlength="12"
              icon="mdi-currency-eur"
              :label="`${t('Msg_.individualAllowance')}*`"
              :value="
                helpers.convertValueToCurrency(
                  peopleRecruitmentContractStore.applicant.individualAllowance
                )
              "
              @change="
                updateCurrencyField(
                  'individualAllowance',
                  helpers.convertValueToCurrency($event)
                )
              "
              @focus="
                updateCurrencyField(
                  'individualAllowance',
                  helpers.convertCurrencyToFloat($event)
                )
              "
              :rules="[
                rules.fieldRequired(
                  `${t('Msg_.individualAllowance')} ${t('Msg_.isRequired')}`,
                  0
                ),
              ]"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="mt-0">
            <RegularTextInput
              data-testid="recruitment-typeform1-monthly-base-salary-field"
              :disabled="isFormDisabled"
              :value="
                helpers.convertValueToCurrency(
                  peopleRecruitmentContractStore.applicant.monthlyBaseSalary
                )
              "
              maxlength="12"
              icon="mdi-currency-eur"
              :label="`${t('Msg_.monthlyBaseSalary')}*`"
              @change="
                updateCurrencyField(
                  'monthlyBaseSalary',
                  helpers.convertValueToCurrency($event)
                )
              "
              @focus="
                updateCurrencyField(
                  'monthlyBaseSalary',
                  helpers.convertCurrencyToFloat($event)
                )
              "
              :rules="[
                rules.fieldRequired(
                  `${t('Msg_.monthlyBaseSalary')} ${t('Msg_.isRequired')}`,
                  0
                ),
              ]"
            />
          </v-col>
          <v-col cols="4" class="mt-0">
            <RegularTextInput
              data-testid="recruitment-typeform1-thirteenth-salary-field"
              :disabled="isFormDisabled"
              maxlength="12"
              icon="mdi-currency-eur"
              :label="`${t('Msg_.13thSalary')}*`"
              :value="
                helpers.convertValueToCurrency(
                  peopleRecruitmentContractStore.applicant.thirteenthSalary
                )
              "
              @change="
                updateCurrencyField(
                  'thirteenthSalary',
                  helpers.convertValueToCurrency($event)
                )
              "
              @focus="
                updateCurrencyField(
                  'thirteenthSalary',
                  helpers.convertCurrencyToFloat($event)
                )
              "
              :rules="[
                rules.fieldRequired(
                  `${t('Msg_.13thSalary')} ${t('Msg_.isRequired')}`,
                  0
                ),
              ]"
            />
          </v-col>
          <v-col cols="4" class="mt-0">
            <RegularTextInput
              data-testid="recruitment-typeform1-annual-salary-field"
              :disabled="isFormDisabled"
              maxlength="12"
              icon="mdi-currency-eur"
              :label="`${t('Msg_.annualSalary')}*`"
              :value="
                helpers.convertValueToCurrency(
                  peopleRecruitmentContractStore.applicant.annualSalary
                )
              "
              @change="
                updateCurrencyField(
                  'annualSalary',
                  helpers.convertValueToCurrency($event)
                )
              "
              @focus="
                updateCurrencyField(
                  'annualSalary',
                  helpers.convertCurrencyToFloat($event)
                )
              "
              :rules="[
                rules.fieldRequired(
                  `${t('Msg_.annualSalary')} ${t('Msg_.isRequired')}`,
                  0
                ),
              ]"
            />
          </v-col>
        </v-row>

        <v-row class="mt-12">
          <v-col cols="4" class="pt-0">
            <DropdownInput
              data-testid="recruitment-typeform1-probation-dropdown"
              :clearable="false"
              :items="selectProbationType"
              :label="`${t('Msg_.probation')}*`"
              v-model="peopleRecruitmentContractStore.applicant.probation"
              :disabled="isFormDisabled"
              :rules="[
                rules.fieldRequired(
                  `${t('Msg_.probation')} ${t('Msg_.isRequired')}`
                ),
              ]"
            />
          </v-col>
          <v-col cols="4" class="pt-0">
            <DropdownInput
              data-testid="recruitment-typeform1-notice-during-probation-dropdown"
              :clearable="false"
              :items="selectProbationNoticePeriodType"
              :label="`${t('Msg_.noticePeriodDuringProbation')}*`"
              v-model="
                peopleRecruitmentContractStore.applicant
                  .noticePeriodDuringProbation
              "
              :disabled="isFormDisabled"
              :rules="[
                rules.fieldRequired(
                  `${t('Msg_.noticePeriodDuringProbation')} ${t(
                    'Msg_.isRequired'
                  )}`,
                  0
                ),
              ]"
            />
          </v-col>
          <v-col cols="4" class="pt-0">
            <DropdownInput
              data-testid="recruitment-typeform1-notice-after-probation-dropdown"
              :clearable="false"
              :items="selectProbationNoticePeriodAfterType"
              :label="`${t('Msg_.noticePeriodAfterProbation')}*`"
              v-model="
                peopleRecruitmentContractStore.applicant
                  .noticePeriodAfterProbation
              "
              :disabled="isFormDisabled"
              :rules="[
                rules.fieldRequired(
                  `${t('Msg_.noticePeriodAfterProbation')} ${t(
                    'Msg_.isRequired'
                  )}`,
                  0
                ),
              ]"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="pt-0">
            <RegularTextInput
              data-testid="recruitment-typeform1-interviewer-field"
              :label="t('Msg_.interviewer')"
              disabled
              :value="displayInterviewerValue"
            />
          </v-col>
          <v-col cols="4" class="pt-0">
            <RegularTextInput
              data-testid="recruitment-typeform1-device-field"
              :label="t('Msg_.device')"
              disabled
              v-model="peopleRecruitmentContractStore.applicant.device"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-container v-if="showEditInformationButton">
      <v-row class="mb-1">
        <v-col class="pl-1" v-if="isFormDisabled">
          <v-btn
            data-testid="recruitment-typeform1-edit-information-btn"
            class="main-action-btn ml-2"
            @click="toggleFormDisabled()"
          >
            {{ buttonText }}
          </v-btn>
        </v-col>
        <v-col v-if="!isFormDisabled" class="pl-1">
          <v-btn
            data-testid="recruitment-typeform1-cancel-btn"
            class="cancel-variant-btn ml-2"
            @click="checkAlteredForm()"
          >
            {{ t('Action_.buttons.cancel') }}
          </v-btn>
          <v-btn
            data-testid="recruitment-typeform1-save-changes-btn"
            class="main-action-btn ml-2"
            @click="updateTypeform()"
            :disabled="!isFormValid"
          >
            {{ buttonText }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script setup>
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import SwitchControl from '@/components/shared/customComponents/controls/switch-control.vue';
import DatePicker from '@/components/shared/customComponents/inputs/date-picker.vue';
import Combobox from '@/components/shared/customComponents/inputs/combobox-input.vue';
import languages from '../../locales/languages';
import helpers from '@/helpers/helpers';
import rules from '@/helpers/rules';
import { usePermissions } from '@/helpers/composables/permissions.js';
import { usePeopleCandidatesStore } from '@/store/pinia/people-candidates.store.js';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { usePeopleStore } from '@/store/pinia/people.store.js';
import { usePeopleRecruitmentContractStore } from '@/store/pinia/people-recruitment-contract.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { ref, computed, defineEmits } from 'vue';
import { useContractTools } from '@/helpers/composables/contract-tools.js';
import { onBeforeRouteLeave, useRouter } from 'vue-router/composables';
import { usePopupDialogStore } from '@/store/pinia/popup-dialog.store.js';

const peopleStore = usePeopleStore();
const peopleRecruitmentContractStore = usePeopleRecruitmentContractStore();
const emit = defineEmits(['update-applicant']);
const router = useRouter();
const popupDialogStore = usePopupDialogStore();
const loadingStore = useLoadingStore();
const peopleCandidatesStore = usePeopleCandidatesStore();
const { canUser } = usePermissions();
const { validateAdditionalTraining } = useContractTools();
const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const isFormDisabled = ref(true);
const billingHubName = ref(null);
const companyName = ref(null);
const anotherJob = ref(false);
const compensationType = ref(null);
const ignoreFormChanges = ref(false);
const nextRoute = ref(null);
const isFormValid = ref(true);

//  --- Methods ---
const checkAlteredForm = () => {
  if (isFormAltered.value) {
    displayConfirmationDialog();
  } else {
    toggleFormDisabled();
  }
};

const handleMainHubOptions = (mainHub) => {
  const source = peopleStore.peopleHubList.filter((hub) => {
    return hub.id === mainHub;
  });
  peopleRecruitmentContractStore.applicant.mainHub = mainHub;
  billingHubName.value = source[0]?.billingHubName || '';
  companyName.value = source[0]?.companyName || '';
};

const updateCurrencyField = (field, value) => {
  peopleRecruitmentContractStore.applicant[field] = value;
};

const toggleFormDisabled = () => {
  isFormDisabled.value = !isFormDisabled.value;
};

const updateTypeform = () => {
  toggleFormDisabled();
  emit('update-applicant');
};

const handleAdditionalTraining = (payload) => {
  const localAdditionalTraining = validateAdditionalTraining(payload);
  peopleRecruitmentContractStore.applicant.additionalTraining =
    localAdditionalTraining.map((item) => item.value);
};

const handleAnotherJobCompensationType = (anotherJob) => {
  if (!anotherJob) {
    peopleRecruitmentContractStore.applicant.contractTypeForOthers = 4; // compensation type = none
  } else {
    peopleRecruitmentContractStore.applicant.contractTypeForOthers = 1; // compensation type = full-time
  }
};

const handleHasAnotherJob = (anotherJobCompensationType) => {
  if (anotherJobCompensationType === 4) {
    // compensation type = none
    anotherJob.value = false;
  } else {
    anotherJob.value = true;
  }
};

const handleInitialFormData = () => {
  handleHasAnotherJob(
    peopleRecruitmentContractStore.applicant.contractTypeForOthers
  );
  handleTotalWeeklyWorkingDays();
  handleCompensationType();
  handleMainHubOptions(peopleRecruitmentContractStore.applicant.mainHub);
};

const handleCompensationType = () => {
  let compensationTypeSource;
  if (peopleRecruitmentContractStore.applicant.contractTypeForKenbi === 1) {
    // contractTypeForKenbi === Employee
    if (peopleRecruitmentContractStore.applicant.weeklyWorkingHours >= 38.5) {
      compensationTypeSource = 1; // compensationType = full-time
    } else {
      compensationTypeSource = 2; // compensationType = part-time
    }
  } else {
    compensationTypeSource = 4; // compensationType = none
  }
  compensationType.value = compensationTypeSource;
};

const displayConfirmationDialog = () => {
  const params = {
    type: 'confirmation',
    title: t('Action_.dialog.saveChanges.title'),
    message: t('Action_.dialog.saveChanges.body'),
    reinforcement: t('Action_.dialog.saveChanges.reinforcement'),
    actionBtnText: t('Action_.buttons.confirm'),
    actionBtn: discardChanges,
  };

  popupDialogStore.params = params;
  popupDialogStore.displayDialog = true;
};

const discardChanges = () => {
  ignoreFormChanges.value = true;
  if (nextRoute.value) {
    router.push(nextRoute.value);
  } else {
    restoreInitialData();
    toggleFormDisabled();
  }
};

const restoreInitialData = () => {
  peopleRecruitmentContractStore.applicant = {
    ...{},
    ...peopleRecruitmentContractStore.applicantInitialData,
  };
};

const displaySaveInProgressDialog = () => {
  const params = {
    type: 'regular',
    title: t('Msg_.workInProgress'),
    message: t('Msg_.waitToFinishTheProcess'),
  };

  popupDialogStore.params = params;
  popupDialogStore.displayDialog = true;
};

const totalWorkingDays = (workingDays) => {
  return Object.keys(workingDays).reduce(
    (total, day) => total + workingDays[day],
    0
  );
};

const handleTotalWeeklyWorkingDays = () => {
  const workingDays = totalWorkingDays(
    peopleRecruitmentContractStore.applicant.workingDays
  );
  if (workingDays < 7) {
    peopleRecruitmentContractStore.applicant.totalWeeklyWorkingDays =
      workingDays;
  }
};

// --- Computed ----
const disableAnotherJobCompensationType = computed(() => {
  return !anotherJob.value;
});

const selectProbationNoticePeriodType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectProbationNoticePeriodType'),
    peopleStore.peopleResources?.probationNoticePeriodTypes
  );
});

const selectProbationNoticePeriodAfterType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectProbationNoticePeriodAfterTypes'),
    peopleStore.peopleResources?.probationNoticePeriodAfterTypes
  );
});

const selectProbationType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectProbationType'),
    peopleStore.peopleResources?.probationTypes
  );
});

const selectJobLevelTypes = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectJobLevelTypes'),
    peopleStore.peopleResources?.jobLevelTypes
  );
});

const selectExperienceLevelTypes = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectExperienceLevelTypes'),
    peopleStore.peopleResources?.experienceLevelTypes
  );
});

const selectAdditionalTrainingType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectAdditionalTrainingTypes'),
    peopleStore.peopleResources?.additionalTrainingTypes
  );
});

const vacationDaysPerYear = computed(() => {
  const vacationDaysByWeeklyWorkDays = {
    0: 0,
    1: 6,
    2: 12,
    3: 18,
    4: 24,
    5: 30,
    6: 36,
  };
  return vacationDaysByWeeklyWorkDays[
    peopleRecruitmentContractStore.applicant.totalWeeklyWorkingDays
  ];
});

const selectJobPosition = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectQualificationType'),
    peopleStore.peopleResources?.typeFormOptionsQualificationTypes
  );
});

const selectLevelEducationType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectLevelOfEducationType'),
    peopleStore.peopleResources?.levelEducationTypes
  );
});

const selectBooleanOptions = computed(() => {
  const options = [
    { Name: t('Action_.radio.yes'), Value: true },
    { Name: t('Action_.radio.no'), Value: false },
  ];
  return helpers.translateAndAdaptToVuetify(tm('Action_.radio'), options);
});

const selectCompensationTypes = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectCompensationType'),
    peopleStore.peopleResources?.otherJobCompensationTypes
  );
});

const selectContractTypes = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectContractType'),
    peopleStore.peopleResources?.contractTypes
  );
});

const selectTShirtSizeTypes = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.tShirtSizeTypes'),
    peopleStore.peopleResources?.tShirtSizeTypes
  );
});

const buttonText = computed(() => {
  return !isFormDisabled.value
    ? t('Action_.buttons.saveChanges')
    : t('Action_.buttons.editInformation');
});

const isFormAltered = computed(() => {
  return !helpers.compareTwoObjects(
    peopleRecruitmentContractStore.applicant,
    peopleRecruitmentContractStore.applicantInitialData
  );
});

const recruitAdditionalTraining = computed(() => {
  return peopleRecruitmentContractStore.applicant.additionalTraining?.map(
    (item) => ({
      text: tm('Action_.selectAdditionalTrainingTypes')[item],
      value: item,
    })
  );
});

const isLoading = computed(() => {
  return loadingStore.isPartialLoading || loadingStore.isGlobalLoading;
});

const displayInterviewerValue = computed(() => {
  return `${peopleRecruitmentContractStore.applicant.interviewer} - ${peopleRecruitmentContractStore.applicant.responsibleOccupation}`;
});

const isHiringMenuSelected = computed(() => {
  return peopleCandidatesStore.selectedMenuItem === 'hiring';
});

const isHandoverMenuSelected = computed(() => {
  return peopleCandidatesStore.selectedMenuItem === 'handover';
});

const showEditInformationButton = computed(() => {
  if (
    (isHiringMenuSelected.value || isHandoverMenuSelected.value) &&
    !canUser(['People.Candidates.GenerateContract'])
  ) {
    return false;
  }
  return true;
});

// --- Lifecycke hooks ---
handleInitialFormData();

// --- Routing guards ---
onBeforeRouteLeave((to, _, next) => {
  if (isLoading.value) {
    displaySaveInProgressDialog();
  } else {
    if (!isFormAltered.value || ignoreFormChanges.value) {
      nextRoute.value = to;
    }
    if (nextRoute.value) {
      next();
    } else {
      nextRoute.value = to;
      displayConfirmationDialog(next);
    }
  }
});
</script>

<style lang="scss" scoped></style>
