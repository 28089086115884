import { render, staticRenderFns } from "./recruitment-typeform-2.vue?vue&type=template&id=4a131b12&scoped=true&"
import script from "./recruitment-typeform-2.vue?vue&type=script&setup=true&lang=js&"
export * from "./recruitment-typeform-2.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./recruitment-typeform-2.vue?vue&type=style&index=0&id=4a131b12&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a131b12",
  null
  
)

export default component.exports