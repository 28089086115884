import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        // primary colors
        appBackground: '#e9f1f6',
        mineBlack: '#0e2131',
        // green
        caribbeanLight: '#71ffca',
        caribbeanRegular: '#00d3af',
        caribbeanDark: '#009a6b',
        greenLight: '#D6FFFF',
        greenRegular: '#A3EFD1',
        greenDark: '#72BCA0',
        lowOpacityGreen: '#D6F6EE',

        // yellow
        yellowLight: '#FFFF99',
        yellowRegular: '#FFED00',
        yellowDark: '#B7A200',

        // red
        redDanger: '#DC3545',
        redError: '#DA2C2C',
        redErrorLight: '#FDCDCD',
        berryLight: '#ff589b',
        berryRegular: '#d3136d',
        berryDark: '#9c0042',
        lowOpacityRed: '#ffddea',
        // yale
        yaleLight: '#42579D',
        yaleRegular: '#324B8D',
        yaleDark: '#000642',

        // blue
        blueLight: '#69a8ff',
        blueRegular: '#007aff',
        blueDark: '#004fcb',
        blueDeep: '#002f6e',
        lowOpacityBlue: '#e4f2ff',

        // secondary colors
        petrol: '#00738C',
        nudeYale: '#54708F',
        nudeBerry: '#FDB29B',
        nudePetrol: '#C9EBBA',
        nudeYaleLight: '#BEE4EA',
        nudeSun: '#FFEBC7',

        // greyscale
        ashLight: '#fafafa',
        ashRegular: '#ededed',
        ashDark: '#dee2e6',
        greyLight: '#b5b5c3',
        greyRegular: '#9e9e9e',
        greyDark: '#666',
        darkBlueGrey: '#6c757d',
      },
    },
  },
});
