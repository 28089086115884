<template>
  <v-container data-testid="candidates-list" class="mt-2 fill-height">
    <v-row class="pb-0">
      <v-col cols="3">
        <SearchInput
          data-testid="candidates-list-search-field"
          :label="t('Msg_.searchCandidate')"
          :placeholder="t('Msg_.search')"
          v-model="pageOptions.searchTerm"
          @update-list="updateCandidatesList(true)"
        />
      </v-col>
      <v-col cols="2">
        <DropdownInput
          data-testid="candidates-list-hub-dropdown"
          :label="t('Msg_.hub')"
          item-text="name"
          item-value="id"
          :items="peopleStore.peopleHubList"
          v-model="pageOptions.hubId"
          @change="updateCandidatesList(true)"
        />
      </v-col>
    </v-row>
    <v-row class="fill-height">
      <v-col cols="4" class="pt-0">
        <CandidatesMenu @update-list="updateCandidatesList()" />
      </v-col>
      <v-col cols="8" class="pa-0">
        <v-container v-if="loadingStore.isPartialLoading" class="fill-height">
          <v-row>
            <v-col class="d-flex justify-center align-center">
              <LoadingAnimation dense />
            </v-col>
          </v-row>
        </v-container>
        <v-container v-else-if="!hasCandidatesData" class="fill-height">
          <v-row>
            <v-col class="d-flex justify-center align-center">
              <EmptyListMessage />
            </v-col>
          </v-row>
        </v-container>
        <v-container v-else-if="hasCandidatesData">
          <v-row>
            <v-col
              v-for="(candidate, index) in peopleCandidatesStore?.candidatesList
                ?.items"
              :key="candidate.id"
              cols="12"
              class="pt-0"
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  :data-testid="`recruit-item-${index}`"
                  :class="hover ? 'shadow-hover' : 'shadow-regular'"
                  class="rounded-lg mb-2"
                >
                  <RecruitmentListItem
                    :index="index"
                    type="candidates"
                    @update-candidates-list="updateCandidatesList()"
                  />

                  <v-divider></v-divider>

                  <CandidateActionRequired :index="index" />
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
          <Pagination
            data-testid="candidates-list-pagination"
            type="wide"
            class="mt-0"
            v-model="pagination"
            @update-list="updateCandidatesList()"
          />
        </v-container>
      </v-col>
    </v-row>

    <CandidateGenerateContract v-if="showCandidateGenerateContractDialog" />
  </v-container>
</template>

<script setup>
import CandidateGenerateContract from '@/components/people/peopleSharedComponents/recruitmentCards/candidates-generate-contract.vue';
import LoadingAnimation from '@/components/shared/customComponents/loadingAnimation/loading-animation.vue';
import EmptyListMessage from '@/components/shared/customComponents/emptyStates/empty-list-message.vue';
import RecruitmentListItem from '@/components/people/peopleSharedComponents/recruitmentCards/recruitment-list-item';
import CandidateActionRequired from '@/components/people/peopleSharedComponents/recruitmentCards/candidates-action-required';
import CandidatesMenu from '@/components/people/candidates/candidates-menu';
import Pagination from '@/components/shared/customComponents/paginations/pagination.vue';
import SearchInput from '@/components/shared/customComponents/inputs/search-input.vue';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';

import languages from '../locales/languages';
import { usePeopleRecruitmentContractStore } from '@/store/pinia/people-recruitment-contract.store.js';
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { usePeopleStore } from '@/store/pinia/people.store.js';
import { usePeopleCandidatesStore } from '@/store/pinia/people-candidates.store.js';
import { usePagination } from '@/helpers/composables/pagination.js';
import { useI18n } from 'vue-i18n-bridge';
import { ref, computed } from 'vue';

const peopleStore = usePeopleStore();
const peopleCandidatesStore = usePeopleCandidatesStore();
const loadingStore = useLoadingStore();
const peopleRecruitmentContractStore = usePeopleRecruitmentContractStore();
const { trackPage } = amplitudeComposable();
const { t } = useI18n({ locale: 'en', messages: languages });
const {
  pagination,
  validatePaginationData,
  validatePageOptions,
  updatePaginationData,
} = usePagination();

// --- Local variables ---
const showCandidateGenerateContractDialog = false;

const pageOptions = ref({
  searchTerm: '',
  hubId: '',
});

// --- Methods ---
const getSummary = async () => {
  const hubId = pageOptions.value.hubId;

  try {
    await peopleCandidatesStore.getSummary(hubId);
  } catch (error) {
    console.log(error);
  }
};

const getCandidatesList = async () => {
  peopleCandidatesStore.showProfileIndex = null;
  validatePaginationData();

  const payload = {
    pagination: pagination.value,
    pageOptions: pageOptions.value,
  };

  try {
    await peopleCandidatesStore.getCandidatesList(payload);
    updatePaginationData(peopleCandidatesStore.candidatesList);
  } catch (error) {
    console.log(error);
  }
};

const updateCandidatesList = (resetPagination = false) => {
  if (resetPagination) pagination.value.page = 1;
  peopleRecruitmentContractStore.selectedHubId = pageOptions.value.hubId;
  peopleCandidatesStore.$partialReset('showProfileIndex');
  validatePageOptions();
  validateFilterOptions();
  scrollUp();
  getSummary();
  getCandidatesList();
  trackPage(pageOptions.value.hubId, {
    routeName: `people.recruitment.listCandidates.${peopleCandidatesStore.selectedMenuItem}`,
  });
};

const validateFilterOptions = () => {
  if (!pageOptions.value.searchTerm) pageOptions.value.searchTerm = '';
  if (!pageOptions.value.hubId) pageOptions.value.hubId = '';
};

const scrollUp = () => {
  window.scrollTo(0, 0);
};

// --- Computed ---
const hasCandidatesData = computed(() => {
  return peopleCandidatesStore.candidatesList?.items?.length;
});

// --- Lifecycle hooks ---
updateCandidatesList();
</script>

<style scoped lang="scss">
#user-initials {
  font-size: 1.6rem;
  color: $mine-black;
}
</style>
