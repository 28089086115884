<template>
  <v-row align="center" class="py-2" data-testid="pagination-modal">
    <v-col v-if="type !== 'narrow'" class="d-flex align-center" cols="5">
      <p class="mr-2 mb-0 greyLight--text">{{ t('Msg_.showing') }}</p>
      <div class="page-select-container">
        <v-select
          flat
          dense
          solo
          :outlined="outlined"
          :hide-details="true"
          item-color="blueRegular"
          :items="value.pageSizes"
          :value="value.pageSize"
          color="blueRegular"
          background-color="white"
          @input="emitPageSize($event)"
        >
        </v-select>
      </div>
      <p class="ml-2 mb-0 greyLight--text">
        {{ t('Msg_.of') }}
        <span class="mineBlack--text ml-1">{{ value.total }}</span>
      </p>
    </v-col>
    <v-spacer v-if="type === 'wide'"></v-spacer>
    <v-col
      cols="7"
      :class="type === 'wide' ? 'd-flex justify-end' : 'd-flex justify-start'"
    >
      <v-pagination
        class="mr-n2"
        :value="value.page"
        :length="value && value.length ? value.length : 1"
        :total-visible="6"
        color="blueRegular"
        next-icon="mdi-chevron-double-right"
        prev-icon="mdi-chevron-double-left"
        @input="emitSelectedPage($event)"
      />
    </v-col>
  </v-row>
</template>

<script setup>
import languages from '../../locales/languages';
import { useI18n } from 'vue-i18n-bridge';
import { defineProps, defineEmits } from 'vue';

const emit = defineEmits(['input', 'update-list']);
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  type: {
    type: String,
    required: true,
  },
  value: {
    type: Object,
    required: true,
  },
  outlined: {
    type: Boolean,
    required: false,
    default: false,
  },
});

// --- Methods ---
const emitSelectedPage = (input) => {
  emit('input', { ...props.value, page: input });
  emit('update-list');
};

const emitPageSize = (input) => {
  emit('input', { ...props.value, pageSize: input });
  emit('update-list');
};
</script>

<style scoped lang="scss">
.page-select-container {
  width: 73px !important;
}

::v-deep .v-pagination {
  display: flex;
  justify-content: flex-end;

  .v-pagination__item {
    box-shadow: none !important;
    font-size: 14px !important;
  }

  .v-pagination__navigation {
    box-shadow: none !important;
  }

  .v-icon {
    font-size: 18px !important;
  }
}
</style>
