import { defineStore } from 'pinia';
import { useMainStore } from '@/store/pinia/main.store.js';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { ref } from 'vue';

export const usePeopleStore = defineStore('people', () => {
  const mainStore = useMainStore();
  const loadingStore = useLoadingStore();

  // --- State ---
  const peopleHubList = ref(null);
  const peopleResources = ref(null);

  // --- Methods ---
  const getPeopleHubList = async () => {
    const params = {
      endpoint: `v2/api/people/resources/hubs`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startGlobalLoading('getPeopleHubList');
    try {
      const res = await mainStore.request(params);
      peopleHubList.value = res;
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endGlobalLoading('getPeopleHubList');
    }
  };

  const getPeopleResources = async () => {
    const params = {
      endpoint: `v2/api/people/resources`,
      configs: {
        method: 'GET',
      },
    };

    loadingStore.startGlobalLoading('getPeopleResources');
    try {
      const res = await mainStore.request(params);
      peopleResources.value = res;
    } catch (error) {
      return Promise.reject(error);
    } finally {
      loadingStore.endGlobalLoading('getPeopleResources');
    }
  };

  return {
    peopleHubList,
    peopleResources,
    getPeopleHubList,
    getPeopleResources,
  };
});
