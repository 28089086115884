<template>
  <v-container>
    <v-row>
      <v-col
        :data-testid="`recruitment-hiring-process-test-day-${index}-title`"
        class="section-title d-flex align-self-center"
      >
        {{ `${t('Msg_.testDay')} # ${index + 1}` }}
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4">
        <DropdownInput
          :data-testid="`recruitment-hiring-process-test-day-${index}-reviewer-field`"
          :label="t('Msg_.testDayReviewer')"
          :items="processResponsibleList"
          :value="testDay.reviewerId"
          disabled
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4" class="pt-0">
        <RegularTextInput
          :data-testid="`recruitment-hiring-process-test-day-${index}-test-day-field`"
          :label="t('Msg_.testDay')"
          :value="helpers.formatDate(testDay.testDayStartsAt)"
          disabled
        />
      </v-col>
      <v-col cols="4" class="pt-0">
        <RegularTextInput
          :data-testid="`recruitment-hiring-process-test-day-${index}-start-time-field`"
          :label="t('Msg_.testDayStartTime')"
          :value="getTestDayTime(testDay.testDayStartsAt)"
          disabled
        />
      </v-col>
      <v-col cols="4" class="pt-0">
        <RegularTextInput
          :data-testid="`recruitment-hiring-process-test-day-${index}-end-time-field`"
          :label="t('Msg_.testDayEndTime')"
          :value="getTestDayTime(testDay.testDayEndsAt)"
          disabled
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';

import languages from '../../locales/languages';
import helpers from '@/helpers/helpers';
import { usePeopleCandidatesStore } from '@/store/pinia/people-candidates.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { defineProps, computed } from 'vue';

const peopleCandidatesStore = usePeopleCandidatesStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  testDay: {
    type: Object,
    default: () => {},
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
});

// --- Methods ---
const getTestDayTime = (testDay) => {
  return testDay.substring(11, 16);
};

// --- Computed ---
const processResponsibleList = computed(() => {
  return peopleCandidatesStore.candidatesList.items[
    peopleCandidatesStore.showProfileIndex
  ].processResponsibleList;
});
</script>

<style></style>
