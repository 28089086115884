<template>
  <div>
    <v-container class="pa-8">
      <v-row class="d-flex align-center" dense>
        <v-col
          :data-testid="`recruitment-item-${props.index}-full-name`"
          class="section-title pr-2"
        >
          <span>
            {{ recruitFullName }}
          </span>
          <span
            v-if="type === 'candidates'"
            :data-testid="`recruitment-item-${props.index}-status`"
            class="section-title candidate-status pl-0"
          >
            {{ filterBySelectedMenu.status }}
          </span>
          <v-icon
            v-if="showWarningIcon"
            :data-testid="`recruitment-item-${props.index}-warning-info`"
            color="redDanger"
            size="16"
            class="mr-5"
          >
            mdi-alert-outline
          </v-icon>
        </v-col>
        <v-col cols="auto" class="align-self-start">
          <v-menu v-if="type === 'applicants'" bottom left offset-x>
            <template #activator="{ on, attrs }">
              <IconButton
                :data-testid="`recruitment-item-${props.index}-option-dots-btn`"
                :bind="attrs"
                :on="on"
                class="icon-btn"
                icon="mdi-dots-vertical"
              />
            </template>
            <v-list class="py-1 px-0">
              <v-list-item>
                <ButtonWithIcon
                  :data-testid="`recruitment-item-${props.index}-delete-btn`"
                  class="pa-2 mx-0 default-btn d-flex justify-start"
                  icon="mdi-delete"
                  :text="t('Action_.buttons.deleteApplicant')"
                  color="redDanger"
                  :only-text="true"
                  width="100%"
                  @click="toggleDisplayDeleteDialog()"
                />
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu v-if="showRecruitMenuOptions" bottom left offset-x>
            <template #activator="{ on, attrs }">
              <IconButton
                :data-testid="`recruitment-item-${props.index}-option-dots-btn`"
                :bind="attrs"
                :on="on"
                class="icon-btn"
                icon="mdi-dots-vertical"
              />
            </template>
            <v-list class="py-1 px-0">
              <v-list-item
                v-for="(optionBtn, optionIndex) in filterBySelectedMenu.options"
                :key="optionIndex"
              >
                <ButtonWithIcon
                  :data-testid="`${optionBtn.dataTestId}-${props.index}`"
                  class="pa-2 mx-0 default-btn d-flex justify-start"
                  :icon="optionBtn.icon"
                  :text="t(optionBtn.text)"
                  :color="optionBtn.color"
                  :only-text="true"
                  width="100%"
                  @click="handleDotsButtonOptions(optionBtn.action)"
                />
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            v-if="type === 'applicants'"
            :data-testid="`recruitment-item-${props.index}-confirm-candidate-btn`"
            class="main-action-btn ml-2"
            :disabled="isApplicantConfirmCandidateBtnDisabled"
            @click="handleActionBtn()"
          >
            {{ t('Action_.buttons.confirmCandidate') }}
          </v-btn>

          <v-btn
            v-if="showCandidatesBtns && showRecruitMenuOptions"
            :data-testid="`${candidatesActionButton.dataTestId}-${props.index}`"
            :class="candidatesActionButton.class"
            class="ml-2"
            @click="handleActionBtn()"
            :disabled="isCandidateActionBtnDisabled"
          >
            {{ t(candidatesActionButton.text) }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="d-flex align-center section-detail">
        <v-col cols="auto" class="d-flex align-center">
          <v-icon class="pr-2" color="greyRegular" small>
            mdi-phone mdi-rotate-270
          </v-icon>
          <p
            :data-testid="`recruitment-item-${props.index}-phone-number-field`"
            class="mb-0"
          >
            {{ recruit.applicant.personalPhoneNumber }}
          </p>
        </v-col>
        <v-col class="d-flex align-center" cols="auto">
          <v-icon class="pr-2" color="greyRegular" small>
            mdi-email-outline
          </v-icon>
          <p
            :data-testid="`recruitment-item-${props.index}-personal-email`"
            class="mb-0"
          >
            {{ recruit.applicant.personalEmail }}
          </p>
        </v-col>
        <v-col cols="auto">
          {{ recruitJobPositionAndContractType }}
        </v-col>
      </v-row>

      <SignatureStatus
        v-if="showSignatureStatus"
        class="mt-4"
        :employee-signature="recruit.signedByEmployee"
        :management-signature="recruit.signedByKenbi"
      />

      <v-row>
        <v-col class="d-flex justify-end">
          <ButtonWithIcon
            :data-testid="`recruitment-item-${props.index}-view-profile-btn`"
            class="default-btn"
            :icon="viewProfileIcon"
            :text="viewProfileBtnText"
            @click="startProfileDisplayProcess()"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-expand-transition>
      <RecruitmentProfile
        :data-testid="`recruitment-item-${props.index}-contract-data`"
        @display-generate-new-contract-dialog="
          toggleGenereteNewContractDialog()
        "
        class="pa-3"
        v-if="isProfileDisplayed"
        :recruitment-id="recruit.id"
      />
    </v-expand-transition>

    <ApplicantsStartProcessDialog
      v-if="displayDialog.applicants"
      data-testid="applicants-start-process-dialog"
      :recruit="recruit"
      @close-dialog="startDisplayDialogProcess(false)"
    />
    <CandidatesProcessDialog
      v-if="displayDialog.rejected || displayDialog.approved"
      data-testid="candidates-engage-process-dialog"
      @send-candidate-to-review="sendCandidateToReview()"
      @candidate-end-process="candidateEndProcess()"
      @close-dialog="startDisplayDialogProcess(false)"
    />
    <CandidatesHandoverDialog
      v-if="displayDialog.handover"
      @send-candidate-to-hr="sendCandidateToHR()"
      @candidate-end-process="candidateEndProcess()"
      @close-dialog="startDisplayDialogProcess(false)"
    />
    <CandidatesHiringSendContractDialog
      v-if="displayDialog.hiringSendContract"
      @send-candidate-contract="sendCandidateContract()"
      @close-dialog="startDisplayDialogProcess(false)"
    />
    <CandidatesHiringContractIsSignedDialog
      v-if="displayDialog.hiringContractIsSigned"
      @upload-signed-contract="uploadSignedContract($event)"
      @close-dialog="startDisplayDialogProcess(false)"
    />
    <CandidatesHiringTurnIntoEmployeeDialog
      v-if="displayDialog.hiringTurnIntoEmployee"
      @turn-into-employee="turnIntoEmployee()"
      @close-dialog="startDisplayDialogProcess(false)"
    />
    <RecruitmentGenerateContractDialog
      @close-dialog="toggleGenereteNewContractDialog()"
      @generate-new-candidate-contract="generateNewCandidateContract()"
      v-if="showGenereteNewContractDialog"
    />
  </div>
</template>

<script setup>
import IconButton from '@/components/shared/customComponents/buttons/icon-button.vue';
import ButtonWithIcon from '@/components/shared/customComponents/buttons/button-with-icon.vue';
import RecruitmentProfile from '@/components/people/contracts/contractRecruitment/recruitment-profile.vue';
import RecruitmentGenerateContractDialog from '@/components/people/contracts/contractRecruitment/recruitment-generate-contract-dialog';
import ApplicantsStartProcessDialog from './applicants-start-process-dialog.vue';
import CandidatesProcessDialog from './candidates-process-dialog.vue';
import CandidatesHandoverDialog from './candidates-handover-dialog.vue';
import CandidatesHiringSendContractDialog from './candidates-hiring-send-contract-dialog.vue';
import CandidatesHiringContractIsSignedDialog from './candidates-hiring-contract-is-signed-dialog.vue';
import CandidatesHiringTurnIntoEmployeeDialog from './candidates-hiring-turn-into-employee-dialog.vue';
import SignatureStatus from '@/components/shared/digitalSignature/digital-signature-status';

import languages from '../../locales/languages';
import helpers from '@/helpers/helpers';
import { usePermissions } from '@/helpers/composables/permissions.js';
import { usePopupDialogStore } from '@/store/pinia/popup-dialog.store.js';
import { usePeopleRecruitmentContractStore } from '@/store/pinia/people-recruitment-contract.store.js';
import { usePeopleCandidatesStore } from '@/store/pinia/people-candidates.store.js';
import { usePeopleApplicantsStore } from '@/store/pinia/people-applicants.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { ref, defineProps, computed, defineEmits } from 'vue';

const popupDialogStore = usePopupDialogStore();
const peopleRecruitmentContractStore = usePeopleRecruitmentContractStore();
const peopleApplicantsStore = usePeopleApplicantsStore();
const peopleCandidatesStore = usePeopleCandidatesStore();
const emit = defineEmits(['update-applicants-list', 'update-candidates-list']);
const { canUser } = usePermissions();
const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const optionButtons = [
  {
    dataTestId: 'recruitment-item-download-contract-btn',
    icon: 'mdi-download',
    text: 'Action_.buttons.downloadContract',
    color: 'blueRegular',
    action: 'downloadContract',
  },
  {
    dataTestId: 'recruitment-item-generate-contract-btn',
    icon: 'mdi-cached',
    text: 'Action_.buttons.generateContract',
    color: 'blueRegular',
    action: 'generateContract',
  },
  {
    dataTestId: 'recruitment-item-obtain-url-to-sign-btn',
    icon: 'mdi-share-variant-outline',
    text: 'Action_.buttons.obtainUrlToSign',
    color: 'blueRegular',
    action: 'obtainUrlToSign',
  },
  {
    dataTestId: 'recruitment-item-download-job-offer-btn',
    icon: 'mdi-download',
    text: 'Action_.buttons.downloadJobOffer',
    color: 'blueRegular',
    action: 'downloadJobOffer',
  },
  {
    dataTestId: 'recruitment-item-missing-signature-btn',
    icon: 'mdi-text-box-remove-outline',
    text: 'Action_.buttons.missingSignature',
    color: 'blueRegular',
    action: 'missingSignature',
  },
  {
    dataTestId: 'recruitment-item-download-signed-contract-btn',
    icon: 'mdi-download',
    text: 'Action_.buttons.downloadSignedContract',
    color: 'blueRegular',
    action: 'downloadSignedContract',
  },
  {
    dataTestId: 'recruitment-item-end-process-btn',
    icon: 'mdi-close',
    text: 'Action_.buttons.endProcess',
    color: 'redDanger',
    action: 'endProcess',
  },
];

const actionButtons = {
  approved: {
    dataTestId: 'recruitment-item-ready-for-contract-btn',
    text: 'Action_.buttons.readyForContract',
    class: 'main-action-btn',
  },
  rejected: {
    dataTestId: 'recruitment-item-end-process-btn',
    text: 'Action_.buttons.endProcess',
    class: 'reject-cancel',
  },
  engage: {
    dataTestId: 'recruitment-item-ready-for-contract-review-btn',
    text: 'Action_.buttons.readyForContractReview',
    class: 'main-action-btn',
  },
  handover: {
    dataTestId: 'recruitment-item-ready-for-contract-review-btn',
    text: 'Action_.buttons.readyForContractReview',
    class: 'main-action-btn',
  },
  hiringReviewContract: {
    dataTestId: 'recruitment-item-review-contract-btn',
    text: 'Action_.buttons.reviewContract',
    class: 'main-action-btn',
  },
  hiringSendContract: {
    dataTestId: 'recruitment-item-send-contract-btn',
    text: 'Action_.buttons.sendContract',
    class: 'main-action-btn',
  },
  hiringContractIsSigned: {
    dataTestId: 'recruitment-item-contract-is-signed-btn',
    text: 'Action_.buttons.contractIsSigned',
    class: 'main-action-btn',
  },
  hiringTurnIntoEmployee: {
    dataTestId: 'recruitment-item-turn-into-employee-btn',
    text: 'Action_.buttons.turnIntoEmployee',
    class: 'main-action-btn',
  },
  hiring: {
    generateContract: {
      dataTestId: 'recruitment-item-generate-contract-btn',
      text: 'Action_.buttons.generateContract',
      class: 'main-action-btn',
    },
    contractNotReadyYet: {
      dataTestId: 'recruitment-item-contract-not-ready-yet-btn',
      text: 'Action_.buttons.contractNotReadyYet',
      class: 'main-action-btn',
    },
    reviewContract: {
      dataTestId: 'recruitment-item-review-contract-btn',
      text: 'Action_.buttons.reviewContract',
      class: 'main-action-btn',
    },
    sendContract: {
      dataTestId: 'recruitment-item-send-contract-btn',
      text: 'Action_.buttons.sendContract',
      class: 'main-action-btn',
    },
    waitingForSignature: {
      dataTestId: 'recruitment-item-wait-for-signature-btn',
      text: 'Msg_.waitingForCandidateSignature',
      class: 'main-action-btn',
    },
    payrollIformation: {
      dataTestId: 'recruitment-item-payroll-information-btn',
      text: 'Msg_.payrollInformation',
      class: 'main-action-btn',
    },
    waitingFirstDay: {
      dataTestId: 'recruitment-item-waiting-first-day-btn',
      text: 'Msg_.waitingFirstDay',
      class: 'main-action-btn',
    },
    turnIntoEmployee: {
      dataTestId: 'recruitment-item-turn-into-employee-btn',
      text: 'Action_.buttons.turnIntoEmployee',
      class: 'main-action-btn',
    },
    contractIsSigned: {
      dataTestId: 'recruitment-item-contract-is-signed-btn',
      text: 'Action_.buttons.contractIsSigned',
      class: 'main-action-btn',
    },
  },
};

const displayDialog = ref({
  applicants: false,
  engage: false,
  approved: false,
  rejected: false,
  handover: false,
  hiringSendContract: false,
  hiringContractIsSigned: false,
  hiringTurnIntoEmployee: false,
});

const showGenereteNewContractDialog = ref(false);

// --- Props ---
const props = defineProps({
  index: {
    type: Number,
    default: null,
  },
  type: {
    type: String,
    default: null,
    required: true,
  },
});

// --- Methods ---
const turnIntoEmployee = async () => {
  startDisplayDialogProcess(false);

  try {
    await peopleCandidatesStore.turnIntoEmployee(recruit.value.id);
    emit('update-candidates-list');
  } catch (error) {
    console.log(error);
  }
};

const uploadSignedContract = async (contractFile) => {
  startDisplayDialogProcess(false);

  const payload = {
    candidateId: recruit.value.id,
    contractFile,
  };

  try {
    await peopleCandidatesStore.uploadSignedContract(payload);
    emit('update-candidates-list');
  } catch (error) {
    console.log(error);
  }
};

const generateNewCandidateContract = () => {
  toggleGenereteNewContractDialog();
  sendCandidateToHR();
};

const toggleGenereteNewContractDialog = () => {
  showGenereteNewContractDialog.value = !showGenereteNewContractDialog.value;
};

const sendCandidateContract = async () => {
  startDisplayDialogProcess(false);

  try {
    await peopleCandidatesStore.sendCandidateContract(recruit.value.id);
    emit('update-candidates-list');
  } catch (error) {
    console.log(error);
  }
};

const sendCandidateToReview = async () => {
  startDisplayDialogProcess(false);
  const status = findCandidateStatus();
  const payload = {
    status: status,
    candidateId: recruit.value.id,
  };

  try {
    await peopleCandidatesStore.sendCandidateToReview(payload);
    emit('update-candidates-list');
  } catch (error) {
    console.log(error);
  } finally {
    peopleCandidatesStore.rejectingCandidate = false;
  }
};

const findCandidateStatus = () => {
  const countTestDayByResult = recruit.value.testDays.reduce(
    (candidate, obj) => {
      const result = obj.testDayResult;
      candidate[result] = (candidate[result] || 0) + 1;
      return candidate;
    },
    {}
  );

  if (countTestDayByResult[55]) return 55; // rejected
  if (countTestDayByResult[47]) return 47; // no show
  return 50; // approved
};

const handleDotsButtonOptions = (option) => {
  peopleCandidatesStore.rejectingCandidate = true;
  switch (option) {
    case 'endProcess':
      handleEndProcess();
      break;
    case 'generateContract':
      generateCandidateContract();
      break;
    case 'downloadJobOffer':
      downloadDocument(recruit.value.jobOffer.documentPath);
      break;
    case 'downloadContract':
      downloadDocument(recruit.value.contract.documentPath);
      break;
    case 'obtainUrlToSign':
      obtainUrlToSign();
      break;
    case 'downloadSignedContract':
      downloadDocument(recruit.value.uploadedContractPath);
      break;
  }
};

const obtainUrlToSign = () => {
  const params = {
    type: 'regular',
    link: recruit.value.candidateSignatureUrl,
    title: t('Msg_.contractUrlReadyToCopy'),
  };

  popupDialogStore.params = params;
  popupDialogStore.displayDialog = true;
};

const downloadDocument = (documentPath) => {
  window.open(
    `${process.env.VUE_APP_LOGIN_API}/v2/api/Documents/download?url=${documentPath}&download=false`
  );
};

const generateCandidateContract = async () => {
  try {
    await peopleCandidatesStore.generateCandidateContract(recruit.value.id);
    emit('update-applicants-list');
  } catch (error) {
    console.log(error);
  }
};

const handleEndProcess = () => {
  peopleCandidatesStore.rejectingCandidate = true;
  startDisplayDialogProcess(true, 'endProcess');
};

const deleteApplicant = async () => {
  try {
    await peopleApplicantsStore.deleteApplicant(recruit.value.id);
    emit('update-applicants-list');
  } catch (error) {
    console.log(error);
  }
};

const toggleDisplayDeleteDialog = () => {
  const params = {
    type: 'confirmation',
    title: t('Action_.dialog.deleteApplicant.title'),
    message: recruitFullName.value,
    reinforcement: t('Action_.dialog.deleteApplicant.reinforcement'),
    actionBtnText: t('Action_.buttons.deleteIt'),
    actionBtn: deleteApplicant,
  };

  popupDialogStore.params = params;
  popupDialogStore.displayDialog = true;
};

const startDisplayDialogProcess = (value, hiringType) => {
  peopleCandidatesStore.candidateIndex = props.index;
  if (value) {
    handleDisplayDialog(value, hiringType);
  } else {
    peopleCandidatesStore.rejectingCandidate = false;
    handleCloseDialog();
  }
};

const handleCloseDialog = () => {
  for (let key in displayDialog.value) {
    displayDialog.value[key] = false;
  }
};

const handleDisplayDialog = (value, hiringType) => {
  if (props.type === 'applicants') {
    displayDialog.value.applicants = value;
  } else {
    toggleCandidatesDisplayDialog(value, hiringType);
  }
};

const toggleCandidatesDisplayDialog = (openDialog, hiringType) => {
  if (isHiringMenuSelected.value) {
    switch (hiringType) {
      case 'sendContract':
        displayDialog.value.hiringSendContract = openDialog;
        break;
      case 'turnIntoEmployee':
        displayDialog.value.hiringTurnIntoEmployee = openDialog;
        break;
      case 'endProcess':
        displayDialog.value.rejected = openDialog;
        break;
      case 'contractIsSigned':
        displayDialog.value.hiringContractIsSigned = openDialog;
        break;
    }
  } else {
    displayDialog.value[filterBySelectedMenu.value.label] = openDialog;
  }
};

const handleActionBtn = async () => {
  if (isCandidatesHiringMenuSelected.value) {
    switch (filterBySelectedMenu.value.label) {
      case 'reviewContract':
        downloadDocument(recruit.value.generatedContractPath);
        recruit.value.clickedOnReviewContract = true;
        break;
      case 'sendContract':
        startDisplayDialogProcess(true, 'sendContract');
        break;
      case 'generateContract':
        await peopleCandidatesStore.generateCandidateContract(recruit.value.id);
        emit('update-candidates-list');
        break;
      case 'turnIntoEmployee':
        startDisplayDialogProcess(true, 'turnIntoEmployee');
        break;
      case 'contractIsSigned':
        startDisplayDialogProcess(true, 'contractIsSigned');
        break;
    }
  } else {
    startDisplayDialogProcess(true);
  }
};

const getJobPosition = () => {
  return helpers.getTranslationById(
    tm('Action_.selectQualificationType'),
    recruit.value.applicant.jobPosition
  );
};

const startProfileDisplayProcess = () => {
  peopleRecruitmentContractStore.recruitType = props.type;
  initateRecruitData();
  assignRecruitInitialData();
  handleProfileDisplay();
};

const handleProfileDisplay = () => {
  if (props.type === 'applicants') {
    peopleApplicantsStore.showProfileIndex = isProfileDisplayed.value
      ? null
      : props.index;
  } else {
    peopleCandidatesStore.showProfileIndex = isProfileDisplayed.value
      ? null
      : props.index;
  }
};

const initateRecruitData = () => {
  peopleRecruitmentContractStore.applicantId = recruit.value.id;
  peopleRecruitmentContractStore.applicant = {
    ...{},
    ...recruit.value.applicant,
  };
  peopleRecruitmentContractStore.documents = {
    ...{},
    ...recruit.value.documents,
  };
  if (recruit.value.payroll) {
    peopleRecruitmentContractStore.payroll = {
      ...{},
      ...recruit.value.payroll,
    };
  }
  peopleRecruitmentContractStore.processStatusHistory = [
    ...[],
    ...recruit.value.processStatusHistory,
  ];
  peopleRecruitmentContractStore.recruitData = { ...{}, ...recruit.value };
};

const assignRecruitInitialData = () => {
  peopleRecruitmentContractStore.applicantInitialData = {
    ...{},
    ...recruit.value.applicant,
  };
  peopleRecruitmentContractStore.payrollInitialData = {
    ...{},
    ...recruit.value.payroll,
  };
};

const filterRecruitMenuOptionsByIndex = (indexes) => {
  return optionButtons.filter((opt, index) => indexes.indexOf(index) >= 0);
};

const getContractType = () => {
  return helpers.getTranslationById(
    tm('Action_.selectContractType'),
    recruit.value.applicant.contractTypeForKenbi
  );
};

const candidateEndProcess = async () => {
  startDisplayDialogProcess(false);
  try {
    await peopleCandidatesStore.candidateEndProcess(recruit.value.id);
    emit('update-candidates-list');
  } catch (error) {
    console.log(error);
  } finally {
    peopleCandidatesStore.rejectingCandidate = false;
  }
};

const sendCandidateToHR = async () => {
  startDisplayDialogProcess(false);
  try {
    await peopleCandidatesStore.sendCandidateToHR(recruit.value.id);
    emit('update-candidates-list');
  } catch (error) {
    console.log(error);
  } finally {
    peopleCandidatesStore.rejectingCandidate = false;
  }
};

// --- Computed ---
const showSignatureStatus = computed(() => {
  return (
    isPayrollInformationCandidateStatus.value ||
    isWaitingFirstDayCandidateStatus.value ||
    isWaitingForCandidateSignatureStatus.value
  );
});

const isPayrollInformationCandidateStatus = computed(() => {
  return recruit.value.processStatus === 67;
});

const isWaitingFirstDayCandidateStatus = computed(() => {
  return recruit.value.processStatus === 70;
});

const isWaitingForCandidateSignatureStatus = computed(() => {
  return recruit.value.processStatus === 65;
});

const isCandidateActionBtnDisabled = computed(() => {
  if (isHiringMenuSelected.value) {
    return isHiringActionBtnDisabledByStatus.value;
  }
  return isActionBtnDisableByStatus.value;
});

const isHiringActionBtnDisabledByStatus = computed(() => {
  if (
    isContractNotReadyLabel.value ||
    isTurnIntoEmployeeLabelAndDoesntHavePayrollYet.value ||
    (!hasCandidatureSignatureUrl.value && isContractSignedLabel.value)
  ) {
    return true;
  }
  return false;
});

const hasCandidatureSignatureUrl = computed(() => {
  return recruit.value.candidateSignatureUrl;
});

const isContractSignedLabel = computed(() => {
  return (
    actionButtons.hiring[filterBySelectedMenu.value.label]?.text ===
    'Action_.buttons.contractIsSigned'
  );
});

const isContractNotReadyLabel = computed(() => {
  return (
    actionButtons.hiring[filterBySelectedMenu.value.label]?.text ===
    'Action_.buttons.contractNotReadyYet'
  );
});

const isTurnIntoEmployeeLabelAndDoesntHavePayrollYet = computed(() => {
  return (
    actionButtons.hiring[filterBySelectedMenu.value.label]?.text ===
      'Action_.buttons.turnIntoEmployee' && !recruit.value.payroll
  );
});

const isActionBtnDisableByStatus = computed(() => {
  switch (actionButtons[filterBySelectedMenu.value.label]?.text) {
    case 'Action_.buttons.readyForContract':
      return isReadyForContractBtnDisabled.value;
    case 'Action_.buttons.readyForContractReview':
      return isReadyForContractReviewBtnDisabled.value;
    default:
      return false;
  }
});

const isHiringMenuSelected = computed(() => {
  return peopleCandidatesStore.selectedMenuItem === 'hiring';
});

const recruitJobPositionAndContractType = computed(() => {
  let basicInformation = getJobPosition();
  if (recruit.value.applicant.contractTypeForKenbi) {
    basicInformation = `${basicInformation} • ${getContractType()}`;
  }
  return basicInformation;
});

const showWarningIcon = computed(() => {
  return (
    (recruit.value.isToGenerateContract ||
      recruit.value.isToUploadRequiredDocuments) &&
    (isHandoverMenuSelected.value || isHiringMenuSelected.value)
  );
});

const isHandoverMenuSelected = computed(() => {
  return peopleCandidatesStore.selectedMenuItem === 'handover';
});

const isReadyForContractBtnDisabled = computed(() => {
  if (
    (recruit.value.needTestDay && isTestDayResultEmpty.value) ||
    !isAllRequiredDocumentationUploaded.value
  ) {
    return true;
  }
  return false;
});

const isReadyForContractReviewBtnDisabled = computed(() => {
  return !isAllRequiredDocumentationUploaded.value;
});

const hasRejectedResult = computed(() => {
  return recruit.value.testDays.some(
    (item) => +item.testDayResult === 55 || +item.testDayResult === 47 // 55 = rejected - 47 = no show
  );
});

const isTestDayResultEmpty = computed(() => {
  return !recruit.value.testDays.filter((item) => item.testDayResult).length;
});

const recruitFullName = computed(() => {
  return `${recruit.value.applicant.firstName} ${recruit.value.applicant.lastName}`;
});

const recruit = computed(() => {
  if (props.type === 'applicants') {
    return peopleApplicantsStore.applicantsList.items[props.index];
  } else {
    return peopleCandidatesStore.candidatesList.items[props.index];
  }
});

const filterBySelectedMenu = computed(() => {
  let options;
  let status;
  let label;
  switch (peopleCandidatesStore.selectedMenuItem) {
    case 'engage':
      options = filterRecruitMenuOptionsByIndex([6]);
      status = testDayGenerateStatusAndLabel.value.status;
      label = testDayGenerateStatusAndLabel.value.label;
      break;
    case 'process':
      options = filterRecruitMenuOptionsByIndex([6]);
      status = testDayGenerateStatusAndLabel.value.status;
      label = testDayGenerateStatusAndLabel.value.label;
      break;
    case 'handover':
      options = filterRecruitMenuOptionsByIndex([6]);
      status = t('Msg_.handover');
      label = 'handover';
      break;
    case 'hiring':
      options = filterHiringLabelOptionsStatus.value.options;
      label = filterHiringLabelOptionsStatus.value.label;
      status = filterHiringLabelOptionsStatus.value.status;
      break;
    case 'ended':
      options = filterRecruitMenuOptionsByIndex([]);
      status = filterCandidateStatus.value;
      break;
    default:
      break;
  }
  return { status, options, label };
});

const filterHiringLabelOptionsStatus = computed(() => {
  let options;
  let label;
  let status;
  switch (recruit.value.processStatus) {
    case 60: // contract review
      options = filterRecruitMenuOptionsByIndex([1, 3, 6]);
      label = hiringReviewContractLabel.value;
      status = t('Msg_.contractReview');
      break;
    case 65: // waiting for candidate signature
      options = filterRecruitMenuOptionsByIndex([0, 2, 3, 6]);
      label = hiringGenerateButtonLabel.value;
      status = t('Msg_.waitingForCandidateSignature');
      break;
    case 67: // payroll information
      options = filterRecruitMenuOptionsByIndex([0, 2, 3, 5, 6]);
      label = hiringGenerateButtonLabel.value;
      status = t('Msg_.payrollInformation');
      break;
    case 70: // waiting first day
      options = filterRecruitMenuOptionsByIndex([0, 2, 3, 5, 6]);
      label = hiringGenerateButtonLabel.value;
      status = t('Msg_.waitingFirstDay');
      break;
  }
  return { options, label, status };
});

const hiringReviewContractLabel = computed(() => {
  if (recruit.value.isToGenerateContract) {
    return 'generateContract';
  } else if (!recruit.value.contract.documentPath) {
    return 'contractNotReadyYet';
  } else if (!recruit.value.clickedOnReviewContract) {
    return 'reviewContract';
  } else {
    return 'sendContract';
  }
});

const hiringGenerateButtonLabel = computed(() => {
  if (recruit.value.isToGenerateContract) {
    return 'generateContract';
  } else if (!recruit.value.contract.documentPath) {
    return 'contractNotReadyYet';
  } else if (
    !recruit.value.hasSignableDocument &&
    !recruit.value.clickedOnReviewContract
  ) {
    return 'reviewContract';
  } else if (
    !recruit.value.hasSignableDocument &&
    recruit.value.clickedOnReviewContract
  ) {
    return 'sendContract';
  } else if (!recruit.value.candidateSignatureUrl) {
    return 'contractIsSigned'; // this button should be disabled
  } else if (!recruit.value.signedByEmployee || !recruit.value.signedByKenbi) {
    return 'contractIsSigned'; // this button should be enable
  } else {
    return 'turnIntoEmployee';
  }
});

const testDayGenerateStatusAndLabel = computed(() => {
  const label = hasRejectedResult.value ? 'rejected' : 'approved';
  const status = filterCandidateStatus.value;
  return { status, label };
});

const filterCandidateStatus = computed(() => {
  return (
    tm('Action_.selectApplicationStatusTypes')[recruit.value.processStatus] ||
    ''
  );
});

const showCandidatesBtns = computed(() => {
  return (
    props.type === 'candidates' &&
    peopleCandidatesStore.selectedMenuItem !== 'ended'
  );
});

const showRecruitMenuOptions = computed(() => {
  if (
    ((isHiringMenuSelected.value || isHandoverMenuSelected.value) &&
      !canUser(['People.Candidates.GenerateContract'])) ||
    !showCandidatesBtns.value
  ) {
    return false;
  }
  return true;
});

const isCandidatesHiringMenuSelected = computed(() => {
  return isHiringMenuSelected.value && props.type === 'candidates';
});

const isProfileDisplayed = computed(() => {
  if (props.type === 'applicants') {
    return peopleApplicantsStore.showProfileIndex === props.index;
  } else {
    return peopleCandidatesStore.showProfileIndex === props.index;
  }
});

const viewProfileBtnText = computed(() => {
  return isProfileDisplayed.value
    ? t('Action_.buttons.hideProfile')
    : t('Action_.buttons.viewProfile');
});

const viewProfileIcon = computed(() => {
  return isProfileDisplayed.value ? 'mdi-close' : 'mdi-plus';
});

const isApplicantConfirmCandidateBtnDisabled = computed(() => {
  if (
    testDayIsNeededAndIsEmpty.value ||
    testDayIsNeededAndAndHasResponsible.value ||
    testDaysRequiredFields.value ||
    !isResponsibleChosen.value
  ) {
    return true;
  }
  return false;
});

const testDayIsNeededAndIsEmpty = computed(() => {
  return (
    peopleApplicantsStore.applicantsList.items[props.index].needTestDay &&
    !peopleApplicantsStore.applicantsList.items[props.index].testDays.length
  );
});

const testDayIsNeededAndAndHasResponsible = computed(() => {
  return (
    !isResponsibleChosen.value &&
    peopleApplicantsStore.applicantsList.items[props.index].needTestDay
  );
});

const testDaysRequiredFields = computed(() => {
  let hasAllRequired = true;
  peopleApplicantsStore.applicantsList.items[props.index].testDays.forEach(
    (testDay) => {
      if (
        !testDay.reviewerId ||
        !testDay.testDayDate ||
        !testDay.testDayStartsAt ||
        !testDay.testDayEndsAt
      )
        hasAllRequired = false;
    }
  );
  return !hasAllRequired;
});

const isResponsibleChosen = computed(() => {
  return peopleApplicantsStore.applicantsList.items[props.index].responsibleId;
});

const isAllRequiredDocumentationUploaded = computed(() => {
  return !recruit.value.documents.some(
    (item) => item.required && (!item.fileName || !item.fileUrl)
  );
});

const candidatesActionButton = computed(() => {
  if (isHiringMenuSelected.value) {
    return actionButtons.hiring[filterBySelectedMenu.value.label];
  }
  return actionButtons[filterBySelectedMenu.value.label];
});
</script>

<style scoped lang="scss">
.candidate-status {
  color: $blue-regular !important;
}
</style>
