<template>
  <div>
    <v-divider></v-divider>
    <v-container class="pb-0 mt-2">
      <v-row class="mb-3 pl-3 pt-2">
        <v-col
          class="pa-0 px-2"
          cols="auto"
          v-for="(button, index) in optionButtons"
          :key="index"
        >
          <v-btn
            dense
            depressed
            :data-testid="button.dataTestId"
            class="rounded-lg"
            :class="selectedOptionClass(button.option)"
            @click="checkApplicantAlteredData(button)"
          >
            {{ button.label }}
          </v-btn>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <RecruitmentPersonalData
        v-if="selectedOption === 1"
        @update-applicant="updateApplicant()"
      />

      <RecruitmentTypeform1
        v-if="selectedOption === 2"
        @update-applicant="updateApplicant()"
      />

      <RecruitmentTypeform2
        v-if="selectedOption === 3"
        @check-display-candidate-generate-contract-dialog="
          checkDisplayCandidateGenerateContractDialog()
        "
      />

      <RecruitmentReferences v-if="selectedOption === 4" />

      <RecruitmentHiringProcess v-if="selectedOption === 5" />

      <RecruitmentRequiredDocumentation v-if="selectedOption === 6" />

      <RecruitmentLog v-if="selectedOption === 7" disable-form />
    </v-container>
  </div>
</template>

<script setup>
import RecruitmentPersonalData from '@/components/people/contracts/contractRecruitment/recruitment-personal-data.vue';
import RecruitmentTypeform1 from '@/components/people/contracts/contractRecruitment/recruitment-typeform-1.vue';
import RecruitmentTypeform2 from '@/components/people/contracts/contractRecruitment/recruitment-typeform-2.vue';
import RecruitmentReferences from '@/components/people/contracts/contractRecruitment/recruitment-references.vue';
import RecruitmentHiringProcess from '@/components/people/contracts/contractRecruitment/recruitment-hiring-process.vue';
import RecruitmentRequiredDocumentation from '@/components/people/contracts/contractRecruitment/recruitment-required-documentation.vue';
import RecruitmentLog from '@/components/people/contracts/contractRecruitment/recruitment-log.vue';

import languages from '../../locales/languages';
import helpers from '@/helpers/helpers';

import { usePeopleCandidatesStore } from '@/store/pinia/people-candidates.store.js';
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { usePopupDialogStore } from '@/store/pinia/popup-dialog.store.js';
import { useContractToolsV2 } from '@/helpers/composables/contract-tools-v2.js';
import { usePeopleRecruitmentContractStore } from '@/store/pinia/people-recruitment-contract.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { ref, computed, defineEmits } from 'vue';

const loadingStore = useLoadingStore();
const peopleRecruitmentContractStore = usePeopleRecruitmentContractStore();
const emit = defineEmits(['display-generate-new-contract-dialog']);
const peopleCandidatesStore = usePeopleCandidatesStore();
const popupDialogStore = usePopupDialogStore();
const { trackPage } = amplitudeComposable();
const { formatCurrencyFieldsToPost, formatPercentageFieldsToPost } =
  useContractToolsV2();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const selectedOption = ref(1); // first tab reference
const selectedOptionWithoutSaveChanges = ref(null);
const currencyKeys = [
  'hourlyWage',
  'nonDynamicAllowance',
  'careAllowance',
  'monthlyBaseSalary',
  'thirteenthSalary',
  'annualSalary',
  'cityBonus',
  'individualAllowance',
];
const percentageKeys = [
  'costCenter1Percentage',
  'costCenter2Percentage',
  'costCenter3Percentage',
];

// --- Methods ---
const selectedOptionClass = (option) => {
  return option === selectedOption.value
    ? 'selected-option'
    : 'non-selected-option';
};

const checkApplicantAlteredData = (item) => {
  if (isLoading.value) {
    displaySaveInProgressDialog();
  } else if (item.option !== selectedOption.value) {
    selectedOptionWithoutSaveChanges.value = item.option;
    if (isFormAltered.value) {
      displayConfirmationDialog();
    } else {
      selectedOption.value = item.option;
      amplitudeTrackPage(item.amplitudePage);
    }
  }
};

const displayConfirmationDialog = () => {
  const params = {
    type: 'confirmation',
    title: t('Action_.dialog.saveChanges.title'),
    message: t('Action_.dialog.saveChanges.body'),
    reinforcement: t('Action_.dialog.saveChanges.reinforcement'),
    actionBtnText: t('Action_.buttons.confirm'),
    actionBtn: discardChanges,
  };

  popupDialogStore.params = params;
  popupDialogStore.displayDialog = true;
};

const displaySaveInProgressDialog = () => {
  const params = {
    type: 'regular',
    title: t('Msg_.workInProgress'),
    message: t('Msg_.waitToFinishTheProcess'),
  };

  popupDialogStore.params = params;
  popupDialogStore.displayDialog = true;
};

const discardChanges = () => {
  restoreInitialData();
  selectedOption.value = selectedOptionWithoutSaveChanges.value;
};

const restoreInitialData = () => {
  peopleRecruitmentContractStore.applicant = {
    ...{},
    ...peopleRecruitmentContractStore.applicantInitialData,
  };
  peopleRecruitmentContractStore.payroll = {
    ...{},
    ...peopleRecruitmentContractStore.payrollInitialData,
  };
};

const updateApplicant = async () => {
  const applicantData = { ...{}, ...peopleRecruitmentContractStore.applicant };

  formatCurrencyFieldsToPost(currencyKeys, applicantData);

  formatPercentageFieldsToPost(percentageKeys, applicantData);

  const payload = {
    applicantId: peopleRecruitmentContractStore.applicantId,
    applicantContractData: applicantData,
  };

  try {
    await peopleRecruitmentContractStore.updateApplicant(payload);
    updateApplicantInitialData(applicantData);
    checkDisplayCandidateGenerateContractDialog();
  } catch (error) {
    console.log(error);
  }
};

const checkDisplayCandidateGenerateContractDialog = () => {
  if (
    (isContractReviewCandidateStatus.value ||
      isPayrollInformationCandidateStatus.value ||
      isWaitingFirstDayCandidateStatus.value ||
      isWaitingForCandidateSignatureStatus.value) &&
    isPersonalDataOrTypeform1Menu.value
  )
    emit('display-generate-new-contract-dialog');
};

const updateApplicantInitialData = (applicantData) => {
  peopleRecruitmentContractStore.applicantInitialData = {
    ...{},
    ...applicantData,
  };
};

const amplitudeTrackPage = (page) => {
  trackPage(peopleRecruitmentContractStore.selectedHubId, {
    resourceId: peopleRecruitmentContractStore.applicantId,
    routeName: `people.recruitment.${pagePath.value}.${page}`,
  });
};

// --- Computed ---
const isPersonalDataOrTypeform1Menu = computed(() => {
  return selectedOption.value === 1 || selectedOption.value === 2;
});

const pagePath = computed(() => {
  if (peopleRecruitmentContractStore.recruitType === 'applicants') {
    return 'listApplicants';
  } else {
    switch (peopleCandidatesStore.selectedMenuItem) {
      case 'engage':
        return 'listCandidates.engage';
      case 'process':
        return 'listCandidates.process';
      case 'handover':
        return 'listCandidates.handover';
      case 'hiring':
        return 'listCandidates.hiring';
      case 'ended':
        return 'listCandidates.ended';
      default:
        return null;
    }
  }
});

const isContractReviewCandidateStatus = computed(() => {
  return peopleRecruitmentContractStore.recruitData.processStatus === 60;
});

const isPayrollInformationCandidateStatus = computed(() => {
  return peopleRecruitmentContractStore.recruitData.processStatus === 67;
});

const isWaitingFirstDayCandidateStatus = computed(() => {
  return peopleRecruitmentContractStore.recruitData.processStatus === 70;
});

const isWaitingForCandidateSignatureStatus = computed(() => {
  return peopleRecruitmentContractStore.recruitData.processStatus === 65;
});

const isLoading = computed(() => {
  return loadingStore.isPartialLoading || loadingStore.isGlobalLoading;
});

const isFormAltered = computed(() => {
  return (
    !helpers.compareTwoObjects(
      peopleRecruitmentContractStore.applicant,
      peopleRecruitmentContractStore.applicantInitialData
    ) ||
    !helpers.compareTwoObjects(
      peopleRecruitmentContractStore.payroll,
      peopleRecruitmentContractStore.payrollInitialData
    )
  );
});

const optionButtons = computed(() => {
  return [
    {
      label: t('Msg_.personalData'),
      dataTestId: 'recruitment-profile-personal-data-btn',
      option: 1,
      amplitudePage: 'personalData',
    },
    {
      label: `${t('Action_.buttons.typeform')} 1`,
      dataTestId: 'recruitment-profile-typeform-1-btn',
      option: 2,
      amplitudePage: 'typeform1',
    },
    {
      label: `${t('Action_.buttons.typeform')} 2`,
      dataTestId: 'recruitment-profile-typeform-2-btn',
      option: 3,
      amplitudePage: 'typeform2',
    },
    {
      label: t('Action_.buttons.references'),
      dataTestId: 'recruitment-profile-references-btn',
      option: 4,
      amplitudePage: 'references',
    },
    {
      label: t('Action_.buttons.hiringProcess'),
      dataTestId: 'recruitment-profile-hiring-process-btn',
      option: 5,
      amplitudePage: 'hiringProcess',
    },
    {
      label: t('Action_.buttons.requiredDocumentation'),
      dataTestId: 'recruitment-profile-required-documentation-btn',
      option: 6,
      amplitudePage: 'requiredDocumentation',
    },
    {
      label: t('Action_.buttons.log'),
      dataTestId: 'recruitment-profile-log-btn',
      option: 7,
      amplitudePage: 'log',
    },
  ];
});

// --- Lifecycle hooks ---
amplitudeTrackPage('personalData');
</script>

<style lang="scss" scoped>
.non-selected-option {
  background-color: white !important;
  font-weight: 500;
  font-size: 13px;
  color: $grey-dark;
  height: 2rem !important;
  padding: 0 6px !important;
}

.selected-option {
  background-color: #e3e3e3 !important;
  font-weight: 500;
  font-size: 13px;
  color: $grey-dark;
  height: 2rem !important;
  padding: 0 6px !important;
}
</style>

<!-- $grey-light: #b5b5c3;
$grey-regular: #9e9e9e; -->
