<template>
  <v-container data-testid="fleet-vehicles-list">
    <v-row align="center">
      <v-col class="card-title" cols="auto">
        {{ t('Title_.page.vehiclesList') }}
      </v-col>
      <v-col class="ml-auto" cols="3">
        <SearchInput
          :placeholder="t('Msg_.fleetSearch')"
          :label="t('Msg_.fleetSearch')"
          v-model="pageOptions.searchTerm"
          @update-list="updateVehicleList()"
          @change="updateVehicleList()"
        />
      </v-col>
      <v-col cols="2">
        <DropdownInput
          :label="t('Msg_.fleetSearchOffices')"
          :items="hubs"
          v-model="pageOptions.officeId"
          :clearable="false"
          @change="updateVehicleList()"
        />
      </v-col>
      <v-col cols="2">
        <DropdownInput
          :label="t('Msg_.fleetSearchAssignments')"
          :items="assignList"
          v-model="pageOptions.assignType"
          :clearable="false"
          @change="updateVehicleList()"
        />
      </v-col>
      <v-col cols="auto">
        <ButtonWithIcon
          class="main-action-icon-btn"
          icon="mdi-car"
          :text="t('Action_.buttons.addCar')"
          v-if="canUser(['Fleet.Create'])"
          :to="{
            name: 'FleetVehicleForm',
          }"
        />
      </v-col>
    </v-row>
    <div
      v-if="
        Array.isArray(vehiclesList) &&
        vehiclesList.length > 0 &&
        !loadingStore.isPartialLoading
      "
    >
      <v-row v-for="vehicle in vehiclesList" :key="vehicle.id">
        <v-col class="my-n1">
          <v-hover v-slot="{ hover }">
            <v-card
              :class="hover ? 'shadow-hover' : 'shadow-regular'"
              class="rounded-lg"
            >
              <v-list-item three-line>
                <v-list-item-content class="pl-2 section-detail">
                  <v-row class="d-flex align-center">
                    <v-col cols="auto">
                      <v-row>
                        <div v-if="vehicle.picture">
                          <v-img
                            class="rounded-lg ma-1"
                            :src="$helpers.convertImgToBase64(vehicle.picture)"
                            alt="car Icon"
                            width="80"
                            height="80"
                          />
                        </div>
                        <div v-else>
                          <v-icon size="80" color="greyLight">mdi-car</v-icon>
                        </div>
                      </v-row>
                    </v-col>
                    <v-col>
                      <v-row align="center">
                        <v-col
                          cols="auto"
                          class="section-title"
                          data-testid="fleet-vehicle-plate"
                        >
                          {{ vehicle.plate }}
                        </v-col>
                        <v-col cols="auto"> </v-col>
                        <v-col
                          cols="auto"
                          data-testid="fleet-vehicle-assigned-driver"
                        >
                          <v-icon color="greyRegular" class="pr-1" small>
                            mdi-account
                          </v-icon>
                          <span
                            @click="redirectToAssignedDriver(vehicle)"
                            :class="
                              vehicle.assigned ? 'assigned-driver-redirect' : ''
                            "
                            >{{ vehicle.driver }}
                          </span>
                        </v-col>
                      </v-row>
                      <v-row align="start" class="mt-0">
                        <v-col cols="auto" data-testid="fleet-vehicle-model">
                          <v-icon color="greyRegular" class="pb-1 pr-1" small>
                            mdi-car
                          </v-icon>
                          {{
                            `${vehicle.brand || '-'}
                            ${vehicle.model || '-'}`
                          }}
                        </v-col>
                        <v-col cols="auto" data-testid="fleet-vehicle-imei">
                          <v-icon color="greyRegular" class="pb-1 pr-1" small>
                            mdi-router-wireless
                          </v-icon>
                          {{
                            (vehicle.imei || '-') +
                            ' ' +
                            (vehicle.serialNumber || '-')
                          }}
                        </v-col>
                        <v-col cols="auto" data-testid="fleet-vehicle-hub">
                          <v-icon color="greyRegular" class="pr-1" small>
                            mdi-store
                          </v-icon>
                          {{ vehicle.office }}
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="auto">
                      <v-menu
                        bottom
                        left
                        offset-x
                        v-if="
                          canUser(['Fleet.Update']) || canUser(['Fleet.Delete'])
                        "
                      >
                        <template #activator="{ on, attrs }">
                          <IconButton
                            :bind="attrs"
                            :on="on"
                            class="icon-btn"
                            icon="mdi-dots-vertical"
                          />
                        </template>
                        <v-list class="py-1 px-0">
                          <template v-if="canUser(['Fleet.Update'])">
                            <v-list-item>
                              <ButtonWithIcon
                                class="default-btn d-flex justify-start"
                                icon="mdi-pencil"
                                :text="t('Action_.buttons.edit')"
                                width="100%"
                                :to="{
                                  name: 'FleetVehicleFormEdit',
                                  params: { vehicleId: vehicle.id },
                                }"
                              />
                            </v-list-item>
                          </template>
                          <v-list-item v-if="canUser(['Fleet.Delete'])">
                            <ButtonWithIcon
                              class="default-btn d-flex justify-start"
                              icon="mdi-delete"
                              :text="t('Action_.buttons.delete')"
                              :only-text="true"
                              color="redDanger"
                              width="100%"
                              @click="displayConfirmationDialog(vehicle)"
                            />
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <v-btn
                        v-if="canUser(['Fleet.Read'])"
                        dark
                        class="main-action-btn ml-2"
                        :to="{
                          name: 'FleetMenu',
                          params: { vehicleId: vehicle.id },
                        }"
                      >
                        {{ t('Action_.buttons.viewCar') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <Pagination
        type="wide"
        v-model="pagination"
        @update-list="updateVehicleList()"
      />
    </div>
    <v-progress-linear
      v-else-if="loadingStore.isPartialLoading"
      indeterminate
      rounded
      color="blueRegular"
    />
    <div v-else-if="noData">
      <EmptyListMessage />
    </div>
  </v-container>
</template>
<script setup>
import ButtonWithIcon from '@/components/shared/customComponents/buttons/button-with-icon.vue';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
import EmptyListMessage from '@/components/shared/customComponents/emptyStates/empty-list-message.vue';
import IconButton from '@/components/shared/customComponents/buttons/icon-button.vue';
import Pagination from '@/components/shared/customComponents/paginations/pagination.vue';
import SearchInput from '@/components/shared/customComponents/inputs/search-input.vue';
import languages from './locales/languages';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { usePopupDialogStore } from '@/store/pinia/popup-dialog.store.js';
import { useFleetVehiclesStore } from '@/store/pinia/fleet-vehicles.store.js';
import { usePermissions } from '@/helpers/composables/permissions.js';
import { useRouter } from 'vue-router/composables';
import { useI18n } from 'vue-i18n-bridge';
import helpers from '@/helpers/helpers';
import { usePagination } from '@/helpers/composables/pagination.js';
import { ref, computed, onMounted } from 'vue';
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';

const { trackPage } = amplitudeComposable();
const router = useRouter();
const { canUser } = usePermissions();
const loadingStore = useLoadingStore();
const popupDialogStore = usePopupDialogStore();
const fleetVehiclesStore = useFleetVehiclesStore();
const { t, tm } = useI18n({ locale: 'en', messages: languages });
const {
  pagination,
  validatePaginationData,
  validatePageOptions,
  updatePaginationData,
} = usePagination();

// --- Local variables ---
const vehiclesList = ref([]);
const noData = ref(false);
const pageOptions = ref({
  searchTerm: '',
  officeId: 'all',
  assignType: 2,
});

// --- Methods ---
const getVehiclesList = async () => {
  validatePaginationData();
  trackPage(
    pageOptions.value.officeId == 'all' ? null : pageOptions.value.officeId
  );
  const payload = {
    pagination: pagination.value,
    pageOptions: pageOptions.value,
  };

  try {
    const response = await fleetVehiclesStore.getVehiclesList(payload);
    updateLocalData(response);
  } catch (error) {
    console.log(error);
  }
};

const updateVehicleList = () => {
  validatePageOptions();
  validateFilterOptions();
  getVehiclesList();
};

const validateFilterOptions = () => {
  if (!pageOptions.value.searchTerm) pageOptions.value.searchTerm = '';
  if (!pageOptions.value.officeId) pageOptions.value.employeeRole = 'all';
  if (!pageOptions.value.assignType) pageOptions.value.hubId = 2;
};

const updateLocalData = (response) => {
  vehiclesList.value = response.items;
  noData.value = vehiclesList.value.length === 0;
  updatePaginationData(response);
};

const displayConfirmationDialog = (vehicle) => {
  const params = {
    type: 'confirmation',
    title: t('Action_.dialog.wantToDelete.title'),
    message: `${t('Action_.dialog.wantToDelete.body1')}
          ${vehicle.brand}
          ${vehicle.model}
          ${t('Action_.dialog.wantToDelete.body2')}`,
    actionBtnText: t('Action_.buttons.delete'),
    actionBtn: () => deleteVehicle(vehicle.id),
    reinforcement: t('Action_.dialog.wantToDelete.reinforcement'),
  };

  popupDialogStore.params = params;
  popupDialogStore.displayDialog = true;
};

const deleteVehicle = async (id) => {
  try {
    await fleetVehiclesStore.deleteVehicle(id);
    await getVehiclesList();
  } catch (error) {
    console.log(error);
  }
};

const redirectToAssignedDriver = async (vehicle) => {
  if (vehicle.assigned) {
    try {
      const assignedDriver =
        await fleetVehiclesStore.getVehicleActualAssignmentDriver(vehicle.id);
      if (assignedDriver && assignedDriver.id) {
        router.push({
          name: 'EmployeeProfileMasterInformation',
          params: { employeeId: assignedDriver.id },
        });
      }
    } catch (e) {
      console.log(e);
    }
  }
};

// --- Computed ---
const assignList = computed(() => {
  let AssignSelectFilter = tm('Action_.selectFilter');
  return helpers.translateAndAdaptToVuetify(
    AssignSelectFilter,
    fleetVehiclesStore.vehicleResources.assignmentStatus
  );
});

const hubs = computed(() => {
  const list = fleetVehiclesStore.hubList.map((hubs) => {
    return { value: hubs.id, text: hubs.name };
  });
  return [{ value: 'all', text: t('Msg_.all') }, ...list];
});

// --- Lifecycle hooks ---
onMounted(() => {
  getVehiclesList();
  fleetVehiclesStore.$partialReset('vehicleDetails');
});
</script>

<style scoped lang="scss">
.assigned-driver-redirect {
  cursor: pointer;
  text-decoration: underline;
}
.assigned-driver-redirect:hover {
  color: $blue-regular !important;
}
</style>
