export const peopleRoutes = {
  EmployeeList: 'people.employeeDetails.listEmployees',
  EmployeeForm: 'people.employeeDetails.create',
  EmployeeProfileContractInformation:
    'people.employeeDetails.contractInformation',
  EmployeeProfileMasterInformation: 'people.employeeDetails.masterInformation',
  EmployeeProfileInternalInformation:
    'people.employeeDetails.internalInformation',
  EmployeeProfileBankDetails: 'people.employeeDetails.bankDetails',
  EmployeeProfileTaxData: 'people.employeeDetails.taxData',
  EmployeeProfileDocuments: 'people.employeeDetails.documents',
  EmployeeProfileCarInformation: 'people.employeeDetails.carInformation',
  EmployeeProfileDownloads: 'people.employeeDetails.downloads',
  EmployeeProfileAbsences: 'people.employeeDetails.absences',
  ContractAmendment: 'people.employeeDetails.contractAmendment',
  PendingEmployeesList:
    'people.recruitment.pendingEmployees.listPendingEmployees',
  PendingEmployeeEdit: 'people.pendingEmployees.editDetails',
  ContractCreation:
    'people.recruitment.pendingEmployees.contractCreation.step1',
  PeopleAbsences: 'people.absences.read',
  EmployeeProfileOffboarding: 'people.employeeDetails.offboardingDetails',
  ApplicantList: 'people.recruitment.listApplicants',
  PendingEmployeeContract: 'people.recruitment.PendingEmployeeContract',
};
