<template>
  <div>
    <v-form v-if="showTypeForm2" ref="form" v-model="isFormValid">
      <v-container>
        <v-row class="mt-1">
          <v-col cols="4">
            <RegularTextInput
              data-testid="recruitment-typeform2-bank-name-field"
              :label="t('Msg_.bankName')"
              :disabled="isFormDisabled"
              v-model="peopleRecruitmentContractStore.payroll.bankName"
            />
          </v-col>
          <v-col cols="4">
            <RegularTextInput
              data-testid="recruitment-typeform2-account-holder-field"
              :label="t('Msg_.accountHolder')"
              :disabled="isFormDisabled"
              v-model="peopleRecruitmentContractStore.payroll.accountOwner"
            />
          </v-col>
          <v-col cols="4">
            <RegularTextInput
              data-testid="recruitment-typeform2-iban-field"
              :label="t('Msg_.iban')"
              :disabled="isFormDisabled"
              v-model="peopleRecruitmentContractStore.payroll.ibanNumber"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="pt-0">
            <DropdownInput
              data-testid="recruitment-typeform2-type-of-health-insurance-dropdown"
              :label="t('Msg_.healthInsuranceType')"
              :disabled="isFormDisabled"
              :clearable="false"
              :items="selectHealthInsuranceType"
              v-model="
                peopleRecruitmentContractStore.payroll.healthInsuranceType
              "
            />
          </v-col>
          <v-col cols="4" class="pt-0">
            <RegularTextInput
              data-testid="recruitment-typeform2-health-insurance-field"
              :label="t('Msg_.healthInsuranceName')"
              :disabled="isFormDisabled"
              v-model="
                peopleRecruitmentContractStore.payroll.healthInsuranceName
              "
            />
          </v-col>
          <v-col cols="4" class="pt-0">
            <RegularTextInput
              data-testid="recruitment-typeform2-social-security-number-field"
              :label="t('Msg_.socialSecurityNumber')"
              :disabled="isFormDisabled"
              v-model="
                peopleRecruitmentContractStore.payroll.socialSecurityNumber
              "
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="pt-0">
            <RegularTextInput
              data-testid="recruitment-typeform2-tax-number-field"
              :label="t('Msg_.taxNumber')"
              :disabled="isFormDisabled"
              v-model="peopleRecruitmentContractStore.payroll.taxId"
            />
          </v-col>
          <v-col cols="4" class="pt-0">
            <DropdownInput
              data-testid="recruitment-typeform2-tax-class-dropdown"
              :label="t('Msg_.taxClass')"
              :disabled="isFormDisabled"
              :clearable="false"
              :items="selectTaxClassType"
              v-model="peopleRecruitmentContractStore.payroll.taxClass"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="pt-0">
            <RegularTextInput
              data-testid="recruitment-typeform2-number-of-children-field"
              :label="t('Msg_.numberOfChildren')"
              :disabled="isFormDisabled"
              v-model="peopleRecruitmentContractStore.payroll.numberOfChildren"
            />
          </v-col>
          <v-col cols="4" class="pt-0">
            <DropdownInput
              data-testid="recruitment-typeform2-religion-dropdown"
              :label="t('Msg_.confession')"
              :disabled="isFormDisabled"
              :clearable="false"
              :items="selectConfessionType"
              v-model="peopleRecruitmentContractStore.payroll.religion"
            />
          </v-col>
          <v-col cols="4" class="pt-0">
            <DropdownInput
              data-testid="recruitment-typeform2-degree-of-disability-dropdown"
              :label="t('Msg_.degreeOfDisability')"
              :disabled="isFormDisabled"
              :clearable="false"
              :items="selectDisabilityType"
              v-model="
                peopleRecruitmentContractStore.payroll.degreeOfDisability
              "
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="pt-0">
            <DatePicker
              data-testid="recruitment-typeform2-qualification-date-field"
              :label="t('Msg_.qualificationDate')"
              v-model="peopleRecruitmentContractStore.payroll.qualificationDate"
              :disabled="isFormDisabled"
            />
          </v-col>
          <v-col cols="4" class="pt-0">
            <RegularTextInput
              data-testid="recruitment-typeform2-lbnr-field"
              class="pr-3"
              :disabled="isFormDisabled"
              :label="t('Msg_.lbnr')"
              v-model="peopleRecruitmentContractStore.payroll.lbnr"
              :rules="[
                rules.totalSize(9, t('Msg_.rules.mustHaveNineDigits')),
                rules.onlyNumbers(t('Msg_.rules.onlyNumbers')),
              ]"
            />
          </v-col>
        </v-row>
        <v-row v-if="showEditInformationButton" class="mb-1">
          <v-col class="pl-1" v-if="isFormDisabled">
            <v-btn
              data-testid="recruitment-typeform2-edit-information-btn"
              class="main-action-btn ml-2"
              @click="toggleFormDisabled()"
            >
              {{ buttonText }}
            </v-btn>
          </v-col>
          <v-col v-if="!isFormDisabled" class="pl-1">
            <v-btn
              data-testid="recruitment-typeform2-cancel-btn"
              class="cancel-variant-btn ml-2"
              @click="checkAlteredForm()"
            >
              {{ t('Action_.buttons.cancel') }}
            </v-btn>
            <v-btn
              data-testid="recruitment-typeform2-save-changes-btn"
              class="main-action-btn ml-2"
              @click="updateTypeform()"
            >
              {{ buttonText }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-container v-else>
      <v-row class="mt-2">
        <v-col class="d-flex justify-center additional-info">
          {{ t('Msg_.noInformationYet') }}
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script setup>
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import DatePicker from '@/components/shared/customComponents/inputs/date-picker.vue';

import rules from '@/helpers/rules';
import languages from '../../locales/languages';
import helpers from '@/helpers/helpers';
import { usePermissions } from '@/helpers/composables/permissions.js';
import { usePeopleCandidatesStore } from '@/store/pinia/people-candidates.store.js';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { usePopupDialogStore } from '@/store/pinia/popup-dialog.store.js';
import { usePeopleStore } from '@/store/pinia/people.store.js';
import { usePeopleRecruitmentContractStore } from '@/store/pinia/people-recruitment-contract.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { ref, computed } from 'vue';
import {
  onBeforeRouteLeave,
  useRouter,
  defineEmits,
} from 'vue-router/composables';

const popupDialogStore = usePopupDialogStore();
const peopleStore = usePeopleStore();
const peopleRecruitmentContractStore = usePeopleRecruitmentContractStore();
const router = useRouter();
const loadingStore = useLoadingStore();
const emit = defineEmits(['check-display-candidate-generate-contract-dialog']);
const peopleCandidatesStore = usePeopleCandidatesStore();
const { canUser } = usePermissions();
const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const isFormDisabled = ref(true);
const isFormValid = ref(true);
const ignoreFormChanges = ref(false);
const nextRoute = ref(null);

// --- Methods ---
const checkAlteredForm = () => {
  if (isFormAltered.value) {
    displayConfirmationDialog();
  } else {
    toggleFormDisabled();
  }
};

const updateTypeform = async () => {
  try {
    await peopleRecruitmentContractStore.updatePayroll(
      peopleRecruitmentContractStore.applicantId
    );
    assignInitialPayrollData();
    toggleFormDisabled();
    emit('check-display-candidate-generate-contract-dialog');
  } catch (error) {
    console.log(error);
  }
};

const assignInitialPayrollData = () => {
  peopleRecruitmentContractStore.payrollInitialData = {
    ...{},
    ...peopleRecruitmentContractStore.payroll,
  };
};

const toggleFormDisabled = () => {
  isFormDisabled.value = !isFormDisabled.value;
};

const displayConfirmationDialog = () => {
  const params = {
    type: 'confirmation',
    title: t('Action_.dialog.saveChanges.title'),
    message: t('Action_.dialog.saveChanges.body'),
    reinforcement: t('Action_.dialog.saveChanges.reinforcement'),
    actionBtnText: t('Action_.buttons.confirm'),
    actionBtn: discardChanges,
  };

  popupDialogStore.params = params;
  popupDialogStore.displayDialog = true;
};

const discardChanges = () => {
  ignoreFormChanges.value = true;
  if (nextRoute.value) {
    router.push(nextRoute.value);
  } else {
    restoreInitialData();
    toggleFormDisabled();
  }
};

const restoreInitialData = () => {
  peopleRecruitmentContractStore.payroll = {
    ...{},
    ...peopleRecruitmentContractStore.payrollInitialData,
  };
};

const displaySaveInProgressDialog = () => {
  const params = {
    type: 'regular',
    title: t('Msg_.workInProgress'),
    message: t('Msg_.waitToFinishTheProcess'),
  };

  popupDialogStore.params = params;
  popupDialogStore.displayDialog = true;
};

// --- Computed ---
const isFormAltered = computed(() => {
  return !helpers.compareTwoObjects(
    peopleRecruitmentContractStore.payroll,
    peopleRecruitmentContractStore.payrollInitialData
  );
});

const selectHealthInsuranceType = computed(() => {
  let healthInsuranceResource = tm('Action_.selectHealthInsuranceType');

  return helpers.translateAndAdaptToVuetify(
    healthInsuranceResource,
    peopleStore.peopleResources?.healthInsuranceTypes
  );
});

const selectTaxClassType = computed(() => {
  let taxClassResource = tm('Action_.selectTaxClassTypes');

  return helpers.translateAndAdaptToVuetify(
    taxClassResource,
    peopleStore.peopleResources?.taxClassTypes
  );
});

const selectConfessionType = computed(() => {
  let confessionResource = tm('Action_.selectReligionTypes');

  return helpers.translateAndAdaptToVuetify(
    confessionResource,
    peopleStore.peopleResources?.religionTypes
  );
});

const selectDisabilityType = computed(() => {
  let disabilityResource = tm('Action_.selectDisabilityType');

  return helpers.translateAndAdaptToVuetify(
    disabilityResource,
    peopleStore.peopleResources?.disabilityTypes
  );
});

const buttonText = computed(() => {
  return !isFormDisabled.value
    ? t('Action_.buttons.saveChanges')
    : t('Action_.buttons.editInformation');
});

const showTypeForm2 = computed(() => {
  return Object.keys(peopleRecruitmentContractStore.payroll).length;
});

const isLoading = computed(() => {
  return loadingStore.isPartialLoading || loadingStore.isGlobalLoading;
});

const isHiringMenuSelected = computed(() => {
  return peopleCandidatesStore.selectedMenuItem === 'hiring';
});

const isHandoverMenuSelected = computed(() => {
  return peopleCandidatesStore.selectedMenuItem === 'handover';
});

const showEditInformationButton = computed(() => {
  if (
    (isHiringMenuSelected.value || isHandoverMenuSelected.value) &&
    !canUser(['People.Candidates.GenerateContract'])
  ) {
    return false;
  }
  return true;
});

// --- Routing guards ---
onBeforeRouteLeave((to, _, next) => {
  if (isLoading.value) {
    displaySaveInProgressDialog();
  } else {
    if (!isFormAltered.value || ignoreFormChanges.value) {
      nextRoute.value = to;
    }
    if (nextRoute.value) {
      next();
    } else {
      nextRoute.value = to;
      displayConfirmationDialog(next);
    }
  }
});
</script>

<style lang="scss" scoped>
.additional-info {
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.3px;
  color: $grey-dark;
}
</style>
