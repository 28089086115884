<template>
  <v-navigation-drawer color="blueDeep" permanent :mini-variant.sync="mini" app>
    <v-container id="nav-container">
      <v-row class="pa-3 pt-2">
        <v-col cols="12" class="pr-0" :class="!mini ? 'pl-2' : ''">
          <div class="d-flex">
            <v-img
              v-if="!mini"
              :src="require('@/assets/navigationDrawer/Kenbi_Logo.svg')"
              alt="kenbi_logo"
              class="pa-0 ma-0"
              width="52px"
              height="52px"
            />
            <v-spacer v-if="!mini" />
            <div v-if="!mini" class="mt-3">
              <v-icon color="white" size="28" @click="mini = !mini">
                mdi-menu-open
              </v-icon>
            </div>
            <div v-else>
              <v-icon
                color="caribbeanRegular"
                size="32"
                class="mt-3 mb-2"
                @click="mini = !mini"
              >
                mdi-menu
              </v-icon>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card
            color="blueDeep"
            class="mx-auto"
            max-width="300"
            elevation="0"
          >
            <v-list>
              <NavigationSingleMenu
                :data-testid="newsDashboard.id"
                :title="newsDashboard.title"
                :icon="newsDashboard.icon"
                :route-name="newsDashboard.routeName"
                :params="newsDashboard.params"
                :permission="newsDashboard.permission"
              />

              <NavigationHeader
                data-testid="menu-header-profile"
                :header="t('Title_.header.quickAccess')"
                :mini="mini"
                v-if="canUser(profile.permission)"
              />
              <NavigationSingleMenu
                :data-testid="profile.id"
                :title="profile.title"
                :icon="profile.icon"
                :route-name="profile.routeName"
                :permission="profile.permission"
                :params="profile.params"
                :control-profile-selected="controlProfileSelected"
              />

              <!-- Disabled until further notice
              <NavigationHeader
                :header="t('Title_.header.myWork')"
                :mini="mini"
                v-if="canUser(today.permission) || canUser(balance.permission)"
              />
              <NavigationSingleMenu
                :title="today.title"
                :icon="today.icon"
                :route-name="today.routeName"
                :permission="today.permission"
                :params="today.params"
              />

              <NavigationSingleMenu
                :title="balance.title"
                :icon="balance.icon"
                :route-name="balance.routeName"
                :permission="balance.permission"
                :params="balance.params"
              /> -->
              <NavigationHeader
                data-testid="menu-header-management"
                :header="t('Title_.header.management')"
                :mini="mini"
                v-if="
                  checkForSubmenu(people.submenus) ||
                  canUser(employeeList.permission) ||
                  checkForSubmenu(surveys.submenus) ||
                  canUser(absences.permission)
                "
              />
              <NavigationSingleMenu
                :data-testid="employeeList.id"
                :title="employeeList.title"
                :icon="employeeList.icon"
                :route-name="employeeList.routeName"
                :permission="employeeList.permission"
              />
              <NavigationMultipleMenu
                :data-testid="people.id"
                :title="people.title"
                :icon="people.icon"
                :submenus="people.submenus"
                :expand-group="expandRecruitingMenu"
              />
              <NavigationSingleMenu
                :data-testid="absences.id"
                :title="absences.title"
                :icon="absences.icon"
                :route-name="absences.routeName"
                :params="absences.params"
                :permission="absences.permission"
              />

              <NavigationMultipleMenu
                v-if="
                  canUser(['Surveys.Management.List', 'Surveys.Questions.List'])
                "
                :title="surveys.title"
                :icon="surveys.icon"
                :submenus="surveys.submenus"
              />

              <NavigationSingleMenu
                :data-testid="permissions.id"
                :title="permissions.title"
                :icon="permissions.icon"
                :route-name="permissions.routeName"
                :params="permissions.params"
                :permission="permissions.permission"
              />

              <!-- Disabled until further notice
              <NavigationHeader
                :header="t('Title_.header.marketplace')"
                :mini="mini"
                v-if="checkForSubmenu(market.submenus)"
              />
              <NavigationMultipleMenu
                :title="market.title"
                :icon="market.icon"
                :submenus="market.submenus"
              /> -->

              <!-- Disabled until further notice
              <NavigationHeader
                :header="t('Title_.header.reporting')"
                :mini="mini"
                v-if="checkForSubmenu(KPIsMenu.submenus)"
              />
              <NavigationMultipleMenu
                :title="KPIsMenu.title"
                :icon="KPIsMenu.icon"
                :submenus="KPIsMenu.submenus"
              /> -->

              <NavigationHeader
                data-testid="menu-header-hub-management"
                :header="t('Title_.header.fleetManagement')"
                :mini="mini"
                v-if="canUser(fleet.permission) || canUser(logbook.permission)"
              />

              <NavigationSingleMenu
                :data-testid="fleet.id"
                :title="fleet.title"
                :icon="fleet.icon"
                :route-name="fleet.routeName"
                :params="fleet.params"
                :permission="fleet.permission"
              />

              <NavigationSingleMenu
                :title="logbook.title"
                :icon="logbook.icon"
                :route-name="logbook.routeName"
                :params="logbook.params"
                :permission="logbook.permission"
              />

              <NavigationHeader
                data-testid="menu-header-hub-management"
                :header="t('Title_.header.hubManagement')"
                :mini="mini"
                v-if="canAccessHubManagement"
              />

              <NavigationSingleMenu
                :data-testid="hubList.id"
                :title="hubList.title"
                :icon="hubList.icon"
                :params="hubList.params"
                :route-name="hubList.routeName"
                :permission="hubList.permission"
              />

              <NavigationSingleMenu
                :data-testid="zdfDashboard.id"
                :title="zdfDashboard.title"
                :icon="zdfDashboard.icon"
                :params="zdfDashboard.params"
                :route-name="zdfDashboard.routeName"
                :permission="zdfDashboard.permission"
              />

              <NavigationMultipleMenu
                :data-testid="revenue.id"
                :title="revenue.title"
                :icon="revenue.icon"
                :submenus="revenue.submenus"
              />

              <NavigationMultipleMenu
                :data-testid="hubAnalysis.id"
                :title="hubAnalysis.title"
                :icon="hubAnalysis.icon"
                :submenus="hubAnalysis.submenus"
              />

              <NavigationMultipleMenu
                :data-testid="tourAnalysis.id"
                :title="tourAnalysis.title"
                :icon="tourAnalysis.icon"
                :submenus="tourAnalysis.submenus"
              />

              <NavigationSingleMenu
                :data-testid="locks.id"
                :title="locks.title"
                :icon="locks.icon"
                :route-name="locks.routeName"
                :permission="locks.permission"
                :params="locks.params"
              />

              <NavigationHeader
                data-testid="menu-header-export"
                :header="t('Title_.header.export')"
                :mini="mini"
                v-if="canUser(['People.Employees.Export', 'Hubs.Export'])"
              />

              <NavigationSingleMenu
                v-for="item in exportMenu"
                :key="item.id"
                :data-testid="item.id"
                :title="item.title"
                :icon="item.icon"
                :route-name="item.routeName"
                :permission="item.permission"
                :params="item.params"
              />

              <template v-if="environmentEquals('Local')">
                <NavigationHeader
                  header="Sandbox"
                  :mini="mini"
                  v-if="checkForSubmenu(sandbox.submenus)"
                />

                <NavigationMultipleMenu
                  :title="sandbox.title"
                  :icon="sandbox.icon"
                  :submenus="sandbox.submenus"
                />
              </template>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p id="version-text">Kenbi {{ appVersion() }} - {{ appHash() }}</p>
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script setup>
import NavigationSingleMenu from './navigation-single-menu.vue';
import NavigationMultipleMenu from './navigation-multiple-menu.vue';
import NavigationHeader from './navigation-header.vue';
import languages from '../../locales/languages';
import { useI18n } from 'vue-i18n-bridge';
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router/composables';
import { usePermissions } from '@/helpers/composables/permissions.js';

// Icons
import AddPersonIcon from '@/assets/icons/add-person-icon.svg?raw';
import AltRouteIcon from '@/assets/icons/alt-route-icon.svg?raw';
import CashMultipleIcon from '@/assets/icons/cash-multiple-icon.svg?raw';
import GroupIcon from '@/assets/icons/group-icon.svg?raw';
import LockOutlineIcon from '@/assets/icons/lock-outline-icon.svg?raw';
import PauseCircleOutlineIcon from '@/assets/icons/pause-circle-outline-icon.svg?raw';
import KeyIcon from '@/assets/icons/key-icon.svg?raw';
import PersonOutlineIcon from '@/assets/icons/person-outline-icon.svg?raw';
import PersonPinOutlineIcon from '@/assets/icons/person-pin-outline-icon.svg?raw';
import StoreDirectoryOutlineIcon from '@/assets/icons/store-directory-outline-icon.svg?raw';
import VehicleOutlineIcon from '@/assets/icons/vehicle-outline-icon.svg?raw';
import ViewDashboardOutlineIcon from '@/assets/icons/view-dashboard-outline-icon.svg?raw';
import ShowChartIcon from '@/assets/icons/show-chart-icon.svg?raw';
import DashboardIcon from '@/assets/icons/dashboard-icon.svg?raw';
// import TriangleCirclesOutlineIcon from '@/assets/icons/triangle-circles-outline-icon.svg?raw';

import QuestionAnswerIcon from '@/assets/icons/question_answer.svg';
const route = useRoute();
const { canUser } = usePermissions();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const mini = ref(false);

// --- Methods ---
const appVersion = () => {
  return process.env.VUE_APP_VERSION;
};

const appHash = () => {
  return process.env.VUE_APP_COMMIT_HASH;
};

const environmentEquals = (env) => {
  return process.env.VUE_APP_TITLE == env;
};

// Disabled until further notice
// const getCorrectEndpoint = (user) => {
//   if (user === 'Admin' || user === 'HRManager') {
//     return '/CompanyDashboard';
//   } else {
//     return '/HubDashboard';
//   }
// };

const checkForSubmenu = (menu) => {
  return menu?.find((submenu) => canUser(submenu.permission));
};

const getRouteToKingdra = () => {
  return process.env.VUE_APP_TITLE === 'Local'
    ? 'https://localhost:5173'
    : process.env.VUE_APP_LOGIN_API;
};

// --- Computed ---
const newsDashboard = computed(() => {
  return {
    id: 'menu-news-dashboard',
    title: t('Action_.item.newsDashboard'),
    icon: ViewDashboardOutlineIcon,
    routeName: `BuroKingdra`,
    params: `${getRouteToKingdra()}/v3/news`,
    permission: ['Announcements.Read'],
  };
});

const profile = computed(() => {
  return {
    id: 'menu-profile',
    title: t('Action_.item.profile'),
    icon: PersonOutlineIcon,
    routeName: 'ProfileMenu',
    permission: ['People.Employees.Read'],
  };
});

// Disabled until further notice
// const today = computed(() => {
//   return {
//     title: t('Action_.item.today'),
//     icon: 'mdi-format-list-checkbox',
//     routeName: 'Horsea',
//     permission: ['People.Employees.Read'],
//     params: `${getRoute()}/Planner`,
//   };
// });

// const balance = computed(() => {
//   return {
//     title: t('Action_.item.balance'),
//     icon: 'mdi-calendar-blank',
//     routeName: 'Horsea',
//     permission: ['Kpis.Balance'],
//     params: `${getRoute()}/Calendar`,
//   };
// });

const logbook = computed(() => {
  return {
    id: 'menu-logbook',
    title: t('Action_.item.logbook'),
    icon: PersonPinOutlineIcon,
    routeName: 'BuroKingdra',
    params: `${getRouteToKingdra()}/v3/logbook/logbook-dashboard`,
    permission: ['Fleet.Logbook.List'],
  };
});

const exportMenu = computed(() => {
  return [
    {
      id: 'menu-export-employees',
      title: t('Action_.item.employeeExport'),
      icon: GroupIcon,
      routeName: 'BuroKingdra',
      permission: ['People.Employees.Export'],
      params: `${getRouteToKingdra()}/v3/export/people`,
    },
    {
      id: 'menu-export-hubs',
      title: t('Action_.item.exportHubs'),
      icon: StoreDirectoryOutlineIcon,
      routeName: 'BuroKingdra',
      permission: ['Hubs.Export'],
      params: `${getRouteToKingdra()}/v3/export/hubs`,
    },
  ];
});

const employeeList = computed(() => {
  return {
    id: 'menu-people-employees',
    title: t('Action_.subItem.employees'),
    icon: GroupIcon,
    routeName: 'EmployeeList',
    permission: ['People.Employees.ListAll', 'People.Employees.ListActive'],
  };
});

const people = computed(() => {
  return {
    id: 'menu-people',
    title: t('Action_.item.people'),
    icon: AddPersonIcon,
    submenus: [
      {
        id: 'menu-people-applicants',
        title: t('Action_.subItem.applicants'),
        routeName: 'ApplicantList',
        permission: ['People.Candidates.List'],
      },
      {
        id: 'menu-people-candidates',
        title: t('Action_.subItem.candidates'),
        routeName: 'CandidateList',
        permission: ['People.Candidates.List'],
      },
      {
        id: 'menu-people-pending-employees',
        title: t('Action_.subItem.pendingEmployees'),
        routeName: 'PendingEmployeesList',
        permission: ['Recruitments.Employees.List'],
      },
    ],
  };
});

const absences = computed(() => {
  return {
    icon: PauseCircleOutlineIcon,
    id: 'menu-people-absences',
    title: t('Action_.subItem.absences'),
    routeName: 'BuroKingdra',
    params: `${getRouteToKingdra()}/v3/people/absences`,
    permission: ['Absences.Requests.List', 'Absences.Schedule.List'],
  };
});

const fleet = computed(() => {
  return {
    id: 'menu-fleet-vehicles',
    title: t('Action_.item.vehiclesList'),
    icon: VehicleOutlineIcon,
    routeName: 'BuroKingdra',
    params: `${getRouteToKingdra()}/v3/fleet/vehicle-list`,
    permission: ['Fleet.Read'],
  };
});

const hubList = computed(() => {
  return {
    icon: StoreDirectoryOutlineIcon,
    id: 'menu-hubs-list',
    title: t('Action_.item.hubList'),
    routeName: 'BuroKingdra',
    params: `${getRouteToKingdra()}/v3/hubs/hub-list`,
    permission: ['Hubs.List'],
  };
});

const zdfDashboard = computed(() => {
  return {
    icon: DashboardIcon,
    id: 'menu-zdf-dashboard',
    title: 'ZDF Dashboard',
    routeName: 'BuroKingdra',
    params: `${getRouteToKingdra()}/v3/zdf-dashboard`,
    permission: ['Profitability.ReadPatientTypeAnalysis'],
  };
});

const revenue = computed(() => {
  return {
    id: 'menu-revenue',
    title: t('Action_.item.revenue'),
    icon: CashMultipleIcon,
    submenus: [
      {
        id: 'menu-revenue-overview-sgb-xi-36',
        title: t('Action_.subItem.overviewSGBXI36'),
        routeName: 'BuroKingdra',
        params: `${getRouteToKingdra()}/v3/revenue/overview-sgb-xi-36`,
        permission: [
          'Profitability.ReadRevenueDevelopment',
          'Profitability.ReadRevenueByCareLevel',
          'Profitability.ReadRevenueByHub',
        ],
      },
      {
        id: 'menu-revenue-overview-sgb-xi-45b',
        title: t('Action_.subItem.overviewSGBXI45b'),
        routeName: 'BuroKingdra',
        params: `${getRouteToKingdra()}/v3/revenue/overview-sgb-xi-45b`,
        permission: ['Profitability.Read45b'],
      },
      {
        id: 'menu-revenue-37-3-planner',
        title: t('Action_.subItem.37-3Planner'),
        routeName: 'BuroKingdra',
        params: `${getRouteToKingdra()}/v3/revenue/37-3-planner`,
        permission: ['Profitability.Read37Planner'],
      },
    ],
  };
});

const hubAnalysis = computed(() => {
  return {
    id: 'menu-hub-analysis',
    title: t('Action_.item.hubAnalysis'),
    icon: ShowChartIcon,
    submenus: [
      {
        id: 'menu-hub-analysis-hub-profitability',
        title: t('Action_.subItem.hubProfitability'),
        routeName: 'BuroKingdra',
        params: `${getRouteToKingdra()}/v3/profitability`,
        permission: ['Profitability.ReadHubProfitability'],
      },
      {
        id: 'menu-hub-analysis-hub-capacity',
        title: t('Action_.subItem.hubCapacity'),
        routeName: 'BuroKingdra',
        params: `${getRouteToKingdra()}/v3/hub-capacity`,
        permission: ['Profitability.ReadHubCapacity'],
      },
    ],
  };
});

const tourAnalysis = computed(() => {
  return {
    id: 'menu-tour-analysis',
    title: t('Action_.item.tourAnalysis'),
    icon: AltRouteIcon,
    submenus: [
      {
        id: 'menu-tour-analysis-tour-planning',
        title: t('Action_.subItem.tourPlanning'),
        routeName: 'BuroKingdra',
        params: `${getRouteToKingdra()}/v3/tours/tour-information`,
        permission: ['Profitability.ReadPlanned'],
      },
      {
        id: 'menu-tour-analysis-tour-capacity',
        title: t('Action_.subItem.tourResults'),
        routeName: 'BuroKingdra',
        params: `${getRouteToKingdra()}/v3/tours/tour-comparison`,
        permission: ['Profitability.ReadActuals'],
      },
    ],
  };
});

const locks = computed(() => {
  return {
    id: 'menu-locks',
    title: t('Action_.item.locks'),
    icon: LockOutlineIcon,
    permission: ['Locks.Read'],
    routeName: 'BuroKingdra',
    params: `${getRouteToKingdra()}/v3/locks/lock-list`,
  };
});

const surveys = computed(() => {
  return {
    id: 'menu-surveys',
    title: t('Action_.item.surveys'),
    icon: QuestionAnswerIcon,
    submenus: [
      {
        id: 'menu-surveys-list',
        title: t('Action_.subItem.surveyList'),
        permission: ['Surveys.Management.List'],
        routeName: 'BuroKingdra',
        params: `${getRouteToKingdra()}/v3/surveys/survey-list`,
      },
      {
        title: t('Action_.subItem.questions'),
        permission: ['Surveys.Questions.List'],
        routeName: 'BuroKingdra',
        params: `${getRouteToKingdra()}/v3/surveys/question-list`,
      },
    ],
  };
});

const permissions = computed(() => {
  return {
    icon: KeyIcon,
    id: 'menu-permissions',
    title: t('Action_.subItem.permissions'),
    routeName: 'BuroKingdra',
    params: `${getRouteToKingdra()}/v3/permissions`,
    permission: ['People.Employees.ReadRole'],
  };
});

const expandRecruitingMenu = computed(() => {
  return (
    route.path.includes('people') &&
    !route.path.includes('people/employees') &&
    !route.path.includes('people/absences') &&
    !route.path.includes('export') &&
    !route.path.includes('people/profile')
  );
});

// This is a temporary solution as this behavior is proprietary to Vuetify and we
// don't have a specific way to prevent profile selection.
// This issue will be properly fixed in Kingdra
const controlProfileSelected = computed(() => {
  return route.params?.employeeId === localStorage.getItem('employeeId');
});

// Disabled until further notice
// const market = computed(() => {
//   return {
//     title: t('Action_.item.market'),
//     icon: 'mdi-cart-outline',
//     submenus: [
//       {
//         title: t('Action_.subItem.products'),
//         routeName: 'Horsea',
//         permission: ['Marketplace.Products.List'],
//         params: `${getRoute()}/Products`,
//       },
//       {
//         title: t('Action_.subItem.sales'),
//         routeName: 'Horsea',
//         permission: ['Marketplace.Products.List'],
//         params: `${getRoute()}/Sales`,
//       },
//     ],
//   };
// });

// Disabled until further notice
// const KPIsMenu = computed(() => {
//   return {
//     title: 'KPIs',
//     icon: 'mdi-chart-donut',
//     submenus: [
//       {
//         title: t('Action_.subItem.dashboard'),
//         routeName: 'Horsea',
//         permission: ['Kpis.Dashboard'],
//         params: `${getRoute()}${getCorrectEndpoint('Admin')}`,
//       },
//       {
//         title: t('Action_.subItem.balances'),
//         routeName: 'Horsea',
//         permission: ['Kpis.Balance'],
//         params: `${getRoute()}/Balances`,
//       },
//       {
//         title: t('Action_.subItem.sickRate'),
//         routeName: 'Horsea',
//         permission: ['Kpis.SickTime'],
//         params: `${getRoute()}/SickRate`,
//       },
//       {
//         title: t('Action_.subItem.billableTime'),
//         routeName: 'Horsea',
//         permission: ['Kpis.BillableTime'],
//         params: `${getRoute()}/BillableTime`,
//       },
//       {
//         title: t('Action_.subItem.recruitmentFunnel'),
//         routeName: 'Horsea',
//         permission: ['Kpis.RecruitmentFunnel'],
//         params: `${getRoute()}/Recruitment/Funnel`,
//       },
//     ],
//   };
// });

const sandbox = computed(() => {
  return {
    title: 'Sandbox',
    icon: 'mdi-sony-playstation',
    submenus: [
      {
        title: 'Playground',
        routeName: 'Sandbox',
        permission: ['People.Employees.Read'],
      },
      {
        title: 'Component Wiki',
        routeName: 'WikiMenu',
        permission: ['People.Employees.Read'],
      },
      {
        title: 'Composition API',
        routeName: 'CompositionApi',
        permission: ['People.Employees.Read'],
      },
    ],
  };
});

const canAccessHubManagement = computed(
  () =>
    canAccessSection(hubList.value) ||
    canAccessSection(revenue.value) ||
    canAccessSection(hubAnalysis.value) ||
    canAccessSection(tourAnalysis.value)
);

const canAccessSection = ({ permission, submenus }) => {
  if (permission && canUser(permission)) {
    return true;
  }

  if (
    submenus?.length &&
    submenus.some(({ permission }) => canUser(permission))
  ) {
    return true;
  }

  return false;
};

// --- Lifecycle hooks ---
appVersion();
</script>

<style lang="scss">
.v-navigation-drawer__content::-webkit-scrollbar {
  width: 0px;
}

%nav-font {
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.4px;
}

#layout-sidebar-subheader {
  font-size: 11px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: $blue-grey-dark;
}

#nav-container {
  padding-top: 0.2rem;
  padding-left: 0;

  .menu_title_text,
  .menu_subtitle_text {
    @extend %nav-font;
    color: white;

    .submenu_dot {
      vertical-align: middle;
      background: $blue-grey-dark;
      margin-right: 13px;
      width: '4px';
      height: '4px';
    }
  }
}

#version-text {
  @extend %nav-font;
  color: $blue-grey-dark;
  padding-left: 1rem;
}
</style>
